import { Button } from "@material-ui/core";
import React from "react";
import useStyles from "./style";

const CustomButton = (props) => {
  const { button } = useStyles();
  return (
    <Button
      className={[button, props.className]}
      // onClick={() => {
      //   if (window.ReactNativeWebView) {
      //     // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      //     console.log("Message Posted to webview");
      //     window.ReactNativeWebView.postMessage(
      //       "Sending data from WebView inside window.ReactNativeWebView.postMessage"
      //     );
      //   }
      //   props.onClick()
      // }}
      onClick={() => props?.onClick()}
      component={props?.component}
      to={props?.to}
      variant={props?.variant}
      startIcon={props?.startIcon}
      endIcon={props?.endIcon}
      disabled={props?.isDisabled}
    >
      {props.title}
      {props.children}
    </Button>
  );
};

export default CustomButton;
