import React, { useState } from "react";
import { useField, Field, ErrorMessage } from "formik";
import "./style.scss";
const GenricDropDown = ({ ...props }) => {
  const [meta] = useField(props);

  const { label, name, dataSet, ...rest } = props;

  const myHandleChange = (e) => {
    props.onChange(e);
    //props.handleValue(e.target.va)
    // props.onChange( e.target.value );
  };

  return (
    <>
      <span className="inputStyle">
        <div className="CSelect">
          {props.labelName && (
            <label for={name} className="lbl-bld">
              {props.labelName}
            </label>
          )}
          <Field
            as="select"
            id={name}
            name={name}
            {...rest}
            className="custom-select custom-select-lg"
          >
            <option value={""}>Seleccione</option>
            {dataSet &&
              dataSet.map((option) => {
                return (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                );
              })}
          </Field>
        </div>
        <ErrorMessage
          name={name}
          render={(msg) => <div className="error">{msg}</div>}
        />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </span>
    </>
  );
};
export default GenricDropDown;
