import React, { useContext } from "react";
import { useHistory } from "react-router";
import {
  customerSupport,
  helpLineNum,
  orderTracking,
  userEmail,
  whyMyParts,
} from "../../common/constants";
import "./TopBar.scss";
import { path } from "../../common/routes-names";
import { Link } from "react-router-dom";
import {
  mainLogo,
  searchIcon,
  userIcon,
  favIcon,
  arrowDown,
} from "../../common/Images";
import ApplicationContext from "../../context-api/context";
import { contactPixel } from "../../utils/fbPixelEvent";

function TopBar(props) {
  const history = useHistory();
  const { isSticky } = useContext(ApplicationContext);

  return (
    <>
      <section className="topBar">
        {!isSticky && (
          <div className={props.logoBig ? "bigLogo" : "smLogo"}>
            <Link to={path.home} className="topBar-logoWrapper">
              <img src={mainLogo} />
              {/* {Icon ? (
                  <>
                    <img src={mainLogo} />
                  </>
                ) : ( 
                  <>
                    <Images src={mainLogo} skeltonStyle="img-fluid mispartes" />
                  </>
                )} */}
            </Link>
          </div>
        )}
        <div className="inner">
          <ul>
            <a
              onClick={() => {
                history.push(path.aboutUs);
              }}
            >
              <li>{whyMyParts}</li>
            </a>
            <li>{orderTracking}</li>
            <li>
              <a
                href="mailto:atencion@okautopartes.com"
                className="userEmail"
                onClick={() => {
                  contactPixel();
                }}
              >
                {userEmail}
              </a>
            </li>
            <li
              onClick={() => {
                contactPixel();
              }}
            >
              {helpLineNum}
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}
export default TopBar;
