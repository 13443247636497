import React, { useState, useEffect } from "react";
import * as service from "../../common/services";
const useForm = (close) => {
  const [show, setShow] = useState(false);
  const [record, setRecord] = useState(service.getAllData());
  const [onSelect, setOnSelect] = useState(null);
  const addAddress = (values) => {
    service.insertData(values);
    setRecord(service.getAllData());
  };
  const handleSubmit = () => {
    setShow(false);
    close();
  };

  return {
    record,
    setRecord,
    show,
    setShow,
    onSelect,
    setOnSelect,
    addAddress,
    handleSubmit,
  };
};

export default useForm;
