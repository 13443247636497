import React from 'react'
import { aboutus_bg, aboutus_sec1, aboutus_sec4,aboutus_sec3 } from '../../common/Images'
import './style.scss'
export default function Content() {
  return (
    <div className='about-us'>
        <div className='about-bg'>
            <h1 className='centered'>{`Quienes Somos?`}</h1>
            <img src={aboutus_bg} alt="icon" className='bg-img'/>
            <img src={aboutus_sec1} alt="icon" className='bottom-left'/>
        </div>
       
        <div className='about-sec1'>
            <div className='outer-sec1'>
            <p >{`Okautopartes.com es el Marketplace especialista en autopartes mas completo y grande de México, poniendo a tu dispociòn mas 100,000 autopartes, de proveeduría nacional y extranjera, solucionamos el abastecimiento de autopartes a precios increíbles y tiempos de entrega récord, siempre priorizando el que tu vehículo se mantenga en la carretera.`}</p>
            <img src={aboutus_sec4} alt="icon" className='b-right'/>

            </div>
        </div>
        <div className='about-sec2'>
        <img src={aboutus_sec3} alt="icon" className='b-left'/>
        <div className='outer-sec2'>
            <p>{`Nacimos como mecánicos y talleres, nos complementamos con tiendas de refacciones y evolucionamos al ecommerce, por lo que entendemos las necesidades particulares de mecánicos, talleres, refaccionarias y dueños de vehículos. Además de nuestro ADN mecánico, nos hemos rodeado de las mejores marcas y asociaciones de autopartes, creando así el equipo con mayor experiencia en el ecommerce de autopartes.`}</p>
        </div>
        </div>
       
    </div>
  )
}
