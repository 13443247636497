import React from "react";
import "./style.scss";
import { useHistory } from "react-router";
import { shopByBrand } from "../../common/constants";
import Carousel from "react-elastic-carousel";
import { Skeleton } from "@material-ui/lab";
import Images from "../imgLoader";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 440, itemsToShow: 2 },
  { width: 550, itemsToShow: 3 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 6 },
];
const BrandSlider = (props) => {
  const history = useHistory();
  return (
    <>
      <div className="container add">
        <div className="brandSlider">
          <h1>{shopByBrand}</h1>
          {props.array.length != 0 ? (
            <Carousel enableAutoPlay={true} breakPoints={breakPoints}>
              {props.array.map((i, index) => {
                return (
                  <div className="item" key={index}>
                    <div
                      className="brandHolder"
                      onClick={() => {
                        if (i?.productType) {
                          history.push({
                            pathname: `/${i?.productType}`,
                            search: `?marca=${i?.name}`,
                          });
                        }
                      }}
                    >
                      <Images src={i.image} height="160px" width={"220px"} />
                    </div>
                  </div>
                );
              })}
            </Carousel>
          ) : (
            <Carousel breakPoints={breakPoints}>
              {[1, 2, 3, 4, 5, 6, 7, 8].map((i, index) => {
                return (
                  <div key={index}>
                    <div className="brandSkelton">
                      <Skeleton
                        animation="wave"
                        height={268}
                        className="skeltonImgs"
                        style={{ marginBottom: 25 }}
                      />
                    </div>
                  </div>
                );
              })}
            </Carousel>
          )}
        </div>
      </div>
    </>
  );
};
export default BrandSlider;
