import React from "react";
import { Route, Redirect } from "react-router-dom";
import { path } from "../common/routes-names";
const PublicRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn === false ? (
            <Component {...props} />
          ) : (
            <Redirect to={path.home} />
          )
        }
      />
    </>
  );
};

export default PublicRoute;
