import React, { useState, useContext } from "react";
import "./style.scss";
import { bannerImage } from "../../common/Images";
import CloseButton from "../../Component/controls/closeButton";
import BannerModal from "./pBannerModal";
import LazyBackground from "../../Component/imgLoader/lazyBackground";
import { useDispatch, useSelector } from "react-redux";
import { closeVehicle, openVehicle } from "../../Redux/reducers/alertSlice";
import { selectedVehical } from "../../Redux/reducers/vehicleSlice";
import { changeVehicle } from "../../common/constants";
import useForm from "../../Component/SelectVehicle/useForm";
import { search } from "./../../common/constants";
import ApplicationContext from "../../context-api/context";

const ProBanner = (props) => {
  // const openPopup = useSelector((state) => state.alert.openPopup);
  const { openVehicle, setOpenVehicle, setSearchedVehical } =
    useContext(ApplicationContext);
  const { setIsData } = useForm();
  const dispatch = useDispatch();
  return (
    <>
      <div className="proBanner">
        <LazyBackground
          src={bannerImage}
          circularStyle="heightStyle"
          className="imgHolder "
          skeltonStyle="d-block w-100 sekeltonStyles"
        >
          <h2 className="proInfo">
            {props.proName}
            <a
              className="proSelectLink"
              onClick={() => {
                if (props.proLink == changeVehicle) {
                  let searchVehicaleList = JSON.parse(
                    localStorage.getItem("vehicle")
                  );
                  const result =
                    searchVehicaleList &&
                    searchVehicaleList.filter(
                      (item) => item?.make?.value == props?.make
                    );
                  const [matchRecord] = result;

                  let obj = {
                    make: {
                      value: props?.make,
                      label: props?.make,
                      id: matchRecord?.make?.id,
                    },
                    model: {
                      value: props?.model,
                      label: props?.model,
                      id: matchRecord?.model?.id,
                    },
                    year: {
                      value: props?.year,
                      label: props?.year,
                      id: matchRecord?.year?.id,
                    },
                    id: matchRecord?.make?.id,
                  };
                  setSearchedVehical(obj);
                  // dispatch(selectedVehical(obj));
                }
                setOpenVehicle(true);
                // dispatch(openVehicle());
                setIsData(true);
              }}
            >
              {props?.proLink}
            </a>
          </h2>
        </LazyBackground>
      </div>
    </>
  );
};
export default ProBanner;
