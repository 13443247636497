import React from "react";
import {Dialog} from "@material-ui/core";
import {  styled } from "@material-ui/core/styles";

export default function BannerModal(props) {
  const { children, openPopup, setOpenPopup} = props;
 
  const BannerModal = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        padding: theme.spacing(0),
        backgroundColor:"transparent"
      },
    '& .MuDialogContent-root': {
      padding: theme.spacing(0),
      border: "none"
    },
    '& .MuiDialogContent-dividers ': {
            padding:theme.spacing(0),
            border: "none"
        },
    '& .MuDialogActions-root': {
      padding: theme.spacing(0),
    },
    "& .MuiDialog-paperWidthSm":{
        minWidth: "sm",
        maxWiidth:"md"
    },
    "& .MuiDialog-scrollPaper ": {
        alignItems: 'center'
      },
      "& .MuiPaper-elevation24": {
         boxShadow: "none"
    }
  }));
  return (
    <>
      <BannerModal
        open={openPopup}
        fullWidth
        onBackdropClick={setOpenPopup}
        maxWidth={"lg"}
        fullWidth={true}>
        {children}
      </BannerModal>
    </>
  );
}