import * as React from "react";
import PropTypes from "prop-types";
import Slider from "@material-ui/core/Slider";
import { styled } from "@material-ui/core";
import "./style.scss";
import clsx from "clsx";
import { colors } from "../../common/theme";
const PriceSlider = styled(Slider)(({ theme }) => ({
  root: {
    width: 320,
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    top: -6,
    backgroundColor: "unset",
    color: colors.baseForground,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: colors.baseForground,
    },
    "& > *": {
      transform: "rotate(0deg)",
      marginTop: "1.7rem",
      marginLeft: "-0.5rem",
    },
  },
}));
function AirbnbThumbComponent(props) {
  const { children, className, ...other } = props;
  const extraClassName =
    other["data-index"] === 0 ? "first-thumb" : "second-thumb";
  return (
    <span {...other} className={clsx(className, extraClassName)}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </span>
  );
}
AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
};
export default function RangeSlider({ value, onChange, maxValue }) {
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="mb-2 mt-4">
      <PriceSlider
        value={value}
        onChange={onChange}
        aria-labelledby="custom thumb label"
        max={maxValue}
        ThumbComponent={AirbnbThumbComponent}
        valueLabelFormat={(value) => (
          <div className="priceSlider">${numberWithCommas(value)}</div>
        )}
        valueLabelDisplay="on"
      />
    </div>
  );
}
