import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Checkbox } from "@material-ui/core";
const SkeltonLoader = ({
  subcategory,
  category,
  brand,
  table,
  imgeGallery,
}) => {
  return (
    <>
      {subcategory &&
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
          return (
            <>
              <div
                key={i}
                style={{
                  textDecoration: "none",
                  listStyle: "none",
                  marginBottom: ".2rem",
                }}
              >
                <div key={i} className="Links pl-0 first">
                  <Skeleton variant="text" width="80%" height="15px" />
                </div>
              </div>
            </>
          );
        })}
      {table &&
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
          return (
            <>
              <div
                key={i}
                style={{
                  textDecoration: "none",
                  listStyle: "none",
                  marginBottom: ".2rem",
                }}
              >
                <div key={i} className="Links pl-0 first">
                  <Skeleton variant="text" width="100%" height="35px" />
                </div>
              </div>
            </>
          );
        })}
      {imgeGallery && (
        //    <>
        //    <div
        //      style={{ display: "flex", width: "100%" }}
        //    >
        //      <div style={{ width: "29%" }}>
        //        <Skeleton
        //          style={{ marginBottom: "1rem" }}
        //          variant="rectangular"
        //          width="90%"
        //          height="4rem"
        //        />
        //        <Skeleton
        //          style={{ marginBottom: "1rem" }}
        //          variant="rectangular"
        //          width="90%"
        //          height="4rem"
        //        />
        //        <Skeleton variant="rectangular" width="90%" height="4rem" />
        //      </div>
        //      <div
        //        style={{ width: "75%", marginBottom: ".2rem" }}
        //      >
        //        <Skeleton variant="rectangular" width="100%" height="15rem" />
        //      </div>
        //    </div>
        //  </>
        <>
          <div
            className="sekeltonList"
            style={{ display: "flex", width: "100%" }}
          >
            <div
              className="sekeltontop"
              style={{ width: "75%", marginBottom: ".2rem" }}
            >
              <Skeleton variant="rectangular" width="100%" height="15rem" />
            </div>
            <div className="sekeltonBottom" style={{ width: "29%" }}>
              <Skeleton
                style={{ marginBottom: "1rem" }}
                variant="rectangular"
                width="90%"
                height="4rem"
                className="sekeltonBottom-items"
              />
              <Skeleton
                style={{ marginBottom: "1rem" }}
                variant="rectangular"
                width="90%"
                height="4rem"
                className="sekeltonBottom-items"
              />
              <Skeleton
                variant="rectangular"
                width="90%"
                height="4rem"
                className="sekeltonBottom-items"
              />
            </div>
          </div>
        </>
      )}
      {category && <Skeleton variant="text" width="50%" height="15px" />}
      {brand && (
        <>
          <Skeleton variant="text" width="95%" height="50px" />

          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
            return (
              <>
                <div
                  key={i}
                  style={{
                    textDecoration: "none",
                    listStyle: "none",
                  }}
                  className="listItem"
                >
                  <Checkbox />
                  <Skeleton variant="text" width="60%" height="15px" />
                </div>
              </>
            );
          })}
        </>
      )}
    </>
  );
};

export default SkeltonLoader;
