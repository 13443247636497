import React, { useState } from "react";
import "../../common/themeColor.scss";
import "./style.scss";
import {
  confirmed,
  delivered,
  orderNum,
  orderPlaced,
  ordrBy,
  pending,
  shipped,
  discount,
  orderItems,
  coln,
  toPay,
  shippingCost,
  subTotal,
  Total,
  orderDetailFactura,
  orderDetail,
  viewInvoice,
  statusEnums,
  invoiceOrder,
} from "../../common/constants.js";
import { Collapse, IconButton } from "@material-ui/core";
import { ReactComponent as ExpandOpen } from "../../images/expandOpen.svg";
import { ReactComponent as ExpandClose } from "../../images/expandClose.svg";
import { CustomButton, CustomModal, InvoicePopUpModal } from "..";
import { colors } from "../../common/theme";
import { Link } from "react-router-dom";
import SkeletonProductBox from "./../productBox/skeltonPBox";
import { GetAccountOrderItems } from "../../utils/api";
import ProductBox from "../productBox/productBox";
import CustomizedDialogs from "../customModal/Modal";

const OrderBox = (props) => {
  const { statusID, saleID, onViewItem } = props;
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [orderItemsResponse, setOrderItemsResponse] = useState([]);
  const [isProduct, setIsProduct] = useState(true);
  const [closePop, setClosePop] = useState(false);
  const [saleId, setSaleId] = useState("");
  const [show, setShow] = useState(false);

  const getAccountOrderItems = async (saleId, statusId) => {
    setSaleId(statusId.toString());
    let response = await GetAccountOrderItems(saleId);
    let result = response?.data?.content;
    setOrderItemsResponse(result);
    setIsProduct(false);
  };
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const closeModal = () => {
    setClosePop(false);
    setShow(false);
  };

  return (
    <div className="orderScreen">
      <div>
        <div className="root">
          <div className="expand_Icon">
            <IconButton
              onClick={() => {
                !isCardOpen && getAccountOrderItems(saleID, statusID);
                setIsCardOpen(!isCardOpen);
              }}
            >
              {isCardOpen ? <ExpandOpen /> : <ExpandClose />}
            </IconButton>
          </div>
          <div className="content_head">
            {props.shift && (
              <>
                <div className="row1 pt-3 pb-3">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>{orderPlaced}</span> {props.orderDate}
                  </div>
                  <div className="buttonDivs">
                    <div className="d-flex">
                      <span>{orderNum}</span> {props.orderNo}
                    </div>
                    <div className="invoiceBtn">
                      <CustomButton
                        title={invoiceOrder}
                        className="GuidedbuttonSecondary"
                        onClick={() => {
                          setClosePop(true);
                        }}
                      />
                      {/* <CustomButton
                        title={orderDetail}
                        className="Guidedbutton"
                        onClick={props.onOrderDetail}
                      /> */}
                      {/* Hint : for Temporary basis its commented Pagar Button*/}
                      {/* <CustomButton
                        title={viewInvoice}
                        className="GuidedbuttonSecondary"
                        onClick={props.onViewInvoice}
                      /> */}
                    </div>
                  </div>
                </div>
              </>
            )}
            {props.placed && (
              <>
                <div className="orderHead">
                  <p>
                    <span>
                      {orderNum}
                      {coln}
                    </span>
                    {props.orderNo}
                  </p>
                  <div className="buttonDivs">
                    {statusID === statusEnums.pending && (
                      <>
                        <div
                          className="statusBox"
                          onClick={props.onClick1}
                          style={{ backgroundColor: colors.yellow }}
                        >
                          {" "}
                          {pending}
                        </div>
                      </>
                    )}
                    {statusID === statusEnums.confirmed && (
                      <>
                        <div
                          className="statusBox"
                          onClick={props.onClick}
                          style={{ backgroundColor: colors.primaryColor }}
                        >
                          {confirmed}
                        </div>
                      </>
                    )}
                    {statusID === statusEnums.shipping && (
                      <>
                        <div
                          className="statusBox"
                          style={{ backgroundColor: colors.skyBlue }}
                        >
                          {" "}
                          {shipped}
                        </div>
                      </>
                    )}
                    {statusID === statusEnums.delivered && (
                      <>
                        <div
                          className="statusBox"
                          onClick={props.onClick}
                          style={{ backgroundColor: colors.green }}
                        >
                          {" "}
                          {delivered}
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="row1">
                  <div>
                    <div>
                      <span>
                        {ordrBy}
                        {coln}
                      </span>
                      <Link>{props.orderBy}</Link>
                    </div>
                    <div>
                      <span>
                        {orderPlaced}
                        {coln}
                      </span>{" "}
                      {props.orderDate}
                    </div>
                  </div>
                  <>
                    <div className="invoiceBtn">
                      {statusID === statusEnums.pending && (
                        <>
                          <CustomButton
                            title={invoiceOrder}
                            className="GuidedbuttonSecondary"
                            onClick={() => {
                              setClosePop(true);
                            }}
                          />
                          {/* Hint : for Temporary basis its commented Pagar Button*/}
                          {/* <CustomButton
                            title={viewInvoice}
                            className="GuidedbuttonSecondary"
                            onClick={props.onViewInvoice}
                          /> */}
                        </>
                      )}
                      {(statusID === statusEnums.confirmed ||
                        statusID === statusEnums.shipping) && (
                        <>
                          <CustomButton
                            title={invoiceOrder}
                            className="GuidedbuttonSecondary"
                            onClick={() => {
                              setClosePop(true);
                            }}
                          />
                        </>
                      )}
                      {statusID === statusEnums.delivered && (
                        <>
                          <CustomButton
                            title={invoiceOrder}
                            className="GuidedbuttonSecondary"
                            onClick={() => {
                              setClosePop(true);
                            }}
                          />
                          {/* <CustomButton
                            title={orderDetailFactura}
                            className="GuidedbuttonSecondary"
                            onClick={props.onViewInvoice}
                          /> */}
                        </>
                      )}
                    </div>
                  </>
                  <div>
                    <div>
                      <span>{shippingCost}</span> $
                      {numberWithCommas(
                        parseFloat(props.orderTotal).toFixed(2)
                      )}
                    </div>
                    <div>
                      <span>{Total}</span>
                      {props.orderItems}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Collapse
          in={isCardOpen}
          classes={{
            wrapper: "wrapperCollapse",
          }}
        >
          {isProduct ? (
            <div style={{ padding: "0.5rem" }}>
              <SkeletonProductBox />
              <SkeletonProductBox />
            </div>
          ) : (
            <div className="productDiv">
              <div className="d-flex">
                <div className="expand_div"></div>
                <div className="content_head">
                  {props.placed && <p className="orderText">{orderItems}</p>}
                  {props.shift && (
                    <>
                      <p className="orderText">{delivered} June 25,2021</p>
                      <div className="orderBy">
                        <div>
                          <span>
                            {ordrBy}
                            {coln}
                          </span>
                          {props.orderBy}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="content_Items">
                    {orderItemsResponse &&
                      orderItemsResponse.map((item) => {
                        return (
                          <ProductBox
                            statusId={saleId}
                            Name={item.productName}
                            itemId={item.partNo}
                            Img={item.image}
                            brandImg={item.brandImage}
                            quantity={item.quantity}
                            brandName={item.brand}
                            itemPrice={item.unitPrice}
                            totalItemPrice={item.total}
                            param={item.encryptedProductID}
                            pathName={item.url}
                            onViewItem={() =>
                              onViewItem(
                                item.quantity,
                                item.itemID,
                                item.productID,
                                item.productName
                              )
                            }
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Collapse>
      </div>
      <CustomizedDialogs
        onClose={() => {
          closeModal();
        }}
        open={closePop}
      >
        <InvoicePopUpModal
          close={closePop}
          setClosePop={setClosePop}
          billAddress
          show={show}
          setShow={setShow}
          onInvoiceSelected={(e) => {
            console.log("Invoice ID", e);
          }}
        />
      </CustomizedDialogs>
    </div>
  );
};

export default OrderBox;
{
  /* <CustomizedDialogs
// onClose={() => {
//   closeModal();
//   myRef.current.childMethod();
// }}
open={true}
>
{/* After opening the modals by clicking on (Edit or Add Button) Following component is visible*/
}
<CustomModal
// close={() => closeModal()}
// billAddress={billingPop}
// payment={paymentPop}
// shipping={true}
// fetchDataToggle={fetchDataToggle}
// state={state}
// setState={setState}
// setClosePop={setClosePop}
// openPop={openPop}
// show={show}
// setShow={setShow}
// ref={myRef}
/>;
//</CustomizedDialogs> */}
