import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addBadge,
  addDefaultCategories,
  newRange,
  priceField,
  removeBadge,
} from "../../Redux/reducers/filterSlice";
import { price } from "../../common/constants";
import { getHomeResponse } from "../../utils/api";
import { alertMsg, openAlert } from "../../Redux/reducers/alertSlice";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import ApplicationContext from "../../context-api/context";

const useForm = ({ brandList }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const default_Category = useSelector((state) => state.filter.defaultList);
  const selected_Link = useSelector((state) => state.filter.linkState);
  const _price = useSelector((state) => state.filter.price);
  const { priceSlider, setPriceSlider, setOpenAlert, setAlertMsg } =
    useContext(ApplicationContext);

  const [categoryType, setCategoryType] = useState("");
  const [slide, setSlider] = useState("");
  const [error, setError] = useState(false);
  const [value, setValue] = useState([_price.min, _price.max]);
  const queryStrings = queryString.parse(useLocation().search);

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }
  const minPriceParam = useQuery().get("minPrice");
  const maxPriceParam = useQuery().get("maxPrice");
  const array = {
    min: minPriceParam ? minPriceParam : _price.min,
    max: maxPriceParam ? maxPriceParam : _price.max,
    id: new Date().getTime().toString(),
    type: price,
  };

  const [maxPriceNew, setmaxPriceNew] = useState(0);

  useEffect(() => {
    selected_Link.map((item) => setCategoryType(decodeURI(item.type)));
    findMaxPrice();
  }, [selected_Link, brandList, maxPriceNew]);

  const findMaxPrice = () => {
    if (brandList.length != 0) {
      const price = Math.max(...brandList.map((item) => item.maxPrice));
      if (price > 0) {
        return setmaxPriceNew(5000);
      } else {
        return setmaxPriceNew(5000);
      }
    } else return setmaxPriceNew(5000);
  };
  //if category lenth 0 it shoes defauld categories
  useEffect(() => {
    getDefaultCategory();
  }, []);

  //return max price from API
  const maxPriceReturn = () => {
    if (brandList.length != 0) {
      const price = Math.max(...brandList.map((item) => item.maxPrice));
      return price;
    } else {
      return _price.max;
    }
  };
  useEffect(() => {
    let price = maxPriceReturn();
    //update the redux to keep the price same in slider and range box
    dispatch(
      priceField({
        target: {
          name: "max",
          value: maxPriceParam === null ? price : maxPriceParam,
        },
      })
    );
    dispatch(
      priceField({
        target: {
          name: "min",
          value: minPriceParam === null ? 0 : minPriceParam,
        },
      })
    );
  }, [brandList]);

  //price handeling
  useEffect(() => {
    setPriceSlider([
      minPriceParam ? minPriceParam : priceSlider[0],
      maxPriceParam ? maxPriceParam : priceSlider[1],
    ]);

    // let price = maxPriceReturn();
    // setValue([
    //   minPriceParam ? minPriceParam : _price.min,
    //   maxPriceParam ? maxPriceParam : price,
    // ]);
    setTimeout(() => {
      minPriceParam != null && dispatch(addBadge(array));
    }, 2000);

    if (minPriceParam && maxPriceParam) {
      const array = {
        min: minPriceParam ? minPriceParam : priceSlider[0],
        max: maxPriceParam ? maxPriceParam : priceSlider[1],
        id: new Date().getTime().toString(),
        type: price,
      };

      setTimeout(() => {
        dispatch(addBadge(array));
      }, 2000);
    }
  }, []);

  async function getDefaultCategory() {
    await getHomeResponse()
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            addDefaultCategories(response.data.content.filterCategories)
          );
        } else {
          setOpenAlert(true);
          setAlertMsg(response.ExceptionMessage);

          // dispatch(alertMsg(response.ExceptionMessage));
          // dispatch(openAlert());
        }
      })
      .catch((error) => {
        setOpenAlert(true);
        setAlertMsg(error);
        // dispatch(alertMsg(error));
        // dispatch(openAlert());
      });
  }
  const handleChange = (event, newValue) => {
    dispatch(newRange(newValue));
    setValue(newValue);
  };
  const handleSliderRange = (e) => {
    dispatch(priceField(e));
    setSlider(e.target.value);
  };

  const resetQuery = (item) => {
    if (item == "calificaciones") {
      const newQueryParam = {
        ...queryStrings,
        rating: [],
      };
      return history.push({
        search: queryString.stringify(newQueryParam),
      });
    } else if (item == "Marca") {
      const newQueryParam = {
        ...queryStrings,
        marca: [],
      };
      return history.push({
        search: queryString.stringify(newQueryParam),
      });
    } else if (item == "All") {
      return history.push({
        search: "",
      });
    } else {
      const newQueryParam = {
        ...queryStrings,
        [item]: [],
      };
      return history.push({
        search: queryString.stringify(newQueryParam),
      });
    }
  };

  const handlePrice = () => {
    // price will submit here
    let min = priceSlider[0];
    let max = priceSlider[1];
    if (max > 5000) {
      setError(true);
    } else if ((min < max && max > min) === true) {
      setError(false);
      const array = {
        min: min,
        max: max,
        id: new Date().getTime().toString(),
        type: price,
      };
      dispatch(addBadge(array));
      const newQueryParam = {
        ...queryStrings,
        minPrice: min,
        maxPrice: max,
      };
      history.push({ search: queryString.stringify(newQueryParam) });
    } else if (min === "" || max === "") {
      setError(true);
    } else {
      setError(true);
    }
  };

  return {
    slide,
    setSlider,
    error,
    setError,
    priceSlider,
    _price,
    value,
    setValue,
    handlePrice,
    handleSliderRange,
    handleChange,
    default_Category,
    selected_Link,
    categoryType,
    setCategoryType,
    history,
    maxPriceNew,
    setmaxPriceNew,
    resetQuery,
  };
};

export default useForm;
