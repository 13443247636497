import React from "react";
import { Helmet } from "react-helmet";
import { mispartes } from "../../common/constants";
import "./style.scss";
const MetaTitle = (props) => {
  return (
    <>
      <Helmet>
        <title className="title">
          {props.title ? `${props.title}` : mispartes}
        </title>
        <meta name="description" content={props.title} />
      </Helmet>
    </>
  );
};

export default MetaTitle;
