import React, { useRef } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";
import ImageGallery from "react-image-gallery";
import "./style.scss";
import "../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
import AttributeTable, {
  renderAtterDetail,
  renderNoneDetail,
} from "../tables/attributeTable";
import CompatibleTable, { renderProDetail } from "../tables/compatTable";
import { colors } from "../../common/theme";
import SkeltonLoader from "../../Sections/side-bar/sideBarSkelton";
import useForm from "./useForm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="tbl-adjust"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
export default function AttributeTab({ tabIndex, id }) {
  const {
    theme,
    data,
    loader,
    value,
    handleChange,
    handleChangeIndex,
    imageList,
  } = useForm({ tabIndex, id });
  const imgRef = useRef();

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          TabIndicatorProps={{
            style: {
              height: "4px",
              top: "0px",
              backgroundColor: colors.secondaryColor,
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            },
          }}
          style={{
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          <Tab label="Images" {...a11yProps(0)} className="borderRight" />
          <Tab
            label="Caracteristicas"
            {...a11yProps(1)}
            className="borderRight"
          />
          <Tab label="Vehiculos Compatibles" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ImageGallery
            items={imageList}
            autoPlay="true"
            lazyload={true}
            sizes="30"
            ref={imgRef}
            onClick={() => {
              imgRef.current.toggleFullScreen();
            }}
          ></ImageGallery>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <AttributeTable>
            {loader ? (
              <>
                {[1, 2].map((i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {" "}
                        <SkeltonLoader table />
                      </td>
                      <td>
                        {" "}
                        <SkeltonLoader table />
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : data.attribute.length != 0 ? (
              data.attribute.map(renderAtterDetail)
            ) : (
              [1, 2].map(renderNoneDetail)
            )}
          </AttributeTable>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <CompatibleTable>
            {loader ? (
              <>
                {[1, 2].map((i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {" "}
                        <SkeltonLoader table />
                      </td>
                      <td>
                        {" "}
                        <SkeltonLoader table />
                      </td>
                      <td>
                        {" "}
                        <SkeltonLoader table />
                      </td>
                      <td>
                        {" "}
                        <SkeltonLoader table />
                      </td>
                      <td>
                        {" "}
                        <SkeltonLoader table />
                      </td>
                      <td>
                        {" "}
                        <SkeltonLoader table />
                      </td>
                      <td>
                        {" "}
                        <SkeltonLoader table />
                      </td>
                      <td>
                        {" "}
                        <SkeltonLoader table />
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              data.compatibity.map(renderProDetail)
            )}
          </CompatibleTable>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
