import * as React from "react";
import { InputLabel, MenuItem, FormControl, Select } from "@material-ui/core";
import MuiMenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { colors } from "../../../common/theme";

export default function ControlledSelect(props) {
  const MenuItem = withStyles({
    root: {
      justifyContent: "left",
      backgroundColor: colors.gray,
      borderBottom: `1px solid ${colors.borderColor}`,
    },
  })(MuiMenuItem);

  return (
    <div>
      <FormControl fullWidth variant="standard">
        <InputLabel id="demo-simple-select">{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.value}
          label={props.label}
          onChange={props.onChange}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            marginTop: "1.2rem",
            MenuListProps: {
              disablePadding: true,
            },
          }}
        >
          {props.options.map((i) => {
            return (
              <MenuItem onClick={props.onClose} key={i.value} value={i.value}>
                {i.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
