import React, { useState, useEffect, useContext } from "react";
import {
  addToCart,
  seeAllFav,
  buyTo,
  favList,
  addFav,
  saveAndOrganize,
  selectFavoriteProduct,
} from "../../../common/constants";
import { CustomButton } from "../..";
import { useHistory } from "react-router";
import { path } from "../../../common/routes-names";
import { QuantityButton } from "../../index";
import { ReactComponent as FavouriteIcon } from "../../../images/favourite.svg";
import { getFavouriteProducts } from "../../../utils/api";
import ApplicationContext from "../../../context-api/context";
import "../Header.scss";

const FavMenu = ({ isLogin, onBuyTo, onAddtoCart, index, favoritedata }) => {
  let history = useHistory();
  const {
    favoriteData,
    setData,
    favoriteProduct,
    setFavoriteData,
    cartList,
    isLoggedIn,
  } = useContext(ApplicationContext);
  const onQuantityChange = (value, Id) => {
    let favData = favoriteData;
    favData.forEach((element) => {
      if (element.productID === Id) {
        element.quantity = value;
      }
    });

    setFavoriteData(favData);
  };
  const increaseQuantity = (Id) => {
    let favData = favoriteData;
    favData.forEach((element) => {
      if (element.productID === Id) {
        element.quantity = element.quantity + 1;
      }
    });
    setFavoriteData(favData);
  };
  const decreaseQuantity = (Id) => {
    let favData = favoriteData;
    favData.forEach((element) => {
      if (element.productID === Id) {
        element.quantity = element.quantity - 1;
      }
    });
    setFavoriteData(favData);
  };
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <>
      {isLogin ? (
        <div className="userPopUp">
          <div className="inner">
            {favoriteData?.length === 0 && (
              <p
                style={{
                  textAlign: "center",
                  fontSize: 15,
                  marginBottom: 0,
                }}
              >
                {selectFavoriteProduct}
              </p>
            )}
            {favoriteData?.slice(0, 3).map((item, index) => {
              return (
                <div
                  onClick={() => {
                    history.push(`/${item.url}?id=${item.encryptedProductID}`);
                  }}
                  className="productWrapper"
                  key={item.productID}
                >
                  <div
                    className="productImg"
                    style={{ backgroundImage: `url(${item.image})` }}
                  ></div>
                  <div className="proDescription">
                    <p className="description">
                      {item.productName || item.name}
                    </p>
                    <div className="proPriceArea">
                      <div className="price">
                        <span className="disPrice">
                          ${numberWithCommas(parseFloat(item.price).toFixed(2))}
                        </span>
                        {/* <span className="orignalPrice">${item.basePrice}</span> */}
                      </div>
                    </div>
                    {/* <div className="links">
                      <QuantityButton
                        onQuantityDecreased={() => {
                          decreaseQuantity(item.productID);
                        }}
                        onQuantityIncreased={() => {
                          increaseQuantity(item.productID);
                        }}
                        isDisabled
                        onQuantityChange={onQuantityChange}
                        quantityChanged={() => {}}
                        quantity={item.quantity}
                        productId={item.productID}
                        productObj={item}
                        favrtMenuView={true}
                      />
                    </div> */}
                  </div>
                </div>
              );
            })}
          </div>
          <button
            className="favBtn bgWhite"
            onClick={() => history.push(path.favourite)}
          >
            {seeAllFav}
          </button>
        </div>
      ) : (
        <div className="userPopUp">
          <div className="inner">
            {isLoggedIn && (
              <CustomButton title={addFav} className="btnSignin" />
            )}
            <p className="nonLoginText border-bottom">
              <span>
                <FavouriteIcon />
              </span>
              <span className="findText">{saveAndOrganize}</span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default FavMenu;
