import React, { useEffect, useState, useContext } from "react";
import { QuantityButton } from "..";
import ApplicationContext from "../../context-api/context";
import Images from "../imgLoader";
import "./style.scss";
import { useHistory } from "react-router";

export default function ItemOnCart({
  productObject,
  url,
  desc,
  price,
  qty,
  productId,
  updateQty,
  pathName,
  param,
  closeBtn,
}) {
  const [quantity, setQuantity] = useState(qty);
  let history = useHistory();
  useEffect(() => {
    setQuantity(qty);
  }, [qty]);

  useEffect(() => {
    updateQty(quantity);
  }, [quantity]);
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="productWrapper" tabIndex="-1">
      <div
        className="productImg"
        style={{ backgroundImage: `url(${url})` }}
        onClick={() => {
          history.push(`/${pathName}?id=${param}`);
          closeBtn();
        }}
      ></div>
      <div className="proDescription fav">
        <p
          onClick={() => {
            history.push(`/${pathName}?id=${param}`);
            closeBtn();
          }}
          className="description"
        >
          {desc}
        </p>
        <div className="proPriceArea">
          <div className="price">
            <span className="disPrice">
              {quantity}x {price} =
            </span>
            <span className="orignalPrice">
              $
              {numberWithCommas(
                parseFloat(
                  parseInt(quantity) * parseFloat(price.replace("$", ""))
                ).toFixed(2)
              )}
            </span>
          </div>
          <div className="proQuantityBtn">
            <QuantityButton
              productObject={productObject}
              disableTrash
              onQuantityDecreased={() => {
                setQuantity(parseInt(qty) - 1);
                // setQuantity(parseInt(quantity) - 1);
              }}
              onQuantityIncreased={() => {
                setQuantity(parseInt(qty) + 1);
                // setQuantity(parseInt(quantity) + 1);
              }}
              quantityChanged={setQuantity}
              // quantity={quantity}
              quantity={qty}
              productId={productId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
