import React, { useState, useContext } from "react";
import { components } from "react-select";
import MySelect from "./mySelect";
import { useDispatch, useSelector } from "react-redux";
import {
  resetFilter,
  setCheckBox,
  setRatingBox,
} from "../../../Redux/reducers/filterSlice";
import "./style.scss";
import { Checkbox } from "@material-ui/core";
import { ratingList, search } from "../../../common/constants";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import {
  removeBrandItem,
  removeDynamicBrandItem,
} from "../../../common/queryString";
import ApplicationContext from "../../../context-api/context";

export default function MultiSelect({ options, type }) {
  const {
    makersList,
    setOpenSearchBar,
    selectSearchProduct,
    setSelectSearchProduct,
  } = useContext(ApplicationContext);
  const dispatch = useDispatch();
  const _checkedState = useSelector((state) => state.filter.checkedState);
  const _dynamicFilter = useSelector((state) => state.filter.dynamicState);
  const selectProduct = selectSearchProduct;
  // const selectProduct = useSelector((state) => state.alert.productSelect);

  const history = useHistory();
  const queryStrings = queryString.parse(useLocation().search);
  const { marca, dynamic } = queryStrings;

  // React.useEffect(() => {
  //   // marca && marcaCheckAvailable();
  //   dynamicCheckAvailable();
  //   ratingCheck();
  //   console.log('Side bar checkBox triggers....');
  // }, []);

  React.useEffect(() => {
    dispatch(resetFilter("All"));
    console.log("Searcbar closed and trigger CheckBox...");
    dynamicCheckAvailable();
    ratingCheck();
  }, [selectProduct]);

  const ratingCheck = () => {
    const { calificaciones } = queryStrings;
    if (calificaciones != undefined) {
      let val = "";
      let myIndex = "";
      if (Array.isArray(calificaciones)) {
        val = calificaciones.map((i) => {
          return (myIndex = ratingList.findIndex((item) => item.value == i));
        });
        val.forEach((element) => {
          dispatch(setRatingBox(ratingList[element]));
        });
      } else {
        myIndex = ratingList.findIndex((item) => item.value == calificaciones);
        dispatch(setRatingBox(ratingList[myIndex]));
      }
    }
  };

  const dynamicCheckAvailable = () => {
    const params = new URLSearchParams(window.location.search);
    let allParams = [],
      parameter = [];
    for (var key of params.keys()) {
      let index = allParams.findIndex((e) => e === key);
      if (index == -1) {
        if (key != "minPrice" && key != "maxPrice" && key != "calificaciones") {
          allParams.push(key);
          parameter.push({ key: key, value: params.getAll(key) });
        }
      }
    }
    if (parameter.length === 0) {
      console.log("No Parameter found!!");
      return dispatch(resetFilter("All"));
    }
    parameter.forEach((dynamicElement) => {
      dynamicCheckGenerator(dynamicElement);
      console.log("Dynmic elements", dynamicElement);
    });
  };

  const dynamicCheckGenerator = (dynamic) => {
    if (dynamic != undefined) {
      let val = "";
      if (Array.isArray(dynamic.value)) {
        if (dynamic.value.length) {
          val = dynamic.value.map((i) => {
            return {
              name: i,
              value: i,
              label: i,
              type: dynamic.key === "marca" ? "Marca" : dynamic.key,
              results: 33,
              maxPrice: 200,
            };
          });
          val.forEach((element) => {
            dispatch(setCheckBox(element));
          });
        }
      } else {
        val = {
          name: dynamic[0].value,
          value: dynamic[0].value,
          label: dynamic[0].value,
          type: dynamic.key === "marca" ? "Marca" : dynamic.key,
          results: 33,
          maxPrice: 200,
        };
        dispatch(setCheckBox(val));
      }
    }
  };
  // const marcaCheckAvailable = () => {
  //   if (marca != undefined) {
  //     console.log('MArca is Array', Array.isArray(marca));
  //     let val = '';
  //     if (Array.isArray(marca)) {
  //       val = marca.map((i) => {
  //         return {
  //           name: i,
  //           value: i,
  //           label: i,
  //           type: 'Marca',
  //           results: 33,
  //           maxPrice: 200,
  //         };
  //       });
  //       val.forEach((element) => {
  //         dispatch(setCheckBox(element));
  //       });
  //     } else {
  //       val = {
  //         name: marca,
  //         value: marca,
  //         label: marca,
  //         type: 'Marca',
  //         results: 33,
  //         maxPrice: 200,
  //       };
  //       dispatch(setCheckBox(val));
  //     }
  //     console.log('Value is =====>', val);
  //   }
  // };
  const handleChanges = (selected) => {
    dispatch(setCheckBox(selected.data));
    if (selected.data.type === "Marca") {
      brandParam(selected.isSelected, selected.value);
    } else {
      dynamicParam(selected, selected.value);
    }
  };

  const brandParam = (selected, value) => {
    const checkList = _checkedState.map((i) => {
      return i.name;
    });
    if (selected == false) {
      const newQueryParam = {
        ...queryStrings,
        marca: value,
      };
      if (!marca) {
        history.push({
          search: queryString.stringify(newQueryParam),
        });
      } else {
        checkList.push(value);
        const addList = checkList;
        const newQueryParam = {
          ...queryStrings,
          marca: addList,
        };
        history.push({
          search: queryString.stringify(newQueryParam),
        });
      }
    } else {
      let removeList = removeBrandItem(checkList, value);
      const newQueryParam = {
        ...queryStrings,
        marca: removeList,
      };
      return history.push({
        search: queryString.stringify(newQueryParam),
      });
    }
  };

  const dynamicParam = (selected, value) => {
    const checkList = _dynamicFilter.map((i) => {
      return { value: i.name, name: i.type };
    });
    if (selected.isSelected == false) {
      if (checkList.length) {
        let index = checkList.findIndex((e) => e.name === selected.data.type);
       
        if (index != -1) {
          //append element in the list
          checkList.push({ value: value, name: type });
          const addList = checkList;
          let tempValue = [];
          //array for removing type(Propery) of CheckBox
          addList.forEach((addListElement) => {
            if (addListElement.name === type) {
              tempValue.push(addListElement.value);
            }
          });
          const newQueryParam = {
            ...queryStrings,
            [type]: tempValue,
          };
          history.push({
            search: queryString.stringify(newQueryParam),
          });
        } else {
          const newQueryParam = {
            ...queryStrings,
            [type]: value,
          };
          history.push({
            search: queryString.stringify(newQueryParam),
          });
        }
      } else {
        const newQueryParam = {
          ...queryStrings,
          [type]: value,
        };
        history.push({
          search: queryString.stringify(newQueryParam),
        });
        return checkList.push({
          name: type,
          value: value,
        });
      }
      ////
    } else {
      //when user unchecked the checkbox
      let removeList = removeDynamicBrandItem(checkList, value);
      const addList = removeList;
      let afterRemovedList = [];
      //array for removing type(Propery) of CheckBox
      addList.forEach((addListElement) => {
        if (addListElement.name === type) {
          afterRemovedList.push(addListElement.value);
        }
      });
      const newQueryParam = {
        ...queryStrings,
        [type]: afterRemovedList,
      };
      return history.push({
        search: queryString.stringify(newQueryParam),
      });
    }
  };

  const optionList = options.map((i) => {
    return {
      id: new Date().getTime().toString(),
      name: i.name,
      value: i.name,
      label: i.name,
      type: type,
      results: i.results,
      maxPrice: i.maxPrice,
    };
  });

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <div
            onClick={(e) => {
              handleChanges(props);
            }}
            className="listItem"
          >
            <Checkbox
              name={props.name}
              value={props.value}
              checked={props.isSelected}
            />
            <label className="checkbox-label">{props.label}</label>
          </div>
        </components.Option>
      </div>
    );
  };

  return (
    <>
      <MySelect
        defaultValue={_checkedState}
        className="_multiSelect"
        options={optionList}
        isMulti
        autoFocus={false}
        controlShouldRenderValue={false}
        placeholder={search}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        allowSelectAll={false}
        menuIsOpen={true}
        value={type == "Marca" ? _checkedState : _dynamicFilter}
      />
    </>
  );
}
