import React, { useState, useEffect } from "react";
import { Grid, Container, CircularProgress } from "@material-ui/core";
import "./style.scss";
import {
  discoverTheParts,
  resetPass,
  send,
  yourFav,
  error,
  notAutherized,
} from "../../../common/constants";
import { Formik, Form } from "formik";
import { TextInput, CustomButton } from "../../../Component/index";
import {
  confirmPassword,
  NewPassword,
} from "../../../common/constant/formConstants";
import { resetValidation } from "../../../common/formValidation";
import DiscoverPart from "../../../Component/dicoverParts";
import MetaTitle from "../../../Component/metaTag";
import AlertBox from "../../../Component/confirm-alert/alertBox";
import { useHistory } from "react-router";
import { path } from "../../../common/routes-names";
import { resetUserPassword, verifyUserForgotToken } from "../../../utils/api";
import { colors } from "../../../common/theme";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccessFullyReset, setIsSuccessFullyReset] = useState(false);
  const history = useHistory();

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }
  const TokenFromUrl = () => {
    const id = useQuery().get("id");
    const token = useQuery().get("tok");
    return {
      id: id,
      token: token,
    };
  };
  const checkProperties = (obj) => {
    let arr = [];
    for (let key in obj) {
      arr.push(obj[key] !== undefined && obj[key] !== null && obj[key] !== "");
    }
    return arr.includes(false);
  };
  const checkPram = () => {
    let params = TokenFromUrl();
    let isObjValid = !checkProperties(params);
    return isObjValid;
  };

  const verifyUserToken = async () => {
    let params = TokenFromUrl();
    let verifyTokenJson = {
      token: params.token,
      encryptedUser: params.id,
      password: "",
    };
    let result = await verifyUserForgotToken(verifyTokenJson);
    const {
      data: { success, description },
    } = result || {};
    if (result && success) {
      // hint: do nothing if user veried successfully
    } else {
      setOpenModal(true);
      setErrorMsg(description);
    }
  };

  useEffect(() => {
    if (!checkPram()) {
      setOpenModal(true);
      setErrorMsg(notAutherized);
      return;
    }
    verifyUserToken();
  }, []);

  const closePressHandler = () => {
    if (!checkPram() || isSuccessFullyReset) {
      history.replace(path.login);
      return;
    }
    history.replace(path.forgetPassword);
  };
  const resetPasswordHandler = async (values) => {
    let params = TokenFromUrl();
    let restJSON = {
      token: "",
      encryptedUser: params.id,
      password: values.cPassword,
    };
    setLoading(true);
    let result = await resetUserPassword(restJSON);
    setLoading(false);
    const {
      data: { success, description },
    } = result || {};
    if (result && success) {
      // if user successfully changed his password
      setIsSuccessFullyReset(true);
      setOpenModal(true);
      setErrorMsg(description);
    } else {
      setOpenModal(true);
      setErrorMsg(description);
    }
  };
  return (
    <>
      <MetaTitle title={resetPass} />
      <Grid container xs={12} style={{ height: "100vh" }}>
        <DiscoverPart
          forgetPass={true}
          className="bg-Img"
          line1={discoverTheParts}
          line2={yourFav}
        />
        <Grid item xs={12} sm={7}>
          <Container className="signIn">
            <Grid xs={12} className="form">
              <Grid xs={10} sm={7} className="content">
                <Formik
                  enableReinitialize
                  validateOnChange={true}
                  validateOnBlur={true}
                  initialValues={{
                    password: "",
                    cPassword: "",
                  }}
                  validationSchema={resetValidation}
                  onSubmit={(values) => {
                    resetPasswordHandler(values);
                  }}
                >
                  {({ handleSubmit }) => (
                    <Form autocomplete="new-password">
                      <div className="loginTitle text-center">{resetPass}</div>
                      <TextInput
                        className={"inputText"}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        labelName={NewPassword}
                        placeholder={NewPassword}
                        labelClass="label"
                        showEye={showPassword}
                        onEye={() => setShowPassword(false)}
                        onEyeOff={() => setShowPassword(true)}
                        colorEye="primary"
                        colorEyeOff="primary"
                        // autocomplete="new-password"
                      />
                      <div style={{ height: 5 }} />
                      <TextInput
                        className={"inputText"}
                        name="cPassword"
                        type={showConfirm ? "text" : "password"}
                        labelName={confirmPassword}
                        placeholder={confirmPassword}
                        labelClass="label"
                        showEye={showConfirm}
                        onEye={() => setShowConfirm(false)}
                        onEyeOff={() => setShowConfirm(true)}
                        colorEye="primary"
                        colorEyeOff="primary"
                        // autocomplete="new-password"
                      />
                      {loading ? (
                        <CustomButton
                          title={""}
                          className="submitBtn marginTop"
                          onClick={handleSubmit}
                        >
                          <CircularProgress size={20} color={colors.white} />
                        </CustomButton>
                      ) : (
                        <CustomButton
                          title={send}
                          className="submitBtn marginTop"
                          onClick={handleSubmit}
                        />
                      )}
                      <AlertBox
                        del={false}
                        onCancel={closePressHandler}
                        heading={isSuccessFullyReset ? "Éxito" : error}
                        text={errorMsg}
                        onClose={openModal}
                        open={openModal}
                        isSuccess={isSuccessFullyReset}
                      />
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPassword;
