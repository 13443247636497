import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ReactComponent as Filter } from "../../../images/filter.svg";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  summary: {
    width: "85%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: "pangram-bold",
  },
  filterBtn: {
    borderTop: "1px solid #C4C4C4",
    width: "15%",
    alignItems: "center",
    display: "flex",
  },
  iconBtn: {
    padding: "0px",
  },
}));
const ExpandBar = ({ title, children, filter, onClick }) => {
  const classes = useStyles();
  return (
    <Accordion defaultExpanded>
      {filter ? (
        <div style={{ display: "flex" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={classes.summary}
          >
            <Typography className={classes.heading}>{title}</Typography>
          </AccordionSummary>
          <div className={classes.filterBtn}>
            <IconButton className={classes.iconBtn} onClick={onClick}>
              <Filter className="filterIcons" />
            </IconButton>
          </div>
        </div>
      ) : (
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
      )}

      <AccordionDetails>
        <Typography>{children}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpandBar;
