import React, { useCallback } from "react";
import Select, { components } from "react-select";
import { colors } from "../../common/theme";
import "./selector-style.css";

const styles = {
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? colors.primaryColor : null,
    "&:hover": {
      color: state.isFocused ? colors.white : null,
      backgroundColor: state.isFocused ? "rgba(0,61,151, 0.5)" : null,
    },
    cursor: "pointer",
  }),
};
const Control = ({ children, ...props }) => {
  const { number } = props.selectProps;
  return <components.Control {...props}>{children}</components.Control>;
};
export default function Selector({
  placeholder,
  numbers,
  options,
  value,
  name,
  handleChange,
  menuIsOpen,
  onBlur,
  onFocus,
  disable,
  isSearchable,
  defaultValue,
  isLoading,
  noOptionsMessage,
  innerRef,
  isFixedHeight,
  onkeydown,
}) {
  return (
    <>
      <Select
        ref={innerRef}
        styles={styles}
        name={name}
        classNamePrefix="react-select"
        menuPosition={isFixedHeight === true ? "fixed" : null}
        placeholder={placeholder}
        options={options}
        menuIsOpen={menuIsOpen}
        value={value}
        onChange={useCallback((value) => handleChange(name, value))}
        closeMenuOnSelect={false}
        onBlur={onBlur}
        onFocus={onFocus}
        blurInputOnSelect={true}
        number={numbers}
        components={{ Control }}
        isDisabled={disable}
        isSearchable={isSearchable}
        defaultValue={defaultValue}
        isLoading={isLoading}
        noOptionsMessage={noOptionsMessage}
        onKeyDown={onkeydown}
      />
    </>
  );
}
