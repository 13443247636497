import React from 'react'
import {  useField} from "formik";
import './style.scss'

const TextArea =({...props }) => {
    const [field, meta] = useField(props);
    return (
      <>
       <span className="inputStyle">
        <textarea className={props.className} {...field} {...props} >
       </textarea>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
        </span>
      </>
    );
  };

export default TextArea
