import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, styled } from "@material-ui/core/styles";
import { CloseButton } from "../index";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paperWidthSm": {
    minWidth: "sm",
    maxWidth: "md",
  },
  "& .MuiDialog-paper": {
    height: 500,
  },
}));

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup, popupSize, overFlowClass } =
    props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <BootstrapDialog
        open={openPopup}
        fullWidth
        onBackdropClick={() => {
          setOpenPopup(false);
        }}
        maxWidth={popupSize}
        fullScreen={fullScreen}
      >
        {props.headerVisible === false ? null : (
          <DialogTitle>
            <div style={{ display: "flex" }}>
              <Typography
                variant="h6"
                component="div"
                style={{ flexGrow: 1, fontFamily: "pangram" }}
              >
                {title}
              </Typography>
              <CloseButton
                onClick={() => {
                  setOpenPopup(false);
                }}
                color="black"
              />
            </div>
          </DialogTitle>
        )}
        <DialogContent
          style={
            props.isPadding === false ? { padding: 10, paddingTop: -20 } : null
          }
          dividers
          className={overFlowClass} // to hide scroll bars on modal screen
        >
          {children}
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
