import React from "react";
import { Footer, MetaTitle, StickyHeader } from "../../Component";
import { metaPath } from "../../common/services";
import MainHeader from "../../Sections/header";
import { Questions, Frequent } from "../../common/constants";
import "./style.scss";

export default function Faq() {
  return (
    <div>
      <MetaTitle title={metaPath()} />
      <MainHeader />
      <StickyHeader />
      <div className="container faq-screen">
        <div className=" container faq-header mt-5">
          <div className="faq-border">
            <h3>{Questions}</h3>
            <h1>{Frequent}</h1>
          </div>
        </div>
        <div className="faq-content">
          <h4>¿Todos los productos de OK Autopartes cuentan con garantías?</h4>
          <p>
            {`Todos los productos de OK Autopartes comercializados están sujetos a
            garantías de 1 año, vencido dicho periodo ya no será válida su
            garantía.`}
          </p>
          <p>
            Recuerda que tenemos 5 días hábiles para realizar el proceso de
            devolución por garantía.
          </p>
          <p>
            Te dejamos a disposición el siguiente correo:{" "}
            <a href="!#">garantiasydevoluciones@okautopartes.com</a>
          </p>
          <h4>¿Qué debo de hacer para validar mi garantía?</h4>
          <p>
            Notificar a <strong>OK Autopartes</strong> ingresando a tu cuenta y
            seguir el proceso de garantía.
          </p>
          <div className="faq-order-list">
            <ol>
              <li> Ingrese a su cuenta.</li>
              <li>Identifique la compra.</li>
              <li>Da clic en el botón de garantía.</li>
              <li>
                Escribe un mensaje explicando la falla del producto o el por qué
                necesitas hacer una garantía.
              </li>
              <li>Anexa una foto o video del producto.</li>
            </ol>
          </div>
          <p>
            Una vez hecho esto, se generará un folio y nos pondremos en contacto
            contigo, por medio de correo o el teléfono que nos proporcionaste en
            tu registro. Deberás estar atento a las notificaciones en tu
            “cuenta”.
          </p>
          <p>
            Recuerda que tenemos 5 días hábiles para realizar el proceso de
            devolución por garantía.
          </p>
          <h4>¿Cómo puedo validar mi garantía en OK Autopartes?</h4>
          <p>
            Primero notifica a OK Autopartes enviando un correo a{" "}
            <a href="!#">garantiasydevoluciones@okautopartes.com</a> en el cual
            nos proporciones lo siguiente:
          </p>
          <div className="premero">
            <ul>
              <li>-El nombre con el que se registró la compra.</li>
              <li>-Indícanos que producto adquiriste</li>
              <li>-Nota de venta y/o factura</li>
              <li> En el Marketplace de Ok Autopartes:</li>
              <li>-Ingresa a tu cuenta.</li>
              <li>-Selecciona el botón “Tengo un problema”</li>
              <li>
                -Del listado de opciones indica cual es la mas cercana a tu
                problema.
              </li>
            </ul>
          </div>
          <p>
            Una vez hecho esto, se generará un folio y nos pondremos en contacto
            contigo, por medio de correo o el teléfono que nos proporcionaste en
            tu registro. Deberás estar atento a las notificaciones en tu
            “cuenta”.
          </p>
          <p>
            Recuerda que tenemos 5 días hábiles para realizar el proceso de
            devolución por garantía.
          </p>
          <h4>¿Qué métodos de pago tienen disponibles?</h4>
          <p>
            Para tu comodidad contamos con formas de pago en efectivo y tarjeta
            de crédito por medio de <strong>Mercado Pago</strong>. Puedes
            realizar tus pagos desde la comodidad de tu casa con tu tarjeta de
            crédito, hasta la facilidad de acudir a tu tienda o banco de
            conveniencia favorito para realizar tu{" "}
            <strong>pago en efectivo</strong>.
          </p>
          <p>
            En okautopartes.com ponemos a su disposición el siguiente método de
            pago:{" "}
          </p>
          <div className="mercado-unorder-list">
            <ul>
              <li> • Mercado Pago: </li>
              <ul className="subList-items">
                <li>o Tarjeta de Crédito: Visa, Master Card</li>
                <li>
                  o Tarjeta de Débito: Banamex, IX, BBVA, Banorte, HSBC,
                  Santander
                </li>
                <li>
                  o Efectivo: OXXO, 7-Eleven, TELECOMM, BBVA, CITIBANAMEX y
                  SANTANDER
                </li>
                <li>
                  o Transferencia: BBVA, CITIBANAMEX, SANTANDER, BANORTE Y HSBC
                </li>
                <li>o Tarjeta Mercado Pago </li>
                <li>o BNPL</li>
              </ul>
            </ul>
          </div>
          <p>
            Para más información o ayuda, contáctanos por correo:{" "}
            <a href="!#">atencion@okautopartes.com</a>{" "}
          </p>
          <h4>
            ¿Cómo puedo identificar el número de serie de mi carro para validar
            que la pieza que compré es compatible?
          </h4>
          <p>
            El número de motor es indispensable para saber cuál será la pieza
            correcta de tu autoparte. Lo puedes localizar entre el bloque y la
            culata, o entre el bloque y la caja de trasmisión del motor, todo
            depende del vehículo
          </p>
          <p>
            O bien puede buscarlo en tu tarjeta de circulación, donde puedes
            identificarlo como el PIN, que consiste de 18 dígitos.
          </p>
          <h4>¿Cuánto tiempo tarda en llegar mi producto?</h4>
          <p>En el caso de producto disponible:</p>
          <p>
            En este caso al momento de ordenar tu pedido, tu compra llegará de 3
            a 4 días hábiles a partir de haber realizado tu pedido.
          </p>
          <p>
            En el caso de compra con disponibilidad de stock a 7 o más días:
          </p>
          <p>
            En este caso al momento de ordenar tu pedido, de 7 a 2 días o más se
            te informará la fecha estimada de salida del producto.
          </p>
          <p>
            Para más información o ayuda, contáctanos por correo:
            <a href="!#">atencion@okautopartes.com</a>{" "}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
