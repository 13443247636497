import ContextProvider from "./context-api/contextProvider";
import Routes from "./routes";
import ErrorBoundary from "./ErrorBoundy";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./Redux/reducers/rootReducer";
import { ToastProvider } from "react-toast-notifications";
import { useState } from "react";
import * as Sentry from "@sentry/react";
import "./style.scss";

const store = createStore(rootReducer);
function App() {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <ToastProvider autoDismissTimeout={2000} autoDismiss={true}>
      <ContextProvider>
        <Provider store={store}>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </Provider>
        {/* <div className="supportDiv">
      <img src={support} className="supportImg"
      onClick={()=> setIsVisible(!isVisible)} />
      {isVisible && <SupportForm  isVisible={isVisible} setIsVisible={setIsVisible}/>}
      </div> */}
      </ContextProvider>
    </ToastProvider>
  );
}
export default Sentry.withProfiler(App);
