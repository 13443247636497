import React from "react";
import "./style.scss";
import {
  addAddressBtn,
  addBillingBtn,
  addPaymentBtn,
  billingAddres,
  editAddress,
  editBilling,
  editPayment,
  paymentMethod,
  ShippingAddres,
} from "../../common/constants";
import SelectBlock from "../../Component/selectBlock";
import { billIcon, paymentIcon, shipIcon } from "../../common/Images";
import SkekeltonCustomerDetailTitle from "./skeletonCustomerDetailTile";

const CustomerDetail = ({
  defaultAddress,
  defaultCard,
  defaultInvoice,
  addAddress,
  addAddress: addNewAddress,
  addPayment: addNewPayment,
  addBill: addNewInvoice,
  children,
}) => {
  return (
    <div className="customerDetail">
      {defaultAddress !== false ? (
        <div className="tile">
          <h3 className="tileHeading">{ShippingAddres}</h3>
          {defaultAddress === null ? (
            <SelectBlock
              icon={shipIcon}
              onClick={addNewAddress}
              btnName={addAddressBtn}
            />
          ) : (
            <SelectBlock
              defaultAddress={defaultAddress}
              icon={shipIcon}
              onClick={addNewAddress}
              btnName={editAddress}
            />
          )}
        </div>
      ) : (
        <SkekeltonCustomerDetailTitle
          heading={ShippingAddres}
          icon={shipIcon}
        />
      )}

      {children}
    </div>
  );
};

export default CustomerDetail;
{
  /* {defaultCard !== false ? (
        <div className="tile">
          <h3 className="tileHeading">{paymentMethod}</h3>
          {defaultCard === null ? (
            <SelectBlock
              icon={paymentIcon}
              onClick={addNewPayment}
              btnName={addPaymentBtn}
            />
          ) : (
            <SelectBlock
              defaultCard={defaultCard}
              icon={paymentIcon}
              onClick={addNewPayment}
              btnName={editPayment}
              marcadoImg={"images/marcadoCard.png"}
            />
          )}
        </div>
      ) : (
        <SkekeltonCustomerDetailTitle
          heading={ShippingAddres}
          icon={shipIcon}
        />
      )} */
}

{
  /* {defaultInvoice !== false ? (
        <div className="tile">
          <h3 className="tileHeading">{billingAddres}</h3>
          {defaultInvoice === null ? (
            <SelectBlock
              icon={billIcon}
              onClick={addNewInvoice}
              btnName={addBillingBtn}
            />
          ) : (
            <SelectBlock
              defaultInvoice={defaultInvoice}
              icon={billIcon}
              onClick={addNewInvoice}
              btnName={editBilling}
            />
          )}
        </div>
      ) : (
        <SkekeltonCustomerDetailTitle
          heading={ShippingAddres}
          icon={shipIcon}
        />
      )} */
}
