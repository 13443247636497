import { trim } from "jquery";
import * as yup from "yup";
import { digits, postalCodeLength } from "./constant/formConstants";
import { max } from "./constants";

export const numericOnly = /^[0-9\b]+$/;
const nameRequire = "Se requiere el nombre";
const emailRequire = "correo electronico es requerido";
const phoneRequire = "Se requiere número de teléfono";
const messageRequire = "se requiere mensaje";
const passwordRequire = "se requiere contraseña";
const minimuLetter = "mínimo 3 letras";
const required = "Campo requerido";
const intRequired = "Número Interior requerido";
const selectRequired = "Uso De CFDI requerido";
const selectFiscalrequired = "Régimen Fiscal requerido";
const selectColonia = "Colonia requerido";
const regimentFiscal = "Régimen Fiscal requerido";
const taxRequired = " RFC requerido";
const addressRequired = "Nombre para identificar requerido";
const taxnamesRequired = "Razon Social requerido";
const streetRequired = "Calle requerido";
const postalRequired = "Código Postal requerido";
const neighborhoodRequired = "Colonia requerido";
const contactRequired = "Nombre y apellido requerido";
const phoneRequired = "Teléfono De Contacto requerido";
const cardNumberRequired = "Número De Tarjeta requerido";
const monthRequired = "Año requerido";
const cvvRequired = "CVV requerido";
const extRequired = "Número Exterior requerido";
const yearRequired = "Mes requerido";
//const requiredPhone = "Número de teléfono requerido";
const requiredPhone = "el formato no coincide";
const invalidEmail = "Dirección de correo electrónico inválida";
const atLeast = `Mínimo ${postalCodeLength} caracteres o más`;
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const mustBe = "Debe tener 16 dígitos";
const strongRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/;
const upperCaseDigit =
  "Debe tener al menos 1 letra mayúscula, 1 letra minúscula y 1 número";
const passwordDonotMatch = "Las contraseñas no coinciden";
const oldMatch = "la nueva contraseña debe ser diferente";
const oldPassRequire =
  "Se requiere contraseña antigua para configurar la nueva contraseña";
const passwordRegex =
  /^[0-9A-Za-z]*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][0-9a-zA-Z]*$/;
const specialCharacterNeeded = "Necesita un carácter especial";

const inValidPhoneNumber = "El número de teléfono no es válido";
const phoneNumberExceeded = "Número de teléfono se supera a 12";
const phoneNumberExceed = "Número De Teléfono debe contener 10 digitos";
const phoneNumberRegx = /^[0-9]{0,12}$/im;
export const formValidation = yup.object().shape({
  addressName: yup.string().required(addressRequired),
  addresscontact: yup.string().required(contactRequired),
  phonenumber: yup
    .string()
    .min(10, phoneNumberExceed)
    .max(10, phoneNumberExceed)
    .matches(phoneRegExp, requiredPhone)
    .required(phoneRequired),
  postalCode: yup
    .string()
    .min(postalCodeLength, atLeast)
    .max(postalCodeLength, atLeast)
    .required(postalRequired),
  street: yup.string().required(streetRequired),
  extno: yup.string().required(extRequired),
  neighborhood: yup.string().required(selectRequired),
});
export const billValidation = yup.object().shape({
  taxno: yup
    .string()
    .required(taxRequired)
    .matches(
      /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/gm,
      "RFC no está en el formato correcto"
    ),
  taxregimen: yup.string().required(selectFiscalrequired),
  taxname: yup.string().required(taxnamesRequired),
  postalCode: yup
    .string()
    .min(postalCodeLength, atLeast)
    .max(postalCodeLength, atLeast)
    .required(postalRequired),
  neighborhood: yup.string().required(selectColonia),
  taxregimen: yup.string().required(regimentFiscal),
  taxname: yup.string().required(taxnamesRequired),
  neighborhood: yup.string().required(neighborhoodRequired),
  extno: yup.string().required(extRequired),
});
export const paymentValidation = yup.object().shape({
  cardName: yup.string().required(nameRequire).min(3, minimuLetter),
  cardNumber: yup.string().min(16, mustBe).required(cardNumberRequired),
  cvv: yup.string().min(3, digits).max(4, digits).required(cvvRequired),
  month: yup.string().min(1, digits).max(2, digits).required(monthRequired),
  year: yup.string().min(4, digits).required(yearRequired),
});

export const problemValidation = yup.object().shape({
  message: yup.string().required(required),
  queryType: yup.string().required(required),
  // file: yup.array().min(1, "Image Required").required(required),
});
export const priceValidation = yup.object().shape({
  min: yup.string().min(2, digits).required(required),
  max: yup.string().required(required),
});

export const taxRegimenValidation = yup.object().shape({
  taxregimen: yup.string().required(selectRequired),
  // cvv: yup.string().min(3, digits).max(4, digits).required(required),
});

export const cvvValidation = yup.object().shape({
  cvv: yup.string().min(3, digits).max(4, digits).required(cvvRequired),
});
export const loginValidation = yup.object().shape({
  email: yup.string().email(invalidEmail).required(emailRequire),
  password: yup.string().required(passwordRequire),
  // .matches(strongRegex, upperCaseDigit),
});
export const contactUSValidations = yup.object().shape({
  name: yup.string().required(nameRequire),
  company: yup.string().required(phoneRequire),
  email: yup.string().email(invalidEmail).required(emailRequire),
  message: yup.string().required(messageRequire),
});
export const signUpValidation = yup.object().shape({
  phoneNumber: yup
    .string()
    .min(10, phoneNumberExceed)
    .max(10, phoneNumberExceed)
    .matches(phoneNumberRegx, inValidPhoneNumber)
    .required(phoneRequired),
  name: yup.string().required(nameRequire).min(3, minimuLetter),
  email: yup.string().email(invalidEmail).required(emailRequire),
  password: yup.string().required(passwordRequire),
  // .matches(passwordRegex, specialCharacterNeeded)
  // .min(6, atLeast)
  // .matches(strongRegex, upperCaseDigit),
  cPassword: yup
    .string()
    .required(required)
    .oneOf([yup.ref("password")], passwordDonotMatch),
});

export const forgetValidation = yup.object().shape({
  email: yup.string().email(invalidEmail).required(emailRequire),
});
export const resetValidation = yup.object().shape({
  password: yup.string().required(passwordRequire),
  cPassword: yup
    .string()
    .required(required)
    .oneOf([yup.ref("password")], passwordDonotMatch),
});
export const newPasswordValidate = yup.object().shape({
  password: yup.string().required(oldPassRequire),
  // .min(6, atLeast),
  // .matches(strongRegex, upperCaseDigit),
  newPassword: yup.string().required(passwordRequire),
  cPassword: yup
    .string()
    .required(required)
    .oneOf([yup.ref("newPassword")], passwordDonotMatch),
});

export const emailValidation = yup.object().shape({
  email: yup.string().email(invalidEmail).required(emailRequire),
});
export const nameValidation = yup.object().shape({
  name: yup.string().required(nameRequire).min(3, minimuLetter),
});

export const phoneNumberValidation = yup.object().shape({
  phone: yup
    .string()
    .required(phoneRequired)
    .min(0)
    .max(12, phoneNumberExceeded)
    .matches(phoneNumberRegx, inValidPhoneNumber),
});
