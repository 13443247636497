import React, { useState } from "react";
import { Problem_Form } from "./problem-input/problem_form";
import { Problem_Div } from "./problem-input/problem_div";
import { CloseButton } from "../index";
import "./style.scss";
import { questionsArray, whtIsYourProblem } from "../../common/constants";
import { Problem_Message } from "./problem-input/problem_Message";
import { Collapse } from "@material-ui/core";

export const Main_form = ({ modeClose, data, formCloses, fetchOrderList }) => {
  const [show, setShow] = useState(null);
  const [close, setClose] = useState(false);

  // set the checks for open complaint form ..
  const setChecks = (indexVal) => {
    setShow(indexVal);
    setClose(!close);
  };
  return (
    <div className="Main_form">
      <div className="formContainer pb-2">
        <div className="headline">
          <text className="texthead">{`${whtIsYourProblem} ${data.productName}?`}</text>
          <CloseButton
            color="black"
            onClick={modeClose}
            className="hoverIcon"
          />
        </div>
        {questionsArray.map((item) => (
          <div className="questionContainer">
            <div className="mainProblemContainer">
              <div className="problemName">
                <Problem_Div
                  showForm={() => setChecks(item.index)}
                  question={`${item.index + 1}. ${item.question}`}
                />
              </div>
              <Collapse
                in={show == item.index && close}
                // in={close}
                classes={{
                  wrapper: "wrapperCollapse",
                }}
              >
                <div className="formInputdisplay">
                  <div className="innerform">
                    <Problem_Form
                      data={data}
                      formCloses={formCloses}
                      problemTypeId={item.index}
                      question={item.question}
                      showForm={() => setChecks(item.index)}
                      isImageAndCheckHide={item.index == 0}
                      fetchOrderList={fetchOrderList}
                    />
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
