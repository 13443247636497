import React from "react";
import { Skeleton } from "@material-ui/lab";

import "./style.scss"

const SkekeltonCustomerDetailTitle = ({
  icon,
  heading,
  ...props
}) => {
  return (
    <div className="tile">
        <h3 className="tileHeading">{heading}</h3>
        <div className="tileBody">
          <div className="icon">
            <img src={icon} alt={heading} />
          </div>
          <div className="itemDiv">
              <div className="detailText">
                <div>
                  <span className="title"> <Skeleton height={12} width={"45px"} /> </span>
                </div>
                <div>
                  <span> <Skeleton animation="pulse" height={12} width={"100%"} /> </span>
                </div>
              </div>
              <div className="buttonDiv">
                <Skeleton height={12} width={"45px"} />
              </div>
          </div>
        </div>
    </div>
  );
};

export default SkekeltonCustomerDetailTitle;
