import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  addLinks,
  add_VehicleIntoUrl,
  setPageSize,
  setOrder,
} from "../../Redux/reducers/filterSlice";
import queryString from "query-string";
import {
  removeBrandItem,
  removeDynamicBrandItem,
  removeMaxPrice,
  removeMinPrice,
  removeRating,
} from "../../common/queryString";
export default function useForm() {
  const history = useHistory();
  const selected_Badge = useSelector((state) => state.filter.SelectedBtn);
  const selected_Check = useSelector((state) => state.filter.checkedState);
  const selected_dynamicFilter = useSelector(
    (state) => state.filter.dynamicState
  );
  const selected_rating = useSelector((state) => state.filter.ratingCheckState);
  const selected_Link = useSelector((state) => state.filter.linkState);
  const default_Category = useSelector((state) => state.filter.defaultList);
  const pageSize = useSelector((state) => state.filter.pageSize);
  const pageNum = useSelector((state) => state.filter.pageNum);
  const addCar = useSelector((state) => state.filter.addCar);

  const [Gridview, setGridView] = useState("proWrapper");
  const [active, setactive] = useState("fas fa-bars listView active");
  const [sorting, setSorting] = useState(1);
  const [activeb, setactiveb] = useState("fas fa-th gridView");
  const [open, setopen] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");

  // handle QueryStrings
  const queryStrings = queryString.parse(useLocation().search);
  const newQueryParam = {
    ...queryStrings,
    minPrice: removeMinPrice(selected_Badge, selected_Badge[0]?.min),
    maxPrice: removeMaxPrice(selected_Badge, selected_Badge[0]?.max),
  };
  const removeRatingParam = (value) => {
    const ratingList = selected_rating.map((i) => {
      return i.value;
    });
    let removeList = removeRating(ratingList, value);
    const newQueryParam = {
      ...queryStrings,
      calificaciones: removeList,
    };
    return history.push({
      search: queryString.stringify(newQueryParam),
    });
  };
  const removeBrandParam = (item) => {
    const checkList = selected_Check.map((i) => {
      return i.name;
    });
    let removeItem = removeBrandItem(checkList, item.name);
    const newQueryParam = {
      ...queryStrings,
      marca: removeItem,
    };
    return history.push({
      search: queryString.stringify(newQueryParam),
    });
  };
  //click on price badge to rremove the badge
  const removePriceBadge = () => {
    const newQueryParam = {
      ...queryStrings,
      maxPrice: [],
      minPrice: [],
    };
    return history.push({
      search: queryString.stringify(newQueryParam),
    });
  };

  //click on to remove badge
  const removeDynamicParam = (item, type) => {
    const params = new URLSearchParams(window.location.search);
    let allParams = [],
      parameter = [];
    for (var key of params.keys()) {
      let index = allParams.findIndex((e) => e === key);
      if (index == -1) {
        allParams.push(key);
        parameter.push({ key: key, value: params.getAll(key) });
      }
    }
    //changing the format of array
    let paramArray = [];
    parameter.forEach((parameterElement) => {
      parameterElement.value.forEach((element) => {
        paramArray.push({
          name: parameterElement.key,
          value: element,
        });
      });
    });
    //removing
    let removeList = removeDynamicBrandItem(paramArray, item);
    const addList = removeList;
    let afterRemovedList = [];
    //array for removing type(Propery) of CheckBox
    addList.forEach((addListElement) => {
      if (addListElement.name === type) {
        afterRemovedList.push(addListElement.value);
      }
    });
    const newQueryParam = {
      ...queryStrings,
      [type]: afterRemovedList,
    };
    console.log("object==>", newQueryParam);
    return history.push({
      search: queryString.stringify(newQueryParam),
    });
  };

  // page on top when do pagination
  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("SOME-ID")?.scrollTo(0, 0);
  }, [pageNum]);
  useEffect(() => {
    createUrl();
  }, [window.location.pathname]);
  useEffect(() => {
    console.log("product listing sorting...");
  }, []);
  function func1() {
    setGridView("proWrapper");
    setactive("fas fa-bars listView active");
    setactiveb("fas fa-th gridView");
  }
  function func3() {
    setGridView("proWrapper gridView");
    setactiveb("fas fa-th gridView active");
    setactive("fas fa-bars listView");
  }
  const vehicle = `${addCar?.make?.value.replaceAll(" ", "-")}-${
    addCar?.model?.value
  }-${addCar?.year?.value}`;
  const categoryProduct =
    selected_Link.length > 0 &&
    selected_Link.map((i) => {
      return i.value == null
        ? `${i?.type}`
        : `${i?.type}-${i?.value.replaceAll(" ", "-").replaceAll("%20", "-")}`;
    });
  const DynamicUrl = (brandName, partnum, productName) => {
    return addCar.make != null &&
      (selected_Link[0].type == null || selected_Link[0].type == "")
      ? `/${vehicle}/${`${brandName.replaceAll(
          " ",
          "-"
        )}-${productName.replaceAll(" ", "-")}`}-${partnum}.html`
      : selected_Link[0].type != null && addCar.make == null
      ? `/${categoryProduct}/${`${brandName.replaceAll(
          " ",
          "-"
        )}-${productName.replaceAll(" ", "-")}`}-${partnum}.html`
      : (selected_Link[0].type != null || selected_Link[0].type != "") &&
        addCar.make != null
      ? `/${vehicle}/${categoryProduct}/${`${brandName.replaceAll(
          " ",
          "-"
        )}-${productName.replaceAll(" ", "-")}`}-${partnum}.html`
      : `/${brandName.replaceAll(" ", "-")}}/${productName.replaceAll(
          " ",
          "-"
        )}-${partnum}.html`;
  };

  const handleChange = (e) => {
    dispatch(setPageSize(e.target.value));
  };
  const handleSort = (e) => {
    setSorting(e.target.value);
    dispatch(setOrder(e.target.value));
    // console.log("sorting working ", e.target.value);
  };
  const dispatch = useDispatch();
  var currentLocation = window.location.pathname;
  const createUrl = () => {
    const lastIndex = currentLocation.lastIndexOf(".");
    const i = currentLocation.substring(0, lastIndex);
    const item = i.split("/").slice(1).join(" ");
    const url = item.replaceAll("-", " ");
    const category = url.split(" ").reverse().slice(1).reverse().join(" ");
    const seprateAttribute = url.split(" ").slice(1).join(" ");
    const values = seprateAttribute
      .split(" ")
      .reverse()
      .slice(3)
      .reverse()
      .join(" ");
    const type = url.split(" ").reverse().slice(3).join(" ");
    const types = type.split(" ").slice(1).join(" ");
    const types2 = url.split(" ").reverse().slice(1).join(" ");
    const make = seprateAttribute
      .split(" ")
      .reverse()
      .slice(2)
      .reverse()
      .join(" ");
    const makes = make.split(" ").slice(1).reverse().join(" ");
    const year = seprateAttribute.split(" ").slice(2).reverse().join(" ");
    const years = year.split(" ").reverse().slice(1).join(" ");
    const modelAttribute = seprateAttribute
      .split(" ")
      .reverse()
      .slice(1)
      .reverse()
      .join(" ");
    const model = modelAttribute.split(" ").slice(1).reverse().join(" ");
    const models = model.split(" ").reverse().slice(1).join(" ");
    const make3 = category.split(" ").reverse().slice(1).join(" ");
    const year3 = seprateAttribute.split(" ").slice(1).reverse().join(" ");
    const length = url.split(" ").length;
    const array = {
      id: new Date().getTime().toString(),
      type:
        length == 1
          ? item
          : length == 5
          ? models
          : length == 4
          ? year
          : length == 2
          ? types2
          : "",
      value: length == 2 ? seprateAttribute : length == 5 ? years : null,
      make:
        length == 3 ? make3 : length == 4 ? type : length == 5 ? types : null,
      model:
        length == 3
          ? modelAttribute
          : length == 4
          ? make
          : length == 5
          ? values
          : null,
      year:
        length == 3 ? year3 : length == 4 ? model : length == 5 ? makes : null,
    };
    const arrayMake = {
      make:
        length == 4
          ? { value: decodeURIComponent(type.replaceAll("%2B", "-")) }
          : length == 5
          ? { value: decodeURIComponent(types.replaceAll("%2B", "-")) }
          : length == 3
          ? { value: decodeURIComponent(make3.replaceAll("%2B", "-")) }
          : null,
      model:
        length == 4
          ? { value: make }
          : length == 5
          ? { value: values }
          : length == 3
          ? { value: modelAttribute }
          : null,
      year:
        length == 4
          ? { value: model }
          : length == 5
          ? { value: makes }
          : length == 3
          ? { value: year3 }
          : null,
    };
    dispatch(add_VehicleIntoUrl(arrayMake));
    dispatch(addLinks(array));
  };
  return {
    selected_Badge,
    selected_Check,
    selected_Link,
    Gridview,
    active,
    activeb,
    func1,
    handleChange,
    func3,
    createUrl,
    DynamicUrl,
    default_Category,
    pageSize,
    selected_rating,
    addCar,
    open,
    setopen,
    defaultValue,
    setDefaultValue,
    selected_dynamicFilter,
    sorting,
    handleSort,
    queryStrings,
    newQueryParam,
    removeRatingParam,
    removeBrandParam,
    removeDynamicParam,
    removePriceBadge,
  };
}
