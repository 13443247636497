import React, { useEffect, useContext } from "react";
import "./style.scss";
import { makeStyles } from "@material-ui/core/styles";
import {
  brand,
  branding,
  category,
  clearAllFilter,
  price,
  rangeShould,
  rating,
  ratingList,
  bandaPolyVAccesorios,
  setBandaPolyVAccesorios,
} from "../../common/constants";
import {
  SingleSelect,
  ExpandBar,
  PriceInput,
  RangeSlider,
  MultiSelect,
  CustomButton,
} from "../../Component/index";
import ApplicationContext from "./../../context-api/context";
import { useDispatch } from "react-redux";
import {
  addLinks,
  priceField,
  resetFilter,
} from "../../Redux/reducers/filterSlice";
import useForm from "./useForm";
import { NavLink } from "react-router-dom";
import Sub_Expand from "../../Component/controls/customAccording/sub_expand";
import { colors } from "../../common/theme";
import SkeltonLoader from "./sideBarSkelton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    border: "1px solid",
    borderColor: colors.borderColor,
    borderTop: "none",
    backgroundColor: colors.white,
  },
}));
const SideBar = ({ brandList, dynamicList, categories, loader }) => {
  const classes = useStyles();
  const { setPriceSlider } = useContext(ApplicationContext);
  const dispatch = useDispatch();
  const {
    default_Category,
    categoryType,
    setCategoryType,
    selected_Link,
    error,
    _price,
    value,
    priceSlider,
    handlePrice,
    handleSliderRange,
    handleChange,
    history,
    maxPriceNew,
    resetQuery,
  } = useForm({ brandList });

  return (
    <>
      <div className={classes.root}>
        <ExpandBar title={category}>
          <div className="innerAccordion">
            <div id="accordion">
              {
                <>
                  {default_Category.length != 0 ? (
                    categories != 0 ? (
                      <>
                        {selected_Link.map((url) => {
                          return (
                            <>
                              {categories.map((item, index) => {
                                return (
                                  <>
                                    <Sub_Expand
                                      key={index}
                                      title={item.name}
                                      id={item.name}
                                      expand={
                                        categoryType == item.name ? true : false
                                      }
                                    >
                                      <>
                                        {item.types.map((i, index) => {
                                          const array = {
                                            make: url.make,
                                            model: url.model,
                                            year: url.year,
                                            type: item.name,
                                            value: i.name,
                                          };
                                          return (
                                            <>
                                              <li key={index}>
                                                <NavLink
                                                  key={index}
                                                  to={
                                                    item.type &&
                                                    item.name &&
                                                    url.make
                                                      ? `${url.make}-${
                                                          url.model
                                                        }-${url.year}-${
                                                          item.name
                                                        }-${
                                                          i.name ===
                                                          bandaPolyVAccesorios
                                                            ? setBandaPolyVAccesorios
                                                            : i.name
                                                        }.html`
                                                      : url.make
                                                      ? `${url.make}-${
                                                          url.model
                                                        }-${url.year}-${
                                                          item.name
                                                        }-${
                                                          i.name ===
                                                          bandaPolyVAccesorios
                                                            ? setBandaPolyVAccesorios
                                                            : i.name
                                                        }.html`
                                                      : `${item.name}-${
                                                          i.name ===
                                                          bandaPolyVAccesorios
                                                            ? setBandaPolyVAccesorios
                                                            : i.name
                                                        }.html`
                                                  }
                                                  activeClassName="Links activeLinks"
                                                  className="Links pl-0 first"
                                                  onClick={() => {
                                                    dispatch(addLinks(array));
                                                  }}
                                                >
                                                  {decodeURI(i.name)}
                                                  {"  "}
                                                  <span
                                                    style={{
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    ({i.results})
                                                  </span>
                                                </NavLink>
                                              </li>
                                            </>
                                          );
                                        })}
                                      </>
                                    </Sub_Expand>
                                  </>
                                );
                              })}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {selected_Link.map((url) => {
                          return (
                            <>
                              {default_Category.map((category) => {
                                return (
                                  <>
                                    {category.map((item, index) => {
                                      return (
                                        <>
                                          <Sub_Expand
                                            key={index}
                                            title={item.name}
                                            id={item.name}
                                            expand={
                                              categoryType == item.name
                                                ? true
                                                : false
                                            }
                                          >
                                            <>
                                              {item.types.map((i, index) => {
                                                const array = {
                                                  make: url.make,
                                                  model: url.model,
                                                  year: url.year,
                                                  type: item.name,
                                                  value: i.name,
                                                };
                                                return (
                                                  <>
                                                    <li key={index}>
                                                      <NavLink
                                                        key={index}
                                                        to={
                                                          item.type &&
                                                          item.name &&
                                                          url.make
                                                            ? `${url.make}-${
                                                                url.model
                                                              }-${url.year}-${
                                                                item.name
                                                              }-${
                                                                i.name ===
                                                                bandaPolyVAccesorios
                                                                  ? setBandaPolyVAccesorios
                                                                  : i.name
                                                              }.html`
                                                            : url.make
                                                            ? `${url.make}-${
                                                                url.model
                                                              }-${url.year}-${
                                                                item.name
                                                              }-${
                                                                i.name ===
                                                                bandaPolyVAccesorios
                                                                  ? setBandaPolyVAccesorios
                                                                  : i.name
                                                              }.html`
                                                            : `${item.name}-${
                                                                i.name ===
                                                                bandaPolyVAccesorios
                                                                  ? setBandaPolyVAccesorios
                                                                  : i.name
                                                              }.html`
                                                        }
                                                        activeClassName="Links activeLinks"
                                                        className="Links pl-0 first"
                                                        onClick={() => {
                                                          dispatch(
                                                            addLinks(array)
                                                          );
                                                          dispatch(
                                                            resetFilter("All")
                                                          );
                                                        }}
                                                      >
                                                        {decodeURI(i.name)}
                                                        {"  "}
                                                        {/* <span
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                        >
                                                          ({i.results})
                                                        </span> */}
                                                      </NavLink>
                                                    </li>
                                                  </>
                                                );
                                              })}
                                            </>
                                          </Sub_Expand>
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              })}
                            </>
                          );
                        })}
                      </>
                    )
                  ) : (
                    <>
                      {[1, 2].map((i) => {
                        return (
                          <Sub_Expand
                            key={i}
                            title={<SkeltonLoader category />}
                            id={"one"}
                            expand={true}
                          >
                            <SkeltonLoader subcategory />
                          </Sub_Expand>
                        );
                      })}
                    </>
                  )}
                </>
              }
            </div>
          </div>
        </ExpandBar>
        {loader === false && brandList.length == 0 ? null : brandList.length !=
          0 ? (
          <ExpandBar
            title={brand}
            filter
            onClick={() => {
              dispatch(resetFilter(brand));
              resetQuery(brand);
            }}
          >
            <div className="brandFilter">
              <div className="brandInner">
                <>
                  <MultiSelect options={brandList} type={brand} />
                </>
              </div>
            </div>
          </ExpandBar>
        ) : (
          <ExpandBar title={<SkeltonLoader category />}>
            <div className="brandFilter">
              <div className="brandInner">
                <SkeltonLoader brand />
              </div>
            </div>
          </ExpandBar>
        )}
        {dynamicList[0]?.name?.length != 0 || dynamicList != 0 ? (
          <>
            {dynamicList.map((i, index) => {
              return (
                <>
                  <ExpandBar
                    title={i.name}
                    key={index}
                    filter
                    onClick={() => {
                      dispatch(resetFilter(i.name));
                      resetQuery(i.name);
                    }}
                  >
                    <div className="brandFilter">
                      <div className="brandInner">
                        <MultiSelect options={i.types} type={i.name} />
                      </div>
                    </div>
                  </ExpandBar>
                </>
              );
            })}
          </>
        ) : (
          <ExpandBar title={<SkeltonLoader category />}>
            <div className="brandFilter">
              <div className="brandInner">
                <SkeltonLoader brand />
              </div>
            </div>
          </ExpandBar>
        )}
        <ExpandBar title={price}>
          <div className="priceSelection">
            <PriceInput
              minValue={priceSlider[0]}
              maxValue={priceSlider[1]}
              minOnChange={(e) => {
                // dispatch(priceField(e));
                setPriceSlider([e.target.value, priceSlider[1]]);
              }}
              maxOnChange={(e) => {
                // handleSliderRange(e);
                setPriceSlider([priceSlider[0], e.target.value]);
              }}
              onClick={handlePrice}
            />
            {error === true ? (
              <span className="errorSpan"> {rangeShould}</span>
            ) : (
              <RangeSlider
                price={true}
                value={priceSlider}
                maxValue={maxPriceNew}
                onChange={(e, value) => setPriceSlider(value)}
                // onChange={handleChange}
              />
            )}
          </div>
        </ExpandBar>
        <ExpandBar
          title={rating}
          filter
          onClick={() => {
            dispatch(resetFilter(rating));
            resetQuery(rating);
          }}
        >
          <div className="brandFilter">
            <div className="brandInner">
              <SingleSelect options={ratingList} />
            </div>
          </div>
        </ExpandBar>
        <CustomButton
          title={clearAllFilter}
          className="clearAll"
          onClick={() => {
            {
              dispatch(resetFilter("All"));
              resetQuery("All");
            }
          }}
        />
      </div>
    </>
  );
};
export default SideBar;
