import React from "react";
import "./style.scss";
import { Skeleton } from "@material-ui/lab";
const ProductSkelton = () => {
  return (
    <div className="productOuter">
      <div className="product">
        <div className="imgholder skeltonImg">
          <Skeleton animation="wave"
           variant="rectangular"
            height={"9.2rem"} 
            />
        </div>
        <div className="description">
          <div>
            <p className="desText">
              <Skeleton
                animation="wave"
                height={12}
                width="80%"
                style={{ marginBottom: 1 }}
              />
              <Skeleton
                animation="wave"
                height={12}
                style={{ marginBottom: 6 }}
              />
            </p>
            <div
              className="priceouter"
              style={{ display: "flex", marginBottom: 10 }}
            >
              <span className="discountedPrice skelton">
                <Skeleton animation="wave" height={24} width="100%" />
              </span>
              <span
                className="orignalPrice skelton"
                style={{ textDecoration: "none" }}
              >
                <Skeleton animation="wave" height={24} width="100%" />
              </span>
            </div>
            <div className="detail">
              <span className="key">
                <Skeleton
                  animation="wave"
                  height={10}
                  width="86%"
                  style={{ marginBottom: 6 }}
                />
              </span>
              <span className="value"></span>
            </div>
            <div className="detail">
              <span className="key">
                <Skeleton
                  animation="wave"
                  height={10}
                  width="60%"
                  style={{ marginBottom: 6 }}
                />
              </span>
              <span className="value"></span>
            </div>
            <div className="detail">
              <span className="key">
                <Skeleton
                  animation="wave"
                  height={10}
                  width="40%"
                  style={{ marginBottom: 6 }}
                />
              </span>
              <span className="value">
                <Skeleton
                  height={60}
                  width="100%"
                  style={{ marginBottom: 0 }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSkelton;
