import React, { useContext } from "react";
import DataItem from "../StickyHeader/MenuItem";
import "./style.scss";
import Fade from "react-reveal/Fade";
import { OutsideClickAlert } from "../../common/OutsideClickAlert";
import { colors } from "../../common/theme";
import ApplicationContext from "../../context-api/context";

const Tooltip = ({ name, open, onClick, onClose, className, array }) => {
  const { isSticky, totalQuantityLength } = useContext(ApplicationContext);
  let [isOutside, setIsOutside] = React.useState(false);
  const wrapperRef = React.useRef("outside");
  OutsideClickAlert(wrapperRef, () => {
    setIsOutside(false);
  });
  return (
    <div
      className="_tooltip"
      ref={wrapperRef}
      onClick={() => setIsOutside(true)}
    >
      <span
        style={{
          color: isOutside
            ? open
              ? colors.secondaryColor
              : colors.baseForground
            : colors.baseForground,
        }}
        onClick={onClick}
      >
        {name}
      </span>
      <Fade big>
        <div
          className={`${
            open
              ? isSticky && totalQuantityLength >= 4 && totalQuantityLength <= 8
                ? "customStyle"
                : isSticky && totalQuantityLength >= 9
                ? "customStyle2"
                : className
              : ""
          }`}
          style={{
            display: isOutside ? (open ? "flex" : "none") : "none",
            justifyContent: "center",
          }}
        >
          {array && <DataItem array={array} title={name} onClose={onClose} />}
          <i></i>
        </div>
      </Fade>
    </div>
  );
};

export default Tooltip;
