import React from "react";
import {
  cylender,
  engine,
  litros,
  make,
  model,
  valvas,
  version,
  year,
} from "../../common/constants";
import "../../Pages/pro-description/style.scss";

export const renderProDetail = (item, index) => {
  return (
    <tr key={index} tabIndex="-1">
      {item.maker != "" ? <td>{item.maker}</td> : <td>N/A</td>}
      {item.model != "" ? <td>{item.model}</td> : <td>N/A</td>}
      {item.year != "" ? <td>{item.year}</td> : <td>N/A</td>}
      {item.engine != "" ? <td>{item.engine}</td> : <td>N/A</td>}
      {item.cylinders != "" ? <td>{item.cylinders}</td> : <td>N/A</td>}
      {item.liters != "" ? <td>{item.liters}</td> : <td>N/A</td>}
      {item.valves != "" ? <td>{item.valves}</td> : <td>N/A</td>}
      {item.version != "" ? <td>{item.version}</td> : <td>N/A</td>}
    </tr>
  );
};
const CompatibleTable = ({ children }) => {
  const myRef = React.createRef();
  React.useEffect(() => {
    moveFocus();
  }, []);

  const moveFocus = () => {
    const node = myRef.current;
    node.addEventListener("keydown", function (e) {
      const active = document.activeElement;
      if (e.keyCode === 40 && active.nextSibling) {
        active.nextSibling.focus();
      }
      if (e.keyCode === 38 && active.previousSibling) {
        active.previousSibling.focus();
      }
    });
  };
  return (
    <table class="table table-bordered table-striped tablePopup">
      <thead>
        <tr>
          <th scope="col">{make}</th>
          <th scope="col">{model}</th>
          <th scope="col">{year}</th>
          <th scope="col">{engine}</th>
          <th scope="col">{cylender}</th>
          <th scope="col">{litros}</th>
          <th scope="col">{valvas}</th>
          <th scope="col">{version}</th>
        </tr>
      </thead>

      <tbody ref={myRef}>{children}</tbody>
    </table>
  );
};

export default CompatibleTable;
