import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { genericError } from "../../common/constants";
import { path } from "../../common/routes-names";
import ApplicationContext from "../../context-api/context";
import { getProductDetail } from "../../utils/api";

export default function useForm() {
  const { setAlertMsg, setOpenAlert } = useContext(ApplicationContext);
  //   for image gallery end
  const [position, setposition] = useState("left");
  const [bullets, setBullets] = useState(true);
  const [isStarFill, setIsStarFill] = useState(false);
  const history = useHistory();

  const queryString = window.location.search;
  useEffect(() => {
    setProductData([]);
    getProDetail();
  }, [queryString]);
  const id = encodeURIComponent(
    queryString.substring(queryString.lastIndexOf("?") + 4)
  );
  const [productData, setProductData] = useState([]);
  const getProDetail = async () => {
    await getProductDetail(id)
      .then((response) => {
        if (response.status === 200 && response?.data?.success) {
          setProductData(response.data.content);
          const isProductActive = response.data.content.active;
          if (!isProductActive) {
            history.replace(path.notFound);
          }
        } else {
          setAlertMsg(response?.exceptionMessage || genericError);
          setOpenAlert(true);
          // dispatch(openAlert());
          // dispatch(alertMsg(response.exceptionMessage));
        }
      })
      .catch((error) => {
        setOpenAlert(true);
        setAlertMsg(error);
        // dispatch(openAlert());
        // dispatch(alertMsg(error));
      });
  };
  return {
    position,
    setposition,
    bullets,
    setBullets,
    isStarFill,
    setIsStarFill,
    history,
    productData,
    setProductData,
    id,
  };
}
