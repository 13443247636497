import React, { useEffect, useContext } from "react";
import { getImageAttributeCompatibilites } from "../../utils/api";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { alertMsg, openAlert } from "../../Redux/reducers/alertSlice";
import ApplicationContext from "../../context-api/context";
export default function useForm({ tabIndex, id }) {
  const { alertMsg, setAlertMsg, openAlert, setOpenAlert } =
    useContext(ApplicationContext);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(tabIndex);
  const [loader, setLoader] = React.useState(false);
  const [data, setdata] = React.useState({
    attribute: [],
    compatibity: [],
    image: [],
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoader(true);
    await getImageAttributeCompatibilites(id)
      .then((response) => {
        if (response.status === 200) {
          setLoader(false);
          setdata({
            ...data,
            attribute: response.data.content.attributes,
            compatibity: response.data.content.compatibilities,
            image: response.data.content.images,
          });
        } else {
          setAlertMsg(response.ExceptionMessage);
          setOpenAlert(true);
          // dispatch(openAlert());
          // dispatch(alertMsg(response.ExceptionMessage));
        }
      })
      .catch((error) => {
        setAlertMsg(error);
        setOpenAlert(true);
        // dispatch(openAlert());
        // dispatch(alertMsg(error));
      });
  };
  const regexImg = (img) => {
    const image = img.replace("https:", "https");
    const pair = Array.from(image);
    pair.splice(5, 0, ":");
    return pair.join("");
  };
  const imageList = data.image.map((i) => {
    return {
      original: regexImg(i),
      thumbnail: regexImg(i),
    };
  });

  return {
    theme,
    data,
    setdata,
    loader,
    setLoader,
    value,
    setValue,
    handleChange,
    handleChangeIndex,
    regexImg,
    imageList,
  };
}
