import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { error } from "../../common/constants";
import { TopBar } from "../../Component";
import { Header } from "../../Component";
import { StickyHeader } from "../../Component";
import AlertBox from "../../Component/confirm-alert/alertBox";
import ApplicationContext from "../../context-api/context";
import { alertMsg, openAlert } from "../../Redux/reducers/alertSlice";
import { getHomeSection } from "../../utils/api";
import "./style.scss";

const MainHeader = ({
  isMenu,
  otherIconOnHeader,
  bottomLine,
  logoBig,
  isCartIcon,
  isFavIcon,
  isStickyCheckoutIcon,
}) => {
  const { setOpenAlert, setAlertMsg } = useContext(ApplicationContext);
  const [data, setData] = useState({
    section: [],
  });
  const dispatch = useDispatch();

  {
    /* Hint: for Temporary basis its commented navBar on the header */
  }
  // useEffect(() => {
  //   getSectionData();
  // }, []);
  // async function getSectionData() {
  //   await getHomeSection()
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setData({
  //           ...data,
  //           section: response.data.content,
  //         });
  //       } else {
  //         setOpenAlert(true);
  //         setAlertMsg(response.ExceptionMessage);

  //         // dispatch(openAlert());
  //         // dispatch(alertMsg(response.ExceptionMessage));
  //       }
  //     })
  //     .catch((error) => {
  //       setOpenAlert(true);
  //       setAlertMsg(error);
  //       // dispatch(openAlert());
  //       // dispatch(alertMsg(error));
  //     });
  // }
  return (
    <>
      <TopBar logoBig={logoBig} />
      <Header
        IconOnHeader={otherIconOnHeader}
        Icon={bottomLine}
        isCartIcon={isCartIcon}
        isFavIcon={isFavIcon}
      />
      {otherIconOnHeader === false || bottomLine ? (
        <div className="bottomline" />
      ) : null}
      {/* Hint: for Temporary basis its commented navBar on the header */}
      {/* {isMenu === true ? <StickyHeader sectionArray={data.section} /> : null} */}
      {isMenu === true ? (
        <StickyHeader isStickyCheckoutIcon={isStickyCheckoutIcon} />
      ) : null}
      <div className="header-extra-space" />
    </>
  );
};
export default MainHeader;
