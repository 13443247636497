import {axiosInstance, axiosToken, axiosMultiPartFormData} from "./api";

const user = "/User";
const home = "/Home";
const vehicles = "/Vehicles";
const product = "/Product";
const cart = "/Cart";
const checkout = "/Checkout";
const tracking = "/Tracking";

const endpoints = {
  UPDATE_USER_INFO: `${user}/UpdateUserInfo`,
  UPDATE_USER_PASSWORD: `${user}/UpdateUserPassword`,
  USER_GETORDERS: `${user}/GetAccountOrdersV2`,
  GET_ACCOUNT_ORDER_ITEMS: `${user}/GetAccountOrderItems`,
  USER_GET_USERINVOICES: `${user}/GetUserInvoices`,
  USER_GET_USERADDRESS: `${user}/GetAddresses`,
  USER_GET_USER_INVOICE: `${user}/GetUserInvoice`,
  USER_GET_USER_Address: `${user}/GetAddressByID`,
  DELETE_USER_INVOICE: `${user}/DeleteUserInvoice`,
  DELETE_USER_ADDRESS: `${user}/DeleteUserAddress`,
  USER_ADD_UPDATE_USERINVOICES: `${user}/AddUpdateUserInvoice`,
  USER_GETUSER_INFO: `${user}/GetUserInfo`,
  USER_LOGIN: `${user}/Login`,
  USER_RESGISTER_USER: `${user}/RegisterUser`,
  USER_FORGET_REQ: `${user}/RequestForgotPassword`,
  USER_VERIFY_TOKEN_FORGOT_PASSWORD: `${user}/ValidateForgotPasswordToken`,
  USER_RESET_PASSWORD: `${user}/ResetForgottenPassword`,
  SEND_ACCOUNTACTIVATION_EMAIL: `${user}/SendAccountActivationEmail`,
  ActivateAccountEmail: `${user}/ActivateAccountEmail`,
  POST_PROBLEM_WITH_ORDER: `${user}/ProblemWithOrder`,
  MAINTAIN_USER_LOG: `${user}/SaveUserSearchText`,
  HOME_RESPONSE: `${home}/GetHomeResponse`,
  PARTNER_RESPONSE: `${home}/GetPartnerLogos`,
  HOME_SECTION: `${home}/GetSections`,
  SECTION_CATEGORY: `${home}/GetSectionCategories/`,
  GET_MAKERS: `${vehicles}/GetMakers`,
  GET_MODEL: `${vehicles}/GetModelsByMaker/`,
  GET_YEAR: `${vehicles}/GetYearsByModelandMaker/`,
  GET_PRODUCT: `${product}/GetProducts/`,
  MARKAS_FAVOURITE: `${product}/MarkAsFavorite/`,
  GET_FAVOURITEPRODUCT: `${product}/GetFavoriteProducts/`,
  GET_IMAGE_ATTRIBUTE: `${product}/GetImageAttributeCompatibilites/`,
  GET_KEYWORDS_SEARCH: `${product}/GetKeywordExplorer?searchText=`,
  withVehicle: "&vehicle=",
  GET_PRODUCT_DETAIL: `${product}/GetProductDetail?Id=`,
  SEND_PRODUCT_REQUEST: `${product}/SendProductRequestEmail?productId=`,
  GET_USERCART: `${cart}/ListCartProducts`,
  EMPTY_CART: `${cart}/EmptyCart`,
  ADD_CARTITEM: `${cart}/UpdateCartItem`,
  GET_CHECKOUT: `${checkout}/GetCheckout`,
  GET_CHECKOUTADDRESSES: `${checkout}/GetCheckoutAddresses`,
  GET_CHECKOUTCARDS: `${checkout}/GetCheckoutCards`,
  GET_CHECKOUTINVOICES: `${checkout}/GetCheckoutINVOICES`,
  GET_ZIPCODEDETAIL: `${checkout}/GetZipCodeDetail/`,
  ADD_CREATEADDRESS: `${checkout}/CreateAddress/`,
  ADD_NEWINVOICE: `${checkout}/CreateInvoice/`,
  SUBMIT_ORDER: `${checkout}/SubmitOrder`,
  APPLY_COUPON: `${checkout}/ApplyCoupon`,
  REMOVE_COUPON: `${checkout}/RemoveCoupon`,
  UPDATE_WELCOME_COUNT: `${tracking}/SaveQRCodeLog`,

  PLACEORDER: `${checkout}/PlaceOrder`,
  ADD_UPDATE_USER_INVOICE: `${user}/AddUpdateUserInvoice`,
  ADD_UPDATE_USER_ADDRESS: `${user}/CreateUpdateAddress`
};
// searchText=&vehicle=
export const registerUser = async data => {
  try {
    let result = await axiosInstance.post(`${endpoints.USER_RESGISTER_USER}`, data);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const loginUser = async data => {
  try {
    let result = await axiosInstance.post(`${endpoints.USER_LOGIN}`, data);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const getHomeResponse = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.HOME_RESPONSE}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const PostProblemWithOrder = async data => {
  try {
    let result = await axiosMultiPartFormData.post(`${endpoints.POST_PROBLEM_WITH_ORDER}`, data);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const UserSearchLogMaintain = async data => {
  try {
    let result = await axiosInstance.post(`${endpoints.MAINTAIN_USER_LOG}?text=${data}`);
    return result;
  } catch (error) {
    return error;
  }
};
export const ApplyCoupon = async coupon => {
  try {
    let result = await axiosInstance.get(`${endpoints.APPLY_COUPON}?coupon=${coupon}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const RemoveCoupon = async coupon => {
  try {
    let result = await axiosInstance.get(`${endpoints.REMOVE_COUPON}?coupon=${coupon}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const GetPartnerLogos = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.PARTNER_RESPONSE}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};

export const getHomeSection = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.HOME_SECTION}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const getHomeSectionById = async id => {
  try {
    let result = await axiosInstance.get(`${endpoints.SECTION_CATEGORY}${id}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const getMakers = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.GET_MAKERS}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const getModelByMaker = async id => {
  try {
    let result = await axiosInstance.get(`${endpoints.GET_MODEL}${id}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const getYearByModelandMaker = async (id, id2) => {
  try {
    let result = await axiosInstance.get(`${endpoints.GET_YEAR}${id}/${id2}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const getProducts = async data => {
  try {
    let result = await axiosInstance.post(`${endpoints.GET_PRODUCT}`, data);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const getImageAttributeCompatibilites = async id => {
  try {
    let result = await axiosInstance.get(`${endpoints.GET_IMAGE_ATTRIBUTE}${id}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const getKeywordExplorer = async searchText => {
  try {
    let result = await axiosInstance.post(`${endpoints.GET_KEYWORDS_SEARCH}${searchText}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const getKeywordWithVehicle = async (searchText, vehicle) => {
  try {
    let result = await axiosInstance.post(`${endpoints.GET_KEYWORDS_SEARCH}${searchText}${endpoints.withVehicle}${vehicle}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const GetAccountOrders = async (filter, page) => {
  try {
    let result = await axiosInstance.get(`${endpoints.USER_GETORDERS}?filter=${filter}&page=${page}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const GetAccountOrderItems = async saleId => {
  try {
    let result = await axiosInstance.get(`${endpoints.GET_ACCOUNT_ORDER_ITEMS}?saleId=${saleId}`);
    return result;
  } catch (error) {
    return error?.response?.data;
  }
};
export const GetUserInvoices = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.USER_GET_USERINVOICES}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const GetUserAddresses = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.USER_GET_USERADDRESS}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const GetInvoice = async id => {
  try {
    let result = await axiosInstance.get(`${endpoints.USER_GET_USER_INVOICE}?invoiceID=${id}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const GetUserAddress = async id => {
  try {
    let result = await axiosInstance.get(`${endpoints.USER_GET_USER_Address}?addressId=${id}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const AddUpdateUserInvoices = async data => {
  try {
    let result = await axiosInstance.post(`${(endpoints.USER_ADD_UPDATE_USERINVOICES, data)}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const DeleteUserInvoice = async id => {
  try {
    let result = await axiosInstance.delete(`${endpoints.DELETE_USER_INVOICE}?invoiceId=${id}`);
    return result;
  } catch (error) {
    return error.response;
  }
};
export const DeleteUserAddress = async id => {
  try {
    let result = await axiosInstance.delete(`${endpoints.DELETE_USER_ADDRESS}?addressId=${id}`);
    return result;
  } catch (error) {
    return error.response;
  }
};
export const GetUserInfo = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.USER_GETUSER_INFO}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const getProductDetail = async id => {
  try {
    let result = await axiosInstance.get(`${endpoints.GET_PRODUCT_DETAIL}${id}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const sendProductRequest = async id => {
  try {
    let result = await axiosInstance.get(`${endpoints.SEND_PRODUCT_REQUEST}${id}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const getCart = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.GET_USERCART}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const emptyCartRequest = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.EMPTY_CART}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const addItemToCart = async data => {
  try {
    let result = await axiosInstance.post(`${endpoints.ADD_CARTITEM}`, data);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const requestForgotPassword = async email => {
  try {
    let result = await axiosInstance.post(`${endpoints.USER_FORGET_REQ}?email=${email}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const verifyUserForgotToken = async data => {
  try {
    let result = await axiosInstance.post(`${endpoints.USER_VERIFY_TOKEN_FORGOT_PASSWORD}`, data);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const resetUserPassword = async data => {
  try {
    let result = await axiosInstance.post(`${endpoints.USER_RESET_PASSWORD}`, data);
    return result;
  } catch (error) {
    return error.response.data;
  }
};

//checkout screen With Url Token
export const getCheckoutWithToken = async data => {
  try {
    let result = await axiosToken.get(`${endpoints.GET_CHECKOUT}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};

//Checkout Screen APIS
export const getCheckout = async data => {
  try {
    let result = await axiosInstance.get(`${endpoints.GET_CHECKOUT}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const getCheckoutAddresses = async data => {
  try {
    let result = await axiosInstance.get(`${endpoints.GET_CHECKOUTADDRESSES}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
//checkout screen With Url Token card
export const getCheckoutCardsWithToken = async data => {
  try {
    let result = await axiosToken.get(`${endpoints.GET_CHECKOUTCARDS}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const getCheckoutCards = async data => {
  try {
    let result = await axiosInstance.get(`${endpoints.GET_CHECKOUTCARDS}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const sendAccountActivationEmail = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.SEND_ACCOUNTACTIVATION_EMAIL}?isMobile=false`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const activateEmail = async id => {
  try {
    let result = await axiosInstance.get(`${endpoints.ActivateAccountEmail}?param=${id}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const getCheckoutInvoices = async data => {
  try {
    let result = await axiosInstance.get(`${endpoints.GET_CHECKOUTINVOICES}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const placeOrder = async data => {
  try {
    let result = await axiosInstance.post(`${endpoints.PLACEORDER}`, data);
    return result;
  } catch (error) {
    return error.response.data;
  }
};

export const getZipCodeDetail = async code => {
  try {
    let result = await axiosInstance.get(`${endpoints.GET_ZIPCODEDETAIL}${code}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const updateUserInfo = async data => {
  try {
    let result = await axiosInstance.post(endpoints.UPDATE_USER_INFO, data);
    return result;
  } catch (err) {
    return err.response.data;
  }
};
export const updateUserPassword = async data => {
  try {
    let result = await axiosInstance.post(endpoints.UPDATE_USER_PASSWORD, data);
    return result;
  } catch (err) {
    return err.response.data;
  }
};
export const addAddress = async data => {
  try {
    let result = await axiosInstance.post(`${endpoints.ADD_CREATEADDRESS}`, data);
    return result;
  } catch (error) {
    return error.response.data;
  }
};

export const createNewInvoice = async data => {
  try {
    let result = await axiosInstance.post(`${endpoints.ADD_NEWINVOICE}`, data);
    return result;
  } catch (error) {
    return error.response.data;
  }
};

export const submitOrder = async requestBody => {
  try {
    let result = await axiosInstance.post(`${endpoints.SUBMIT_ORDER}`, requestBody);
    return result;
  } catch (error) {
    return error.response.data;
  }
};

export const AddUpdateUserInvoice = async data => {
  try {
    let result = await axiosInstance.post(`${endpoints.ADD_UPDATE_USER_INVOICE}`, data);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const AddUpdateUserAddress = async data => {
  try {
    let result = await axiosInstance.post(`${endpoints.ADD_UPDATE_USER_ADDRESS}`, data);
    return result;
  } catch (error) {
    return error.response.data;
  }
};

export const getFavouriteProducts = async data => {
  try {
    let result = await axiosInstance.get(`${endpoints.GET_FAVOURITEPRODUCT}`, data);
    return result;
  } catch (error) {
    return error.response.data;
  }
};

export const postFavouritePro = async data => {
  try {
    let result = await axiosInstance.post(`${endpoints.MARKAS_FAVOURITE}`, data);
    return result;
  } catch (error) {
    return error.response.data;
  }
};

export const getTheCountUpdated = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.UPDATE_WELCOME_COUNT}`);
    return result;
  } catch (error) {
    return error.response.data;
  }
}
