import axios from "axios";

let BASE_URL = process.env.REACT_APP_BASE_API_URL;
// let BASE_URL =
//   window.location.host === hostName ? productionBaseUrl : developmentBaseUrl;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});
const axiosMultiPartFormData = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "multipart/form-data",
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*"
  }
});

const axiosToken = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});
function useQuery() {
  return new URLSearchParams(window.location.search);
}

axiosToken.interceptors.request.use(function (config) {
  // Do something before request is sent
  const token = useQuery().get("token");
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
});

axiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent
  let token = localStorage.getItem("sessionToken");
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
});
axiosMultiPartFormData.interceptors.request.use(function (config) {
  // Do something before request is sent
  let token = localStorage.getItem("sessionToken");
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const {status} = error.response;
    if (status === 401) {
      let token = localStorage.getItem("sessionToken");
      console.log("Token in local storage is 786", token);
      if (token) {
        localStorage.setItem("sessionToken", ``);
        window.location.href = "/";
      }
    }
  }
);

export {axiosInstance, axiosToken, axiosMultiPartFormData};
