import React, { useEffect, useState, useContext } from "react";
import "./style.scss";
import Popup from "../../Component/popup";
import {
  changeVehicle,
  chooseVehicle,
  filter,
  selectVehicle,
} from "../../common/constants";
import SortedProducts from "../../Sections/sorted-products";
import SideBar from "../../Sections/side-bar";
import MainHeader from "../../Sections/header";
import ProBanner from "../../Sections/product-banner";
import { metaPath } from "../../common/services";
import MainLayout from "../../Component/layout/layout";
import useForm from "./useForm";
import { ModelLogin } from "../../Component";
import { emailCredential, passCredential } from "../../common/constants";
import { useDispatch } from "react-redux";
import ApplicationContext from "../../context-api/context";
import { getMakers } from "../../utils/api";

const SelectedVehicle = () => {
  const {
    openPopup,
    setOpenPopup,
    errorMsg,
    addCar,
    productItem,
    loader,
    encodeMake,
    encodeModel,
    pageProduct,
  } = useForm();

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loaderLogin, setLoaderLogin] = useState(false);

  const {
    setMakersList,
    makersList,
    setSearchbarActive,
    setAlertMsg,
    setOpenAlert,
    setShowLogInPopModel,
    setIsLoggedIn,
  } = useContext(ApplicationContext);
  const checkUserauthentication = () => {
    setLoaderLogin(true);
    if (email === emailCredential && password === passCredential) {
      setTimeout(() => {
        setLoaderLogin(false);
        setShowLogInPopModel(false);
        setIsLoggedIn(true);
      }, 1500);
    } else {
      console.log("not match ");
    }
  };
  useEffect(() => {
    if (makersList.length === 0) {
      getMakerList();
    }
    setSearchbarActive(true);
    // setTimeout(() => {
    // }, 6000);
  }, []);

  const getMakerList = async () => {
    await getMakers()
      .then((response) => {
        if (response.status === 200) {
          setMakersList(response.data.content);
        }
      })
      .catch((error) => {
        setAlertMsg(error);
        setOpenAlert(true);
      });
  };
  return (
    <MainLayout metaTitle={metaPath()} errorText={errorMsg}>
      <MainHeader isMenu otherIconOnHeader={true} logoBig={true} />
      <section className="selectedItemPage">
        <span className="filterIconOuter" onClick={() => setOpenPopup(true)}>
          <span className="fas fa-filter filterIcon"></span>
        </span>
        <Popup
          title={filter}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          overFlowClass="overflowX"
        >
          <SideBar
            loader={loader}
            brandList={productItem.brands}
            dynamicList={productItem.dynamicFilter}
            categories={productItem.categories}
          />
        </Popup>
        <div className="inner">
          <div className="proBannerOuter">
            <ProBanner
              proName={
                addCar.length != 0 && addCar.make != null
                  ? `${decodeURI(encodeMake)}${" "}${decodeURI(
                      encodeModel
                    )}${" "}${decodeURI(addCar.year?.value)}`
                  : chooseVehicle
              }
              proLink={
                addCar.length != 0 && addCar.make != null
                  ? changeVehicle
                  : selectVehicle
              }
              make={decodeURI(encodeMake)}
              model={decodeURI(encodeModel)}
              year={decodeURI(addCar.year?.value)}
            />
          </div>
          <div className="container add">
            <div className="sideBar">
              <SideBar
                loader={loader}
                brandList={productItem.brands}
                dynamicList={productItem.dynamicFilter}
                categories={productItem.categories}
              />
            </div>
            <div className="showProducts">
              <SortedProducts
                product={pageProduct}
                listView
                loader={loader}
                categories={productItem.categories}
              />
            </div>
          </div>
        </div>
      </section>
      {/* // we can put these states to global as well for for time bieng it is
      local */}

      <ModelLogin
        email={setEmail}
        password={setPassword}
        onPress={() => checkUserauthentication()}
        loading={loaderLogin}
      />
    </MainLayout>
  );
};
export default SelectedVehicle;
