import React from "react";
import {
  begnning,
  hadThisRemove,
  pageLookingFor,
  pageNotFound,
} from "../../common/constants";
import { backError, error404 } from "../../common/Images";
import { CustomButton } from "..";
import "./style.scss";
import { path } from "../../common/routes-names";
import { useHistory } from "react-router";
import { Grid } from "@material-ui/core";
import LazyBackground from "../imgLoader/lazyBackground";
import Images from "../imgLoader";

const PageNotFound = () => {
  const history = useHistory();
  return (
    <Grid xs={12} className="">
      <LazyBackground
        src={backError}
        circularStyle="backPhotoSkelton"
        className="d-block w-100 backPhoto"
        skeltonStyle="d-block w-100 backPhotoSkelton"
      >
        <div className="contentAbsolute">
          <div className="errorImg">
            <Images src={error404} width={200} height={80} />
          </div>
          <br></br>
          <h2 className="warning">{pageNotFound}</h2>
          <p className="warningalert"> {pageLookingFor}</p>
          <p className="warningalert"> {hadThisRemove}</p>
          <div className="btnContainer">
            <CustomButton
              title={begnning}
              className="buttonBack"
              onClick={() => history.push(path.home)}
            />
          </div>
        </div>
      </LazyBackground>
    </Grid>
  );
};

export default PageNotFound;
