import * as React from "react";
import { Dialog, styled, Fade } from "@material-ui/core";

import "./style.scss";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paperWidthSm": {
    minWidth: "sm",
    maxWidth: "500px",
  },
  "& .MuiDialog-paperScrollPaper": {
    // Temprary increased height from 450px to 600px
    maxHeight: "600px",
  },
  "& .MuiDialog-paper": {
    oveflow: "hidden",
    marginTop: "30px",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="left" ref={ref} {...props} />;
});
const CustomDialogs = (props) => {
  const { children, onClose, open, popheight, ...other } = props;

  return (
    <BootstrapDialog
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="customized-dialog-title"
      open={open}
      minWidth="40rem"
      fullWidth={true}
    >
      {children}
    </BootstrapDialog>
  );
};

export default CustomDialogs;
