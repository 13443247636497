import React from "react";
import "../billingBox/style.scss";
import { Skeleton } from "@material-ui/lab";

function SkeletonBox() {
  return (
    <div className="py-1">
      <div className="detailDiv mt-2 mb-2">
        <div className="addNewText">
          <div>
            <Skeleton height={60} width={"3rem"} animation="wave" />
          </div>
          <div>
            <Skeleton
              animation="wave"
              height={12}
              width={"22rem"}
              style={{
                marginLeft: "1rem",
                marginRight: "2rem",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonBox;
