import React, { useState, useContext, useEffect } from "react";
import "./Header.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Cart from "../../Sections/side-menu-cart/cart";
import {
  mainLogo,
  searchIcon,
  userIcon,
  favIcon,
  arrowDown,
  companyLogo,
} from "../../common/Images";
import { path } from "../../common/routes-names";
import SearchBar from "../searchBar";
import SearchData from "../../common/Data.json";
import SearchDialog from "../searchBar/searchModal";
import { ModelLogin } from "./../index";
import { login } from "./../../Redux/reducers/authSlice";
import {
  favourites,
  searchBars,
  myAccount,
  nivel,
  Rizwan,
  garage,
  hello,
  signUp,
} from "../../common/constants";
import { Badge } from "@material-ui/core";
import { CloseButton } from "..";
import BannerModal from "../../Sections/product-banner/pBannerModal";
import SelectVehicle from "../SelectVehicle";
import MyAccountMenu from "./DropMenu/myAccountMenu";
import GarageMenu from "./DropMenu/garage";
import FavMenu from "./DropMenu/favMenu";
import Images from "../imgLoader";
import { loginUser } from "./../../utils/api";
import {
  closeSearchBar,
  closeVehicle,
  openSearchBar,
  openVehicle,
  alertMsg,
  openAlert,
} from "../../Redux/reducers/alertSlice";
import useForm from "./useForm";
import { useDispatch } from "react-redux";
import {
  makeVehicle,
  selectedVehical,
} from "../../Redux/reducers/vehicleSlice";
import ApplicationContext from "./../../context-api/context";
import AlertBox from "../confirm-alert/alertBox";
import CustomDialogs from "../../Component/customModal/modalAlert";
import { Confirm_Alert } from "../../Component";
import CustomizedDialogs from "../customModal/Modal";

const Header = ({ IconOnHeader, isCartIcon, isFavIcon }) => {
  const {
    openModal,
    clearGarage,
    onDelete,
    alertModel,
    setAlertModel,
    alertPopup,
    setAlertPopup,
    GarageCars,
    dispatch,
    history,
    openSearch,
  } = useForm();
  const [openLoginModel, setOpenLoginModel] = useState(false);
  const [openCartPanel, setOpenCartPanel] = useState(false);
  const {
    isLoggedIn,
    setIsLoggedIn,
    cartList,
    isOpenLoginModel,
    setIsOpenLoginModel,
    getUserDataFromLocalStorage,
    setTotalQuantityLength,
    searchbarActive,
    openVehicle,
    setOpenVehicle,
    openSearchBar,
    setOpenSearchBar,
    vehicleData,
    setVehicleData,
    setSearchedVehical,
    favoriteData,
    setFavoriteData,
  } = useContext(ApplicationContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [garageLength, setGarageLength] = useState();

  const renderLoginAPI = async () => {
    const response = await loginUser({
      userName: username,
      password: password,
    });
    if (response && response.data) {
      const {
        data: { success, description, content },
      } = response;
      if (!success) {
        setLoginError(description);
        return false;
      }
      if (content) {
        const { token, user } = content;
        localStorage.setItem("sessionToken", `bearer ${token}`);
        localStorage.setItem("UserData", JSON.stringify(user));
        setOpenLoginModel(false);
        setIsLoggedIn(true);
        setIsOpenLoginModel(false);
      }
    }
  };

  const getCartTotalValues = () => {
    let totalQuantity = 0;
    let totalAmount = 0;
    if (cartList && cartList.length) {
      totalQuantity =
        cartList.reduce((qty, item) => (qty = qty + item.quantity), 0) || 0;
      totalAmount =
        parseFloat(
          cartList.reduce(
            (qty, item) =>
              (qty = qty + parseFloat(item.price) * parseInt(item.quantity)),
            0
          )
        ).toFixed(2) || 0;
    }

    return {
      totalQuantity,
      totalAmount,
    };
  };

  useEffect(() => {
    if (getCartTotalValues().totalQuantity !== 0) {
      const quantityLength = Array.from(
        String(getCartTotalValues().totalQuantity),
        Number
      );
      if (quantityLength.length > 0) {
        setTotalQuantityLength(quantityLength.length);
      }
    }
  }, [getCartTotalValues().totalQuantity]);
  return (
    <>
      <header className="container add" style={{ marginBottom: "8px" }}>
        <div className="clogo">
          <Link to={path.home} className="topBar-logoWrapper">
            <img src={companyLogo} alt="Company Logo" />
          </Link>
        </div>
        <div className="inner serachBar">
          <div
            className={
              isLoggedIn &&
              getUserDataFromLocalStorage().name.length >= 17 &&
              getCartTotalValues().totalAmount.length <= 7
                ? "searchArea SV"
                : isLoggedIn &&
                  getUserDataFromLocalStorage().name.length >= 17 &&
                  getCartTotalValues().totalAmount.length >= 10 <= 22
                ? "searchArea KV"
                : isLoggedIn &&
                  getUserDataFromLocalStorage().name.length >= 10 &&
                  getCartTotalValues().totalAmount.length <= 7
                ? "searchArea LV"
                : isLoggedIn &&
                  getUserDataFromLocalStorage().name.length >= 10 &&
                  getCartTotalValues().totalAmount.length >= 10 <= 22
                ? "searchArea CV"
                : "searchArea DV"
            }
            // onClick={() => searchbarActive && setOpenSearchBar(true)}
            onClick={() => setOpenSearchBar(true)}
          >
            <div className="inner">
              <div className="searchicon">
                <div className="searchText">
                  <span>{searchBars}</span>
                </div>
                <button>
                  <img src={searchIcon} className="searchIcon" />
                </button>
              </div>
            </div>
          </div>
          <div
            className={
              isLoggedIn && getUserDataFromLocalStorage().name.length >= 10
                ? "userInfo user"
                : "userInfo"
            }
          >
            <ul className={IconOnHeader == false && "checkoutPage"}>
              <li className="usrD">
                <div className="icon">
                  <img src={userIcon} alt="" className="img-fluid" />
                </div>
                <div
                  className="detail"
                  style={{
                    width:
                      isLoggedIn &&
                      getUserDataFromLocalStorage().name.length >= 16 &&
                      "11rem",
                  }}
                >
                  <span className="heading">
                    {myAccount}
                    <span className="arrow add">
                      <img src={arrowDown} alt="" />
                    </span>
                  </span>
                  <span className="subHeading">
                    {hello}
                    {!isLoggedIn
                      ? signUp
                      : `${getUserDataFromLocalStorage().name}`}
                  </span>
                </div>
                <MyAccountMenu
                  level={nivel}
                  isLogin={isLoggedIn ? true : false}
                  onCheckout={IconOnHeader === false && true}
                />
              </li>
              {IconOnHeader === false ? null : (
                <>
                  <li className="garageD">
                    <div className="icon">
                      <Badge
                        badgeContent={
                          GarageCars.length != 0 ? garageLength : null
                        }
                        color="primary"
                        overlap="circular"
                      >
                        <img
                          src="/images/garage1.svg"
                          alt=""
                          className="img-fluid"
                        />
                      </Badge>
                    </div>

                    <div className="detail">
                      <span className="heading">
                        {garage}
                        <span className="arrow">
                          <img src={arrowDown} alt="" />
                        </span>
                      </span>
                    </div>
                    <GarageMenu
                      isLogin={GarageCars.length != 0 ? true : false}
                      onAddVehicle={() => setOpenVehicle(true)}
                      // onAddVehicle={() => dispatch(openVehicle())}

                      garageList={GarageCars}
                      onDelete={onDelete}
                      clearGarage={() => setAlertModel(true)}
                      // clearGarage={() => }
                      setGarageLength={setGarageLength}
                    />
                  </li>
                  {isFavIcon ? null : (
                    <li className="favD">
                      <div
                        className="icon"
                        onClick={() => {
                          isLoggedIn
                            ? history.push(path.favourite)
                            : history.push(path.home);
                        }}
                      >
                        <Badge
                          badgeContent={
                            favoriteData?.length > 9
                              ? "9+"
                              : favoriteData?.length || 0
                          }
                          color="primary"
                          overlap="circular"
                        >
                          <img src={favIcon} alt="" className="img-fluid" />
                        </Badge>
                      </div>
                      <div
                        className="detail"
                        onClick={() => {
                          isLoggedIn
                            ? history.push(path.favourite)
                            : history.push(path.home);
                        }}
                      >
                        <span className="heading">
                          {favourites}
                          <span className="arrow">
                            <img src={arrowDown} alt="" />
                          </span>
                        </span>
                      </div>
                      <FavMenu isLogin={isLoggedIn ? true : false} />
                    </li>
                  )}
                  {isCartIcon ? null : (
                    <li>
                      <Cart
                        openCartPanel={openCartPanel}
                        isLoggedIn={isLoggedIn}
                        price={getCartTotalValues().totalAmount}
                        quantity={getCartTotalValues().totalQuantity}
                        openLoginPopup={() => {
                          console.log("working funtion");
                          setIsOpenLoginModel(true);
                        }}
                      />
                    </li>
                  )}
                </>
              )}
            </ul>
          </div>
          <div
            className={
              IconOnHeader == false ? "searchArea search-MV" : "searchArea MV"
            }
            style={{ marginTop: IconOnHeader == false && ".7rem" }}
            onClick={() => setOpenSearchBar(true)}
            // onClick={() => dispatch(openSearchBar())}
          >
            <div className="inner">
              <div className="searchicon">
                <div className="searchText">
                  <span>{searchBars}</span>
                </div>
                {/* <input type="search" placeholder={searchBars} /> */}
                <button>
                  <img src={searchIcon} className="searchIcon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      {
        <SearchDialog
          popupSize="md"
          openPopup={openSearch}
          headerVisible={false}
          setOpenPopup={() => {
            setOpenSearchBar(false);
            setVehicleData([]);
            // dispatch(closeSearchBar());
            // dispatch(makeVehicle("reset"));
          }}
          isPadding={false}
        >
          <SearchBar
            placeholder={searchBars}
            data={SearchData}
            onClose={() => {
              setOpenSearchBar(false);
              setVehicleData([]);
              // dispatch(closeSearchBar());
              // dispatch(makeVehicle("reset"));
            }}
          />
        </SearchDialog>
      }
      {
        <ModelLogin
          email={(value) => setUsername(value)}
          password={(value) => setPassword(value)}
          onPress={() => renderLoginAPI()}
          loading={""}
          error={loginError}
          showPopup={isOpenLoginModel}
        />
      }
      {
        <BannerModal
          popupSize="sm"
          openPopup={openModal}
          headerVisible={false}
          setOpenPopup={() => dispatch(closeVehicle())}
          isPadding={false}
        >
          <div className="modalDiv ">
            <CloseButton
              className="crossPosition"
              onClick={() => {
                setOpenVehicle(false);
                setSearchedVehical({});
              }}
              color="rgb(216, 216, 216)"
              fontSize="2rem"
            />
            <SelectVehicle
              isFixedHeight={true}
              modal={true}
              carModel={false}
              disableModal
              disableYear
            />
          </div>
        </BannerModal>
      }

      <CustomDialogs onClose={alertModel} open={alertModel}>
        <Confirm_Alert
          del={true}
          onCancel={() => setAlertModel(false)}
          onConfirm={() => clearGarage()}
        />
      </CustomDialogs>
    </>
  );
};
export default Header;
