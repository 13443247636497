import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  openModal: false,
  openPopup: false,
  openSearch: false,
  msg: "",
  productSelect: false,
  loginModel: false,
  checkOutTrue: "",
};
const alertSlie = createSlice({
  name: "alert",
  initialState,
  reducers: {
    openAlert: (state, { payload }) => {
      state.openModal = true;
    },
    closeAlert: (state, { payload }) => {
      state.openModal = false;
    },
    openVehicle: (state, { payload }) => {
      state.openPopup = true;
    },
    closeVehicle: (state, { payload }) => {
      state.openPopup = false;
    },
    alertMsg: (state, { payload }) => {
      state.msg = payload;
    },
    openSearchBar: (state, { payload }) => {
      state.openSearch = true;
    },
    closeSearchBar: (state, { payload }) => {
      state.openSearch = false;
    },
    selectSearchProduct: (state, { payload }) => {
      state.productSelect = payload;
    },
    setShowLogInPopModel: (state, { payload }) => {
      state.loginModel = payload;
    },
    setCheckOut: (state, { payload }) => {
      state.checkOutTrue = payload;
    },
  },
});

export const {
  openAlert,
  closeAlert,
  openVehicle,
  closeVehicle,
  alertMsg,
  openSearchBar,
  closeSearchBar,
  selectSearchProduct,
  setShowLogInPopModel,
  setCheckOut,
} = alertSlie.actions;

export default alertSlie.reducer;
