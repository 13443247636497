import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import {
  cartDetail,
  emptyBasket,
  emptyCart,
  inviteReturnTo,
  itemInCart,
  order,
  toBuy,
  pDescription,
  subTotal,
} from "../../common/constants";
import { tyreImage } from "../../common/Images";
import { path } from "../../common/routes-names";
import ItemOnCart from "../../Component/item-on cart";
import { CustomButton } from "../../Component";
import { ReactComponent as CartIcon } from "../../images/cart.svg";
import "./style.scss";
import {
  setShowLogInPopModel,
  setCheckOut,
} from "../../Redux/reducers/alertSlice";

import { useSelector, useDispatch } from "react-redux";
import ApplicationContext from "./../../context-api/context";
import { emptyCartRequest } from "../../utils/api";
import {
  alertMsg,
  openAlert,
  closeAlert,
} from "../../Redux/reducers/alertSlice";
import CustomizedDialogs from "../../Component/customModal/Modal";
import { Confirm_Alert } from "../../Component";
import { initiateCheckoutPixel } from "../../utils/fbPixelEvent";

export default function SideMenuCart({ closeBtn, cart }) {
  const {
    cartList,
    setCartList,
    isLoggedIn,
    setShowLogInPopModel,
    setCheckOut,
    favoriteData,
    setFavoriteData,
  } = useContext(ApplicationContext);
  let history = useHistory();
  const [data, setData] = useState(cartList);
  useEffect(() => {
    setData(cartList);
  }, [cartList]);
  const updatelatestQty = (Id, updateQty) => {
    let temp = [...data];
    temp.filter((p) => p.productID == Id)[0].quantity = updateQty;
    setCartList(temp);

    let favData = favoriteData;
    let index = favData.findIndex((s) => s.productID === Id);
    if (index > -1) {
      favData[index].quantity = updateQty;
    }
    setFavoriteData(favData);
  };

  const makeCartEmpty = async () => {
    return await emptyCartRequest()
      .then((response) => {
        if (response.status === 200) {
          let favQty = favoriteData;
          favQty.forEach((item) => {
            item.quantity = 0;
          });
          setFavoriteData(favQty);
          setCartList([]);
          setOpenModal(false);
        }
      })
      .catch((error) => {});
  };
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);

  const emptyCart1 = () => {
    setOpenModal(true);
  };
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="addToCartWrspper">
      <div className="inner">
        <div className="header">
          <p className="text">{cartDetail}</p>
          <span className="btnCross" onClick={() => closeBtn()}>
            x
          </span>
        </div>
        {data.length == 0 ? (
          <div className="empty">
            <div>
              <CartIcon />
            </div>
            <p className="text">{emptyCart}</p>
            <p className="inviteReturn">{inviteReturnTo}</p>
          </div>
        ) : (
          <div className="products">
            {data != undefined &&
              data.map(
                (i, index) =>
                  i.quantity !== 0 &&
                  i.quantity >= 0 && (
                    <ItemOnCart
                      url={i.image?.replace("https//", "https://")}
                      price={"$" + parseFloat(i?.price).toFixed(2)}
                      desc={i.productName}
                      qty={i.quantity}
                      productObject={i}
                      productId={i.productID}
                      pathName={i.url}
                      param={i.encryptedProductID}
                      updateQty={(value) => updatelatestQty(i.productID, value)}
                      cartList={cartList}
                      closeBtn={closeBtn}
                    />
                  )
              )}
          </div>
        )}
        <div className="cart-bottom">
          <div className="subTotalArea">
            <div className="bottomBtn">
              <a href="#" className="bottomBtn" onClick={() => emptyCart1()}>
                {emptyBasket}
              </a>
            </div>
            <div className="subtatal">
              <p className="productSummery">
                {data.reduce((qty, item) => (qty = qty + item.quantity), 0)}
                {/* {data.qtyTotal.quantity} */}
                {itemInCart}
              </p>
              <span className="subtotalAmount">
                {subTotal} $
                {numberWithCommas(
                  parseFloat(
                    data.reduce(
                      (qty, item) =>
                        (qty =
                          qty +
                          parseFloat(item.price) * parseInt(item.quantity)),
                      0
                    )
                  ).toFixed(2)
                )}
              </span>
            </div>
          </div>
          <div className="chk-btn">
            {data.length > 0 && (
              <CustomButton
                title={order}
                onClick={() => {
                  isLoggedIn
                    ? history.push(path.checkout)
                    : setShowLogInPopModel(true);
                  setCheckOut("checkout");
                  let userEmail = JSON.parse(
                    localStorage.getItem("UserData")
                  ).email;
                  initiateCheckoutPixel(userEmail);
                  // : dispatch(setShowLogInPopModel(true));
                  // dispatch(setCheckOut("checkout"));
                  closeBtn();

                  // : history.push({
                  //     pathname: path.login,
                  //     state: {
                  //       data: "checkout",
                  //     },
                  //   });
                  // history.push(path.checkout);
                }}
                className="checkOutBtn"
                endIcon={<span className="fas fa-arrow-right pl-2 m-0"></span>}
              />
            )}
          </div>
        </div>
      </div>

      {
        <CustomizedDialogs onClose={openModal} open={openModal}>
          <Confirm_Alert
            del={true}
            onCancel={() => setOpenModal(false)}
            onConfirm={() => makeCartEmpty()}
          />
        </CustomizedDialogs>
      }
    </div>
  );
}
