import React, { useState, useEffect } from "react";
import ApplicationContext from "./context";
import { getFavouriteProducts, GetInvoice } from "../utils/api";
const ContextProvider = ({ children }) => {
  const [addMakeList, setAddMakeList] = useState([]);
  const [searchedVehical, setSearchedVehical] = useState({});
  const [vehicleSubmit, setVehicleSubmit] = useState(false);
  const [openSelectVehicle, setOpenSelectVehicle] = useState(false);
  const [vehicleData, setVehicleData] = useState([]);
  const [checkOut, setCheckOut] = useState("");
  const [showLogInPopModel, setShowLogInPopModel] = useState(false);
  const [selectSearchProduct, setSelectSearchProduct] = useState(false);
  const [favoriteData, setFavoriteData] = useState();
  const [openVehicle, setOpenVehicle] = useState(false);
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [isIncrement, setIsIncrement] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCartMenu, setIsCartMenu] = React.useState(false);
  const [isFromRegisteration, setIsFromRegisteration] = useState(false);
  const [totalQuantityLength, setTotalQuantityLength] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const [priceSlider, setPriceSlider] = useState([1, 5000]);
  const [cartList, setCartList] = useState([]);
  const [isOpenLoginModel, setIsOpenLoginModel] = useState(false);
  const [selectedCheckoutBillingAddreess, setSelectedCheckoutBillingAddreess] =
    useState(null);
  const [
    selectedCheckoutShippingAddreess,
    setSelectedCheckoutShippingAddreess,
  ] = useState(null);
  const [selectedCheckoutPayment, setSelectedCheckoutPayment] = useState(null);
  const [makersList, setMakersList] = useState([]);
  const [banksCheckoutList, setBanksCheckoutList] = useState([]);
  const [storesCheckoutList, setStoresCheckoutList] = useState([]);
  const [purchaseReasonInvoiceList, setPurchaseReasonInvoiceList] = useState(
    []
  );
  const [regimenInvoiceList, setRegimenInvoiceList] = useState([]);
  const [checkoutFormValidation, setCheckoutFormValidation] = useState(false);
  const [searchbarActive, setSearchbarActive] = useState(false);
  const [invoiceData, setInvoiceData] = useState();
  const [recordForEdit, setRecordForEdit] = useState();
  const [singleAddressData, setSingleAddressData] = useState([]);
  const [partner, setPartner] = useState([]);

  const addUpdateCartItem = (cartProduct) => {
    const isExist =
      cartList.filter((item) => item.productID === cartProduct.productID)
        .length > 0;
    let tempData = [...cartList];
    if (isExist) {
      if (cartProduct.quantity === 0) {
        setIsDeleted(!isDeleted);
        tempData = tempData.filter(
          (item) => item.productID !== cartProduct.productID
        );
      } else {
        setIsDeleted(!isDeleted);
        tempData.filter(
          (item) => item.productID === cartProduct.productID
        )[0].quantity = cartProduct.quantity;
      }
    } else {
      setIsIncrement(!isIncrement);
      tempData.push(cartProduct);
    }
    setCartList(tempData);
  };

  const getTheUpdateProduct = (productId) => {
    let tempData = [...cartList];
    const productObject = tempData.filter(
      (item) => item.productID === productId
    )[0];
    if (productObject) {
      productObject.quantity = productObject.quantity || 0;
      return productObject;
    }
    return null;
  };

  const isProductExistInCart = (productId) => {
    return cartList.filter((item) => item.productID === productId).length > 0;
  };

  const getUserDataFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem("UserData"));
  };

  useEffect(() => {
    favoriteProduct();
  }, [isLoggedIn]);

  const favoriteProduct = async () => {
    if (isLoggedIn) {
      await getFavouriteProducts()
        .then((res) => {
          if (res && res.data.success) {
            setFavoriteData(res.data.content);
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  return (
    <ApplicationContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        priceSlider,
        setPriceSlider,
        cartList,
        setCartList,
        isOpenLoginModel,
        setIsOpenLoginModel,
        addUpdateCartItem,
        getTheUpdateProduct,
        isProductExistInCart,
        getUserDataFromLocalStorage,
        selectedCheckoutBillingAddreess,
        setSelectedCheckoutBillingAddreess,
        selectedCheckoutShippingAddreess,
        setSelectedCheckoutShippingAddreess,
        selectedCheckoutPayment,
        setSelectedCheckoutPayment,
        setBanksCheckoutList,
        banksCheckoutList,
        setStoresCheckoutList,
        storesCheckoutList,
        regimenInvoiceList,
        setRegimenInvoiceList,
        purchaseReasonInvoiceList,
        setPurchaseReasonInvoiceList,
        checkoutFormValidation,
        setCheckoutFormValidation,
        isFromRegisteration,
        setIsFromRegisteration,
        totalQuantityLength,
        setTotalQuantityLength,
        isSticky,
        setIsSticky,
        isCartMenu,
        setIsCartMenu,
        isDeleted,
        setIsDeleted,
        isIncrement,
        setIsIncrement,
        makersList,
        setMakersList,
        searchbarActive,
        setSearchbarActive,
        openAlert,
        setOpenAlert,
        alertMsg,
        setAlertMsg,
        openVehicle,
        setOpenVehicle,
        openSearchBar,
        setOpenSearchBar,
        selectSearchProduct,
        setSelectSearchProduct,
        showLogInPopModel,
        setShowLogInPopModel,
        checkOut,
        setCheckOut,
        vehicleData,
        setVehicleData,
        openSelectVehicle,
        setOpenSelectVehicle,
        vehicleSubmit,
        setVehicleSubmit,
        searchedVehical,
        setSearchedVehical,
        addMakeList,
        setAddMakeList,
        favoriteData,
        setFavoriteData,
        favoriteProduct,
        invoiceData,
        setInvoiceData,
        recordForEdit,
        setRecordForEdit,
        singleAddressData,
        setSingleAddressData,
        partner,
        setPartner,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ContextProvider;
