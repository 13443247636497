import React, { useRef, useState } from "react";
import "./side-template.scss";
import { NavLink } from "react-router-dom";
import { OutsideClickAlert } from "../../common/OutsideClickAlert";
export default function AccountSideMenu({ list }) {
  let [open, setOpen] = useState(false);
  const wrapperRef = useRef("menu");
  OutsideClickAlert(wrapperRef, () => {
    setOpen(false);
  });
  return (
    <>
      <section className="menu1 container add">
        <nav className="navbar navbar-expand-lg navbar-light " ref={wrapperRef}>
          <button
            onClick={() => setOpen(!open)}
            className="navbar-toggler position"
            type="button"
            data-toggle="collapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`toggleList ${open ? "active" : "inactive"}`}>
            <ul className="navbar-nav">
              {list.map((data) => (
                <li className="nav-item1">
                  <NavLink
                    key={data.id}
                    to={data.link}
                    activeClassName="sideTab sideTabActive"
                    className="sideTab"
                  >
                    <p className="TabText ">{data.name}</p>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </section>
    </>
  );
}
