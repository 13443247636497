export const cartDetail = "DETALLES DEL CARRITO";
export const emptyBasket = "Vaciar canasta";
export const subTotal = "Total parcial:";
export const itemInCart = " artículos en su carrito";
export const notAutherized = "¡No está autorizado para acceder a la pantalla!";
export const homeScreenLabels = {
  title: "thijgjh"
};
export const breakPoints = {
  mdScreen: "767"
};
export const productDescription = {
  TblAttributeHeading: "Caracteristicas",
  TblCompatibilidadesHeading: "Vehiculos Compatibles",
  productsAreaHeading: "Productos Destacados",
  ratingSummeryHeading: "Opiniones Sobre El Producto",
  ratingBoxTitle: "lo que más le gustó a la gente"
};
export const buttonList = [
  {id: 1, name: "Seguridad", link: "/cuenta/security"},
  {id: 2, name: "Pedidos", link: "/cuenta/orders"},
  {id: 3, name: "Facturacion", link: "/cuenta/billing"},
  {id: 4, name: "Direcciones", link: "/cuenta/address"},
  {id: 5, name: "Favoritos", link: "/cuenta/favourite"}
];
export const favourite = "favoritos:";
export const garage = "garage";
export const addToCart = "agregar al carrito";
export const buyTo = "comprar";
export const ShippingAddres = "dirección de envío:";
export const billingAddres = "facturación:";
export const AddNew = "nueva dirección de envío";
export const AddNewAddress = "nueva dirección de envío";
export const addBillingBtn = "agregar nueva facturación";
export const postalAddress = "código postal ";
export const postCode = "c.p.88275";
export const paymentStatusRejected = "rejected";
export const contAddress = "andador 24 de febrero 12-1 de mayo- ladrillos amarillos...";
export const expresser = "Expresar";
export const errMsgCard = "invalid parameter card Month";
export const errMsgInput = "La cadena de entrada no estaba en el formato correcto";
export const noOptionFound = "Sin opcion";
export const customStyles = {
  content: {
    border: "0",
    borderRadius: "4px",
    bottom: "auto",
    height: "auto",
    left: "50%",
    padding: "-2rem",
    position: "fixed",
    right: "auto",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "40rem",
    maxHeight: "40rem",
    height: "auto"
  }
};

export const privateRouteArray = ["/cuenta/security", "/cuenta/billing", "/cuenta/address", "/cuenta/favourite", "/cuenta/orders", "/cuenta", "/checkout"];

export const statusEnums = {
  pending: "1",
  confirmed: "2",
  shipping: "4",
  delivered: "5"
};
export const addPaymentBtn = "AGREGAR METODO DE PAGO";
export const addAddressBtn = "agregar nueva dirección";
export const shipmentAddressRquired = "Dirección requerido";
export const invoicesRquired = "se requiere factura";
export const paymentMethodRquired = "detalles de pago requerido";
export const editPayment = "Editar pago";
export const editBilling = "Editar facturación";
export const editAddress = "Editar dirección";
export const unitPrice = "Precio:";
export const totalUnitPrice = "Importe:";
export const products = "productos:";
export const paymentMethod = "método de pago:";
export const payWithMercadoPago = "Billetera de Mercado Pago";
export const debitCardPayment = "tarjeta de crédito/débito";
export const summary = "resumen de compra";
export const product = "productos";
export const shippingCost = "Total:";
export const shippingCostLabel = "Costo De Envio: ";
export const discount = "descuento:";
export const Total = "Articulos:";
export const CheckoutTotal = "Total:";
export const applyDiscount = "aplicar descuento";
export const apply = "Aplicar";
export const orderRealizer = "Realizar Pedido";
export const nyear25 = "nyear25";
export const RFC = "RFC";
export const creditDebit = "nueva tarjeta de crédito/débito";
export const Continue = "Seguir";
export const year = "Año";
export const additions = "Para Tu Vehiculo";
export const partnersLogo = "En Asociación con";

export const TokenKey = "APP_USR-6a33637c-fa43-4e98-b118-434a0595b631"; //used for Develop server
// export const TokenKey = "TEST-4cd5fbdd-b3e3-426c-97cf-89a015feab3b"; //used for locally test
export const month = "Mes";
export const payMethod = [
  {
    id: 2,
    isDefault: false,
    icon: "/images/Group1768.png",
    cardBrand: 1,
    paymentType: 2,
    name: "transfericia electrónica"
  },
  {
    id: 3,
    isDefault: false,
    cardBrand: 2,
    paymentType: 3,
    icon: "/images/Group1780.png",
    name: "Efectivo en puntos de pago"
  }
];
export const selectVehicle = "Seleccionar Vehículo";
export const changeVehicle = "cambiar de vehiculo";
export const chooseVehicle = "elige un vehículo";
export const toyota = "toyota tacoma 2020";

export const name = "Nombre:";
export const email = "Correo electrónico:";
export const emailVerify = "Verifique su dirección de correo electrónicoorreo electrónico";
export const emailVerifyBtn = "verificar correo";
export const phoneVerify = "enviar OTP para verificar el número";
export const otp = "enviar OTP";
export const phoneNum = "Número de teléfono:";
export const password = "contraseña:";
export const enterOldpassword = "ingrese su antigua contraseña";
export const enterNewpassword = "entra tu Nueva contraseña";
export const enterConfirmpassword = "entra tu Confirmar contraseña";
export const changPassword = "cambiar la contraseña:";
export const itsGood = "Es una buena idea utilizar una contraseña segura que no utilice en ningún otro lugar.";
export const current = "Actual";
export const newPass = "Nueva";
export const retypeNew = "Reescriba nueva";
export const edit = "Editar";
export const upgrade = "Actualizar";
export const add = "Agregar";
export const confirmar = "confirmar";
export const resetPass = "¿Restablecer su contraseña?";
export const forgetPass = "¿Olvidaste tu contraseña?";
export const order = "comprar";
export const min = "$ Min";
export const max = "$ Max";
export const category = "Categorías";
export const interior = "Interior";
export const brand = "Marca";
export const yes = "Sí";
export const no = "No";
export const price = "Precio";
export const filter = "filtrar";
export const rating = "calificaciones";

export const cancel = "Cancelar";
export const remove = "Eliminar";
export const areYouWant = "Desea eliminar los productos de su carrito?";
export const areYouSure = "¿Está seguro?";
export const outOfStockRequest = "Solicitar Producto?";
export const outOfStockDetails = "Este producto esta agotado, pero podemos conseguirtelo!";
export const searchBars = "Busque por marca año de modelo, Tipo de producto, número de pieza o marca ...";
export const enterWrong = "ha introducido una contraseña incorrecta";
export const incorretPassword = "contraseña incorrecta";
export const emailCredential = "abc@Okautopartes.com";
export const passCredential = "123456";

// for image gallery
export const productImages = [
  {
    original: "/images/image77.jpg",
    thumbnail: "/images/image77.jpg"
  },
  {
    original: "/images/image77.jpg",
    thumbnail: "/images/image77.jpg"
  },
  {
    original: "/images/image77.jpg",
    thumbnail: "/images/image77.jpg"
  }
];
// capability table detail end
// attribute table detail
export const goBack = "Volver";
export const first = "Valor";
export const make = "Fabricante";
export const model = "Modelo";
export const engine = "motor";
export const partNum = "Número de parte";
export const guideTracking = "Seguimiento de guia";
export const deliveredProductButtonText = "Tengo Un Problema";
export const amount = "cantidad";
export const available = "Disponibles";
export const coln = ":";
export const pcs = "pzs.";
export const perOff = " APAGADO";
export const featureProduct = "Productos Destacados";
export const welcomeText = "Bienvenidos a OkAutopartes. Te llevaremos al sitio en un rato.";

export const orderNum = "folio# ";
export const orderDetail = "Descargar Recibo";
export const invoiceOrder = "Factura";
export const viewInvoice = "Pagar";
export const orderDetailFactura = "Descargar Factura";
export const viewItem = "ver ítem";
export const confirmed = "Confirmado";
export const delivered = "Entregado";
export const shipped = "Enviado";
export const ordrBy = "Envia A";
export const orderPlaced = "pedido realizado";
export const orderItems = "encargar artículos";
export const toPay = "pagar";
export const pending = "Pendiente";
export const pDescription = "Aceite de motor totalmente sintético Mobil1 High Mileage 5W-30, 5 cuartos";
export const pDescription2 = "fram alto kilometraje 5W-30 completo Aceite de motor sintético, 5 cuartos.";

export const garageList = [
  {
    id: 1,
    name: "2017 Alfa Romeo 4C",
    off: true
  },
  {
    id: 2,
    name: "2017 Alfa Romeo 4C",
    off: false
  },
  {
    id: 3,
    name: "2017 Alfa Romeo 4C",
    off: false
  }
];
export const favList = [
  {
    id: 1,
    pDescription: "Aceite de motor totalmente sintético Mobil1 High Mileage 5W-30, 5 cuartos",
    img: "/images/image42.png",
    discountPrice: "35.99",
    originalPrice: "49.44"
  },
  {
    id: 2,
    pDescription: "Aceite de motor totalmente sintético Mobil1 High Mileage 5W-30, 5 cuartos",
    img: "/images/image42.png",
    discountPrice: "21.99",
    originalPrice: "43.54"
  },
  {
    id: 3,
    pDescription: "Aceite de motor totalmente sintético Mobil1 High Mileage 5W-30, 5 cuartos",
    img: "/images/image42.png",
    discountPrice: "32.99",
    originalPrice: "44.44"
  }
];
// header
export const login = "INICIAR SESIÓN";
export const signout = "CERRAR SESIÓN";
export const newUser = "¿Nuevo cliente?";
export const exsistAccount = "Ya tienes una cuenta?";
export const signUp = "Regístrate";
export const seeAllFav = "ver todos los favoritos";
export const favourites = "favoritos";
export const myGarage = "Mi garage";
export const OFF = "APAGNADO";
export const assigned = "APAGNADO";
export const findParts = "Encuentra partes y accesorios de manera mas sencilla.";
export const receiveProduct = "Recibe recomendaciones de productos para tu vehículo.";
export const saveAndOrganize = "Guarda Y Organiza Los Productos Favoritos O Compras Frecuntes";
export const clearAll = "limpiar todo";
export const addFav = "Agregar favoritos";
export const addVehicle = "agregar vehículo";
export const alfaRomeo = " 2017 Alfa Romeo 4C";
export const priceInDigit = "$49.44";
export const discountInDigit = "$35.99";
export const myAccount = "My Cuenta";
export const security = "Seguridad";
export const orders = "Pedidos";
export const addresses = "Direcciones";
export const billing = "Facturacion";
export const contactDetail = " dirección completa: ";
export const hola = "Hola Angel.";
export const nivel = "nivel 4 - okautopartes puntos";
export const Rizwan = "rizwan";
export const hello = "hola, ";
export const enterCouponCode = "Introduce el código de cupón";
export const secureTransaction = "Transacción segura";
export const MispartesCom = "Okautopartes.com";
export const itemReturnable = "este artículo es retornable";
export const shopByBrand = "Compra por marca";
export const brandText = "marca";
export const ImageRequired = "La imagen es obligatoria";

// order modal
export const questionsArray = [
  {
    question: "No Recibi Mi Producto / Orden Incompleta",
    index: 0
  },
  {
    question: "No es lo que pedi",
    index: 1
  },
  {
    question: "No es compatible con mi vehiculo",
    index: 2
  },
  {
    question: "Producto Danado",
    index: 3
  },
  {
    question: "Otro",
    index: 4
  }
];

export const whtIsYourProblem = "Que problema tienes con ";
export const contactUs = "Contactarnos";
export const requestReturn = "Solicitar Retorno";
export const send = "Enviar";
export const typeYourMsg = "Escribe tu mensaje";
export const uploadPhoto = "Subir Foto(Máximo 5)* ";
export const upload = "Subir";

export const atterDetail = [
  {
    id: "1",
    attributeName: "features & benefits",
    attributeValue:
      "	centric parts offer a full line of c-tek standard replacement brake and clutch parts for import and domesticvehicles.centric parts offer a full line of c-tek standard replacement brake and clutch parts for import and domestic vehicles."
  },
  {
    id: "2",
    attributeName: "product description",
    attributeValue: "	shimmed to eliminate noise and vibration. formulated for reduced noise. semi-metallic compounds."
  },
  {
    id: "3",
    attributeName: "weight - each (gross pounds)",
    attributeValue: "shimmed to eliminate noise and vibration. formulated for reduced noise. semi-metallic compounds."
  },
  {id: "4", attributeName: "product description", attributeValue: "	3.1000"}
];
// capability table detail
export const proDetail = [
  {
    id: "1",
    maker: "chevrolet",
    model: "	cruze",
    year: "2020",
    engine: "All",
    liters: "2.4",
    valves: "2020",
    cylinders: "All",
    version: "9"
  },
  {
    id: "2",
    maker: "chevrolet",
    model: "	cruze",
    year: "2020",
    engine: "All",
    liters: "2.4",
    valves: "2020",
    cylinders: "All",
    version: "9"
  },
  {
    id: "3",
    maker: "chevrolet",
    model: "	cruze",
    year: "2020",
    engine: "All",
    liters: "2.4",
    valves: "2020",
    cylinders: "All",
    version: "9"
  },
  {
    id: "4",
    maker: "chevrolet",
    model: "	cruze",
    year: "2020",
    engine: "All",
    liters: "2.4",
    valves: "2020",
    cylinders: "All",
    version: "9"
  }
];
export const opinion = "0 opiniones";
export const buyNow = "compra ahora";
export const cajaDe = "caja de apoyabrazos";
export const mark = "Marca :";
export const position = "posiciones :";
export const comment = "comentario :";
export const requestProduct = "solicitar producto";

export const application = "Aplicaciones :";
export const warranty = "Garantia:";
export const attributeBtn = "Atributos";
export const features = "Caracteristicas";
export const vehicles = "Vehiculos";
export const compatibiltyBtn = "Compatibilidades";
export const search = "Buscar...";
export const resultsNotFound = "(0) Resultados";
export const brandList = [
  {
    id: 11,

    label: "Somos Nosotros",
    type: "Marca"
  },
  {
    id: 12,

    label: "american shifter",
    type: "Marca"
  },
  {
    id: 13,

    label: "Grupo FH",
    type: "Marca"
  },
  {
    id: 14,

    label: "Proscar",
    type: "Marca"
  },
  {
    id: 15,

    label: "black mountain",
    type: "Marca"
  },
  {
    id: 16,
    label: "cobra seats",
    type: "Marca"
  },
  {
    id: 17,

    label: "bugatti",
    type: "Marca"
  },
  {
    id: 18,

    label: "foose",
    type: "Marca"
  }
];
export const ratingList = [
  {
    id: 211,
    label: "5 valoración",
    value: 5,
    name: "5 valoración",
    type: "Calificaciones"
  },
  {
    id: 111,
    label: "4 valoración",
    value: 4,
    name: "4 valoración",
    type: "Calificaciones"
  },
  {
    id: 112,
    name: "3 valoración",
    label: "3 valoración",
    value: 3,
    type: "Calificaciones"
  },
  {
    id: 113,
    label: "2 valoración",
    name: "2 valoración",
    value: 2,
    type: "Calificaciones"
  },
  {
    id: 114,
    label: "1 valoración",
    name: "1 valoración",
    value: 1,
    type: "Calificaciones"
  },
  {
    id: 115,
    label: "aún no calificado",
    name: "aún no calificado",
    value: 0,
    type: "Calificaciones"
  }
];
export const rangeShould = "El rango debe ser como, por ejemplo, 0 a 5000";
export const showMoreProducts = "Mostrar más productos";
export const showMoreCategory = "Mostrar más categoría";
export const resultNot = "0 resultado encontrado para";
export const tryOthersKaywords = "Inténtelo de nuevo con otras palabras clave.";
export const categoryLinks = [
  {
    id: 41,
    type: "Exterior",
    value: "Herramientas Automotrices"
  },
  {
    id: 42,
    type: "Actuación",
    value: "F226 ASCOT"
  },
  {
    id: 43,
    type: "Encendiendo"
  },
  {
    id: 44,
    type: "partes del cuerpo"
  },
  {
    id: 46,
    type: "interior",
    value: "volantes"
  }
];
export const _Home = "Inicio";
export const Next = "Siguiente";
export const prev = "Previo";
export const review = " críticas";
export const uniqueStyle = "estilo único";
export const wellKerned = "bien kerned";
export const featuredProducts = "Productos Destacados";
export const easyToUse = "fácil de usar";
export const overall = "en general";
export const duarability = "durabilidad";
export const compatibility = "compatibilidad";
export const easeOf = "facilidad de uso";
export const model_drop = [
  {value: "Accord", label: "Accord"},
  {value: "Civic", label: "Civic"},
  {value: "Corolla", label: "Corolla"},
  {value: "Sortage", label: "Sortage"},
  {value: "Boolan", label: "Boolan"}
];
export const year_drop = [
  {value: "2019", label: "2019"},
  {value: "2018", label: "2018"},
  {value: "2017", label: "2017"},
  {value: "2016", label: "2016"},
  {value: "2015", label: "2015"},
  {value: "2014", label: "2014"},
  {value: "2013", label: "2013"},
  {value: "2012", label: "2012"},
  {value: "2011", label: "2011"},
  {value: "2010", label: "2010"}
];
export const make_drop = [
  {value: "Audi", label: "Audi"},
  {value: "BMW", label: "BMW"},
  {value: "Daewoo", label: "Daewoo"},
  {value: "Honda", label: "Honda"},
  {value: "Suzuki", label: "Suzuki"},
  {value: "Kia", label: "Kia"},
  {value: "Sportage", label: "Sportage"},
  {value: "Vitz", label: "Vitz"}
];
export const page_size = [
  {value: "10", label: "10"},
  {value: "15", label: "15"},
  {value: "20", label: "20"},
  {value: "25", label: "25"},
  {value: "30", label: "30"}
];
export const sortBy = [
  {value: 1, label: "Nombre ascendente"},
  {value: 2, label: "Nombre descendente"},
  {value: 3, label: "Precio alto a bajo"},
  {value: 4, label: "Precio bajo a alto"}
];
export const branding = [
  {name: "Interfil", results: 2, maxPrice: 400},
  {name: "fitfil", results: 4, maxPrice: 500},
  {name: "normal", results: 2, maxPrice: 400},
  {name: "moderate", results: 2, maxPrice: 400}
];
export const sortByValue = "Ordenar por";
export const mispartes = "Okautopartes";
export const showItems = "Mostrar artículos";
export const searchDrop = "Buscar";
export const accesorios = "accesorios";
export const lluminacion = "lluminacion";

export const kitsArray = [
  {
    id: 0,
    label: "accesorios"
  },
  {id: 1, label: "lluminacion"},
  ,
  {id: 2, label: "Refacciones"},
  {id: 3, label: "Llantas"},
  {id: 4, label: "Colision"},
  {id: 5, label: "Pesado"},
  {id: 6, label: "Kits"}
];

export const subMenuObj = {
  accesorios: ["ruedas personalizadas", "asiento de coche clásico", "asiento de coche clásico", "ruedas personalizadas", "ruedas personalizadas"],
  lluminacion: [
    {
      id: "mDT240G2G0B",
      image: "/images/image42.png",
      category: "ruedas personalizadas",
      link: "https://en.wikipedia.org/wiki/Things_Fall_Apart\n"
    },
    {
      id: "MDS2w3G2G0B",
      image: "/images/image42.png",
      category: "ruedas personalizadas",
      link: "https://en.wikipedia.org/wiki/Things_Fall_Apart\n"
    },
    {
      id: "2340G2G0B",
      image: "/images/wheels.png",
      category: "asiento de coche clásico",
      link: "https://en.wikipedia.org/wiki/Things_Fall_Apart\n"
    }
  ],
  Refacciones: [
    {
      id: "1540G2G0B",
      image: "/images/image77.jpg",
      category: "asiento de coche clásico",
      link: "https://en.wikipedia.org/wiki/Things_Fall_Apart\n"
    },
    {
      id: "WDS2w3G2G0B",
      image: "/images/image77.jpg",
      category: "ruedas personalizadas",
      link: "https://en.wikipedia.org/wiki/Things_Fall_Apart\n"
    },
    {
      id: "MDS2w3G2G0B",
      image: "/images/image42.png",
      category: "ruedas personalizadas",
      link: "https://en.wikipedia.org/wiki/Things_Fall_Apart\n"
    },
    {
      id: "mDT240G2G0B",
      image: "/images/image42.png",
      category: "ruedas personalizadas",
      link: "https://en.wikipedia.org/wiki/Things_Fall_Apart\n"
    },

    {
      id: "2340G2G0B",
      image: "/images/wheels.png",
      category: "asiento de coche clásico",
      link: "https://en.wikipedia.org/wiki/Things_Fall_Apart\n"
    }
  ]
};
export const backToMain = "Volver al menú principal";
// signin
export const discoverTheParts = "Descubra las piezas de";
export const yourFav = "su vehículo favoritas.";
const loginField = [
  {
    id: 1,
    label: "Correo electrónico",
    type: "email",
    name: "email"
  },
  {
    id: 2,
    label: "contraseña",
    type: "password",
    name: "password"
  }
];
export const signUpField = [
  {
    id: 1,
    label: "nombre",
    type: "text",
    name: "name"
  },
  {
    id: 2,
    label: "Correo electrónico",
    type: "email",
    name: "email"
  },
  {
    id: 3,
    label: "contraseña",
    type: "password",
    name: "password"
  },
  {
    id: 4,
    label: "confirmar contraseña",
    type: "password",
    name: "cPassword"
  }
];
export const pageNotFound = "página no encontrada!";
export const pageLookingFor = "es posible que se haya eliminado la página que está buscando. tuvo su";
export const hadThisRemove = "nombre cambiado o no está disponible temporalmente.";
export const begnning = "inicio";
export const goBackToHome = "Vuelve a casa";
export const emptyCart = "Tu canasta está vacía";
export const inviteReturnTo = "Lo invitamos a regresar a nuestros productos y agregar productos a su carrito.";
export const loadingSomeContnet = "Cargando tu contenido ...";
export const freeShipping = "Envio Gratis";
export const inAllYourPurchase = "En todas tus compras mayores a $ 500.00";

// footer
export const information = "información";
export const termsAndCondition = "terminos y condiciones";
export const noticeOfPrivacy = "aviso de privacidad";
export const frequentQuestion = "preguntas frecuentes";
export const aboutUs = "Quienes Somos";
export const doYouWantToSupplier = "quieres ser proveedor?";
export const downloadOn = "descargar en el";
export const appStore = "tienda de aplicaciones";
export const googlePlays = "Google Play";
export const subcribe = "suscribete";
export const subcribeToOurNewsLetter = " suscribete a nuestro boletin y recibe ofertas,promociones y noticias de todos nuestros productos.";
export const allRights = "todos los derechos reservados Okautopartes.com";
export const mobileApp = "aplicacion movil";
// topBar
export const whyMyParts = "por qué Okautopartes?";
export const orderTracking = "Rastreo de orden";
export const customerSupport = "Atención al cliente";
export const helpLineNum = "+52 1 656 436 9502";
export const userEmail = "atencion@okautopartes.com";
// rating
export const holaDummyProduct =
  "hola, esta es una revisión de producto ficticia, verifique estos productos y cómprelo. Estoy realmente impresionado con sus productos, esta es una revisión de producto ficticia, por favor revise estos productos y cómprela. Esta es una revisión de producto ficticia, por favor revise estos productos y cómprelo. Esta es una revisión de producto ficticia, por favor revise estos productos y cómprela. Esta es una revisión de producto ficticia, por favor revise estos productos y cómprelos gracias a las partes equivocadas.";
export const excellentProduct = "excelente producto!";
export const austerlitz = "¡austerlitz, nY";
export const bLavett = "b. lavett";
export const postDate = "29/4/2021";
// listProduct
export const applications = "Puede Escribir La Aplicación Allí Para Contarnos Sobre El Producto.";
export const comments = "Este Producto Es Muy Bueno Y Original.";
export const availables = "78";
export const positions = "34d";
export const warranties = "6 meses";
export const error = "Error";
export const clearFilter = "filtro claro";
export const clearAllFilter = "limpiar todo";
export const cylender = "cilindros";
export const valvas = "valvulas";
export const version = "versión";
export const litros = "litros";
export const clearHistory = "Historia clara";
export const selectYourvehicle = "Seleccione su vehículo primero";
export const noInvoiceWant = "no deseo factura  fiscal";
export const bandaPolyVAccesorios = "Banda Poly-V Accesorios";
export const setBandaPolyVAccesorios = "Banda Poly V Accesorios";
export const mercadoPagoId = "paywithmercadoopago";
export const payWithMarcadoCardId = "paywithmarcadocardid";
export const defaultPaymentMethodId = "1642029735696";
export const previouseSelectedId = "previouseSelectedId";
export const productionBaseUrl = "https://dev-mispartes-api.azurewebsites.net/api/";
export const developmentBaseUrl = "https://okautopartes-api-dev.azurewebsites.net/api/";
export const hostName = "mispartes.azurewebsites.net";
//Tost notification
export const markAsFavorite = "El producto ha sido marcado como favorito.";
export const removeFromFavorite = "Producto eliminado de favoritos.";
export const selectPaymentMethod = "¡Seleccione cualquier método de pago!";
//No Order in List
export const noOrderInList = "no hay pedidos disponibles en la lista de pedidos.";
//Select Fovorite Product
export const selectFavoriteProduct = "no hay productos en la lista de favoritos.";
export const companyNumber = "+5216564369502";
export const genericError = "Something Went Wrong!";

export const companyName = "OKAUTOPARTES";
export const statusMessage = "Normalmente responde en una hora";
export const chatMessage = "Como podemos ayudarte?";
export const VerifiedEmail = "correo verificado";
export const successfulEmail = "verified. Su correo electrónico ha sido verificado con éxito.";
export const continued = "Continuar";
export const mobileverification = "verificación de teléfono móvil";
export const enterotp = "Por favor ingrese la OTP que acabamos de enviar a su teléfono al";
export const receivedCode = "No recibes el código?";
export const forward = "Reenviar OTP";
export const typemessage = "Escribe un mensaje...";
export const getInto = "Ingresar :";
export const telephoneNo = "teléfono/correo electrónico:";
export const message = "mensaje:";
export const company = "Empresa:";
export const successMessage = "Éxito";

export const valueProposal = "propuesta de valor";
export const Envio = "Envio Gratis : Ordenes Mayores $399";
export const Problema = "Devoluciones : Sin Problema";
export const Calidad = "100% Grantizada Calidad";
export const Questions = "Preguntas";
export const Frequent = "Frecuentes";
export const MinimumshipingPrice = 399;
export const Include = "Te recomendamos no usar espacios al buscar por numero de parte";
