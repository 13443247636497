import { useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  alertMsg,
  closeVehicle,
  openAlert,
} from "../../Redux/reducers/alertSlice";
import {
  add_VehicleIntoUrl,
  resetFilter,
} from "../../Redux/reducers/filterSlice";
import * as service from "../../common/services";
import {
  getMakers,
  getModelByMaker,
  getYearByModelandMaker,
} from "../../utils/api";
import {
  isVehicleSelect,
  makeVehicle,
  vehicleSubmit,
  selectedVehical,
} from "../../Redux/reducers/vehicleSlice";
import ApplicationContext from "../../context-api/context";

export default function useForm(popup, wordEntered) {
  const {
    alertMsg,
    setAlertMsg,
    openAlert,
    setOpenAlert,
    setOpenVehicle,
    vehicleData,
    setVehicleData,
    setOpenSelectVehicle,
    setVehicleSubmit,
    setSearchedVehical,
    searchedVehical,
  } = useContext(ApplicationContext);
  let result = wordEntered && wordEntered?.split(" ");
  const [isData, setIsData] = useState(false);
  const innerRef = useRef();
  const yearRef = useRef();
  const createVehicle = vehicleData;
  const searchVehicales = searchedVehical;
  // const createVehicle = useSelector((state) => state.vehicleCreate.vehicleData);
  // const searchVehicales = useSelector(
  //   (state) => state.vehicleCreate.searchedVehical
  // );

  // finding index value of selected model value
  const modelIndex =
    createVehicle.length != 0 && createVehicle[0]?.models != undefined
      ? createVehicle[0]?.models.findIndex((item) => item.selected == true)
      : null;
  const [isModel, setIsModel] = useState({ model: "" });
  //initial state of make,model,year
  const [select, setSelect] = useState({
    make:
      createVehicle?.length != 0
        ? {
            value: createVehicle[0]?.maker,
            label: createVehicle[0]?.maker,
          }
        : "",
    model:
      createVehicle.length != 0 && createVehicle[0]?.models != undefined
        ? {
            value: createVehicle[0]?.models[modelIndex].model,
            label: createVehicle[0]?.models[modelIndex].model,
          }
        : "",
    year: "",
  });

  const _isVehicleChange = useSelector((state) => state.filter.isVehicleChange);
  const [showModel, setShowModel] = useState(false);
  const [showCar, setShowCar] = useState(false);
  const [showYear, setShowYear] = useState(false);
  const [isYearChange, setIsYearChange] = useState(false);
  const [disable, setdisable] = useState(false);
  const [disableYear, setDisableYear] = useState(true);
  const [disableModal, setDisableModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [makeId, setmakeId] = useState(
    createVehicle.length != 0 ? createVehicle[0].makerID : null
  );
  const [data, setData] = useState({
    make: [],
    isLoading: false,
  });
  const [modelData, setmodelData] = useState({
    model: [],
    isLoading: false,
  });
  const [yearData, setyearData] = useState({
    year: [],
    isLoading: false,
  });
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (wordEntered && select.year !== null && select.year !== undefined) {
      handleSubmit();
    }
  }, [isYearChange]);

  useEffect(() => {
    if (createVehicle.length != 0) {
      if (createVehicle[0].models != undefined) {
        // model name searched by search bar then open year dropdown
        createVehicle[0]?.models?.filter((item) => {
          return result?.filter((result) => {
            if (item?.model?.toLowerCase() == result?.toLowerCase()) {
              setSelect({
                ...select,
                model: {
                  id: parseInt(item?.modelID),
                  label:
                    result.charAt(0).toUpperCase() +
                    result.slice(1).toLowerCase(),
                  value:
                    result.charAt(0).toUpperCase() +
                    result.slice(1).toLowerCase(),
                },
              });
              setIsModel({
                model: {
                  id: parseInt(item?.modelID),
                  label:
                    result.charAt(0).toUpperCase() +
                    result.slice(1).toLowerCase(),
                  value:
                    result.charAt(0).toUpperCase() +
                    result.slice(1).toLowerCase(),
                },
              });
            }
          });
        });
        return (
          setShowModel(false),
          setShowYear(true),
          setdisable(false),
          select.model == null ||
            (select.model == undefined &&
              ModelMake(createVehicle[0].makerID, true)),
          yearMake(
            createVehicle[0]?.models[modelIndex].modelID,
            createVehicle[0].makerID
          )
        );
      } else {
        // make name searched by search bar then open dropdown
        return setShowModel(true), ModelMake(createVehicle[0].makerID);
      }
    } else {
      return setShowYear(false), setShowModel(false);
    }
  }, [createVehicle]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (showModel) {
      innerRef.current && innerRef.current.focus();
    } else {
      innerRef.current && innerRef.current.blur();
    }
  }, [showModel]);

  //make selector
  async function getData() {
    await getMakers()
      .then((response) => {
        if (response.status === 200) {
          setData({
            ...data,
            isLoading: false,
            make: response.data.content,
          });
        } else {
          setAlertMsg(response.ExceptionMessage);
          setOpenAlert(true);
          // dispatch(alertMsg(response.ExceptionMessage));
          // dispatch(openAlert());
        }
      })
      .catch((error) => {
        setAlertMsg(error);
        setOpenAlert(true);
        // dispatch(alertMsg(error));
        // dispatch(openAlert());
      });
  }

  const handleMake = (name, value) => {
    setmakeId(value.id);
    setSelect({ ...select, [name]: value });
    // dispatch(makeVehicle("reset"));
    setVehicleData([]);
    value.id != 0 ? ModelMake(value.id) : ModelMake(createVehicle.makerID);
    setShowModel(true);
    setIsData(true);
  };
  // model selector
  async function ModelMake(id, loading = false, isShow = false) {
    setShowYear(false);
    if (
      isShow ||
      select.model == "" ||
      select.model == null ||
      select.model == undefined
    ) {
      setShowModel(true);
    }
    setmodelData({
      ...modelData,
      model: [],
      isLoading: !loading && true,
    });
    setDisableModal(false);
    if (id != undefined) {
      await getModelByMaker(id)
        .then((response) => {
          if (response.status === 200) {
            setmodelData({
              ...modelData,
              isLoading: false,
              model: response.data.content,
            });
            if (!isShow) {
              response?.data?.content.filter((item) => {
                return result?.filter((result) => {
                  if (item?.name.toLowerCase() == result.toLowerCase()) {
                    setIsModel({
                      model: {
                        id: item?.id,
                        label:
                          result.charAt(0).toUpperCase() +
                          result.slice(1).toLowerCase(),
                        value:
                          result.charAt(0).toUpperCase() +
                          result.slice(1).toLowerCase(),
                      },
                    });
                    setShowModel(false);
                    setSelect({
                      ...select,
                      model: {
                        id: item?.id,
                        label:
                          result.charAt(0).toUpperCase() +
                          result.slice(1).toLowerCase(),
                        value:
                          result.charAt(0).toUpperCase() +
                          result.slice(1).toLowerCase(),
                      },
                    });
                    innerRef.current && innerRef.current.blur();
                    setShowModel(false);
                    setShowYear(true);
                  }
                });
              });
            }
          } else {
            setmodelData({
              ...modelData,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          setAlertMsg(error);
          setOpenAlert(true);
          // dispatch(alertMsg(error));
          // dispatch(openAlert());
        });
    }
  }

  const handleModel = async (name, value) => {
    setSelect({ ...select, [name]: value });
    setIsModel({ model: value });
    setShowModel(false);
    yearMake(value.id, makeId);
  };

  // year selector
  async function yearMake(id, id2) {
    setyearData({
      ...yearData,
      year: [],
      isLoading: true,
    });
    setShowModel(false);
    if (isData || (id && id2)) {
      setShowYear(true);
    } else {
      setShowYear(false);
    }
    setDisableYear(false);
    if (id != undefined && id2 != undefined) {
      await getYearByModelandMaker(id, id2)
        .then((response) => {
          if (response.status === 200) {
            setyearData({
              ...yearData,
              isLoading: false,
              year: response.data.content,
            });
            yearRef.current && yearRef.current.focus();
            response?.data?.content.filter((item) => {
              return result?.filter((result) => {
                if (item?.name == result) {
                  setSelect({
                    ...select,
                    model: isModel.model && isModel.model,
                    year: {
                      value:
                        result.charAt(0).toUpperCase() +
                        result.slice(1).toLowerCase(),
                      label:
                        result.charAt(0).toUpperCase() +
                        result.slice(1).toLowerCase(),
                      id: parseInt(result),
                    },
                  });
                  yearRef.current && yearRef.current.blur();
                  setShowYear(false);
                  setTimeout(() => {
                    handleSubmit();
                  }, 1000);
                }
              });
            });
          } else {
            setyearData({
              ...yearData,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          setAlertMsg(error);
          setOpenAlert(true);
          // dispatch(alertMsg(error));
          // dispatch(openAlert());
        });
    }
  }

  const handleYear = (name, value) => {
    yearRef.current.blur();
    setSelect({ ...select, [name]: value });
    setyearData({ ...yearData, isLoading: false });
    setmodelData({ ...modelData, isLoading: false });
    setShowModel(false);
    setShowYear(false);
    setIsData(false);
    setIsYearChange(true);
  };

  const handleSubmit = () => {
    if (select.make === "" || select.year === "" || select.model === "") {
      return null;
    } else {
      setLoader(true);
      // add vehicle into garage through local storage
      service.insertVehicle(select);
      setTimeout(() => {
        setLoader(false);

        // for the time being it is commented beacuse it create problem (WordEntered becomes empty when its executed)
        // adding vehicle into url
        // dispatch(add_VehicleIntoUrl(select));

        if (createVehicle.length != 0 || wordEntered) {
          setVehicleData([]);
          setOpenSelectVehicle(false);
          setVehicleSubmit(true);
          // dispatch(makeVehicle("reset"));
          // dispatch(isVehicleSelect(false));
          // dispatch(vehicleSubmit(true));
        } else {
          if (!wordEntered) {
            const encodeMake = select.make.value.replaceAll("-", "+");
            const encodeModel = select.model.value.replaceAll("-", "+");
            history.push(
              `${encodeURIComponent(encodeMake)}-${encodeURIComponent(
                encodeModel
              )}-${encodeURIComponent(select.year.value)}.html`,

              setOpenVehicle(false)
              // dispatch(closeVehicle())
            );
          }
          setSearchedVehical({});
          // dispatch(selectedVehical({}));
          setIsData(false);
        }
        if (
          _isVehicleChange?.make?.id == select?.make.id &&
          _isVehicleChange?.model?.id == select?.model.id &&
          _isVehicleChange?.year?.id == select?.year.id
        ) {
        } else {
          dispatch(resetFilter("All"));
        }
      }, 2000);
    }
  };

  useEffect(() => {
    if (createVehicle.length == 0) {
      if (searchVehicales) {
        setIsData(true);
        setmakeId(searchVehicales?.make?.id);
        setSelect((prevState) => ({
          ...prevState,
          make: searchVehicales?.make,
        }));
        ModelMake(searchVehicales?.make?.id);
        setSelect((prevState) => ({
          ...prevState,
          model: searchVehicales?.model,
        }));
        setShowModel(false);
        yearMake(searchVehicales?.model?.id, searchVehicales?.make?.id);
        setSelect((prevState) => ({
          ...prevState,
          year: searchVehicales?.year,
        }));
        setShowYear(false);
      }
    }
  }, []);

  useEffect(() => {
    if (createVehicle.length != 0) {
      if (
        select.model !== null &&
        select.model !== undefined &&
        select.model !== ""
      ) {
        if (select?.model?.id && createVehicle[0]?.makerID) {
          setShowModel(false);
          yearMake(select?.model?.id, createVehicle[0]?.makerID);
        }
      }
    }
  }, [select.model]);

  const clickOnModelDropdown = () => {
    ModelMake(makeId, false, true);
  };

  return {
    select,
    setSelect,
    showYear,
    setShowYear,
    showModel,
    setShowModel,
    disableModal,
    handleMake,
    handleYear,
    handleModel,
    handleSubmit,
    disable,
    disableYear,
    loader,
    data,
    setData,
    innerRef,
    yearRef,
    openModal,
    setOpenModal,
    modelData,
    setmodelData,
    yearData,
    setyearData,
    createVehicle,
    setIsData,
    clickOnModelDropdown,
  };
}
