import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    minWidth: theme.spacing(0),
    fontFamily: "pangram-bold",
  },
}));
export default useStyles;
