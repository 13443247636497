import React, { useState } from "react";
import { Grid, Container, CircularProgress } from "@material-ui/core";
import "./style.scss";
import {
  discoverTheParts,
  error,
  forgetPass,
  send,
  yourFav,
} from "../../../common/constants";
import { Formik, Form } from "formik";
import { TextInput, CustomButton } from "../../../Component/index";
import { Email } from "../../../common/constant/formConstants";
import { forgetValidation } from "../../../common/formValidation";
import DiscoverPart from "../../../Component/dicoverParts";
import MetaTitle from "../../../Component/metaTag";
import AlertBox from "../../../Component/confirm-alert/alertBox";
import { colors } from "../../../common/theme";
import { requestForgotPassword } from "../../../utils/api";
import { useToasts } from "react-toast-notifications";

const ForgetPassword = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const requestResetHandler = async (values, { resetForm }) => {
    setLoading(true);
    let result = await requestForgotPassword(values.email);
    setLoading(false);
    const {
      data: { success, description },
    } = result || {};
    if (result && success) {
      showToast(description);
      resetForm();
    } else {
      setOpenModal(true);
      setErrorMsg(description);
      resetForm();
    }
  };
  const closePressHandler = () => {
    setOpenModal(false);
  };
  const showToast = (message) => {
    addToast(message, { appearance: "success", autoDismiss: false });
  };
  return (
    <>
      <MetaTitle title={forgetPass} />
      <Grid container xs={12} style={{ height: "100vh" }}>
        <DiscoverPart
          forgetPass={true}
          className="bg-Img"
          line1={discoverTheParts}
          line2={yourFav}
        />
        <Grid item xs={12} sm={7}>
          <Container className="signIn">
            <Grid xs={12} className="form">
              <Grid xs={10} sm={7} className="content">
                <Formik
                  enableReinitialize
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={forgetValidation}
                  onSubmit={requestResetHandler}
                >
                  {({ handleSubmit }) => (
                    <Form autoComplete="off">
                      <div className="loginTitle text-center">{forgetPass}</div>
                      <TextInput
                        className={"inputText"}
                        name="email"
                        type="email"
                        labelName={Email}
                        placeholder={Email}
                        labelClass="label"
                      />

                      {loading ? (
                        <CustomButton
                          title={""}
                          className="submitBtn marginTop"
                          onClick={handleSubmit}
                        >
                          <CircularProgress size={20} color={colors.white} />
                        </CustomButton>
                      ) : (
                        <CustomButton
                          title={send}
                          className="submitBtn marginTop"
                          onClick={handleSubmit}
                        />
                      )}

                      <AlertBox
                        del={false}
                        onCancel={closePressHandler}
                        heading={error}
                        text={errorMsg}
                        onClose={openModal}
                        open={openModal}
                      />
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgetPassword;
