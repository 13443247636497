import React, { useState } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { CustomButton, TextArea } from "../..";
import {
  contactUs,
  requestReturn,
  send,
  typeYourMsg,
  upload,
  uploadPhoto,
  ImageRequired,
  successMessage,
} from "../../../common/constants";
import "./style.scss";
import { problemValidation } from "../../../common/formValidation";
import { Thumb } from "../imageThumb";
import { PostProblemWithOrder } from "../../../utils/api";
import { CircularProgress } from "@material-ui/core";
import AlertBox from "../../confirm-alert/alertBox";

export const Problem_Form = ({
  query,
  data,
  problemTypeId,
  question,
  isImageAndCheckHide,
  formCloses,
  fetchOrderList,
}) => {
  const fileInput = React.useRef(null);
  const [isImageAvailabe, setIsImageAvailabe] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    heading: "",
    description: "",
  });

  const gererateFormData = (
    saleId,
    folioNo,
    saleItemId,
    problemTypeId,
    quantity,
    question,
    productId,
    solutionTypeId,
    solutionType,
    message,
    file
  ) => {
    let formData = new FormData();
    formData.append("SaleID", saleId); //append the values with key, value pair
    formData.append("FolioNo", folioNo);
    formData.append("SaleItemID", saleItemId);
    formData.append("ProblemTypeID", problemTypeId);
    formData.append("Quantity", quantity);
    formData.append("ProblemType", question);
    formData.append("ProductID", productId);
    formData.append("SolutionTypeID", isImageAndCheckHide ? 0 : solutionTypeId);
    formData.append("SolutionType", isImageAndCheckHide ? "" : solutionType);
    formData.append("Message", message);
    if (file.length === 0) {
      formData.append(`Files`, null);
    } else {
      file.map((item, id) => {
        formData.append(`Files`, item);
      });
    }
    return formData;
  };

  const handleForm = async (e) => {
    if ([...e.file].length === 0 && !isImageAndCheckHide) {
      setIsImageAvailabe(true);
      return;
    }
    let formData = gererateFormData(
      data.saleID,
      data.folioNo,
      parseInt(data.saleItemID),
      problemTypeId + 1,
      data.quantity,
      question,
      parseInt(data.productID),
      e.queryType == "Contactarnos" ? 1 : 2,
      e.queryType,
      e.message,
      e.file
    );
    try {
      setLoading(true);
      let result = await PostProblemWithOrder(formData);
      const {
        data: { success, description },
      } = result;
      if (result && success) {
        setLoading(false);
        setAlertMsg({ heading: successMessage, description: description });
        setOpenAlert(true);
      } else {
        setLoading(false);
        setAlertMsg({ heading: "Error", description: description });
        setOpenAlert(true);
      }
    } catch (e) {}
  };

  const onAlertCloseSuccess = () => {
    setOpenAlert(false);
    fetchOrderList(1, 1);
    formCloses(false);
  };
  const onAlertCloseFailure = () => {
    setOpenAlert(false);
  };
  return (
    <>
      <Formik
        initialValues={{
          message: "",
          queryType: contactUs,
          file: [],
        }}
        validationSchema={problemValidation}
        onSubmit={handleForm}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Form className="innerform">
            {!isImageAndCheckHide && (
              <div
                className="radioContainer"
                role="group"
                aria-labelledby="my-radio-group"
              >
                <label className="radioOptions">
                  <Field type="radio" name="queryType" value={contactUs} />
                  {contactUs}
                </label>
                <label className="radioOptions">
                  <Field type="radio" name="queryType" value={requestReturn} />
                  {requestReturn}
                </label>
                <ErrorMessage
                  name="queryType"
                  component="span"
                  className="error"
                />
              </div>
            )}
            <div className="problemContainer">
              <TextArea
                className="problem"
                name="message"
                type="text"
                placeholder={typeYourMsg}
              />
              {!isImageAndCheckHide && (
                <div className="uploadDiv">
                  <span className="text">{uploadPhoto} </span>
                  <CustomButton
                    className="uploadButton"
                    onClick={(e) => fileInput.current.click(e)}
                    title={upload}
                  />
                  <input
                    type="file"
                    name="file"
                    style={{ display: "none" }}
                    multiple
                    required
                    onChange={(e) => {
                      let imagesArr = [...e.target.files];
                      const slicedArray = imagesArr.slice(0, 5); //Hint: This is only select top 5 images from  the gallery and remaining trunk off
                      setFieldValue("file", slicedArray);
                      setIsImageAvailabe(false);
                      e.target.value = "";
                    }}
                    ref={fileInput}
                  />
                  <div
                    className="subir-section mt-3"
                    style={{ textAlign: "center" }}
                  >
                    <Thumb
                      onRemoveImage={(id) => {
                        let filesArr = [...values.file];
                        filesArr.splice(id, 1);
                        setFieldValue("file", filesArr);
                      }}
                      file={values.file}
                    />
                  </div>
                  {isImageAvailabe && (
                    <span className="error">{ImageRequired}</span>
                  )}
                </div>
              )}
              <CustomButton
                className="submitButton"
                onClick={handleSubmit}
                title={loading ? "" : send}
              >
                {loading ? <CircularProgress size={15} color="white" /> : ""}
              </CustomButton>
            </div>
          </Form>
        )}
      </Formik>
      <AlertBox //Alert in case of any error
        del={false}
        onCancel={
          alertMsg.heading === successMessage
            ? onAlertCloseSuccess
            : onAlertCloseFailure
        }
        heading={alertMsg.heading}
        text={alertMsg.description}
        onClose={
          alertMsg.heading === successMessage
            ? onAlertCloseSuccess
            : onAlertCloseFailure
        }
        open={openAlert}
        isSuccess={alertMsg.heading === successMessage}
      />
    </>
  );
};
