import React, { useState, useEffect, useContext } from "react";
import { Footer, MetaTitle, StickyHeader } from "../../Component";
import MainHeader from "../../Sections/header";
import { metaPath } from "../../common/services";
import ApplicationContext from "../../context-api/context";
import { valueProposal } from "../../common/constants";
import {
  StickyBarImage,
  gratis,
  devoluciones,
  calidad,
} from "../../common/Images";
import "./style.scss";
const StickyBar = () => {
  return (
    <div>
      <MetaTitle title={metaPath()} />
      <MainHeader otherIconOnHeader={true} isMenu={false} logoBig={true} />
      <StickyHeader />
      <div className="sticky-bar">
        <div
          className="StickyImage"
          style={{
            backgroundImage: `url(${StickyBarImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundColor: "black",
          }}
        >
          <h2>{valueProposal}</h2>
        </div>

        <div className="sticky-content">
          <div className="container content-item" id="gratis-item">
            <div className="image-gratis">
              <div className="image-box">
                <img src={gratis} alt="icon" />
              </div>
            </div>
            <div className="content-text-gratis">
              <h2>Envíos Gratis más $399 </h2>
              <p>
                Sabemos que no hay nada más importante que mantenerte en
                movimiento, es por eso nuestra garantía de envió gratis en tus
                compras de más $399, para que el costo del envió no te limite y
                pidas lo que necesites hasta la puerta de tu casa o taller.
              </p>
            </div>
          </div>
          <div className="container content-item" id="devoluciones-item">
            <div className="image-devoluciones">
              <div className="image-box">
                <img src={devoluciones} alt="icon" />
              </div>
            </div>
            <div className="content-text-devoluciones">
              <h2>Devoluciones Sin Problema</h2>
              <p>
                Entendemos que hay varias razones por las cuales te gustaría
                regresar alguna de tus compras en Okautopartes.com, y hacemos
                este proceso lo más transparente y rápido posible, para que
                puedas volver a comprar lo antes posible.
              </p>
            </div>
          </div>
          <div className="container content-item " id="garantizada-item">
            <div className="image-garantizada">
              <div className="image-box">
                <img src={calidad} alt="icon" />
              </div>
            </div>
            <div className="content-text-garantizada">
              <h2>Calidad 100% Garantizada</h2>
              <p>
                Somos expertos en autopartes, por esto garantizamos que todos
                los productos que encuentres en Okautopartes.com son de las
                mejores marcas y calidad en la industria respaldando nuestros
                productos con una garantía de 90 días.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default StickyBar;
