import React from 'react'
import "./style.css"
export default function Content() {
  return (
    <div className='noticeofprivacy '>
        <h3>Aviso de privacidad OK autopartes 2.0</h3>
        <h4>El aviso de privacidad forma parte del uso del sitio web <a href='https://dev-mispartes.azurewebsites.net/'>www.okautopartes.com</a>
        </h4>
        <p>
        Con el presente aviso contempla en todo momento los principios de licitud, consentimiento, información, calidad, finalidad, lealtad y responsabilidad en los tratamientos de datos personales <a href='https://dev-mispartes.azurewebsites.net/'>www.okautopartes.com</a></p>
        <p>
        Su privacidad y confianza son muy importantes para Radiadores Frontera S.A de C.V. (con nombre comercial okautopartes.com), es por lo cual nos queremos asegurar que conozca como la empresa utilizara y salvaguardara sus datos personales, en apego a lo previsto por la legislación en la materia, específicamente en la “Ley federal de Protección de Datos Personales”, publicada en el diario en el Diario Oficial de la Federación el 21 de marzo del 2018.
        </p>
        <p>
        La empresa responsable de la protección de sus datos Radiadores Frontera S.A de C.V ubicada en: Avenida Valentín Fuentes #1643 Fraccionamiento Villa Hermosa, código postal 3251, Ciudad Juárez Chihuahua México, usted podrá contactarla a través del correo electrónico xxxxxxxxxxxxx , para cualquier duda o aclaración.
        </p>
        <p>
        Para Radiadores Frontera S.A de C.V, es importante obtener cierta información para poder llevar acabo las actividades de venta que se llevan a cabo en <a href='https://dev-mispartes.azurewebsites.net/'>www.okautopartes.com</a> , la cual cumple tiene la obligación legal y social, de cumplir con las medidas mas estrictas de resguardo de toda su información que será recabada para los fines mencionados en el presente Aviso de Privacidad. 
        </p>
        <p>
        Lo anteriormente mencionado se realiza con la finalidad que usted tenga el conocimiento, control y decisión sobre sus datos personales. Es por lo cual le sugerimos que revise el aviso de privacidad y si tiene alguna duda nos contacte, al correo anteriormente descrito. Dicho aviso de privacidad siempre estará disponible en nuestra página web <a href='https://dev-mispartes.azurewebsites.net/'>www.okautopartes.com</a>,  cualquier modificación a nuestras políticas de calidad le serán notificadas.
        </p>
        <h5>
        Datos de la empresa
        </h5>
        <p>
        Radiadores Frontera S.A de C.V declara ser una empresa legalmente constituida ante fedatario de conformidad con las leyes de la República Mexicana, con domicilio en la Avenida Valentín Fuentes #1643, Fraccionamiento Villahermosa código postal 3251, en Ciudad Juárez Chihuahua México y como titular responsable del tratamiento de datos, Ok autopartes.com, ubicado en Blvd. Independencia #1571 Col. Zaragoza código postal 32590, en Ciudad Juárez Chihuahua México.
        </p>
        <h5>
        Datos personales
        </h5>
        <p>
        Radiadores Frontera S.A de C.V , recabara la información necesaria  para procesar de manera adecuada sus compras a través de la página <a href='https://dev-mispartes.azurewebsites.net/'>www.okautopartes.com</a>, los datos solicitados serán: 
        Datos personales tales como: Nombre Completo (Persona Física o Moral), dirección completa, Registro Federal de Contribuyentes expedida por el SAT (RFC), teléfono de casa, oficina y/o celular, correo electrónico (s). Datos de facturación y razón social. 

        </p>
        <h5>
        Uso de la información recabada y transferencia de datos a terceros
        </h5>
        <p>

        Okautopartes.com utilizará la información recabada por los usuarios interesados para generar una cuenta y contraseña, con la cual el usuario podrá realizar sus pedidos, procesar compras, rastrear su guía de envió, descargar facturas y solicitar cualquier información acerca de alguna duda o un comentario referentes algún producto o también cualquier duda derivada de su compra.  <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a>  no compartirá información con terceros. 
        </p>
        <h5>
        Protección
        </h5>
        <p>
        Ok autopartes.com se compromete asegurar la información obtenida a través de este medio, utilizando su información solamente para los fines establecidos en este aviso de privacidad, hemos establecido diversos procedimientos con la finalidad de evitar el uso o divulgación no autorizada de sus datos, permitiéndonos tratarlos debidamente con la finalidad establecida.
        </p>
        <p>
        Es importante señalar que la recepción de cualquier correo o llenado de cualquier formulario enviado por OK autopartes.com, implica la aceptación del aviso de privacidad. Todos los datos obtenidos a través de este medio serán utilizados para él envió de correos electrónicos y el producto o los productos comprados.
        </p>
        <h5>
        Limitación y uso de divulgación de datos 
        </h5>
        <p>
        Todos sus datos personales son tratados de acuerdo a la legislación aplicable y vigente en el país, por ello le informamos que usted tiene en todo momento los derechos ARCO (acceso a la rectificación, cancelación y oposición) conforme a la Ley Federal de Protección de datos personales, en posesión de particulares.  
        </p>
        <p>
        El derecho lo podrá hacer valer a través del área de privacidad encargada de la seguridad de datos personales de Ok autopartes.com en el teléfono (656) XXXXXXXXX, o por medio de correo electrónico a contactoaokautopartes.com.  Su solicitud deberá ir acompañada de los argumentos por los cuales desea ejercer cualquiera de los derechos, junto con una identificación oficial del titular de los datos o su apoderado, su petición será atendida en una plaza no mayor a 14 días hábiles. 
        </p>
        <p>
        A través de estos canales usted podrá actualizar sus datos y especificar el medio por el cual desea recibir información, ya que, en caso de no contar con esta especificación de su parte, Radiadores Frontera, S. A de C.V. establecerá libremente el canal que considere pertinente para enviarle la información. 
        </p>
        <h5>
        Datos personales sensibles
        </h5>
        <p>
        Radiadores Frontera, S.A de C.V. no recabara datos sensibles desde la pagina 
        <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com</a>,</p>
        <p>
        <ul>
            <li>
                Datos financieros (Ingresos, Estados de cuenta, y demás relacionados)
            </li>
            <li>
                Datos Patrimoniales (Bienes materiales, Inmuebles y demás relacionados) 
            </li>
            <li>
                Datos Personales (Conyugue, Estado Civil, Nacionalidad, educación hijos y demás relacionados) 
            </li>
            <li>
                Referencias familiares y no familiares.
            </li>
        </ul>
        </p>
        <p>
        Todas las operaciones en línea están protegidas por un servidor seguro bajo el protocolo SECURE SOCKET LAYE (SSL), El cual podrá corroborar al momento de realizar su compra en la barra del navegador, dichos datos serán encriptados para asegurar su resguardo.
        </p>
        <div>
        Los medios de pago en línea utilizados son:
        <ul>
            <li>
                Pago de tarjeta de crédito y/o debito por medio del motor de pago ( xxxxxxxxxxx Entidades financieras )
            </li>
            <li>
                Paypal
            </li>
            <li>
                Mercado pago 
            </li>
            <li>
                Otros 
            </li>
        </ul>
        </div>
        <h5>
        Cambios en el aviso de privacidad 
        </h5>
        <p>
        Este aviso de privacidad podrá ser modificado por Radiadores Frontera, S.A. de C.V. dichas modificaciones serán informadas oportunamente a través de correo electrónico, o cualquier otro medio que Radiadores Frontera, S.A. de C.V. determine para tal efecto.  Al igual se hará publico el cambio del aviso de privacidad a través de un comunicado en la página <a href='https://dev-mispartes.azurewebsites.net/'>www.okautopartes.com</a> y en todas sus redes sociales. 
        </p>
        <h5>
        Aceptación de los términos 
        </h5>
        <p>
        Si el usuario utiliza los servicios de la página digital <a href='https://dev-mispartes.azurewebsites.net/'>www.okautopartes.com</a>, significa que ha leído entendido y acordado los términos expuestos anteriormente.  En caso que cualquiera de nuestros usuarios considere que sus derechos no han sido respetados, tiene el derecho de acudir con las autoridades correspondientes para defender sus derechos. La autoridad es el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI),<a href='https://home.inai.org.mx/'>www.inai.org.mx</a> 
        </p>
    </div>

  )
}
