export const saveChnges = "guardar y continuar";
export const companyName = "Nombre de la empresa (opcional)";
export const yourName = "Nombre";
export const phonNumber = "Número De Teléfono";
export const email = "Email";
export const postalCode = "Código postal";
export const postalCodeLength = 5;
export const direction = "Dirección (área y calle)";
export const city = "Diudad / distrito / pueblo";
export const express = "Expresar";
export const cardNumber = "Número de tarjeta";
export const cardName = "Nombre del Titular";
export const fullName = "Nombre completo";
export const rfc = "RFC";
export const regimentFiscal = "Régimen Fiscal";
export const razonSocial = "Razon social";
export const dirección1 = "Dirección 1";
export const dirección2 = "Dirección 2";
export const cvv = "CVV";
export const digits =
  "3 o 4 dígitos que generalmente se encuentran en la etiqueta de la firma";

export const Email = "Correo electrónico";
export const Password = "Contraseña";
export const NewPassword = "Nueva Contraseña";
export const confirmPassword = "Confirmar contraseña";
export const signIn = "Iniciar sesión";
export const forgotPassword = "¿Olvidó Su Contraseña?";
export const registerNow = "Regístrate ahora";
export const registerYourAccount = "registre su cuenta";
export const unlimitedProducts = "productos ilimitados";
export const forYourProducts = "para su vehículo";

export const addressName = "Identificador";
export const addressNamePlaceholder =
  "Nombre para identificar este domicilio, ej. Casa, Oficina";
export const addressContact = "Nombre y Apellido";
export const addressContactPlaceholder = "Nombre y Apellido";
export const phoneNo = "Teléfono de Contacto";
export const phoneNoPlaceholder = "Teléfono de contacto";

export const state = "Estado";
export const statePlaceholder = "Estado";

export const addressCity = "Ciudad";
export const addressCityPlaceholder = "Ciudad";
export const neighborhood = "Colonia";
export const street = "Calle";
export const extno = "Número Exterior";
export const extnoPlaceholder = "Número Exterior";
export const intno = "Número Interior";
export const intnoPlaceholder = "Número Interior";

