import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  vehicleData: [],
  openSelectVehicle: false,
  vehicleSend: false,
  makeList: [],
  searchedVehical: {},
};
const vehicleSlice = createSlice({
  name: "vehicleCreate",
  initialState,
  reducers: {
    makeVehicle: (state, { payload }) => {
      if (payload == "reset") {
        state.vehicleData = initialState.vehicleData;
      } else {
        state.vehicleData = [payload];
      }
    },
    isVehicleSelect: (state, { payload }) => {
      state.openSelectVehicle = payload;
    },
    vehicleSubmit: (state, { payload }) => {
      state.vehicleSend = payload;
    },
    addMakeList: (state, { payload }) => {
      // storing make list to use it later at searchbar
      state.makeList = [payload];
    },
    selectedVehical: (state, { payload }) => {
      state.searchedVehical = payload;
    },
  },
});

export const {
  vehicleSubmit,
  makeVehicle,
  isVehicleSelect,
  addMakeList,
  selectedVehical,
} = vehicleSlice.actions;

export default vehicleSlice.reducer;
