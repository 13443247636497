import React, { useEffect, useState, useContext } from "react";
import BillBox from "../../../Component/billingBox";
import MiCuenta from "../mi-cuenta/index";
import { GetUserInvoices, AddUpdateUserInvoices } from "../../../utils/api";
import "./style.scss";
import {
  billingAddres,
  addBillingBtn,
  billing,
} from "../../../common/constants";
import useForm from "./useForm";
import { BillForm, Confirm_Alert } from "../../../Component";
import CustomizedDialogs from "../../../Component/customModal/Modal";
import { Skeleton } from "@material-ui/lab";
import ApplicationContext from "../../../context-api/context";
import { useToasts } from "react-toast-notifications";

const Billing = () => {
  const {
    recordForEdit,
    setRecordForEdit,
    addOrEdit,
    handleDelete,
    openModal,
    setOpenModal,
    loading,
  } = useForm();
  const [invoiceID, setInvoiceID] = useState();
  const [ismodalOpens, setIsmodalOpens] = useState(false);
  const { setInvoiceData } = useContext(ApplicationContext);
  const { addToast } = useToasts();
  const showToast = (message) => {
    addToast(message, { appearance: "success" });
  };
  return (
    <MiCuenta title={billing}>
      <div className="Billingmain">
        <div className="mainScreen">
          <p className="billText">{billingAddres}</p>
          {loading == true
            ? [1, 2, 3].map((e) => (
                <Skeleton
                  width={"100%"}
                  height={150}
                  style={{ marginBottom: -3, marginTop: -7 }}
                />
              ))
            : recordForEdit?.invoices?.map((i, index) => {
                return (
                  <BillBox
                    index={index}
                    key={i.id}
                    invoicingID={i.invoicingID}
                    // onEdit={() => editItem(i)}
                    onDelete={() => {
                      setInvoiceID(i.invoicingID);
                      setOpenModal(true);
                    }}
                    billAddress={i}
                    address={i.address}
                    express={i.express}
                    fullName={i.fullName}
                    Rfc={i.taxID}
                    businessName={i.businessName}
                    address2={i.address2}
                    addOrEdit={addOrEdit}
                    isOpened={i.isOpened}
                    ismodalOpens={ismodalOpens}
                    setIsmodalOpens={setIsmodalOpens}
                    recordForEdit={recordForEdit}
                    setRecordForEdit={setRecordForEdit}
                    onClick={() => {}}
                    fromAccountSection
                    onSuccessEdited={(e) => {
                      showToast(e);
                    }}
                  />
                );
              })}
          {/* Add new Card is Aggregar */}
          {!loading && (
            <BillBox
              fromAccountSection
              addOrEdit={addOrEdit}
              addNew={addBillingBtn}
              addNewInvoices={true}
              ismodalOpens={ismodalOpens}
              setIsmodalOpens={setIsmodalOpens}
              recordForEdit={recordForEdit}
              setRecordForEdit={setRecordForEdit}
              onClick={() => {
                const arr = recordForEdit.invoices;
                let newArr = arr.map((obj, ind) => ({
                  ...obj,
                  isOpened: false,
                }));
                setRecordForEdit({ invoices: newArr });
                setInvoiceData();
              }}
              isOpen={true}
            />
          )}
        </div>
      </div>
      {
        <CustomizedDialogs onClose={openModal} open={openModal}>
          <Confirm_Alert
            del={true}
            onCancel={() => setOpenModal(false)}
            onConfirm={
              () => handleDelete(invoiceID, showToast)
              // setOpenModal(false)
            }
          />
        </CustomizedDialogs>
      }
    </MiCuenta>
  );
};

export default Billing;
