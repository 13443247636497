import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";

export default function MobileCheckout({}) {
  useEffect(() => {
    parameterGetHandler();
  }, []);
  function UseQuery() {
    return new URLSearchParams(window.location.search);
  }

  const parameterGetHandler = () => {
    const collection_status = UseQuery().get("collection_status");
    const status = UseQuery().get("status");
    const folio = UseQuery().get("folio");
    let orderPlacememnt = {
      collection_status: collection_status || "",
      status: status,
      folio: folio,
    };
    console.log(JSON.stringify(orderPlacememnt));
    if (window.ReactNativeWebView) {
      // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage(JSON.stringify(orderPlacememnt));
    }
  };

  return (
    <div>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={50} color="black" />{" "}
      </Box>
    </div>
  );
}
