import React from "react";

import "./style.scss";

export const Problem_Div = ({ showForm, question }) => {
  return (
    <div>
      <div className="mainContainer" onClick={showForm}>
        <text className="textBox">{question}</text>
      </div>
    </div>
  );
};
