import { CircularProgress } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import "./style.scss";
export default function LazyBackground({
  src,
  className,
  children,
  style,
  circularStyle,skeltonStyle
}) {
  const [source, setSource] = useState("");

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setSource(src);
  }, [src]);

  return (
    <>
      {source === "" ? (
        <div className={`preloader ${circularStyle}`} style={style}>
          {/* <CircularProgress /> */}
          <Skeleton animation="wave"
                      height={320}
                      className={`${skeltonStyle}`} />
        </div>
      ) : (
        <>
          <div
            className={className}
            style={{
              backgroundImage: `url(${source})`,
            }}
          ></div>
          {children}
        </>
      )}
    </>
  );
}
