import React from "react";
import "./style.scss";
import { Rating, Skeleton } from "@material-ui/lab";
import StarIcon from "@material-ui/icons/Star";
import Images from "../imgLoader";
const SearchSkelton = (props) => {
  return (
    <div
      className={props.row ? "categoryDiv" : "dataDiv"}
      onClick={props.onClick}
    >
      {props.image && <Skeleton animation="wave" height={"100px"} width={"100%"} />}
      <a className={props.row ? "items" : "dataItem"}>
        {props.brand && (
          <p className="title">
            {" "}
            <Skeleton animation="wave" height={12} />{" "}
          </p>
        )}
        {props.title && (
          <p className="title">
            {" "}
            <Skeleton animation="wave" height={12} />
          </p>
        )}
        {props.rating && (
          <Rating
            name="half-rating"
            className="rating"
            defaultValue={props.rating}
            precision={0.5}
            readOnly
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
        )}
        {props.price && (
          <p>
            <Skeleton animation="wave" height={12} width={30}/>
          </p>
        )}
        {props.category && (
          <p className="title">
            <Skeleton
              animation="wave"
              height={12}
              width={"100%"}
              style={{ zIndex: "10" ,textAlign:"center"}}
            />{" "}
          </p>
        )}
      </a>
    </div>
  );
};

export default SearchSkelton;
