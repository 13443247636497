import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as service from "../../common/services";
import ApplicationContext from "../../context-api/context";

const useForm = () => {
  const { searchbarActive, openVehicle, setOpenVehicle, openSearchBar } =
    useContext(ApplicationContext);
  let history = useHistory();
  const dispatch = useDispatch();
  const openSearch = openSearchBar;
  // const openSearch = useSelector((state) => state.alert.openSearch);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const openModal = openVehicle;
  // const openModal = useSelector((state) => state.alert.openPopup);
  const [alertPopup, setAlertPopup] = useState(false);
  const [alertModel, setAlertModel] = useState(false);
  const [GarageCars, setGarageCars] = useState(service.getAllVehicle());
  useEffect(() => {
    setGarageCars(service.getAllVehicle());
  }, [openModal, openSearch]);

  const onDelete = (id) => {
    service.removeVehicle(id);
    const updateData = service.getAllVehicle().filter((i) => {
      return i.id !== id;
    });
    setGarageCars(updateData);
  };
  const clearGarage = () => {
    setGarageCars([]);
    service.removeAllVehicle();
    setAlertModel(false);
  };

  return {
    openModal,
    alertModel,
    setAlertModel,
    clearGarage,
    onDelete,
    isLoggedIn,
    alertPopup,
    setAlertPopup,
    GarageCars,
    setGarageCars,
    dispatch,
    history,
    openSearch,
  };
};

export default useForm;
