import React from "react";
import "./style.scss";
import { Rating } from "@material-ui/lab";
import StarIcon from "@material-ui/icons/Star";
import Images from "../imgLoader";

const SearchProduct = (props) => {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div
      className={props.row ? "categoryDiv" : "dataDiv"}
      onClick={props.onClick}
    >
      {props.image && (
        <Images src={props.image} height={"45px"} width={"100px"} />
      )}
      <a className={props.row ? "items" : "dataItem"}>
        {props.brand && <p className="title">{props.brand} </p>}
        {props.title && <p className="title">{props.title} </p>}
        {props.rating && (
          <Rating
            name="half-rating"
            className="rating"
            defaultValue={props.rating}
            precision={0.5}
            readOnly
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
        )}
        <div>
          {props.price && (
            <span className="d-inline-block">
              {numberWithCommas(
                props.basePrice > props.price ? props.price : props?.basePrice
              )}
            </span>
          )}
          {props.basePrice > props.price && (
            <span className="base-price">
              {numberWithCommas(props.basePrice)}
            </span>
          )}
        </div>

        {props.category && <p className="title">{props.category} </p>}
      </a>
    </div>
  );
};

export default SearchProduct;
