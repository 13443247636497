import React, { useState, useEffect } from "react";
import "./style.scss";
import { closeIcon } from "./../../common/Images";

export const Thumb = ({ file, onRemoveImage }) => {
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);

  const setFileUrls = (files) => {
    const urls = files?.map((file) => URL.createObjectURL(file));
    if (imageUrls.length > 0) {
      imageUrls.forEach((url) => URL.revokeObjectURL(url));
    }
    setImageUrls(urls);
    setLoading(true);
  };
  const removePeople = (e) => {
    var imgArr = [...imageUrls]; // make a separate copy of the array
    var index = imgArr.indexOf(e);
    if (index !== -1) {
      imgArr.splice(index, 1);
      setImageUrls(imgArr);
    }
  };
  useEffect(() => {
    const filesArr = [...file];
    setFileUrls(filesArr);
  }, [file]);

  return (
    <div>
      {loading && (
        <div>
          {imageUrls?.map((url, i) => (
            <div className="subir-img-thumbnail mt-2">
              <span
                onClick={() => {
                  onRemoveImage(i);
                  removePeople(url);
                }}
              >
                x
              </span>
              <img src={url} alt={i} height={100} width={100} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
