import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState, useContext, useRef } from "react";
import { CustomButton } from "..";
import {
  addVehicle,
  make,
  model,
  noOptionFound,
  searchDrop,
  year,
} from "../../common/constants";
import * as service from "../../common/services";
import { addIcon } from "../../common/Images";
import ApplicationContext from "../../context-api/context";
import { getModelByMaker, getYearByModelandMaker } from "../../utils/api";
import Selector from "./selector";
import "./styles.scss";
import useForm from "./useForm";
import { useHistory } from "react-router";

const SelectVehicle = ({
  modal,
  popup,
  isFixedHeight,
  disableModal,
  disableYear,
  modelsList,
  yearsList,
}) => {
  const { createVehicle } = useForm(popup);
  const {
    setAlertMsg,
    setOpenAlert,
    setOpenVehicle,
    searchedVehical,
    makersList,
  } = useContext(ApplicationContext);
  let modelsListArray;
  let yearsListArray;

  function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  const isListContainingWord = (wordToSearch, listArray) => {
    return listArray?.find(
      (o) => o.name.toLowerCase() === wordToSearch.toLowerCase()
    );
  };
  const changeSelectVehicleHandler = async () => {
    if (!isObjectEmpty(searchedVehical)) {
      let makerName = searchedVehical.make.value;
      let modelName = searchedVehical.model.value;
      const {
        make: { value: makeValue, label: makerLabel },
        model: { value: modelValue, label: modelLabel },
        year: { value: yearValue, label: yearLabel },
      } = searchedVehical || {};
      let tempSelect = {
        make: {
          value: makeValue,
          label: makerLabel,
        },
        model: {
          value: modelValue,
          label: modelLabel,
        },
        year: {
          value: yearValue,
          label: yearLabel,
        },
      };
      setSelect(tempSelect);
      setVehicleName({ maker: makeValue, model: modelValue, year: yearValue });
      setModelLoading(true);
      setYearLoading(true);
      let maker = isListContainingWord(makerName, makersList);
      setMakerId(maker.id);
      await fetchModelsList(maker.id);
      setModelDisable(false);
      setModelLoading(false);
      let model = isListContainingWord(modelName, modelsListArray);
      await fetchYearList(maker.id, model.id);
      setYearDisable(false);
      setYearLoading(false);
    }
  };
  useEffect(() => {
    changeSelectVehicleHandler();
  }, []);

  const modelRef = useRef(null);
  const yearRef = useRef(null);
  const [makerId, setMakerId] = useState("");
  const [modelDisable, setModelDisable] = useState(disableModal);
  const [yearDisable, setYearDisable] = useState(disableYear);
  const [select, setSelect] = useState({
    make: { value: make, label: make },
    model: { value: model, label: model },
    year: { value: year, label: year },
  });
  const [vehicleName, setVehicleName] = useState({
    maker: "",
    model: "",
    year: "",
  });
  const [makerLoading, setMakerLoading] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [yearLoading, setYearLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [showYear, setShowYear] = useState(false);
  const [modelList, setModelList] = useState(modelsList);
  const [yearList, setYearList] = useState(yearsList);
  const history = useHistory();

  const disableYearModal = () => {
    setYearDisable(true);
    setVehicleName((e) => ({ ...e, year: "" }));
    setSelect((e) => ({
      ...e,
      year: { value: year, label: year },
    }));
  };

  const handleMake = async (name, value) => {
    let makerName = value.label;
    setShowModel(true);
    setMakerforDropdown(makerName);
    setMakerId(value.id);
    setModelLoading(true);
    setModelList([]);
    await fetchModelsList(value.id);
    modelRef.current.focus();
    setModelLoading(false);
    setModelDisable(false);
    disableYearModal();
  };

  const handleModel = async (name, value) => {
    let modelId = value.id;
    let modelName = value.label;
    setShowYear(true);
    setYearLoading(true);
    yearRef.current.focus();
    setShowModel(false);
    setYearDisable(false);
    setYearList([]);
    setVehicleName((e) => ({ ...e, model: modelName }));
    setSelect((e) => ({
      ...e,
      model: { value: modelName, label: modelName },
    }));
    await fetchYearList(makerId || value.makerId, modelId);
    setYearLoading(false);
  };
  const handleYear = (name, value) => {
    let year = value.label;
    setShowYear(false);
    setVehicleName((e) => ({ ...e, year: year }));
    setSelect((e) => ({
      ...e,
      year: { value: year, label: year },
    }));
  };
  const checkProperties = (obj) => {
    let arr = [];
    for (let key in obj) {
      arr.push(obj[key] !== undefined && obj[key] !== null && obj[key] !== "");
    }
    return arr.includes(false);
  };
  const handleSubmit = () => {
    if (!checkProperties(vehicleName) && !showModel && !showYear) {
      setLoader(true);
      let localStorageObj = {
        make: { value: vehicleName.maker, label: vehicleName.maker },
        model: { value: vehicleName.model, label: vehicleName.model },
        year: { value: vehicleName.year, label: vehicleName.year },
      };
      service.insertVehicle(localStorageObj); //adding object to local storage
      setTimeout(() => {
        setLoader(false);
        setLoader(false);
        setOpenVehicle(false);
        const encodeMake = vehicleName.maker.replaceAll("-", "+");
        const encodeModel = vehicleName.model.replaceAll("-", "+");
        const encodeYear = vehicleName.year.replaceAll("-", "+");
        history.push(
          `/${encodeURIComponent(encodeMake)}-${encodeURIComponent(
            encodeModel
          )}-${encodeURIComponent(encodeYear)}.html`
        );
      }, 500);
    }
  };

  const setMakerforDropdown = (makerName) => {
    setVehicleName((e) => ({ ...e, maker: makerName }));
    setSelect((e) => ({
      ...e,
      make: { value: makerName, label: makerName },
    }));
  };
  //lists to populate dropdowns
  const makeList = makersList?.map((i) => {
    return {
      value: i.name,
      label: i.name,
      id: i.id,
    };
  });
  const modelListArr = modelList?.map((i) => {
    return {
      value: i.name,
      label: i.name,
      id: i.id,
    };
  });
  const yearListArr = yearList?.map((i) => {
    return {
      value: i.name,
      label: i.name,
      id: i.id,
    };
  });
  async function fetchModelsList(makerId) {
    if (makerId != undefined) {
      await getModelByMaker(makerId)
        .then((response) => {
          if (response.status === 200) {
            modelsListArray = response.data.content;
            setModelList(response.data.content);
          } else {
            console.log("Error in fetching Data");
            errorOccur();
          }
        })
        .catch((error) => {
          console.log("Error in fetching Data", error);
          errorOccur();
        });
    }
  }
  async function fetchYearList(makerId, modalId) {
    if (modalId != undefined && makerId != undefined) {
      await getYearByModelandMaker(modalId, makerId)
        .then((response) => {
          if (response.status === 200) {
            yearsListArray = response.data.content;
            setYearList(yearsListArray);
          } else {
            console.log("Error in fetching Data");
            errorOccur();
          }
        })
        .catch((error) => {
          console.log("Error in fetching Data", error);
          errorOccur();
        });
    }
  }

  const errorOccur = () => {
    setOpenVehicle(false);
    setAlertMsg("Something failed!");
    setOpenAlert(true);
  };

  return (
    <>
      <div
        className={
          modal === true ? "_mobileView" : "relativePosition container add"
        }
      >
        <div
          className={
            modal === true
              ? "section-search isBoxShadow"
              : "section-search isBoxShadow absolute"
          }
        >
          <div>
            <span className="ASIcon">
              <img src={addIcon} />
            </span>
          </div>
          <div className="searches">
            <div
              className={modal === true ? "slectorSize" : "widthSize"}
              style={{ cursor: "pointer" }}
            >
              <Selector
                name="make"
                value={select.make}
                handleChange={handleMake}
                placeholder={make}
                options={makeList}
                onFocus={() => {
                  setShowModel(false);
                  setShowYear(false);
                }}
                noOptionsMessage={() => noOptionFound}
                isLoading={makerLoading}
                isFixedHeight={isFixedHeight}
                onkeydown={(e) => {
                  if (e.code === "Enter") {
                    setTimeout(() => {
                      modelRef.current.focus();
                    }, 1000);
                  }
                }}
              />
            </div>
            <div
              className={modal === true ? "slectorSize" : "widthSize"}
              style={{ cursor: !showModel ? "not-allowed" : "pointer" }}
            >
              <Selector
                innerRef={modelRef}
                name="model"
                value={select.model}
                handleChange={handleModel}
                placeholder={model}
                options={modelListArr}
                menuIsOpen={showModel}
                onFocus={() => {
                  setShowYear(false);
                  setShowModel(true);
                }}
                isSearchable={true}
                disable={modelDisable}
                noOptionsMessage={() => noOptionFound}
                isLoading={modelLoading}
                isFixedHeight={isFixedHeight}
                onkeydown={(e) => {
                  if (e.code === "Enter") {
                    setTimeout(() => {
                      yearRef.current.focus();
                    }, 1000);
                  }
                }}
              />
            </div>
            <div
              className={modal === true ? "slectorSize" : "widthSize"}
              style={{ cursor: !showYear ? "not-allowed" : "pointer" }}
            >
              <Selector
                name="year"
                innerRef={yearRef}
                value={select.year}
                handleChange={handleYear}
                placeholder={year}
                options={yearListArr}
                onFocus={() => {
                  setShowYear(true);
                  setShowModel(false);
                }}
                menuIsOpen={showYear}
                isSearchable={showYear}
                disable={yearDisable}
                noOptionsMessage={() => noOptionFound}
                isLoading={yearLoading}
                isFixedHeight={isFixedHeight}
              />
            </div>
            <div className="btnSize">
              {loader ? (
                <CustomButton className="SBtn btn ">
                  <CircularProgress size={25} color="white" />
                </CustomButton>
              ) : (
                <CustomButton
                  title={
                    createVehicle.length == 0
                      ? searchDrop.replace(/\s/g, "")
                      : addVehicle
                  }
                  className={
                    createVehicle.length == 0 ? "SBtn btn " : "btn Subtn"
                  }
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectVehicle;
