import ReactPixel from "react-facebook-pixel";

// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init("1272892356582116", { em: "" }, options); // For Client Account
// ReactPixel.init("779391489937539", { em: "" }, options); // For Qasim Account
// you have to chage ID in index.html

export const PageView = () => {
  ReactPixel.fbq("track", "ViewContent", {
    partNumber1: "Qasim Subhani ViewContent",
  });
};

export const addToCartPixel = (id) => {
  ReactPixel.fbq("track", "AddToCart", {
    productId: id,
  });
};

export const addToWishListPixel = (id, email) => {
  ReactPixel.fbq("track", "AddToWishlist", {
    productId: id,
    email: email,
  });
};
export const initiateCheckoutPixel = (email) => {
  ReactPixel.fbq("track", "InitiateCheckout", {
    email: email,
  });
};
export const contactPixel = () => {
  ReactPixel.fbq("track", "Contact");
};
export const completeRegistraionPixel = () => {
  ReactPixel.fbq("track", "CompleteRegistration");
};
export const purchasePixel = (data) => {
  ReactPixel.fbq("track", "Purchase", data);
};
