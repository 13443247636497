import React from "react";
import "../../common/themeColor.scss";
import "./style.scss";
import {
  confirmed,
  delivered,
  orderNum,
  orderPlaced,
  ordrBy,
  pending,
  shipped,
  discount,
  orderItems,
  coln,
  toPay,
  shippingCost,
  subTotal,
  Total,
  orderDetail,
  viewInvoice,
} from "../../common/constants.js";
import { Collapse, IconButton } from "@material-ui/core";
import { ReactComponent as ExpandOpen } from "../../images/expandOpen.svg";
import { ReactComponent as ExpandClose } from "../../images/expandClose.svg";
import { CustomButton } from "..";
import { colors } from "../../common/theme";
import { Skeleton } from "@material-ui/lab";

const SkeletonOrderBox = (props) => {
  return (
    <div className="orderScreen">
      <div>
        <div className="root">
          <div className="expand_Icon">
            <IconButton onClick={props.onClick}>
              {props.isCollapse ? <ExpandOpen /> : <ExpandClose />}
            </IconButton>
          </div>
          <div className="content_head">
            

                <div className="orderHead">
                  <p style={{width:"13rem"}}>
                   <Skeleton width={"100%"} height={18}/>
                  </p>
                  <div className="buttonDivs">
                   
                      <div
                        className="statusBox" >
                      <Skeleton width={"100%"} height={45} style={{marginBottom:-3,marginTop:-7}} />
                      </div>
               
                  </div>
                </div>

                <div className="row1">
                  <div style={{width:"5rem"}}>
                    <div>
                    <Skeleton width={"100%"} height={15} />
                    </div>
                    <div>
                    <Skeleton width={"100%"} height={15} />
                    </div>
                  </div>
                  <div style={{width:"5rem"}}>
                    <div>
                    <Skeleton width={"100%"} height={15} />
                    </div>
                    <div>
                    <Skeleton width={"100%"} height={15} />
                    </div>
                  </div>
                  <div style={{width:"5rem"}}>
                    <div>
                    <Skeleton width={"100%"} height={15} />
                    </div>
                    <div>
                    <Skeleton width={"100%"} height={15} />
                    </div>
                  </div>
                </div>
          </div>
        </div>
        <Collapse
          in={props.isCollapse}
          classes={{
            wrapper: "wrapperCollapse",
          }}
        >
          <div className="productDiv">
            <div  className="d-flex">
              <div className="expand_div"></div>
              <div className="content_head">
               <p className="orderText"> <Skeleton width={"30%"} height={15} /></p>
                <div className="content_Items">{props.children}</div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default SkeletonOrderBox;
