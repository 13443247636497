import React, { useState, useEffect } from 'react';
import './style.scss';
import CustomizedProgressBars from '../progress-bar';
import {
  compatibility,
  duarability,
  easeOf,
  easyToUse,
  featuredProducts,
  overall,
  productDescription,
  review,
  uniqueStyle,
  wellKerned,
} from '../../common/constants';
import Rating_Star from '../ratingStar';

const RatingSummery = ({ reviewObj }) => {
  return (
    <React.Fragment>
      <h2 className="heading">{productDescription.ratingSummeryHeading}</h2>
      <div className="ratingSummery">
        <div className="ratingHeader">
          <strong>{reviewObj.generalRating}</strong>
          <span className="totalRating">
            {reviewObj.totalReviews}
            {review}
          </span>
          <Rating_Star
            _ratingValue={reviewObj.generalRating}
            className="ratingStarMainImg"
          />
        </div>
        {/* <div className="ratingBody">
          <div className="box">
            <h4 className="boxTitle">{productDescription.ratingBoxTitle}</h4>
            <div className="features">
              <div className="left">
                <span>{wellKerned}</span>
                <span>{uniqueStyle}</span>
              </div>
              <div className="right">
                <span>{featuredProducts}</span>
                <span>{easyToUse}</span>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="result">
              <div className="left">
                <span className="starCount">5</span>
                <span className="fas fa-star starIcon"></span>
              </div>
              <CustomizedProgressBars rating={90} />
            </div>
            <div className="result">
              <div className="left">
                <span className="starCount">5</span>
                <span className="fas fa-star starIcon"></span>
              </div>
              <CustomizedProgressBars rating={60} />
            </div>
            <div className="result">
              <div className="left">
                <span className="starCount">5</span>
                <span className="fas fa-star starIcon"></span>
              </div>
              <CustomizedProgressBars rating={80} />
            </div>
            <div className="result">
              <div className="left">
                <span className="starCount">5</span>
                <span className="fas fa-star starIcon"></span>
              </div>
              <CustomizedProgressBars rating={70} />
            </div>
          </div>
          <div className="box2">
            <div className="result">
              <div className="left">
                <span className="starCount">{overall}</span>
              </div>
              <CustomizedProgressBars rating={50} />
              <span className="rightCount">189</span>
            </div>
            <div className="result">
              <div className="left">
                <span className="starCount">{duarability}</span>
              </div>
              <CustomizedProgressBars rating={30} />
              <span className="rightCount">189</span>
            </div>
            <div className="result">
              <div className="left">
                <span className="starCount">{easeOf}</span>
              </div>
              <CustomizedProgressBars rating={20} />
              <span className="rightCount">189</span>
            </div>
            <div className="result">
              <div className="left">
                <span className="starCount">{compatibility}</span>
              </div>
              <CustomizedProgressBars rating={60} />
              <span className="rightCount">189</span>
            </div>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
};
export default RatingSummery;
