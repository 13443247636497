import React from "react";
import {
  areYouSure,
  areYouWant,
  cancel,
  no,
  outOfStockDetails,
  outOfStockRequest,
  remove,
  yes,
} from "../../common/constants";
import { CustomButton } from "..";
import "./style.scss";
import { ReactComponent as Alert } from "../../images/alert.svg";
import { ReactComponent as Delete } from "../../images/delete.svg";
import { ReactComponent as Success } from "../../images/success.svg";
import { ReactComponent as Warning } from "../../images/warning.svg";
import { CircularProgress } from "@mui/material";

export const Confirm_Alert = (props) => {
  return (
    <div className="alertRoot">
      {props.del ? (
        <>
          <div style={{ marginTop: "1rem" }}>
            {props.requestProduct ? (
              <Warning width={95} height={131} />
            ) : (
              <Delete width={95} height={131} />
            )}
          </div>
          <h2 className="warning">
            {props.requestProduct ? outOfStockRequest : areYouSure}
          </h2>
          <p className="warningalert">
            {props.requestProduct ? outOfStockDetails : areYouWant}
          </p>
          <div className="btnContainer">
            <CustomButton
              title={
                props.requestProduct
                  ? [props?.btnLoader ? <CircularProgress size={15} /> : yes]
                  : remove
              }
              className="confirmButton"
              onClick={props.onConfirm}
            />
            <CustomButton
              title={props.requestProduct ? no : cancel}
              className="buttonCancel"
              onClick={props.onCancel}
            />
          </div>
        </>
      ) : (
        <>
          {!props.isSuccess ? (
            <div>
              <Alert width={120} />
            </div>
          ) : (
            <div style={{ marginBottom: 40 }}>
              <Success width={100} />
            </div>
          )}
          <h2 className="warning">{props.heading}</h2>
          <p className="warningalert"> {props.text}</p>

          <div className="btnContainer">
            <CustomButton
              title={"OK"}
              className="confirmButton width"
              onClick={props.onCancel}
            />
          </div>
        </>
      )}
    </div>
  );
};
