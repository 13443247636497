import React, { useEffect, useState, useContext } from "react";
import {
  favourite,
  favourites,
  pDescription,
  selectFavoriteProduct,
} from "../../../common/constants";
import { product2, tyreImage } from "../../../common/Images";
import { Confirm_Alert } from "../../../Component";
import CustomizedDialogs from "../../../Component/customModal/Modal";
import FavProduct from "../../../Component/productBox/favouritProduct";
import { Skeleton } from "@material-ui/lab";
import { getFavouriteProducts } from "../../../utils/api";
import MiCuenta from "../mi-cuenta";
import { postFavouritePro } from "../../../utils/api";
import { useToasts } from "react-toast-notifications";
import { regexImg } from "../../../common/services";
import ApplicationContext from "../../../context-api/context";
import "./style.scss";

const Favourite = () => {
  const { setFavoriteData, favoriteData } = useContext(ApplicationContext);
  const [openModal, setOpenModal] = useState(false);
  // const [favoriteData, setFavoriteData] = useState([]);
  const [loading, setloading] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const { addToast } = useToasts();
  const favouriteProduct = async () => {
    setloading(true);
    await getFavouriteProducts()
      .then((res) => {
        if (res && res.data.success) {
          setloading(false);
          setFavoriteData(res.data.content);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffect(() => {
    favouriteProduct();
  }, []);

  const delFavoriteProduct = async () => {
    setOpenModal(false);
    const obj = {
      productID: idToDelete,
      add: false,
    };
    const showToast = (message) => {
      addToast(message, { appearance: "success", autoDismiss: true });
    };
    {
      const res = await postFavouritePro(obj);
      if (res && res.data?.success) {
        showToast(res.data?.description);
        const filteredProducts = favoriteData.filter(
          (pro) => pro.productID !== idToDelete
        );
        setFavoriteData(filteredProducts);
      }
    }
  };
  const updatelatestQty = (Id, updateQty) => {
    let temp = favoriteData;
    temp.filter((p) => p.productID === Id)[0].quantity = updateQty;
    setFavoriteData(temp);
  };

  return (
    <MiCuenta title={favourites}>
      <div className="Billingmain">
        <div className="mainScreen ">
          {loading ? (
            [1, 2, 3].map((e) => (
              <Skeleton className="skeleton" animation="wave" />
            ))
          ) : (
            <>
              <p className="billText">{favourite}</p>
              {favoriteData?.length === 0 && (
                <div>
                  <span className="message">{selectFavoriteProduct} </span>
                </div>
              )}
              {favoriteData?.map((item, index) => (
                <FavProduct
                  name={item.productName || item.name}
                  Img={item.image}
                  param={item.encryptedProductID}
                  pathName={item.url}
                  itemPrice={item.price}
                  cartQuantity={item.quantity}
                  productID={item.productID}
                  productObj={item}
                  updateQty={(value) => updatelatestQty(item.productID, value)}
                  onCart={() => {
                    console.log("item added to cart successfully!");
                  }}
                  onDelete={() => {
                    setIdToDelete(item.productID);
                    setOpenModal(true);
                  }}
                />
              ))}
            </>
          )}
        </div>
      </div>
      {
        <CustomizedDialogs onClose={openModal} open={openModal}>
          <Confirm_Alert
            del={true}
            onCancel={() => setOpenModal(false)}
            onConfirm={delFavoriteProduct}
          />
        </CustomizedDialogs>
      }
    </MiCuenta>
  );
};

export default Favourite;
