import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { Grid, Container, IconButton, InputAdornment } from "@material-ui/core";
import "./style.scss";
import AlertBox from "../../../Component/confirm-alert/alertBox";
import { error } from "./../../../common/constants";
import { colors } from "../../../common/theme";
import { ReactComponent as FbIcon } from "../../../images/fb.svg";
import { ReactComponent as Google } from "../../../images/google.svg";
import { signUp } from "../../../common/constants";
import { Formik, Form } from "formik";
import { TextInput, CustomButton } from "../../../Component/index";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import {
  confirmPassword,
  Email,
  forYourProducts,
  Password,
  registerYourAccount,
  signIn,
  unlimitedProducts,
  yourName,
  phonNumber,
} from "../../../common/constant/formConstants";
import { path } from "../../../common/routes-names";
import { signUpValidation } from "../../../common/formValidation";
import DiscoverPart from "../../../Component/dicoverParts";
import MetaTitle from "../../../Component/metaTag";
import useForm from "./useForm";

const SignUp = () => {
  const { handleForm, loading, errorMsg, openModal, setOpenModal } = useForm();
  const [showPasswrd, setShowPasswrd] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);

  return (
    <>
      <MetaTitle title={signUp} />
      <Grid container xs={12} style={{ height: "100vh" }}>
        <DiscoverPart
          className="backgImg"
          line1={unlimitedProducts}
          line2={forYourProducts}
        />
        <Grid item xs={12} sm={7}>
          <Container className="signIn">
            <div className="register">
              <Link
                to={{
                  pathname: path.login,
                  state: {
                    from: path.signUp,
                  },
                }}
                className="register"
              >
                {signIn}
              </Link>
            </div>
            <Grid xs={12} className="register-form">
              <Grid xs={10} sm={7} className="content">
                <Formik
                  enableReinitialize
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{
                    name: "",
                    email: "",
                    password: "",
                    cPassword: "",
                    phoneNumber: "",
                  }}
                  validationSchema={signUpValidation}
                  onSubmit={handleForm}
                >
                  {({ handleSubmit }) => (
                    <Form autoComplete="off">
                      <div className="loginTitle">{registerYourAccount}</div>
                      <TextInput
                        className={"inputText"}
                        name="name"
                        type="text"
                        labelName={yourName}
                        placeholder={yourName}
                        labelClass="label"
                      />
                      <TextInput
                        className={"inputText"}
                        name="phoneNumber"
                        labelName={phonNumber}
                        placeholder={phonNumber}
                        labelClass="label"
                        type="phone"
                        onInput={
                          (e) => (e.target.value = e.target.value.slice(0, 10)) // vaidation to get only numbers from the user
                        }
                      />
                      <TextInput
                        className={"inputText"}
                        name="email"
                        type="email"
                        labelName={Email}
                        placeholder={Email}
                        labelClass="label"
                      />
                      <TextInput
                        className={"passwordText"}
                        name="password"
                        type={showPasswrd ? "text" : "password"}
                        labelName={Password}
                        placeholder={Password}
                        labelClass="label"
                        showEye={showPasswrd}
                        onEye={() => setShowPasswrd(false)}
                        onEyeOff={() => setShowPasswrd(true)}
                        colorEye="primary"
                        colorEyeOff="primary"
                      />
                      <TextInput
                        className={"passwordText"}
                        name="cPassword"
                        type={showConfirm ? "text" : "password"}
                        labelName={confirmPassword}
                        placeholder={confirmPassword}
                        labelClass="label"
                        showEye={showConfirm}
                        onEye={() => setShowConfirm(false)}
                        onEyeOff={() => setShowConfirm(true)}
                        colorEye="primary"
                        colorEyeOff="primary"
                      />
                      {/* <CustomButton
                        title={signUp}
                        // title={!loading ? { signUp } : ""}
                        className="submitBtn marginTop"
                        onClick={handleSubmit}
                      > */}

                      {loading ? (
                        <CustomButton
                          title={""}
                          className="submitBtn marginTop"
                          onClick={handleSubmit}
                        >
                          <CircularProgress size={20} color={colors.white} />
                        </CustomButton>
                      ) : (
                        <CustomButton
                          title={signUp}
                          className="submitBtn marginTop"
                          onClick={handleSubmit}
                        />
                      )}
                      {/* </CustomButton> */}
                      <div className="socialBtns">
                        {/* <IconButton className="bg">
                        <FbIcon />
                      </IconButton>
                      <IconButton className="bg">
                        <Google />
                      </IconButton> */}
                      </div>
                      <AlertBox
                        del={false}
                        onCancel={() => setOpenModal(false)}
                        heading={error}
                        text={errorMsg}
                        onClose={openModal}
                        open={openModal}
                      />
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default SignUp;
