import React, { useState, useEffect } from "react";
import "./style.scss";
import {
  VerifiedEmail,
  successfulEmail,
  continued,
} from "../../common/constants";
import { companyLogo, correoVerificado } from "../../common/Images";
import { CircularProgress } from "@material-ui/core";
import { activateEmail } from "../../utils/api";
import { useHistory } from "react-router";
import { path } from "../../common/routes-names";

export default function EmailVerification() {
  const [isValidToken, setIsValidToken] = useState(false);
  const history = useHistory();

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }
  const TokenFromUrl = () => {
    const id = useQuery().get("id");
    return id;
  };
  const urlDecoded = (string) => {
    const newText = decodeURI(string);
    return newText;
  };
  useEffect(() => {
    const token = TokenFromUrl();
    varifyEmail(urlDecoded(token));
  }, []);

  const varifyEmail = async (id) => {
    if (id) {
      try {
        let result = await activateEmail(id);
        if (result && result.data.success) {
          setIsValidToken(true);
        } else {
          setIsValidToken(false);
        }
      } catch (error) {
        alert(error);
      }
    }
  };

  return (
    <>
      {isValidToken ? (
        <div className="container emailVerification-screen">
          <div className="logo">
            <div className="imageLogo">
              <img src={companyLogo} alt="Company-Logo" />
            </div>
          </div>
          <div className="email-box">
            <div className="email-items">
              <div className="email-image">
                <img src={correoVerificado} alt="correoVerificado" />
              </div>
              <div className="email-content">
                <span>{VerifiedEmail}</span>
                <p>{successfulEmail}</p>
              </div>
              <button
                onClick={() => {
                  history.replace(path.login);
                }}
                className="emailContinue-btn"
              >
                {continued}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="circular-progress">
          <CircularProgress />
        </div>
      )}
    </>
  );
}
