import { IconButton } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  apply,
  applyDiscount,
  discount,
  enterCouponCode,
  itemReturnable,
  MispartesCom,
  orderRealizer,
  product,
  secureTransaction,
  shippingCost,
  shippingCostLabel,
  summary,
  Total,
  CheckoutTotal,
} from "../../common/constants";
import { CustomButton } from "../../Component";
import { ApplyCoupon, emptyCartRequest, RemoveCoupon } from "../../utils/api";
import {
  addBadge,
  removeBadge,
  resetCoupon,
} from "../../Redux/reducers/filterSlice";
import Badges from "../../Sections/sorted-products/badge";
import "./style.scss";
import { ReactComponent as SecureIcon } from "../../images/secure.svg";
import ApplicationContext from "./../../context-api/context";
import { path } from "../../common/routes-names";
import { useHistory } from "react-router";
import { error } from "../../common/constants";
import AlertBox from "../../Component/confirm-alert/alertBox";
import { CircularProgress } from "@material-ui/core";
import { submitOrder } from "./../../utils/api/index";
import { useToasts } from "react-toast-notifications";
import { initiateCheckoutPixel } from "../../utils/fbPixelEvent";

const PaymentSummary = (props) => {
  const {
    selectedCheckoutBillingAddreess,
    selectedCheckoutShippingAddreess,
    selectedCheckoutPayment,
    cartList,
    setCheckoutFormValidation,
    checkoutFormValidation,
    setCartList,
  } = useContext(ApplicationContext);

  const { addToast } = useToasts();
  const showToast = (isSuccess, message) => {
    addToast(message, { appearance: isSuccess ? "success" : "error" });
  };
  const dispatch = useDispatch();
  const selected_Badge = useSelector((state) => state.filter.SelectedBtn);
  const [couponCode, setCouponCode] = useState("");
  const [couponLoader, setCouponLoader] = useState(false);
  const [removeCouponLoader, setRemoveCouponLoader] = useState(false);
  const [isCouponDisable, setIsCouponDisable] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const [discountedAmount, setDiscountedAmount] = useState(
    parseFloat(props.discount).toFixed(2)
  );
  const [errorMsg, setError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [checkoutBtnClicked, setCheckoutBtnClicked] = useState(false);
  const history = useHistory();
  const [data, setData] = useState(false);

  // removing coupon badge
  useEffect(() => {
    dispatch(resetCoupon());
    setCouponId(null);
  }, []);

  const makeCartEmpty = async () => {
    return await emptyCartRequest()
      .then((response) => {
        if (response.status === 200) {
          setCartList([]);
          setOpenModal(false);
        }
      })
      .catch((error) => {});
  };
  const handleSubmit = async () => {
    if (couponCode !== "") {
      setCouponLoader(true);
      const array = {
        id: new Date().getTime().toString(),
        code: couponCode,
      };
      try {
        let result = await ApplyCoupon(couponCode);
        const {
          data: {
            success,
            content: { resultText, discountAmount, couponID },
          },
        } = result;
        if (result && success) {
          if (discountAmount >= props.total) {
            setCouponLoader(false);
            showToast(
              false,
              "No tiene una cantidad total suficiente para aprovechar este descuento."
            );
            return;
          }
          setDiscountedAmount(parseFloat(discountAmount).toFixed(2));
          setCouponLoader(false);
          dispatch(addBadge(array));
          setCouponCode("");
          setIsCouponDisable(true);
          setCouponId(couponID);
          showToast(true, resultText);
        } else {
          // in case invalid coupon
          showToast(false, resultText);
          setCouponLoader(false);
        }
      } catch (error) {
        showToast(true, error?.message);
        setCouponLoader(false);
      }
    }
  };

  //removing coupon code
  const onCouponBadgeClick = async (item) => {
    setRemoveCouponLoader(true);
    let result = await RemoveCoupon(item.code);
    const {
      data: { success, description },
    } = result;
    if (result && success) {
      dispatch(removeBadge(item));
      setIsCouponDisable(false);
      showToast(true, description);
      setCouponId(null);
      setRemoveCouponLoader(false);
      setDiscountedAmount(parseFloat(props.discount).toFixed(2));
    }
  };

  const getCompletedJSON = () => {
    return {
      // Total: props.total,
      CouponID: couponId == null ? null : couponId.toString(),
      Total: (parseFloat(props.total) - parseFloat(discountedAmount)).toFixed(
        2
      ),
      AddressID:
        selectedCheckoutShippingAddreess && selectedCheckoutShippingAddreess.id,
      PaymentTypeID: "",
      CardPaymentTypeID: "",
      CardToken: "",
      CardSaveCard: "",
      InvoicingID: "",
      TransferPaymentTypeID: "",
      StorePaymentTypeID: "",
      UsoCFDI: "",
      // PaymentTypeID:
      //   selectedCheckoutPayment && selectedCheckoutPayment.paymentType,
      // CardPaymentTypeID:
      //   selectedCheckoutPayment && selectedCheckoutPayment.cardBrand,
      // CardToken:
      //   selectedCheckoutPayment && (selectedCheckoutPayment.CardToken || ""),
      // CardSaveCard:
      //   selectedCheckoutPayment &&
      //   (selectedCheckoutPayment.CardSaveCard || false),
      // InvoicingID:
      //   selectedCheckoutBillingAddreess &&
      //   "" + selectedCheckoutBillingAddreess.id,
      // TransferPaymentTypeID:
      //   selectedCheckoutPayment && selectedCheckoutPayment.paymentType === 2
      //     ? selectedCheckoutPayment.name
      //     : "",
      // StorePaymentTypeID:
      //   selectedCheckoutPayment && selectedCheckoutPayment.paymentType === 3
      //     ? selectedCheckoutPayment.name
      //     : "",
      // UsoCFDI:
      //   selectedCheckoutBillingAddreess &&
      //   selectedCheckoutBillingAddreess.UsoCFDI
      //     ? selectedCheckoutBillingAddreess.UsoCFDI
      //     : "",
    };
  };

  const createCheckoutObject = () => {
    let requestedObject = getCompletedJSON();
    const checkoutPayment = selectedCheckoutPayment;
    requestedObject.PaymentTypeID = 4;
    requestedObject.CardToken = "";
    requestedObject.CardPaymentTypeID = "";
    requestedObject.StorePaymentTypeID = "";
    requestedObject.TransferPaymentTypeID = "";
    requestedObject.CardSaveCard = false;
    requestedObject.InvoicingID = "-1";
    // if (checkoutPayment.paymentType === 1) {
    //   requestedObject.PaymentTypeID = 1;
    //   requestedObject.CardToken = checkoutPayment.CardToken;
    //   requestedObject.CardPaymentTypeID = checkoutPayment.cardBrand
    //     ? checkoutPayment.cardBrand
    //     : checkoutPayment.CardPaymentTypeID;
    //   requestedObject.CardSaveCard = checkoutPayment.CardSaveCard
    //     ? checkoutPayment.CardSaveCard
    //     : false;
    // } else if (checkoutPayment.paymentType === 2) {
    //   requestedObject.PaymentTypeID = 2;
    //   requestedObject.CardToken = "";
    //   requestedObject.CardPaymentTypeID = "";
    //   requestedObject.TransferPaymentTypeID = checkoutPayment.id;
    //   requestedObject.StorePaymentTypeID = "";
    // } else if (checkoutPayment.paymentType === 3) {
    //   requestedObject.PaymentTypeID = 3;
    //   requestedObject.CardToken = "";
    //   requestedObject.CardPaymentTypeID = "";
    //   requestedObject.StorePaymentTypeID = checkoutPayment.id;
    //   requestedObject.TransferPaymentTypeID = "";
    // } else if (checkoutPayment.paymentType === 4) {
    //   requestedObject.PaymentTypeID = 4;
    //   requestedObject.CardToken = "";
    //   requestedObject.CardPaymentTypeID = "";
    //   requestedObject.StorePaymentTypeID = "";
    //   requestedObject.TransferPaymentTypeID = "";
    // }

    submitOrder(requestedObject).then((response) => {
      // console.log("resonse Of place order ", requestedObject);

      const {
        data: { content, success, description },
      } = response;
      setCheckoutBtnClicked(false);
      //     if ([2, 3].includes(checkoutPayment.paymentType)) {
      //       if (!success) {
      //         setData(true);
      //         setError(response.data.description);
      //         setOpenModal(true);
      //       } else {
      //         history.replace(path.orderConfirmation, {
      //           paymentTypeId: checkoutPayment.paymentType,
      //           responseObject: content,
      //         });
      //       }
      //     } else
      // if (checkoutPayment.paymentType === 4) {
      if (!success) {
        setData(true);
        setError(response.data.description);
        setOpenModal(true);
      } else {
        content.externalURL && window.open(content.externalURL, "_self");
      }
      //     } else if (checkoutPayment.paymentType === 1) {
      //       console.log(response.data.description);
      //       if (!success) {
      //         setData(true);
      //         setError(response.data.description);
      //         setOpenModal(true);
      //       } else {
      //         history.replace(path.orderConfirmation, {
      //           paymentTypeId: checkoutPayment.paymentType,
      //           responseObject: content,
      //           description: description,
      //         });
      //       }
      //     }
      //   })
      //   .catch((e) => {
      //     console.log("Error : ", e);
    });
  };
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="summaryWrapper">
      <h3 className="summaryHeading">{summary}</h3>
      <div className="summmaryBody">
        <div className="priceSummary">
          <div className="detail">
            <span className="key">
              {product}({props.qty}):
            </span>
            <span className="value">
              ${numberWithCommas(props.poductPrice)}
            </span>
          </div>
          <div className="detail">
            <span className="key">{shippingCostLabel}</span>
            <span className="value">
              ${numberWithCommas(props.shippingCost)}
            </span>
          </div>
          <div className="detail">
            <span className="key">{discount}</span>
            <span className="value">${numberWithCommas(discountedAmount)}</span>
          </div>
          <div className="total">
            <span className="key">{CheckoutTotal}</span>
            <span className="value">
              $
              {numberWithCommas(
                parseFloat(props.total - discountedAmount).toFixed(2)
              )}
            </span>
          </div>
          <div className="couponArea">
            <h3 className="couponAreaHaeding">{applyDiscount}</h3>
            <form
              className={
                isCouponDisable ? "disable-coupon-code inputArea" : "inputArea"
              }
              autoComplete="off"
            >
              <input
                autoFocus={false}
                type="text"
                placeholder={enterCouponCode}
                name="coupon"
                value={couponCode}
                disabled={isCouponDisable}
                onChange={(e) => setCouponCode(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
              />
              <CustomButton
                title={couponLoader ? "" : apply}
                className="primaryBtn"
                onClick={handleSubmit}
                isDisabled={isCouponDisable}
              >
                {couponLoader ? (
                  <CircularProgress size={15} color="white" />
                ) : (
                  ""
                )}
              </CustomButton>
            </form>
            <div className="tagsOuter">
              {selected_Badge.length > 0 &&
                selected_Badge.map((item) => (
                  <Badges
                    couponCode={item.code}
                    onClick={() => onCouponBadgeClick(item)}
                    loading={removeCouponLoader}
                  />
                ))}
            </div>
          </div>
          <div className="order-Descript">
            <p>
              <span>
                <SecureIcon />
              </span>
              <span>{secureTransaction}</span>
            </p>
            {/* <p>
              <span>
                <OrderIcon />
              </span>
              <span>{MispartesCom}</span>
            </p>
            <p>
              <span>
                <ReturnIcon />
              </span>
              <span>{itemReturnable}</span>
            </p> */}
          </div>
        </div>

        <CustomButton
          title={!checkoutBtnClicked ? orderRealizer : ""}
          // isDisabled // for the time being it is disbaled for the order completion
          className="primaryBtn"
          onClick={() => {
            // console.log("Selected Checkout PRocess ", selectedCheckoutPayment);
            // if (
            //   (selectedCheckoutPayment?.paymentType === 1 &&
            //     !selectedCheckoutPayment?.CardToken) ||
            //   (selectedCheckoutBillingAddreess?.id !== -1 &&
            //     !selectedCheckoutBillingAddreess?.UsoCFDI) ||
            //   selectedCheckoutShippingAddreess === null
            // ) {
            //   setCheckoutFormValidation(true);
            // } else {

            if (selectedCheckoutShippingAddreess === null) {
              setCheckoutFormValidation(true);
            } else {
              createCheckoutObject();
              setCheckoutBtnClicked(true);
            }

            // }
            // return false;
          }}
        >
          {checkoutBtnClicked ? (
            <CircularProgress size={20} color="white" />
          ) : (
            ""
          )}
        </CustomButton>

        <AlertBox
          del={false}
          onCancel={() => setOpenModal(false)}
          heading={error}
          text={errorMsg}
          onClose={openModal}
          open={openModal}
        />
        {data ? (
          <AlertBox
            del={false}
            onCancel={() => setOpenModal(false)}
            heading={error}
            text={errorMsg}
            onClose={openModal}
            open={openModal}
          />
        ) : null}
      </div>
    </div>
  );
};

export default PaymentSummary;
