import React, { useEffect, useContext } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { path, privateRoute, publicRoute } from "./common/routes-names";
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router";
import PublicRoute from "./routes/publiceRoutes";
import PrivateRoute from "./routes/private";
import PageNotFound from "./Component/confirm-alert/error404";

import ApplicationContext from "./context-api/context";
import FloatingWhatsApp from "react-floating-whatsapp";
import logo from "./images/logo.svg";
import support from "./images/support.png";
import {
  chatMessage,
  statusMessage,
  companyName,
  companyNumber,
  typemessage,
} from "./common/constants";
import { contactPixel } from "./utils/fbPixelEvent";
//Scroll to top when ever page navigating
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("SOME-ID")?.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const Routes = () => {
  const _PublicRoutes = Object.values(publicRoute);
  const _PrivateRoutes = Object.values(privateRoute);
  const { isLoggedIn, setIsLoggedIn, isCartMenu } =
    useContext(ApplicationContext);

  setIsLoggedIn(localStorage.getItem("sessionToken") ? true : false);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        {isLoggedIn || localStorage.getItem("sessionToken")
          ? _PrivateRoutes.map((route) => (
              <PrivateRoute
                key={`route-${route.name}`}
                path={route.path}
                component={route.component}
                exact
                isLoggedIn={isLoggedIn || localStorage.getItem("sessionToken")}
              />
            ))
          : _PublicRoutes.map((route) => (
              <PublicRoute
                key={`route-${route.name}`}
                path={route.path}
                component={route.component}
                exact
                isLoggedIn={isLoggedIn}
              />
            ))}
        <Redirect to="/" path={path.login} />
        <Redirect to="/" path={path.signUp} />
      </Switch>
      <div
        onClick={() => {
          contactPixel();
        }}
      >
        <FloatingWhatsApp
          chatMessage={chatMessage}
          statusMessage={statusMessage}
          avatar={logo}
          phoneNumber={companyNumber}
          accountName={companyName}
          height={false}
          allowClickAway={true}
          allowEsc={true}
          styles={{ bottom: "77px", right: isCartMenu ? "31rem" : "9px" }}
        />
      </div>
    </BrowserRouter>
  );
};
export default Routes;
{
  /* <Route path={"/sign-in"} exact component={PageNotFound} /> */
}
