import React, {
  useState,
  useEffect,
  useContext,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import { RadioBox } from "../index";
import "../customModal/style.scss";
import BillBox from "../billingBox";
import {
  addBillingBtn,
  billingAddres,
  Continue,
  noInvoiceWant,
  previouseSelectedId,
} from "../../common/constants";
import useForm from "../customModal/useForm";
import { CustomButton, CloseButton } from "../index";
import { getCheckoutInvoices } from "./../../utils/api";
import ApplicationContext from "../../context-api/context";
import { BillForm } from "../index";
import { Collapse } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { taxRegimenValidation } from "../../common/formValidation";

export const InvoicePopUpModal = forwardRef((props, ref) => {
  const {
    close,
    shipping,
    billAddress,
    state,
    setState,
    setClosePop,
    show,
    setShow,
    onInvoiceSelected,
  } = props || {};

  useImperativeHandle(ref, () => ({
    childMethod() {
      childMethod();
    },
  }));
  function childMethod() {
    onModelCloseHandler();
  }

  const { addAddress } = useForm();
  const [billingAddressList, setBillingAddressList] = useState([]);
  const [showBankModal, setshowBankModal] = useState(false);
  const [showStoreModal, setshowStoreModal] = useState(false);
  const [paymentSelectedId, setPaymentSelectedId] = useState(0);
  const [invoiceId, setInvoiceId] = useState(0);
  const [purchaseReason, setPurchaseReason] = useState(false);
  const formRef = useRef();
  const { setSelectedCheckoutBillingAddreess } = useContext(ApplicationContext);

  const handleClose = () => {
    setShow(false);
    close();
  };

  const handleSubmit = () => {
    setState((prev) => ({
      prev,
      selectedId: prev.selectedId,
    }));
    localStorage.removeItem(previouseSelectedId);
    // setClosePop(false);
  };

  const clo = () => {
    setPaymentSelectedId(0);
    setshowBankModal(false);
    setshowStoreModal(false);
    setShow(!show);
  };

  const getListData = async () => {
    // ===== It is use to get billing addresses for the checkout page ===== //
    if (billAddress) {
      const response = await getCheckoutInvoices();
      if (response) {
        const {
          data: { content },
        } = response;

        console.log("response", response);
        setBillingAddressList(content);
      }
    }
  };

  const newInvoiceInsertion = (data) => {
    let result = data.data;
    if (result.success) {
      setSelectedCheckoutBillingAddreess(result.content);
      handleClose();
    }
  };

  useEffect(() => {
    if (billingAddressList.length === 0) getListData();
  }, []);

  const onModelCloseHandler = () => {
    if (billAddress) {
      handleSubmit();
      return;
    }
  };
  let purchaseReasonInvoiceList = [
    {
      id: "G01",
      name: "Adquisición de mercancías",
    },
    {
      id: "G03",
      name: "Gastos en general",
    },
  ];

  const PurchaseReasonForm = () => {
    return (
      <Formik
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={{
          id: new Date().getTime().toString(),
          taxregimen: "",
        }}
        validationSchema={taxRegimenValidation}
        onSubmit={(values, actions) => {}}
        innerRef={formRef}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form>
            <div className={props.checkout ? "checkoutRow" : "inputRow"}>
              <div>
                <Field name="taxregimen">
                  {({ field, form, meta }) => (
                    <select
                      name="taxregimen"
                      value={values.taxregimen}
                      className="custom-select-checkout custom-select custom-select-md"
                      onChange={(e) => {
                        console.log(e);
                        handleChange(e);
                        setFieldValue("taxregimen", e.target.value);
                        setPurchaseReason(false);
                      }}
                    >
                      <option value="" disabled selected hidden>
                        Seleccione
                      </option>
                      {purchaseReasonInvoiceList &&
                        purchaseReasonInvoiceList.map((option) => {
                          return (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          );
                        })}
                    </select>
                  )}
                </Field>
                <br />
                {purchaseReason && (
                  <span className="error">{"Uso De CFDI requerido"}</span>
                )}
              </div>

              {/* <GenricDropDown name="taxregimen" dataSet={purchaseReasonInvoiceList} handleValue={handleTaxRegimen}  /> */}
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <div className="custom-wrapper-scroll">
        <div className="headline">
          <text className="texthead">{billingAddres}</text>
          <CloseButton
            onClick={() => {
              setClosePop(false);
            }}
          />
        </div>

        <div className="upperFormContainer">
          {billAddress && (
            <div className="formContainers">
              <div className="upperContainer">
                <BillBox addNew={addBillingBtn} onClick={clo}>
                  <Collapse
                    in={show}
                    className="collapseOpen"
                    classes={{
                      wrapper: "wrapperCollapse",
                    }}
                  >
                    <div className="formStyle">
                      <BillForm
                        checkout
                        addOrEdit={addAddress}
                        newInvoice={newInvoiceInsertion}
                        openPop={close}
                        puchaseReason={<PurchaseReasonForm />}
                      />
                    </div>
                  </Collapse>
                </BillBox>
              </div>
            </div>
          )}
          <div className="actualAddress">
            {billAddress && (
              <RadioBox
                onClick={() => {
                  setInvoiceId(-1);
                }}
                select={invoiceId === -1 ? true : false}
              >
                <BillBox noInvoiceWant={noInvoiceWant} />
              </RadioBox>
            )}

            {billAddress &&
              billingAddressList.map((item, index) => {
                const { id, taxID, name, taxAddress, isDefault } = item;
                return (
                  <RadioBox
                    key={id}
                    onClick={() => {
                      setInvoiceId(index);
                    }}
                    select={index === invoiceId}
                  >
                    <BillBox
                      billAddress={item}
                      addNew
                      name={taxID}
                      address={name}
                      isCheckout
                    />
                  </RadioBox>
                );
              })}
            <div>
              <span className="title">{"Uso De CFDI: "}</span>
              {<PurchaseReasonForm />}
            </div>
            <div className="genralBtn"></div>
          </div>
        </div>
        <br></br>
        <div
          className={
            shipping
              ? show === false
                ? "modal-footer modal-footer--ship"
                : "modal-footer modal-footer--sticky"
              : billAddress
              ? show === false
                ? "modal-footer modal-footer--bill"
                : "modal-footer modal-footer--sticky"
              : "modal-footer modal-footer--sticky"
          }
        >
          <CustomButton
            title={Continue}
            onClick={() => {
              if (invoiceId != -1) {
                if (formRef.current.values.taxregimen === "") {
                  setPurchaseReason(true);
                  return;
                }
                onInvoiceSelected(
                  billingAddressList[invoiceId],
                  formRef.current.values.taxregimen
                );
                setClosePop(false);
              } else {
                onInvoiceSelected(invoiceId);
                setClosePop(false);
              }
            }}
            className="innerbtn "
          />
        </div>
      </div>
    </>
  );
});
