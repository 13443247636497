import React, { useEffect, useContext } from "react";
import { Footer, MetaTitle } from '../../Component'
import { metaPath } from "../../common/services";
import MainHeader from '../../Sections/header'
import ApplicationContext from "../../context-api/context";
import Content from "./content";


export default function AboutUs() {
  const { setIsSticky } = useContext(ApplicationContext);
  useEffect(() => {
    setIsSticky(false);
  }, []);
  return (
    <div>
      <MetaTitle title={metaPath()} />
      <MainHeader otherIconOnHeader={true} isMenu={true} logoBig={true} />
      <Content />
      <Footer />
    </div>
  )
}
