import React, { useState, useContext } from "react";
import "./style.scss";
import Tooltip from "../tootltip_tab/tooltip";
import MenuDrawer from "./MenuDrawer";
import { getHomeSectionById } from "../../utils/api";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router";
import Cart from "../../Sections/side-menu-cart/cart";
import AlertBox from "../confirm-alert/alertBox";
import { error } from "../../common/constants";
import ApplicationContext from "./../../context-api/context";
import { alertMsg, openAlert } from "../../Redux/reducers/alertSlice";
import { useDispatch } from "react-redux";
import { devolucino, enviogratis, gurrantee } from "../../common/Images";
import { path } from "../../common/routes-names";
import { Envio, Problema, Calidad } from "../../common/constants";

const StickyHeader = (props) => {
  const [data, setData] = useState([]);
  const [active, setactive] = useState(-1);
  const [openCartPanel, setOpenCartPanel] = useState(false);
  const {
    isLoggedIn,
    cartList,
    setIsOpenLoginModel,
    isSticky,
    setIsSticky,
    totalQuantityLength,
    setAlertMsg,
    setOpenAlert,
  } = useContext(ApplicationContext);
  const dispatch = useDispatch();
  React.useEffect(() => {
    getId();
  }, []);
  async function getId() {
    await getHomeSectionById(3)
      .then((response) => {
        if (response.status === 200) {
          const slicedArray = response.data.content.slice(0, 6); //select only top 5 categories from the list
          setData(slicedArray);
        } else {
          setAlertMsg(response.ExceptionMessage);
          setOpenAlert(true);
        }
      })
      .catch((error) => {
        setAlertMsg(error);
        setOpenAlert(true);
      });
  }
  const ref = React.useRef();
  const sticky = useStickyHeader(50);
  const headerClasses = `header ${
    sticky ? "sticky menu" : "menu container add"
  }`;
  function useStickyHeader(offset = 0) {
    const [stick, setStick] = useState(false);
    const handleScroll = () => {
      setStick(window.scrollY > offset);
    };
    React.useLayoutEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });
    setIsSticky(stick);
    return stick;
  }

  const getCartTotalValues = () => {
    let totalQuantity = 0;
    let totalAmount = 0;
    if (cartList && cartList.length) {
      totalQuantity =
        cartList.reduce((qty, item) => (qty = qty + item.quantity), 0) || 0;
      totalAmount =
        parseFloat(
          cartList.reduce(
            (qty, item) =>
              (qty = qty + parseFloat(item.price) * parseInt(item.quantity)),
            0
          )
        ).toFixed(2) || 0;
    }
    return {
      totalQuantity,
      totalAmount,
    };
  };
  const history = useHistory();

  return (
    <>
      <section ref={ref} className={headerClasses}>
        <nav
          className="navbar navbar-expand-lg navbar-light menuBar"
          style={{
            paddingLeft: isSticky ? "0rem" : "0rem",
            paddingRight:
              isSticky && totalQuantityLength >= 3 && totalQuantityLength <= 6
                ? "0rem"
                : isSticky &&
                  totalQuantityLength >= 6 &&
                  totalQuantityLength <= 10 &&
                  "0rem",
          }}
        >
          {/* Hint: for Temporary basis its commented navBar on the header */}
          {/* <MenuDrawer sectionArray={props.sectionArray} array={data} /> */}
          <div className="menuList">
            {/* <ul className="navbar-nav">
              {/* Hint: for Temporary basis its commented navBar on the header */}
            {/* {props.sectionArray.length != 0 ? ( */}
            {/* {data.length != 0 ? ( */}
            {/* <> */}
            {/* {data.map((i, index) => { */}
            {/* return ( */}
            {/* <li key={index} className="nav-item"> */}
            {/* <a className="nav-link"> */}
            {/* <Tooltip */}
            {/* onClick={() => { */}
            {/* setactive(i.id); */}
            {/* // navigate to listing screen */}

            {/* history.push(`/${i.name}-${i.productType}.html`); */}

            {/* console.log(i); */}
            {/* }} */}
            {/* name={i.name} */}
            {/* // array={i.id === 3 ? data : false} */}
            {/* array={false} */}
            {/* className={`_bottom${i.id}`} */}
            {/* onClose={() => setactive(!i.id)} */}
            {/* open={active === i.id ? true : false} */}
            {/* /> */}
            {/* </a> */}
            {/* /          </li> */}
            {/* ); */}
            {/* })} */}

            {/* {sticky ? ( */}
            {/* <div className="cart">
                      <Cart
                        openCartPanel={openCartPanel}
                        isLoggedIn={isLoggedIn}
                        price={getCartTotalValues().totalAmount}
                        quantity={getCartTotalValues().totalQuantity}
                        openLoginPopup={() => {
                          setIsOpenLoginModel(true);
                        }}
                      />
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  {[1, 2, 3, 4, 5, 6, 7].map((i, index) => {
                    return (
                      <li key={index} className="nav-item">
                        <a className="nav-link">
                          <Skeleton
                            animation="wave"
                            height={12}
                            width="4.8rem"
                            style={{ margin: ".6rem 0rem" }}
                          />
                        </a>
                      </li>
                    );
                  })}
                </>
              )}
                </ul> } */}
            <div className="navslider">
              <div className="main-bar">
                <div className="bar-items text-center">
                  <a
                    href="#"
                    onClick={() => {
                      history.push(path.stickyBar);
                    }}
                  >
                    <img src={enviogratis} alt="icon" />
                    <label>{Envio}</label>
                  </a>
                </div>
                <div className="bar-items text-center">
                  <a
                    href="#devoluciones-item"
                    onClick={() => {
                      history.push(path.stickyBar);
                    }}
                  >
                    <img src={devolucino} alt="icon" />
                    <label>{Problema}</label>
                  </a>
                </div>
                <div className="bar-items text-center">
                  <a
                    href="#garantizada-item"
                    onClick={() => {
                      history.push(path.stickyBar);
                    }}
                  >
                    <img src={gurrantee} alt="icon" />
                    <label>{Calidad}</label>
                  </a>
                </div>
              </div>
            </div>
            {sticky && !props.isStickyCheckoutIcon ? (
              <div className="cart">
                <Cart
                  openCartPanel={openCartPanel}
                  isLoggedIn={isLoggedIn}
                  price={getCartTotalValues().totalAmount}
                  quantity={getCartTotalValues().totalQuantity}
                  openLoginPopup={() => {
                    setIsOpenLoginModel(true);
                  }}
                />
              </div>
            ) : null}
          </div>
        </nav>
      </section>
    </>
  );
};
export default StickyHeader;
