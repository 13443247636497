import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import {
  allRights,
  appStore,
  downloadOn,
  doYouWantToSupplier,
  frequentQuestion,
  googlePlays,
  aboutUs,
  information,
  mobileApp,
  noticeOfPrivacy,
  subcribe,
  subcribeToOurNewsLetter,
  termsAndCondition,
  name,
  phoneNum,
  email,
  message,
  getInto,
  send,
  contactUs,
  error,
} from "../../common/constants";
import {
  appleIcon,
  arrowIcon,
  discover,
  emailIcon,
  fb,
  google,
  googlePlay,
  insta,
  linkedIn,
  maestro,
  masterCard,
  misPartesLogo,
  payPal,
  visaElectron,
  visaIcon,
} from "../../common/Images";
import { path } from "../../common/routes-names";
import CustomDialogs from "../customModal/modalAlert";
import "./style.scss";
import { closeIcon } from "./../../common/Images";
import { Formik, Form } from "formik";
import { contactUSValidations } from "../../common/formValidation";
import { GetPartnerLogos } from "../../utils/api";
import ApplicationContext from "../../context-api/context";
const Footer = () => {
  const history = useHistory();
  const [contactusPopUp, setcontactusPopUp] = useState(false);
  // const [nam, setNam] = useState("");
  // const [telephone, setTelephone] = useState("");

  const { partner, setPartner, setOpenAlert, setAlertMsg } =
    useContext(ApplicationContext);

  useEffect(() => {
    GetPartnerLogo();
  }, []);

  async function GetPartnerLogo() {
    await GetPartnerLogos()
      .then((response) => {
        if (response.status === 200) {
          const {
            data: { content },
          } = response;
          setPartner(content);
        } else {
          setOpenAlert(true);
          setAlertMsg(response.ExceptionMessage);
        }
      })
      .catch((error) => {
        setOpenAlert(true);
        setAlertMsg(error);
      });
  }
  const ContactUspopup = ({}) => {
    return (
      <div className="contactModal p4">
        <div
          onClick={() => {
            setcontactusPopUp(false);
          }}
          className="close-icon"
        >
          <div className="close-icon">
            <img src={closeIcon} />
          </div>
        </div>
        <Formik
          enableReinitialize
          validateOnChange={true}
          validateOnBlur={false}
          initialValues={{
            name: "",
            company: "",
            email: "",
            message: "",
          }}
          validationSchema={contactUSValidations}
          onSubmit={(values) => {
            console.log("error", values);
          }}
        >
          {({ handleSubmit, errors, handleChange }) => (
            <Form autoComplete="off">
              <div className="modalItem mt-3">
                <h3>{contactUs}</h3>
                <div className="input-field">
                  <label htmlFor="name">{name}</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      handleChange("name");
                    }}
                    className="d-block modal-input"
                    placeholder={getInto}
                  />
                  {errors.name ? <span>{errors.name}</span> : null}
                </div>
                <div className="input-field">
                  <label htmlFor="telephone">{phoneNum}</label>
                  <input
                    type="number"
                    onChange={(e) => {
                      handleChange("name");
                    }}
                    className="d-block"
                    placeholder={getInto}
                  />
                  {errors.company ? <span>{errors.company}</span> : null}
                </div>

                <div className="input-field">
                  <label htmlFor="email">{email}</label>
                  <input
                    type="email"
                    className="d-block"
                    autoComplete="true"
                    placeholder={getInto}
                    onChange={(e) => {
                      handleChange("email");
                    }}
                  />
                  {errors.email ? <span>{errors.email}</span> : null}
                </div>
                <div className="contactMessage">
                  <label htmlFor="message">{message}</label>
                  <textarea
                    name="message"
                    id=""
                    placeholder={getInto}
                    onChange={(e) => {
                      handleChange("message");
                    }}
                    className="d-block"
                  ></textarea>
                  {errors.message ? <span>{errors.message}</span> : null}
                </div>
                <button
                  type="submit"
                  onSubmit={handleSubmit}
                  className="contacbtn-enviar"
                >
                  {send}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <>
      <footer style={{ backgroundColor: "black" }}>
        <div className="container add footertop">
          <div className="contentArea">
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <div className="informationArea">
                  <h4 className="heading">{information}</h4>
                  <ul>
                    <a
                      onClick={() => {
                        history.push(path.termsAndCondition);
                      }}
                    >
                      <li>{termsAndCondition}</li>
                    </a>
                    <a
                      onClick={() => {
                        history.push(path.noticeOfPrivacy);
                      }}
                    >
                      <li>{noticeOfPrivacy}</li>
                    </a>
                    <a
                      onClick={() => {
                        history.push(path.faq);
                      }}
                    >
                      <li>{frequentQuestion}</li>
                    </a>
                    <a
                      onClick={() => {
                        history.push(path.aboutUs);
                      }}
                    >
                      <li>{aboutUs}</li>
                    </a>

                    <li
                      onClick={() => {
                        setcontactusPopUp(true);
                      }}
                    >
                      {doYouWantToSupplier}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <div className="appLinksArea">
                  <h4 className="heading">{mobileApp}</h4>
                  <ul>
                    <li>
                      <a href="https://apps.apple.com/pk/app/okautopartes/id6443723448">
                        <div className="LinkOuter">
                          <div className="icon">
                            <img src={appleIcon} className="img-fluid"></img>
                          </div>
                          <div className="description">
                            <span className="text">{downloadOn}</span>
                            <span className="textHighlight">{appStore}</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="https://play.google.com/store/apps/details?id=com.mispartesmobileapp">
                        <div className="LinkOuter">
                          <div className="icon">
                            <img src={googlePlay} className="img-fluid"></img>
                          </div>
                          <div className="description">
                            <span className="text">{downloadOn}</span>
                            <span className="textHighlight">{googlePlays}</span>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-12">
                <div className="subscribe">
                  <h4 className="heading">{subcribe}</h4>
                  <ul>
                    <li>
                      <div className="LinkOuter">
                        <div className="icon">
                          <img src={emailIcon} className="img-fluid"></img>
                        </div>
                        <input type="text" placeholder="Correo" />
                        <a href="#" className="arrowOuter">
                          <img src={arrowIcon} className="arrow"></img>
                        </a>
                      </div>
                    </li>
                    <li>
                      <span className="subText">
                        {" "}
                        {subcribeToOurNewsLetter}
                      </span>
                    </li>
                    <li>
                      <ul className="cardOption">
                        <li>
                          <img src={visaIcon} className="card1"></img>
                        </li>
                        <li>
                          <img src={masterCard} className="card1"></img>
                        </li>
                        <li>
                          <img src={maestro} className="card1"></img>
                        </li>
                        <li>
                          <img src={payPal} className="card1"></img>
                        </li>
                        <li>
                          <img src={visaElectron} className="card1"></img>
                        </li>
                        <li>
                          <img src={discover} className="card1"></img>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerBottom ">
          <div className="container add">
            <div className="left-section">
              <div className="rigth-content">
                <div className="companyLogo">
                  <img src={misPartesLogo}></img>
                </div>
                <div className="smLinksOuter">
                  <ul>
                    <li>
                      <a href="#">
                        <img src={insta} className="SMicon"></img>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={fb} className="SMicon"></img>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <img src={linkedIn} className="SMicon"></img>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={google} className="SMicon"></img>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right-section">
              <div className="partner-images text-right">
                {partner.map((i, id) => {
                  if ((id + 1) % 5 == 0) {
                    return (
                      <>
                        <img src={i} />
                        <br />
                      </>
                    );
                  }
                  return <img src={i} />;
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="copyRight">&copy; {allRights}</div>
        <CustomDialogs
          onClose={contactusPopUp}
          open={contactusPopUp}
          popheight="600px"
        >
          <ContactUspopup
            onPress={() => {
              setcontactusPopUp(false);
            }}
          />
        </CustomDialogs>
      </footer>
    </>
  );
};
export default Footer;
