import React from "react";
import ImageLoader from "react-imageloader";
import "./style.scss";
import { Skeleton } from "@material-ui/lab";
function preloader({ height, width, skeltonStyle }) {
  return (
    <>
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={height}
        width={width}
        className={skeltonStyle}
      />
    </>
  );
}
const Images = (props) => {
  if (props.src !== "") {
    return (
      <ImageLoader
        src={props.src}
        wrapper={React.createFactory("div")}
        preloader={() => preloader(props)}
      ></ImageLoader>
    );
  } else {
    return null;
  }
};

export default Images;
