import React from "react";
import { Redirect, Route } from "react-router";
import { path } from "../common/routes-names";
const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn ? <Component {...props} /> : <Redirect to={path.login} />
        }
      />
    </>
  );
};

export default PrivateRoute;
