import React, { useState, useContext, useEffect } from "react";
import "./style.scss";
import Popup from "../popup";
import AttributeTab from "../attributeTabs/index.js";
import { QuantityButton, CloseButton, CustomButton } from "..";
import { ReactComponent as DeliveryTime } from "../../images/deliveryTimeIcon.svg";
import { ReactComponent as FreeDelivery } from "../../images/freeDeliveryIcon.svg";
import {
  application,
  attributeBtn,
  features,
  vehicles,
  available,
  buyNow,
  coln,
  comment,
  compatibiltyBtn,
  mark,
  opinion,
  partNum,
  pcs,
  position,
  shippingCostLabel,
  warranty,
  requestProduct,
  companyNumber,
} from "../../common/constants";
import { useSelector, useDispatch } from "react-redux";
import {
  setShowLogInPopModel,
  setCheckOut,
} from "../../Redux/reducers/alertSlice";

import Rating_Star from "../ratingStar";
import Images from "../imgLoader";
import { useHistory } from "react-router";
import ApplicationContext from "../../context-api/context";
import { addItemToCart, sendProductRequest } from "./../../utils/api";

const ListGridViewProduct = ({
  imag,
  gridView,
  visibleTwoButtons,
  detailView,
  _warranty,
  _discountPrice,
  _orignalPrice,
  _partNo,
  _availableIn,
  _productId,
  _productName,
  _available,
  _markValue,
  _comment,
  shipping_Cost,
  onBuy,
  _position,
  _application,
  _quantity,
  _ratingValue,
  pathName,
  param,
  productId,
  productObject,
  listView,
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [tabIndex, settabIndex] = useState(1);
  const {
    cartList,
    isProductExistInCart,
    getTheUpdateProduct,
    addUpdateCartItem,
    isLoggedIn,
    setIsOpenLoginModel,
    favoriteData,
    setFavoriteData,
  } = useContext(ApplicationContext);
  const [quantity, setQuantity] = useState(cartList.length > 0 ? _quantity : 0);
  // const { isLoggedIn, setIsLoggedIn, addUpdateCartItem, getTheUpdateProduct, cartList, setIsOpenLoginModel } = useContext(ApplicationContext);
  const dispatch = useDispatch();
  //const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const openPopUpModal = (check, index) => {
    setOpenPopup(check);
    settabIndex(index);
  };
  useEffect(() => {
    if (cartList.length === 0) {
      setQuantity(0);
    } else {
      const productItem = cartList.find(
        (item) => item.productID === productObject.productID
      );
      if (productItem) {
        let favProducts = favoriteData;
        favProducts?.forEach((element) => {
          if (element.productID === productItem.productID) {
            element.quantity = productItem.quantity;
          }
        });
        setFavoriteData(favProducts);
        setQuantity(productItem.quantity);
      }
    }
  }, [cartList]);
  const history = useHistory();
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleWhatsAppShare = (phoneNumber, text) => {
    const urlEncodedText = encodeURIComponent(text);
    const url = `https://wa.me/${phoneNumber}/?text=${urlEncodedText}`;
    window.open(url, "_blank");
  };
  return (
    <>
      <div className={gridView}>
        <div className={detailView ? "proInner" : "proInner innerBorder"}>
          <div
            className={"ProImgWrapper"}
            style={{ backgroundImage: `url(${imag})` }}
            onClick={() =>
              history.push({
                pathname: pathName,
                search: "?id=" + param,
                state: {
                  data: "productListing",
                },
              })
            }
          ></div>
          <div
            className={
              detailView
                ? "description d-inline-block"
                : "description descriptionBorder d-inline-block"
            }
          >
            <div className="ratingArea">
              <div className="left">
                {detailView ? (
                  <div className="proName">
                    <h3>{_productName}</h3>
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      history.push({
                        pathname: pathName,
                        search: "?id=" + param,
                        state: {
                          data: "productListing",
                        },
                      })
                    }
                    className="proName"
                  >
                    <h3>{_productName}</h3>
                  </div>
                )}
                <div>
                  <Rating_Star _ratingValue={_ratingValue} />
                  {detailView ? (
                    <span className="opinion">{opinion}</span>
                  ) : null}
                </div>
              </div>
              <div className="right">
                {_available > 0 && (
                  <>
                    <span
                      className={detailView ? "discounted" : "discountedPrice"}
                    >
                      ${numberWithCommas(Number(_discountPrice).toFixed(2))}
                    </span>
                    {_orignalPrice > _discountPrice ? (
                      <span className={detailView ? "orignal" : "orignalPrice"}>
                        ${numberWithCommas(Number(_orignalPrice).toFixed(2))}
                      </span>
                    ) : null}
                  </>
                )}
                {listView && _available <= 0 && (
                  <button
                    onClick={() => {
                      handleWhatsAppShare(
                        companyNumber,
                        `Hola, estoy buscando ${_productName} - ${_partNo}.`
                      );
                    }}
                    className="request-button"
                  >
                    {requestProduct}
                  </button>
                )}
              </div>
            </div>
            <div
              className={detailView ? "detail mt-2" : "detail"}
              style={{
                // flexDirection: _application == null ? 'column' : 'row',
                flexDirection: "row",
              }}
            >
              {detailView ? (
                <>
                  <div>
                    <div className="info">
                      <span className="key">
                        {partNum}
                        {coln}
                      </span>
                      <span className="value">{_partNo}</span>
                    </div>
                    <div className="info">
                      <span className="key">{mark}</span>
                      <span className="value"> {_markValue}</span>
                    </div>
                    {/* <div className="info">
                      <span className="key">{position}</span>
                      <span className="value"> {_position}</span>
                    </div> */}
                    <div className="info">
                      <span className="key">{warranty}</span>
                      <span className="value">{_warranty}</span>
                    </div>
                    {Number(_orignalPrice) <= 300 &&
                    Number(shipping_Cost) >= 1 ? (
                      <>
                        <div className="info">
                          <span className="key">{shippingCostLabel}</span>
                          <span className="value proNumber">
                            ${shipping_Cost.toFixed(2)}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="info add">
                          <div>
                            <FreeDelivery />
                            <span
                              style={{ marginLeft: 5 }}
                              className="value proNumber"
                            >
                              {"envio gratis"}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="info">
                      {_available > 0 ? (
                        <div>
                          <DeliveryTime />
                          <span
                            style={{ marginLeft: 5 }}
                            className="value proNumber"
                          >
                            {Number(_availableIn) > 0
                              ? "Disponible en " + _availableIn + " días"
                              : "Disponible"}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <DeliveryTime />
                          <span
                            style={{ marginLeft: 5 }}
                            className="value proNumber"
                          >
                            {"No Disponible"}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="prod-list-left">
                    <div className="info">
                      <span className="key">
                        {partNum}
                        {coln}
                      </span>
                      <span className="value">{_partNo}</span>
                    </div>
                    <div className="info">
                      <span className="key">{mark}</span>
                      <span className="value"> {_markValue}</span>
                    </div>
                    {_application == null ? (
                      <div className="info add">
                        <span className="key">{warranty}</span>
                        <span className="value proNumber">
                          {_warranty || "6 Meses"}
                        </span>
                      </div>
                    ) : null}

                    {_position != null && (
                      <div className="info">
                        <span className="key">{position}</span>
                        <span className="value"> {_position}</span>
                      </div>
                    )}
                    {_comment != null && (
                      <div className="info">
                        <span className="key">{comment}</span>
                        <span className="value"> {_comment}</span>
                      </div>
                    )}

                    {_application != null && (
                      <div className="info">
                        <span className="key">{application}</span>
                        <span className="value">{_application}</span>
                      </div>
                    )}
                  </div>
                  <div className={_application == null ? "" : "extraInfo"}>
                    {_application != null ? (
                      <div className="info add">
                        <span className="key">{warranty}</span>
                        <span className="value proNumber">
                          {_warranty || "6 Meses"}
                        </span>
                      </div>
                    ) : null}
                    {Number(_orignalPrice) <= 300 ? (
                      <div className="info add">
                        <span className="key">{shippingCostLabel}</span>
                        <span className="value proNumber">
                          ${shipping_Cost.toFixed(2)}
                        </span>
                      </div>
                    ) : (
                      <div className="info add">
                        <div style={{ marginLeft: 2 }}>
                          <FreeDelivery />
                          <span
                            style={{ marginLeft: 5 }}
                            className="value proNumber"
                          >
                            {"envio gratis"}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="info add">
                      {_available > 0 ? (
                        <div>
                          <DeliveryTime />
                          <span className="value proNumber">
                            {Number(_availableIn) > 0
                              ? "Disponible en " + _availableIn + " días"
                              : "Disponible"}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <DeliveryTime />
                          <span className="value proNumber">
                            {"No Disponible"}
                          </span>
                        </div>
                      )}
                    </div>
                    {/* <div className="info add">
                      <span className="key">{available}:</span>
                      <span className="value proNumber">
                        {_available}
                        {pcs}
                      </span>
                    </div> */}
                  </div>
                </>
              )}
            </div>
            {_available > 0 ? (
              <div className="btnArea">
                {detailView ? (
                  <CustomButton
                    className="addToCartBtn"
                    title={buyNow}
                    onClick={() => {
                      const isExist = isProductExistInCart(_productId);
                      if (!isLoggedIn) {
                        setIsOpenLoginModel(true);
                        return false;
                      } else {
                        const requestObject = {
                          productID: _productId,
                          quantity: -1,
                          operationType: 1,
                          vehicleID: 0,
                        };
                        if (isExist) {
                          requestObject.quantity =
                            (getTheUpdateProduct(_productId) &&
                              getTheUpdateProduct(_productId).quantity) ||
                            1;
                        } else {
                          requestObject.quantity = 1;
                          productObject.quantity = 1;
                          addUpdateCartItem(productObject);
                        }
                        addItemToCart(requestObject).then((response) => {
                          const {
                            data: { success },
                          } = response;
                          if (success) {
                            onBuy();
                          }
                        });
                      }
                    }}
                  />
                ) : null}
                {visibleTwoButtons === false ? null : (
                  <div className="btnInner">
                    <CustomButton
                      title={features}
                      className="btn attributeBtn"
                      onClick={() => {
                        openPopUpModal(true, 1);
                      }}
                    />

                    <CustomButton
                      title={vehicles}
                      onClick={() => {
                        openPopUpModal(true, 2);
                      }}
                      className="btn compatibilityBtn"
                    />
                  </div>
                )}

                {detailView ? (
                  <QuantityButton
                    onQuantityDecreased={() => {
                      if (!isLoggedIn) {
                        dispatch(setShowLogInPopModel(true));
                        dispatch(setCheckOut("checkout"));
                      } else {
                        setQuantity(parseInt(quantity) - 1);
                      }
                    }}
                    detailView
                    onQuantityIncreased={() => {
                      if (!isLoggedIn) {
                        dispatch(setShowLogInPopModel(true));
                        dispatch(setCheckOut("checkout"));
                      } else {
                        setQuantity(parseInt(quantity) + 1);
                      }
                    }}
                    quantityChanged={setQuantity}
                    quantity={quantity}
                    productId={_productId}
                    productObject={productObject}
                  />
                ) : (
                  <QuantityButton
                    onQuantityDecreased={() => {
                      if (!isLoggedIn) {
                        dispatch(setShowLogInPopModel(true));
                        dispatch(setCheckOut("checkout"));
                      } else {
                        setQuantity(parseInt(quantity) - 1);
                      }
                    }}
                    fullWidth={
                      gridView === "proWrapper gridView" ? true : false
                    }
                    onQuantityIncreased={() => {
                      if (!isLoggedIn) {
                        dispatch(setShowLogInPopModel(true));
                        dispatch(setCheckOut("checkout"));
                      } else {
                        setQuantity(parseInt(quantity) + 1);
                      }
                      console.log(
                        "Increaseing quantity with given Product Id ",
                        _productId
                      );
                    }}
                    quantityChanged={setQuantity}
                    quantity={quantity}
                    productId={_productId}
                    productObject={productObject}
                  />
                )}
              </div>
            ) : !listView ? (
              <div className="request-btn-container">
                <CustomButton
                  className="addToCartBtn"
                  title={requestProduct}
                  onClick={() => {
                    handleWhatsAppShare(
                      companyNumber,
                      `Hola, estoy buscando ${_productName} - ${_partNo}.`
                    );
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Popup
        popupSize="md"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        headerVisible={false}
        overFlowClass="overflow-hidden"
      >
        <div className="closeIcon">
          <CloseButton
            onClick={() => {
              setOpenPopup(false);
            }}
            color="black"
          />
        </div>

        <AttributeTab tabIndex={tabIndex} id={_productId} />
      </Popup>
    </>
  );
};
export default ListGridViewProduct;
