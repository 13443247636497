import React from "react";
import "./style.scss";
export const RadioBox = ({ onClick, children, select, id }) => {
  return (
    <>
      <label className="upperContainer" onClick={onClick}>
        <input
          className="radioSelect"
          type="radio"
          name="s"
          checked={select}
          onClick={onClick}
        />
        {children}
      </label>
    </>
  );
};
