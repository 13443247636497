import React from "react";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
const CloseButton = ({ onClick, color, className, fontSize }) => {
  return (
    <IconButton
      aria-label="close"
      onClick={onClick}
      className={className}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon style={{ color: color, fontSize: fontSize }} />
    </IconButton>
  );
};

export default CloseButton;
