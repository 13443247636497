import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import "./style.scss";
import { TextInput, CustomButton, GenricDropDown } from "..";
import { formValidation } from "../../common/formValidation";
import { CircularProgress } from "@material-ui/core";
import {
  saveChnges,
  postalCode,
  addressNamePlaceholder,
  addressName,
  addressContact,
  addressContactPlaceholder,
  phoneNo,
  phoneNoPlaceholder,
  state,
  statePlaceholder,
  addressCity,
  addressCityPlaceholder,
  neighborhood,
  street,
  extno,
  extnoPlaceholder,
  intnoPlaceholder,
  intno,
  postalCodeLength,
} from "../../common/constant/formConstants";
import {
  getZipCodeDetail,
  addAddress,
  AddUpdateUserAddress,
} from "./../../utils/api";
import { useContext } from "react";
import ApplicationContext from "../../context-api/context";

const AddressForm = (props) => {
  const {
    recordForEdit,
    openPop,
    fromAccountSection,
    setRecordForEdit,
    setIsmodalOpens,
    onSuccessEdited,
  } = props;

  const { singleAddressData } = useContext(ApplicationContext);
  const [stateValue, setStateValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [neighborhoodValue, setNeighborhoodValue] = useState([]);
  const [zipErrorMessage, setZipErrorMessage] = useState("");
  const [postalLoader, setPostalLoader] = useState(false);
  let createAddressObj = {};
  const [loader, setLoader] = useState(false);

  const [input, setInput] = useState({
    id: "",
    addressName: "",
    addresscontact: "",
    phonenumber: "",
    postalCode: "",
    state: "",
    city: "",
    neighborhood: "",
    street: "",
    extno: "",
    intno: "",
  });
  let inputValueCoppy = {
    id: input.id,
    addressName: input.addressName,
    addresscontact: input.addresscontact,
    phonenumber: input.phonenumber,
    postalCode: input.postalCode,
    state: input.state,
    city: input.city,
    neighborhood: input.neighborhood,
    street: input.street,
    extno: input.extno,
    intno: input.intno,
  };
  useEffect(() => {
    if (!openPop) {
      clearForm();
    }
  }, [openPop]);
  // in case of edit this useform triggers to set the values
  useEffect(() => {
    if (singleAddressData.length !== 0) {
      const {
        addressID,
        city,
        colony,
        colonyID,
        contact,
        extNo,
        intNo,
        name,
        phone,
        state,
        street,
        zipCode,
        coloniesList,
      } = singleAddressData || {};
      if (coloniesList?.length > 0) {
        setNeighborhoodValue(coloniesList);
      }
      // setzipErrorMessage("");
      setInput({
        ...input,
        id: addressID,
        addressName: name,
        addresscontact: contact,
        phonenumber: phone,
        postalCode: zipCode,
        state: state,
        city: city,
        neighborhood: colonyID,
        street: street,
        extno: extNo,
        intno: intNo,
      });
      setStateValue(state);
      setCityValue(city);
    } else {
      clearForm();
    }
  }, [singleAddressData]);

  const clearForm = () => {
    setInput({
      id: "",
      addressName: "",
      addresscontact: "",
      phonenumber: "",
      postalCode: "",
      state: "",
      city: "",
      neighborhood: "",
      street: "",
      extno: "",
      intno: "",
    });
    setStateValue("");
    setCityValue("");
  };

  const addUpdateAddress = async (data) => {
    setLoader(true);
    const result = await AddUpdateUserAddress(data);
    const {
      data: { content, success },
    } = result || {};
    if (success) {
      // const inv = response.data.content;
      let arr = recordForEdit.filter((i) => {
        return i.id !== data.addressID;
      });
      arr.push({ ...content, isOpened: false });
      setRecordForEdit(arr);
      setIsmodalOpens(false);
      setLoader(false);
      return result;
    }
  };

  const createAddress = async (obj) => {
    setLoader(true);
    const response = await addAddress(obj);
    if (response) {
      const {
        data: { content, success, description },
      } = response;
      return await content;
    }
  };

  const getColonies = async (zip) => {
    if (zip !== "" && zip.length === postalCodeLength) {
      setPostalLoader(true);
      const response = await getZipCodeDetail(zip);
      setPostalLoader(false);
      if (response) {
        const {
          data: { content, success, description },
        } = response;
        if (success) {
          if (content) {
            setStateValue(content.state);
            setCityValue(content.city);
            setNeighborhoodValue(content.colonies);
            // DUE TO EMPTY THE NEIGHBORHOODID WE USE inputValueCoppy WHICH ENFORCE USER TO SELECT NEIGHBOR AFTER ZIPCODE CHANGE
            inputValueCoppy.neighborhood = "";
            setInput(inputValueCoppy);
            setZipErrorMessage("");
          }
        } else {
          setStateValue("");
          setCityValue("");
          setNeighborhoodValue("");
          setZipErrorMessage(description);
          inputValueCoppy.neighborhood = "";
          setInput(inputValueCoppy);
        }
      }
    }
  };

  const handleForm = async (values, { resetForm }) => {
    if (fromAccountSection) {
      if (!postalLoader) {
        setLoader(true);
        createAddressObj = {
          name: values.addressName,
          contact: values.addresscontact,
          phone: values.phonenumber,
          zipCode: values.postalCode.toString(),
          colony: values.neighborhood,
          street: values.street,
          extNo: values.extno,
          intNo: values.intno,
          addressID: values.id === "" ? 0 : parseInt(values.id),
        };
        let result = await addUpdateAddress(createAddressObj);
        if (values.id !== "") {
          onSuccessEdited(result.data.description);
        }
        if (result && result.data.success) {
          resetForm();
        }
      }
    } else {
      if (!postalLoader) {
        props.addOrEdit(values);
        resetForm();
        createAddressObj = {
          name: values.addressName,
          contact: values.addresscontact,
          phone: values.phonenumber,
          zipCode: values.postalCode.toString(),
          colony: values.neighborhood,
          street: values.street,
          extNo: values.extno,
          intNo: values.intno,
        };
        const obj = await createAddress(createAddressObj);
        if (obj) {
          props.newAddress(obj);
          setLoader(false);
        }
      }
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={{
          id: input.id,
          addressName: input.addressName,
          addresscontact: input.addresscontact,
          phonenumber: input.phonenumber,
          postalCode: input.postalCode,
          state: input.state,
          city: input.city,
          neighborhood: input.neighborhood,
          street: input.street,
          extno: input.extno,
          intno: input.intno,
        }}
        validationSchema={formValidation}
        onSubmit={handleForm}
      >
        {({ handleSubmit, handleChange }) => (
          <Form>
            <TextInput
              className={props.checkout ? "inputCheckout" : "textInput"}
              name="addressName"
              type="text"
              labelName={addressName}
              placeholder={addressNamePlaceholder}
              labelClass="lbl-bld"
              onChange={(e) => {
                handleChange(e);
                let {
                  target: { value },
                } = e;
                inputValueCoppy = { ...inputValueCoppy, addressName: value };
              }}
            />
            <div className={props.checkout ? "checkoutRow" : "inputRow"}>
              <TextInput
                className={
                  props.checkout ? "inputCheckout marginBtm" : "textInput"
                }
                name="addresscontact"
                type="text"
                placeholder={addressContactPlaceholder}
                labelName={addressContact}
                labelClass="lbl-bld"
                onChange={(e) => {
                  handleChange(e);
                  let {
                    target: { value },
                  } = e;
                  inputValueCoppy = {
                    ...inputValueCoppy,
                    addresscontact: value,
                  };
                }}
              />
              <TextInput
                className={props.checkout ? "inputCheckout" : "textInput"}
                name="phonenumber"
                type="phone"
                placeholder={phoneNoPlaceholder}
                labelName={phoneNo}
                labelClass="lbl-bld"
                onChange={(e) => {
                  handleChange(e);
                  let {
                    target: { value },
                  } = e;
                  inputValueCoppy = { ...inputValueCoppy, phonenumber: value };
                }}
                onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
              />
            </div>
            <div className={props.checkout ? "checkoutRow" : "inputRow"}>
              <TextInput
                className={props.checkout ? "inputCheckout" : "textInput"}
                name="postalCode"
                type="number"
                loading={postalLoader}
                placeholder={postalCode}
                labelName={postalCode}
                error={zipErrorMessage}
                labelClass="lbl-bld"
                onChange={(e) => {
                  handleChange(e);
                  let {
                    target: { value },
                  } = e;
                  inputValueCoppy = { ...inputValueCoppy, postalCode: value };
                }}
                onInput={(e) => {
                  if (e.target.value.length == postalCodeLength)
                    getColonies(e.target.value);
                  return (e.target.value = e.target.value.slice(
                    0,
                    postalCodeLength
                  )); // vaidation to get only numbers from the user
                }}
              />
            </div>
            <div className={props.checkout ? "checkoutRow" : "inputRow"}>
              <TextInput
                className={
                  props.checkout ? "inputCheckout marginBtm " : "textInput"
                }
                name="state"
                type="text"
                placeholder={statePlaceholder}
                labelName={state}
                readonly="true"
                value={stateValue}
                labelClass="lbl-bld"
              />
              <TextInput
                className={props.checkout ? "inputCheckout " : "textInput"}
                name="city"
                type="text"
                placeholder={addressCityPlaceholder}
                labelName={addressCity}
                readonly="true"
                value={cityValue}
                labelClass="lbl-bld"
              />
            </div>
            <div className={props.checkout ? "checkoutRow" : "inputRow"}>
              <GenricDropDown
                name="neighborhood"
                dataSet={neighborhoodValue}
                labelName={neighborhood}
              />
            </div>
            <div className={props.checkout ? "checkoutRow" : "inputRow"}>
              <TextInput
                className={
                  props.checkout ? "inputCheckout marginBtm" : "textInput"
                }
                name="street"
                type="text"
                placeholder={street}
                labelName={street}
                labelClass="lbl-bld"
                onChange={(e) => {
                  handleChange(e);
                  let {
                    target: { value },
                  } = e;
                  inputValueCoppy = { ...inputValueCoppy, street: value };
                }}
              />
            </div>
            <div className={props.checkout ? "checkoutRow" : "inputRow"}>
              <TextInput
                className={
                  props.checkout ? "inputCheckout marginBtm" : "textInput"
                }
                name="extno"
                type="text"
                placeholder={extnoPlaceholder}
                labelName={extno}
                labelClass="lbl-bld"
                onChange={(e) => {
                  handleChange(e);
                  let {
                    target: { value },
                  } = e;
                  inputValueCoppy = { ...inputValueCoppy, extno: value };
                }}
              />
              <TextInput
                className={props.checkout ? "inputCheckout " : "textInput"}
                name="intno"
                type="text"
                placeholder={intnoPlaceholder}
                labelName={intno + " (Opcional)"}
                labelClass="lbl-bld"
                onChange={(e) => {
                  handleChange(e);
                  let {
                    target: { value },
                  } = e;
                  inputValueCoppy = { ...inputValueCoppy, intno: value };
                }}
              />
            </div>
            <CustomButton
              title={!loader ? saveChnges : ""}
              className="submitBtn"
              onClick={handleSubmit}
            >
              {loader ? <CircularProgress size={20} color="white" /> : ""}
            </CustomButton>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default AddressForm;
