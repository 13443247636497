import React from "react";
import { max, min } from "../../common/constants";
import "./style.scss";
import { IconButton } from "@material-ui/core";
import { whiteSearchBarIcon } from "../../common/Images";
const PriceInput = ({
  minValue,
  maxValue,
  maxOnChange,
  minOnChange,
  onClick,
}) => {
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="priceShow">
      <div className="min">
        <span className="smallLabel">{min}</span>
        <input
          value={numberWithCommas(minValue)}
          onChange={minOnChange}
          name="min"
          autoComplete="off"
        ></input>
      </div>
      <div className="max">
        <span className="smallLabel">{max}</span>
        <input
          value={numberWithCommas(maxValue)}
          onChange={maxOnChange}
          name="max"
          autoComplete="off"
        ></input>
      </div>
      <IconButton onClick={onClick} className="btn">
        <img src={whiteSearchBarIcon} alt="checkIcon" />
      </IconButton>
    </div>
  );
};

export default PriceInput;
