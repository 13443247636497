export const searchIcon = "/images/searchIcon.png";
export const userIcon = "/images/userIcon.png";
export const arrowRight = "/images/arrow-right.png";
export const arrowDown = "/images/arrow.png";
export const arrowIcon = "/images/arrow icon.png";
export const favIcon = "/images/favoritIcon.png";
export const cartIcon = "/images/cartIcon.png";
export const userProfile = "/images/userProfile.png";
export const securityIcon = "/images/security icon.png";
export const paymentIcon = "/images/payment-icon.png";
export const billIcon = "/images/bill-icon.png";
export const trollyIcon = "/images/trolly icon.png";
export const shipIcon = "/images/shipp-icon.png";
export const visaIcon = "/images/visa.png";
export const bankIcon = "/images/Group1768.png";
export const moneyIcon = "/images/Group1780.png";
export const walletIcon = "/images/Group1773.png";
export const deleteIcon = "/images/icons/delete.png";
export const editIcon = "/images/icons/edit.png";
export const closeIcon = "/images/icons/close.png";
export const emailIcon = "/images/email icon.png";
export const visaElectron = "/images/visa electron image.png";
export const masterCard = "/images/mastercard image.png";
export const maestro = "/images/maestro image.png";
export const payPal = "/images/paypal image.png";
export const discover = "/images/discover image.png";
export const misPartesLogo = "/images/CompanyLogo.png";
export const addIcon = "/images/adIcon.png";
export const alertIcon = "/images/circle.svg";
export const vector = "/images/Vector.png";
export const offersArrow = "/images/offersArrow.png";
export const searchBarIcon = "/images/icons/search.svg";
export const whiteSearchBarIcon = "/images/icons/search-white.svg";
export const wheels = "/images/wheels.png";
export const engine = "/images/engine.png";
export const kN = "/images/kn.png";
export const backError = "/images/404back.png";
export const error404 = "/images/404.png";
export const appleIcon = "/images/apple icon.png";
export const googlePlay = "/images/google play icon.png";
export const marcadoLogoImage = "/images/marcadoLogoImg";
// export const fb = "/images/facebook icon.png";
// export const linkedIn = "/images/linkedin icon.png";
// export const google = "/images/googleIcon.png";
// export const insta = "/images/instagram icon.png";
export const fb = "/images/Facebook.svg";
export const insta = "/images/Instagram.svg";
export const linkedIn = "/images/linkedin.svg";
export const google = "/images/googleplus.svg";
export const StickyBarImage = "/images/stickyBarimage.svg";
export const devoluciones = "/images/devoluciones.svg";
export const gratis = "/images/gratisv2.svg";
export const calidad = "/images/calidad.svg";

export const mercadopagoLogo = "/images/mercadopagoLogo.png";

export const feature1 = "/images/featureImg.png";
export const feature2 = "/images/featureImg2.png";
export const feature3 = "/images/featureImg3.png";
export const feature4 = "/images/featureImg4.png";

export const yakima = "/images/brand1.png";
export const carBrand = "/images/brandLogo.png";
export const aboutus_bg = "/images/bg-aboutus.png";
export const aboutus_sec1 = "/images/aboutus-logo1.png";
export const aboutus_sec2 = "/images/aboutus-logo2.png";
export const aboutus_sec3 = "/images/aboutus-logo3.png";
export const aboutus_sec4 = "/images/aboutus-logo4.png";
export const previousIcon = "images/pre.png";
export const nextIcon = "images/next.png";
export const sliderImage = "/images/sliderImg1.jpg";
export const bannerImage = "/images/ProBannerImg.jpg";
export const mainLogo = "/images/CompanyLogo.png";
export const product1 = "/images/image77.jpg";
export const tyreImage = "/images/image42.png";
export const product2 = "/images/image 44.png";
export const image1 = "/images/image 1.png";
export const checkIcon = "/images/icons/check.png";
export const companyLogo = "/images/CompanyLogo.png";
export const done = "/images/complete.png";
export const verified = "/images/verified.jpg";
export const unverified = "/images/unverified.jpg";
export const correoVerificado = "/images/correoVerificado.svg";
export const enviogratis = "/images/enviogratis.png";
export const devolucino = "/images/devolucino.png";
export const gurrantee = "/images/gurrantee.png";
export const paymentIcons = [
  "/images/Group1768.png",
  "/images/Group1780.png",
  "/images/wallet.png",
];
