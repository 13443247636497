import React, { useContext } from "react";
import {
  hola,
  security,
  billing,
  addresses,
  orders,
  login,
  signout,
  signUp,
  exsistAccount,
  hello,
  privateRouteArray,
  favourites,
} from "../../../common/constants";
import { Link } from "react-router-dom";
import { path } from "../../../common/routes-names";
import { userIcon, arrowRight } from "../../../common/Images";
import { useHistory } from "react-router";
import { ReactComponent as SecurityIcon } from "../../../images/security.svg";
import { ReactComponent as AddressIcon } from "../../../images/address.svg";
import { ReactComponent as CerrarSession } from "../../../images/cerrarsesion.svg";
import { ReactComponent as OrderIcon } from "../../../images/orderIcon.svg";
import { ReactComponent as BillingIcon } from "../../../images/billingIcon.svg";
import { ReactComponent as FavouriteIcon } from "../../../images/star.svg";
import { CustomButton } from "../..";
import "../Header.scss";
import ApplicationContext from "./../../../context-api/context";

const MyAccountMenu = ({ level, isLogin, onCheckout }) => {
  const {
    isLoggedIn,
    setIsLoggedIn,
    setCartList,
    getUserDataFromLocalStorage,
    setIsOpenLoginModel,
  } = useContext(ApplicationContext);

  let history = useHistory();
  const currentUrl = window.location.pathname;
  return (
    <>
      {isLogin ? (
        <div style={{ marginRight: 200 }} className="userPopUp wLogin">
          <div className={onCheckout ? "checkoutInner" : "inner"}>
            <div className="userOuter">
              <div className="usrImg">
                <img src={userIcon} alt="" className="img-fluid" />
              </div>
              <div
                className="discription"
                onClick={() => history.push(path.security)}
              >
                <p className="usrName">
                  {hello}
                  {isLoggedIn ? `${getUserDataFromLocalStorage().name}` : ""}
                </p>
                <span className="des">
                  {level} <img src={arrowRight} className="rightArrow"></img>{" "}
                </span>
              </div>
            </div>
            <div className="profileLinksOuter">
              <div className="profileLink">
                {" "}
                <Link to={path.security}>
                  <a className="">
                    <SecurityIcon className="icons" />
                    {security}
                  </a>
                </Link>
              </div>
              <div className="profileLink">
                <Link to={path.orders}>
                  <a className="">
                    <OrderIcon className="icons" />
                    {orders}
                  </a>
                </Link>
              </div>
              <div className="profileLink">
                <Link to={path.billing}>
                  <a className="">
                    <BillingIcon className="icons" />
                    {billing}
                  </a>
                </Link>
              </div>
              <div className="profileLink">
                <Link to={path.address}>
                  <a>
                    <AddressIcon className="icons" />
                    {addresses}
                  </a>
                </Link>
              </div>
              <div className="profileLink">
                <Link to={path.favourite}>
                  <a>
                    <FavouriteIcon className="icons" />
                    {favourites}
                  </a>
                </Link>
              </div>
              <div className="profileLink">
                <a
                  onClick={() => {
                    let isIncluded = privateRouteArray.includes(currentUrl);
                    setIsLoggedIn(false);
                    localStorage.setItem("sessionToken", ``);
                    setCartList([]);
                    if (isIncluded) {
                      history.push("/");
                    } else {
                      window.location.reload();
                    }
                  }}
                >
                  <CerrarSession className="icons" />
                  {signout}
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="userPopUp">
          <div className={onCheckout ? "checkoutInner" : "inner"}>
            <CustomButton
              title={signUp}
              className="registrate"
              onClick={() =>
                history.push({
                  pathname: path.signUp,
                  state: {
                    data: "home",
                  },
                })
              }
            />
            <span className="btn-signin">
              {exsistAccount}{" "}
              <a onClick={() => history.push(path.login)}>{login}</a>{" "}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default MyAccountMenu;
