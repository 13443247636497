import React from "react";
import {
  amount,
  cancel,
  coln,
  guideTracking,
  unitPrice,
  totalUnitPrice,
  deliveredProductButtonText,
  partNum,
  viewItem,
  statusEnums,
} from "../../common/constants";
import { CustomButton } from "..";
import "./style.scss";
import { useHistory } from "react-router";
import Images from "../imgLoader";
const ProductBox = (props) => {
  const { pathName, param, encryptedId, url, onViewItem } = props;
  let history = useHistory();

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div
      className={
        props.status === "checkout"
          ? "checkoutDiv"
          : props.status === "shift"
          ? "shiftBox"
          : "productBox"
      }
    >
      <div className="contentDiv">
        {props.status === "checkout" ? (
          <div
            className="imgArea"
            onClick={() => {
              history.push(`/${pathName}?id=${param}`);
            }}
          >
            <Images src={props.Img} skeltonStyle="imgProducts" />
          </div>
        ) : (
          <div
            className="imgArea"
            onClick={() => {
              history.push(`/${pathName}?id=${param}`);
            }}
          >
            <Images src={props.Img} skeltonStyle="imgProducts" />
          </div>
        )}
        {props.status === "shift" ? (
          <div className="item-detail">
            <p
              onClick={() => {
                history.push(`/${pathName}?id=${param}`);
              }}
              className="itemName"
            >
              {props.Name}
            </p>
            <p className="mt-4">
              <CustomButton
                title={viewItem}
                className="view_Item"
                onClick={props.onViewItem}
              />
            </p>
          </div>
        ) : (
          <>
            <div className="item-detail">
              <p
                onClick={() => {
                  history.push(`/${pathName}?id=${param}`);
                }}
                className="itemName"
              >
                {props.Name}
              </p>
              <p className="categoryName">
                <span>
                  {partNum}
                  {coln}
                </span>{" "}
                {props.itemId}
              </p>

              <p className="categoryName">
                <span>{props.brandName}</span>{" "}
              </p>

              {/** <img src={props.brandImg} className="brandLogo" /> */}
              <p className="categoryName">
                <span>
                  {amount}
                  {coln}
                </span>
                {props.quantity}
              </p>
            </div>
          </>
        )}
      </div>
      <div className="priceBox">
        {props.status === "checkout" && (
          <>
            <div>
              <p>
                <span>{unitPrice}</span>$
                {numberWithCommas(parseFloat(props.itemPrice).toFixed(2))}
              </p>
            </div>
            <div>
              <p>
                <span>{totalUnitPrice}</span> $
                {numberWithCommas(parseFloat(props.TotalPrice).toFixed(2))}
              </p>
            </div>
          </>
        )}
        {(props.statusId === statusEnums.pending ||
          props.statusId === statusEnums.confirmed) && (
          <>
            <div>
              <p>
                <span>{unitPrice}</span> $
                {numberWithCommas(parseFloat(props.itemPrice).toFixed(2))}
              </p>
            </div>
            <div>
              <p>
                <span>{totalUnitPrice}</span> $
                {numberWithCommas(parseFloat(props.totalItemPrice).toFixed(2))}
              </p>
            </div>
            {props.statusId === statusEnums.pending && (
              <CustomButton
                title={cancel}
                className="GuidedbuttonDanger"
                onClick={() => {}}
              />
            )}
          </>
        )}
        {props.statusId === statusEnums.shipping && (
          <>
            <div>
              <p>
                <span>{unitPrice}</span> $
                {numberWithCommas(parseFloat(props.itemPrice).toFixed(2))}
              </p>
            </div>
            <div>
              <p>
                <span>{totalUnitPrice}</span> $
                {numberWithCommas(parseFloat(props.totalItemPrice).toFixed(2))}
              </p>
            </div>
            <CustomButton
              title={guideTracking}
              className="Guidedbutton"
              onClick={() => {}}
            />
          </>
        )}
        {props.statusId === statusEnums.delivered && (
          <>
            <div>
              <p>
                <span>{unitPrice}</span> $
                {numberWithCommas(parseFloat(props.itemPrice).toFixed(2))}
              </p>
            </div>
            <div>
              <p>
                <span>{totalUnitPrice}</span> $
                {numberWithCommas(parseFloat(props.totalItemPrice).toFixed(2))}
              </p>
            </div>
            <CustomButton
              title={deliveredProductButtonText}
              className="GuidedbuttonDanger"
              onClick={onViewItem}
              // onClick={postOrderProblem}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProductBox;
