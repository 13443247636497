import React, { useState, useContext } from "react";
import {
  deleteIcon,
  editIcon,
  paymentIcons,
  closeIcon,
} from "../../common/Images";
import { CustomButton } from "../index";
import "./style.scss";
import { BillForm } from "../../Component";
import { Collapse } from "@material-ui/core";
import CloseButton from "../controls/closeButton";
import { contactDetail, postalAddress } from "../../common/constants";
import ApplicationContext from "../../context-api/context";
import { CircularProgress } from "@material-ui/core";
import { GetInvoice, GetUserAddress, getZipCodeDetail } from "../../utils/api";
import AddressForm from "../addressForm";

const BillBox = ({
  isOpened,
  children,
  index,
  onDelete,
  companyName,
  address,
  shippingId,
  addNew,
  zip,
  onPress,
  email,
  phone,
  postalCode,
  express,
  city,
  name,
  address2,
  businessName,
  Rfc,
  shippingAddress,
  billAddress,
  shipAddress,
  payment,
  icon,
  paymentType,
  htmlFor,
  fullName,
  fullAddress,
  contactAddress,
  onClickCard,
  onClick,
  bank,
  noInvoiceWant,
  addOrEdit,
  recordForEdit,
  setRecordForEdit,
  addressData,
  setAddressData,
  addNewInvoices,
  isCheckout,
  key,
  invoicingID,
  ismodalOpens,
  setIsmodalOpens,
  fromAccountSection,
  addressId,
  shipAddressForm,
  onSuccessEdited,
  isOpen,
  marcadoCardPayment,
  marcadoImage,
}) => {
  const { setInvoiceData, setSingleAddressData } =
    useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const update = () => {
    const arr = recordForEdit.invoices;
    let newArr = arr.map((obj, ind) => ({
      ...obj,
      isOpened: obj.invoicingID === invoicingID ? !obj.isOpened : false,
    }));
    setRecordForEdit({ invoices: newArr });
  };
  //used to open form in case of edit in account section Direction Screen
  const updateAddress = () => {
    const arr = addressData;
    let newArr = arr.map((obj, ind) => ({
      ...obj,
      isOpened: obj.id === addressId ? !obj.isOpened : false,
    }));
    setAddressData(newArr);
  };

  const getInvoice = async (id) => {
    setIsmodalOpens(false);
    if (isOpened) {
      setInvoiceData();
      update();
      return;
    }
    setLoading(true);
    const res = await GetInvoice(id);
    const {
      data: {
        success,
        content,
        content: { zipCode },
      },
    } = res || {};
    const zipDetails = await getZipCodeDetail(zipCode);
    const {
      data: { success: successZip, content: contentZip },
    } = zipDetails || {};
    content.coloniesList = contentZip.colonies;
    setLoading(false);
    update();
    if (res && success && successZip) {
      setInvoiceData(content);
    }
  };
  const getAddressInfo = async (id) => {
    setIsmodalOpens(false);
    if (isOpened) {
      setSingleAddressData([]);
      updateAddress();
      return;
    }
    setLoading(true);
    const res = await GetUserAddress(id);
    const {
      data: {
        success,
        content,
        content: { zipCode },
      },
    } = res || {};
    const zipDetails = await getZipCodeDetail(zipCode);
    const {
      data: { success: successZip, content: contentZip },
    } = zipDetails || {};
    content.coloniesList = contentZip.colonies;
    updateAddress();
    setLoading(false);
    if (res && success && successZip) {
      setSingleAddressData(content);
    }
  };

  const editAndDeleteButtons = () => (
    <div className="buttonDiv">
      {/* Edit button */}
      <CustomButton
        className="iconButton"
        onClick={() => {
          if (shipAddress) getAddressInfo(addressId);
          else getInvoice(invoicingID);
        }}
        startIcon={
          loading ? (
            <div className="icon-loader">
              <CircularProgress size={20} color="inherit" />
            </div>
          ) : !isOpened ? (
            <img src={editIcon} className="imgIcon" />
          ) : (
            <img src={closeIcon} className="imgIcon" />
          )
        }
      />
      {/* Delete button */}
      <CustomButton
        className="iconButton"
        onClick={onDelete}
        startIcon={<img src={deleteIcon} className="imgIcon" />}
      />
    </div>
  );
  const showModal = () => {
    if (fromAccountSection) {
      setIsmodalOpens(!ismodalOpens);
      setInvoiceData();
    }
  };
  return (
    <div className="mainCollapse" htmlFor={htmlFor}>
      <div
        className="detailDiv"
        onClick={isOpen === true ? showModal : onClick ? onClick : ""}
      >
        {shippingAddress && (
          <div className="detailText">
            <p className="mb-0">{shippingAddress.zipCode} </p>
            <p className="mb-0">
              <span> {shippingAddress.name} </span>
            </p>
            <p className="mb-0"> {shippingAddress.fullAdress}</p>
            <p className="mb-0">{shippingAddress.addressContact}</p>
          </div>
        )}
        {shipAddress && (
          <>
            <div className="detailText">
              <p className="mb-0">
                {" "}
                <span className="title">{"Nombre: "}</span>
                {fullName}
              </p>
              <p className="mb-0">
                <span className="title">{postalAddress}</span>
                {zip}
              </p>
              <p className="mb-0">
                {" "}
                <span className="title">{contactDetail}</span>
                {contactAddress}
              </p>
            </div>
            {!isCheckout && editAndDeleteButtons()}
          </>
        )}
        {billAddress && (
          <>
            <div className="detailText">
              <p className="mb-0">
                <span className="title">{"RFC: "}</span> {billAddress.taxID}
              </p>
              <p className="mb-0">
                <span className="title">{"Razon Social: "}</span>{" "}
                {billAddress.taxName || billAddress.name}
              </p>
              <p className="mb-0">
                <span className="title">{"Direccion: "}</span>{" "}
                {billAddress.taxAddress}
              </p>
            </div>
            {!isCheckout && editAndDeleteButtons()}
          </>
        )}
        {noInvoiceWant && (
          <div className="addNewText">
            <div>
              <span> {noInvoiceWant}</span>
            </div>
          </div>
        )}
        {addNew && (
          <div className="addNewText">
            <div>
              <span> {addNew}</span>
            </div>
          </div>
        )}
        {payment && (
          <div className="addNewText">
            <div className="icon">
              {paymentType === 1 ? (
                <img
                  src={"/images/" + icon + ".png"}
                  className="iconStyle"
                  alt={"card"}
                />
              ) : (
                <img
                  src={paymentIcons[icon ? icon - 1 : 0]}
                  className="iconStyle"
                  alt={"card"}
                />
              )}
            </div>
            <div>
              <span> {payment}</span>
            </div>
          </div>
        )}
        {bank && (
          <div className="addNewText">
            <div className="icon">
              <img src={icon} className="iconStyle" alt={"card"} />
            </div>
            <div>
              <span> {bank}</span>
              {marcadoCardPayment && (
                <span className="marcadoCardPaymentImage">
                  <img src={marcadoImage} className="iconStyle" alt={"card"} />
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      {children || (
        <Collapse
          in={addNew ? ismodalOpens : isOpened}
          className="openCollapse"
          classes={{
            wrapper: "wrapperCollapse",
          }}
        >
          <div className="formStyle">
            {shipAddressForm === true ? (
              <AddressForm
                recordForEdit={addressData}
                setRecordForEdit={setAddressData}
                ismodalOpens={ismodalOpens}
                setIsmodalOpens={setIsmodalOpens}
                fromAccountSection
                onSuccessEdited={onSuccessEdited}
              />
            ) : (
              <BillForm
                addOrEdit={addOrEdit}
                recordForEdit={recordForEdit}
                setRecordForEdit={setRecordForEdit}
                ismodalOpens={ismodalOpens}
                setIsmodalOpens={setIsmodalOpens}
                fromAccountSection
                onSuccessEdited={onSuccessEdited}
              />
            )}
          </div>
        </Collapse>
      )}
    </div>
  );
};

export default BillBox;
