import React, { useState, useEffect } from "react";
import "./style.scss";
import { CustomButton } from "../index";
import { addToCart, buyTo } from "../../common/constants";
import { deleteIcon } from "../../common/Images";
import Images from "../imgLoader";
import { useHistory } from "react-router";
import { Skeleton } from "@material-ui/lab";
import { QuantityButton } from "../index";
import ApplicationContext from "../../context-api/context";
import { useSelector, useDispatch } from "react-redux";
const FavProduct = (props) => {
  const { productID, cartQuantity, productObj, updateQty, param, pathName } =
    props;
  const [quantity, setQuantity] = useState(cartQuantity);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    setQuantity(cartQuantity);
  }, [cartQuantity]);

  useEffect(() => {
    updateQty(quantity);
  }, [quantity]);
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="favrtDiv">
      {props.loader ? (
        <div className="contentDiv">
          <Skeleton className="imgProducts" height="8.2em" />
          <div className="product-detail">
            <p className="productName w-100">
              <Skeleton
                animation="wave"
                height={12}
                width={"100%"}
                className="mt-3"
              />
              <Skeleton
                animation="wave"
                height={12}
                width={"9rem"}
                className="mt-1"
              />
            </p>

            <p className="productPrice">
              <Skeleton animation="wave" height={12} width={"40px"} />
            </p>
          </div>
        </div>
      ) : (
        <div className="contentDiv">
          <div
            className="imageloader"
            style={{ backgroundImage: `url(${props.Img})` }}
            onClick={() => {
              history.push(`/${pathName}?id=${param}`);
            }}
          ></div>
          {/* <Images src={props.Img} skeltonStyle="imgProduct" /> */}
          <div
            onClick={() => {
              history.push(`/${pathName}?id=${param}`);
            }}
            className="product-detail"
          >
            <p className="productName">{props.name}</p>
            <p className="productPrice">
              <span>
                ${numberWithCommas(parseFloat(props.itemPrice).toFixed(2))}
              </span>
            </p>
          </div>
        </div>
      )}
      <div className="buttonBox">
        <div className="d-inline-block trash">
          <CustomButton
            className="iconButton"
            onClick={props.onDelete}
            startIcon={<Images src={deleteIcon} skeltonStyle="imgIcon" />}
          />
        </div>
        <div className="carrito">
          {/* <CustomButton
            className="Guidedbutton"
            onClick={()=>  setCartbtn(!cartbtn)}
            title={addToCart}
          /> */}
          <QuantityButton
            onQuantityDecreased={() => {
              setQuantity(parseInt(quantity) - 1);
            }}
            onQuantityIncreased={() => {
              setQuantity(parseInt(quantity) + 1);
            }}
            quantityChanged={setQuantity}
            quantity={quantity}
            productId={productID}
            productObj={productObj}
            dontShowTitle={true}
            favrtView={true}
          />
        </div>
      </div>
    </div>
  );
};

export default FavProduct;
