import React from "react";
import MainHeader from "../../../Sections/header";
import "./style.scss";
import SideMenu from "../../../Component/my-account-side-bar";
import { NavLink } from "react-router-dom";
import { buttonList, mispartes, myAccount } from "../../../common/constants";
import MetaTitle from "../../../Component/metaTag";

export default function MiCuenta({ children, title }) {
  return (
    <>
      <MetaTitle title={`${myAccount}${" "}${title}${"  -  "}${mispartes}`} />
      <div>
        <MainHeader bottomLine otherIconOnHeader={true} logoBig={false} />
        <SideMenu list={buttonList} />
        <div className="container add container_padding">
          <div className="MainContainer">
            <div className="sideBar _padding">
              {buttonList.map((data, i) => (
                <NavLink
                  key={data.id}
                  to={data.link}
                  activeClassName="sideTab sideTabActive"
                  className="sideTab"
                >
                  <p className="TabText ">{data.name}</p>
                </NavLink>
              ))}
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  );
}
