import { CircularProgress } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import { CustomButton } from "..";
import {
  addVehicle,
  make,
  model,
  noOptionFound,
  searchDrop,
  year,
} from "../../common/constants";
import { addIcon } from "../../common/Images";
import { getModelByMaker, getYearByModelandMaker } from "../../utils/api";
import Selector from "./selector";
import "./styles.scss";
import useForm from "./useForm";

const SelectVehicleForSearchbar = ({
  modal,
  popup,
  isFixedHeight,
  wordEntered,
  makersList,
  modelsList,
  yearsList,
  disableModal,
  disableYear,
  onSubmit,
  defaultSelectedMaker,
  defaultSelectedModel,
}) => {
  const { data, modelData, createVehicle, yearData } = useForm(
    popup,
    wordEntered
  ); // most of the code in useForm is useLess because new implementation is available in this same file

  const makerRef = useRef(null);
  const modelRef = useRef(null);
  const yearRef = useRef(null);
  let modelsListArray;
  let yearsListArray;

  const [loader, setLoader] = useState(false);
  const [makerId, setMakerId] = useState("");
  const [showYear, setShowYear] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [yearLoading, setYearLoading] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [modelList, setModelList] = useState(modelsList);
  const [yearList, setYearList] = useState(yearsList);
  const [modelDisable, setModelDisable] = useState(disableModal);
  const [yearDisable, setYearDisable] = useState(disableYear);
  const [select, setSelect] = useState({
    make: { value: make, label: make },
    model: { value: model, label: model },
    year: { value: year, label: year },
  });
  const [vehicleName, setVehicleName] = useState({
    maker: "",
    model: "",
    year: "",
  });
  const makeList = makersList?.map((i) => {
    return {
      value: i.name,
      label: i.name,
      id: i.id,
    };
  });
  const modelListArr = modelList?.map((i) => {
    return {
      value: i.name,
      label: i.name,
      id: i.id,
    };
  });
  const yearListArr = yearList?.map((i) => {
    return {
      value: i.name,
      label: i.name,
      id: i.id,
    };
  });
  function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  const setMakerforDropdown = (makerName) => {
    setVehicleName((e) => ({ ...e, maker: makerName }));
    setSelect((e) => ({
      ...e,
      make: { value: makerName, label: makerName },
    }));
  };

  const dropdownOpener = () => {
    if (defaultSelectedMaker != null && !isObjectEmpty(defaultSelectedMaker)) {
      let makerName = defaultSelectedMaker.maker;
      let makerId = defaultSelectedMaker.makerID;
      setMakerId(makerId);
      if (
        defaultSelectedModel != null &&
        !isObjectEmpty(defaultSelectedModel)
      ) {
        let modelName = defaultSelectedModel.model;
        let modelId = defaultSelectedModel.modelId;
        let modelList = defaultSelectedModel.modelsListArray;
        let modelObj = {
          label: modelName,
          id: modelId,
          makerId: makerId,
        };
        setModelList(modelList);
        setMakerforDropdown(makerName);
        setModelDisable(false);
        handleModel("", modelObj);
      } else {
        let makerObj = {
          label: makerName,
          id: makerId,
        };
        handleMake("", makerObj);
      }
    }
  };

  useEffect(() => {
    dropdownOpener();
  }, []);

  const checkProperties = (obj) => {
    let arr = [];
    for (let key in obj) {
      arr.push(obj[key] !== undefined && obj[key] !== null && obj[key] !== "");
    }
    return arr.includes(false);
  };
  const handleSubmit = () => {
    if (!checkProperties(vehicleName) && !showModel && !showYear) {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
        onSubmit(vehicleName);
      }, 500);
    }
  };
  const disableYearModal = () => {
    setYearDisable(true);
    setVehicleName((e) => ({ ...e, year: "" }));
    setSelect((e) => ({
      ...e,
      year: { value: year, label: year },
    }));
  };

  const handleMake = async (name, value) => {
    let makerName = value.label;
    setMakerforDropdown(makerName);
    setMakerId(value.id);
    await fetchModelsList(value.id);
    setModelDisable(false);
    disableYearModal();
    modelRef.current.focus();
  };
  const handleModel = async (name, value) => {
    let modelId = value.id;
    let modelName = value.label;
    setVehicleName((e) => ({ ...e, model: modelName }));
    setSelect((e) => ({
      ...e,
      model: { value: modelName, label: modelName },
    }));
    await fetchYearList(makerId || value.makerId, modelId);
    setShowModel(false);
    setYearDisable(false);
    setShowYear(true);
    yearRef?.current?.focus();
  };
  const handleYear = (name, value) => {
    let year = value.label;
    setShowYear(false);
    setVehicleName((e) => ({ ...e, year: year }));
    setSelect((e) => ({
      ...e,
      year: { value: year, label: year },
    }));
  };

  async function fetchYearList(makerId, modalId) {
    if (modalId != undefined && makerId != undefined) {
      setYearLoading(true);
      await getYearByModelandMaker(modalId, makerId)
        .then((response) => {
          if (response.status === 200) {
            yearsListArray = response.data.content;
            setYearList(yearsListArray);
            setYearLoading(false);
          } else {
            console.log("Error in fetching Data");
          }
        })
        .catch((error) => {
          console.log("Error in fetching Data", error);
        });
    }
  }
  async function fetchModelsList(makerId) {
    if (makerId != undefined) {
      setModalLoading(true);
      await getModelByMaker(makerId)
        .then((response) => {
          if (response.status === 200) {
            modelsListArray = response.data.content;
            console.log("modelsListArray 786", modelsListArray);
            setModelList(response.data.content);
            setModalLoading(false);
          } else {
            console.log("Error in fetching Data");
          }
        })
        .catch((error) => {
          console.log("Error in fetching Data", error);
        });
    }
  }

  return (
    <>
      <div
        className={
          modal === true ? "_mobileView" : "relativePosition container add"
        }
      >
        <div
          className={
            modal === true
              ? "section-search isBoxShadow"
              : "section-search isBoxShadow absolute"
          }
        >
          <div>
            <span className="ASIcon">
              <img src={addIcon} />
            </span>
          </div>
          <div className="searches">
            <div
              className={modal === true ? "slectorSize" : "widthSize"}
              style={{ cursor: "pointer" }}
            >
              <Selector
                name="make"
                value={select.make}
                handleChange={handleMake}
                placeholder={make}
                options={makeList}
                onFocus={() => {
                  // setData({ ...data });
                  setShowModel(false);
                  setShowYear(false);
                }}
                noOptionsMessage={() => noOptionFound}
                isLoading={data.isLoading}
                isFixedHeight={isFixedHeight}
                innerRef={makerRef}
              />
            </div>
            <div
              className={modal === true ? "slectorSize" : "widthSize"}
              style={{ cursor: !showModel ? "not-allowed" : "pointer" }}
            >
              <Selector
                innerRef={modelRef}
                name="model"
                value={select.model}
                handleChange={handleModel}
                placeholder={model}
                options={modelListArr}
                onFocus={() => {
                  // clickOnModelDropdown();
                  setShowYear(false);
                  setShowModel(true);
                }}
                menuIsOpen={showModel}
                isSearchable={true}
                disable={modelDisable}
                noOptionsMessage={() => noOptionFound}
                isLoading={modalLoading}
                isFixedHeight={isFixedHeight}
              />
            </div>
            <div
              className={modal === true ? "slectorSize" : "widthSize"}
              style={{ cursor: !showYear ? "not-allowed" : "pointer" }}
            >
              <Selector
                name="year"
                innerRef={yearRef}
                value={select.year}
                handleChange={handleYear}
                placeholder={year}
                options={yearListArr}
                onFocus={() => {
                  setShowYear(true);
                  setShowModel(false);
                }}
                menuIsOpen={showYear}
                isSearchable={showYear}
                disable={yearDisable}
                noOptionsMessage={() => noOptionFound}
                isLoading={yearLoading}
                isFixedHeight={isFixedHeight}
              />
            </div>
            <div className="btnSize">
              {loader ? (
                <CustomButton className="SBtn btn ">
                  <CircularProgress size={25} color="white" />
                </CustomButton>
              ) : (
                <CustomButton
                  title={
                    createVehicle.length == 0
                      ? searchDrop.replace(/\s/g, "")
                      : addVehicle
                  }
                  className={
                    createVehicle.length == 0 ? "SBtn btn " : "btn Subtn"
                  }
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectVehicleForSearchbar;
