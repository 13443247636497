import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "./style.scss";
import MainHeader from "../../Sections/header";
import { CustomModal } from "../../Component/index";
import {
  products,
  previouseSelectedId,
  TokenKey,
  error,
  paymentStatusRejected,
  MinimumshipingPrice,
} from "../../common/constants";
import ProductBox from "../../Component/productBox/productBox";
import CustomerDetail from "./customerDetail";
import PaymentSummary from "./summary";
import CustomizedDialogs from "../../Component/customModal/Modal";
import MetaTitle from "../../Component/metaTag";
import { metaPath } from "../../common/services";
import SkeletonProductBox from "../../Component/productBox/skeltonPBox";
import { getCheckout } from "../../utils/api";
import { useDispatch } from "react-redux";
import ApplicationContext from "../../context-api/context";
import SkeletonPaymentSummary from "./skeletonSummary";
import { useMercadopago } from "react-sdk-mercadopago";
import queryString from "query-string";
import { path } from "../../common/routes-names";
import AlertBox from "../../Component/confirm-alert/alertBox";
import { useHistory } from "react-router";
import { purchasePixel } from "../../utils/fbPixelEvent";

const popUpModalType = {
  billingAddreess: 1,
  payment: 2,
  shippingAddress: 3,
};

const CheckOut = ({ location, history }) => {
  const {
    selectedCheckoutBillingAddreess,
    setSelectedCheckoutBillingAddreess,
    selectedCheckoutShippingAddreess,
    setSelectedCheckoutShippingAddreess,
    selectedCheckoutPayment,
    setSelectedCheckoutPayment,
    setBanksCheckoutList,
    setStoresCheckoutList,
    setRegimenInvoiceList,
    setPurchaseReasonInvoiceList,
    setAlertMsg,
    setOpenAlert,
    openAlert,
    alertMsg,
  } = useContext(ApplicationContext);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [openPop, setClosePop] = useState(false);
  const [shippingPop, setShippingPop] = useState(false);
  const [paymentPop, setPaymentPop] = useState(false);
  const [billingPop, setBillingPop] = useState(false);
  const [checkoutObject, setCheckObject] = useState(null);
  const [fetchDataToggle, setFetchDataToggle] = useState(false);
  const [checkoutDataPresent, setCheckoutDataPresent] = useState(false);
  const [paymentError, setPaymentError] = useState(false);

  const historyRoute = useHistory();
  const [state, setState] = useState({
    selectedId: "",
    previouse: "",
  });
  const mercadopago = useMercadopago.v2(TokenKey, {
    locale: "en-US",
  });
  const myRef = useRef();

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }
  const TokenFromUrl = () => {
    const payment_status = useQuery().get("status");
    const folio = useQuery().get("folio");
    if (payment_status == paymentStatusRejected) {
      setOpenAlert(true);
      setAlertMsg("Payment Rejected!");
      setPaymentError(true);
    } else if (payment_status == "success" && folio != null) {
      purchasePixel({
        folio: folio,
        status: payment_status,
        email: JSON.parse(localStorage.getItem("UserData")).email,
      });
    }
  };

  useEffect(() => {
    TokenFromUrl();
  }, []);

  const closeModal = () => {
    const previouseId = localStorage.getItem(previouseSelectedId);
    if (previouseId) {
      setState((prev) => ({
        ...prev,
        selectedId: previouseId,
      }));
      localStorage.removeItem(previouseSelectedId);
      setClosePop(false);
      setShow(false);
    } else {
      setState((prev) => ({
        ...prev,
        selectedId: prev.previouse,
      }));
      setClosePop(false);
      setShow(false);
    }
  };
  const { collection_status, status, folio } = queryString.parse(
    location.search
  );

  useEffect(() => {
    if (collection_status && status) {
      if ([collection_status, status].includes("approved")) {
        history.replace(path.orderConfirmation, {
          paymentTypeId: 4,
          responseObject: {
            message: `Hemos recibido tu pedido con folio ${folio}, hemos comenzado a prepararlo para que lo recibas lo antes posible.`,
            folio: folio,
          },
        });
      }
    }
  }, [collection_status, status]);

  const openModel = (count) => {
    switch (count) {
      // eslint-disable-next-line no-lone-blocks
      case popUpModalType.billingAddreess:
        setClosePop(true);
        setBillingPop(false);
        setPaymentPop(false);
        setShippingPop(true);
        setFetchDataToggle(!fetchDataToggle);
        break;
      case popUpModalType.payment:
        setClosePop(true);
        setPaymentPop(true);
        setShippingPop(false);
        setBillingPop(false);
        setFetchDataToggle(!fetchDataToggle);
        break;
      case popUpModalType.shippingAddress:
        setClosePop(true);
        setBillingPop(true);
        setShippingPop(false);
        setPaymentPop(false);
        setFetchDataToggle(!fetchDataToggle);
        break;
      default:
        setClosePop(true);
        setBillingPop(false);
        setShippingPop(false);
        setPaymentPop(false);
    }
  };

  const getCompletedJSON = () => {
    return {
      Total: TotalPrice,
      AddressID:
        selectedCheckoutShippingAddreess && selectedCheckoutShippingAddreess.id,
      PaymentTypeID:
        selectedCheckoutPayment && selectedCheckoutPayment.paymentType,
      CardPaymentTypeID:
        selectedCheckoutPayment && selectedCheckoutPayment.cardBrand,
      CardToken:
        selectedCheckoutPayment && (selectedCheckoutPayment.CardToken || ""),
      CardSaveCard:
        selectedCheckoutPayment &&
        (selectedCheckoutPayment.CardSaveCard || false),
      InvoicingID: parseInt(
        selectedCheckoutBillingAddreess && selectedCheckoutBillingAddreess.id
      ),
      TransferPaymentTypeID:
        selectedCheckoutPayment && selectedCheckoutPayment.paymentType === 2
          ? selectedCheckoutPayment.name
          : "",
      StorePaymentTypeID:
        selectedCheckoutPayment && selectedCheckoutPayment.paymentType === 3
          ? selectedCheckoutPayment.name
          : "",
      UsoCFDI:
        selectedCheckoutBillingAddreess &&
        selectedCheckoutBillingAddreess.UsoCFDI
          ? selectedCheckoutBillingAddreess.UsoCFDI
          : "",
    };
  };

  const createToken = async (paymentObject) => {
    const cardToken = await mercadopago.createCardToken(paymentObject);
    return cardToken;
  };

  async function getData() {
    await getCheckout()
      .then((response) => {
        if (response.status === 200) {
          const {
            data: {
              content: {
                defaultAddress,
                defaultCard,
                defaultInvoice,
                banks,
                stores,
                purchaseReason,
                taxRegimes,
              },
            },
          } = response;
          setCheckoutDataPresent(true);
          // Adding this line below to test the Shiping coast of the Checkout
          // response.data.content.products[0].shippingCost = 90;
          setCheckObject(response.data.content || null);
          setSelectedCheckoutBillingAddreess(defaultInvoice || null);
          setSelectedCheckoutShippingAddreess(defaultAddress || null);
          // setSelectedCheckoutPayment(defaultCard || null); {/* For the Time Being it commenting 4-Nov-2022 */}
          setSelectedCheckoutPayment(null);
          setBanksCheckoutList(banks || null);
          setStoresCheckoutList(stores || null);
          setPurchaseReasonInvoiceList(purchaseReason || null);
          setRegimenInvoiceList(taxRegimes || null);
        } else {
          setOpenAlert(true);
          setAlertMsg(response.ExceptionMessage);
        }
      })
      .catch((error) => {
        setOpenAlert(true);
        setAlertMsg(error);
      });
  }
  useEffect(() => {
    console.log(checkoutObject);
    if (checkoutObject == null) {
      getData();
    }
  }, []);
  const totalQty = checkoutObject
    ? parseFloat(
        checkoutObject.products.reduce(
          (qty, item) => (qty = qty + parseInt(item.quantity)),
          0
        )
      )
    : 0;
  const totalProductPrice = checkoutObject
    ? parseFloat(
        checkoutObject.products.reduce(
          (qty, item) =>
            (qty = qty + parseFloat(item.price) * parseInt(item.quantity)),
          0
        )
      ).toFixed(2)
    : 0.0;
  const totalShipping = checkoutObject
    ? totalProductPrice < MinimumshipingPrice
      ? parseFloat(
          checkoutObject.products.reduce(
            (max, item) => (item.shippingCost > max ? item.shippingCost : max),
            0
          )
        ).toFixed(2)
      : parseFloat(0).toFixed(2)
    : parseFloat(0).toFixed(2);

  const TotalPrice = (
    parseFloat(totalProductPrice) + parseFloat(totalShipping)
  ).toFixed(2);

  return (
    <>
      <MetaTitle title={metaPath()} />
      <MainHeader
        otherIconOnHeader={true}
        isMenu={true}
        logoBig={true}
        isCartIcon={true}
        isFavIcon={true}
        isStickyCheckoutIcon
      />
      <div className="container add">
        <div className="cartMainArea">
          {/* 3 boxes of DirectionDeEnvio,Method de Pago, Facturation  */}
          <CustomerDetail
            addAddress={() => openModel(popUpModalType.billingAddreess)} //Shipping
            addPayment={() => openModel(popUpModalType.payment)}
            addBill={() => openModel(popUpModalType.shippingAddress)}
            defaultAddress={
              checkoutDataPresent ? selectedCheckoutShippingAddreess : false
            }
            defaultCard={checkoutDataPresent ? selectedCheckoutPayment : false}
            defaultInvoice={
              checkoutDataPresent ? selectedCheckoutBillingAddreess : false
            }
          >
            <div className="selectedProductsOuter">
              <h3 className="heading">{products}</h3>
              <div className="selectedProductsBody">
                {checkoutObject && checkoutObject.products
                  ? checkoutObject.products.map((i, index) => {
                      return (
                        <ProductBox
                          status="checkout"
                          discountPrice={0}
                          Name={i.name}
                          itemId={i.noParte}
                          Img={i.image.replace("https//", "https://")}
                          brandImg={i.brandImage}
                          brandName={i.brandName}
                          quantity={i.quantity}
                          itemPrice={parseFloat(i.price).toFixed(2)}
                          TotalPrice={parseFloat(i.amount).toFixed(2)}
                          pathName={i.url}
                          param={i.encryptedProductID}
                        />
                      );
                    })
                  : [1, 2, 3, 4].map((i, index) => {
                      return (
                        <SkeletonProductBox
                          status="checkout"
                          discountPrice={0}
                          Name={i.className}
                          itemId={i.noParte}
                          brandImg={i.brandImage}
                          quantity={i.quantity}
                          itemPrice={parseFloat(i.price).toFixed(2)}
                          TotalPrice={parseFloat(i.amount).toFixed(2)}
                        />
                      );
                    })}
              </div>
            </div>
          </CustomerDetail>
          {/* Right Section of Payment Total and Details */}
          {totalQty ? (
            <PaymentSummary
              qty={totalQty}
              poductPrice={totalProductPrice}
              shippingCost={totalShipping}
              discount={0.0}
              total={TotalPrice}
            />
          ) : (
            <SkeletonPaymentSummary />
          )}
          {/* Products to Ordered on Checkout Screen */}
          {/* Product Box Component is also used in Orders (Pepidos) Screen */}
        </div>
        {
          <CustomizedDialogs
            onClose={() => {
              closeModal();
              myRef.current.childMethod();
            }}
            open={openPop}
          >
            {/* After opening the modals by clicking on (Edit or Add Button) Following component is visible*/}
            <CustomModal
              close={() => closeModal()}
              billAddress={billingPop}
              payment={paymentPop}
              shipping={shippingPop}
              fetchDataToggle={fetchDataToggle}
              state={state}
              setState={setState}
              setClosePop={setClosePop}
              openPop={openPop}
              show={show}
              setShow={setShow}
              ref={myRef}
            />
          </CustomizedDialogs>
        }
      </div>
      {/* in case of any error Dialogue box visible */}
      <AlertBox
        del={false}
        onCancel={() => {
          if (paymentError) {
            historyRoute.replace(path.home);
          }
          setOpenAlert(false);
        }}
        heading={error}
        text={alertMsg}
        onClose={() => {
          if (paymentError) {
            historyRoute.replace(path.home);
          }
          setOpenAlert(false);
        }}
        open={openAlert}
      />
    </>
  );
};
export default CheckOut;
