import React, {useState, useContext} from "react";
import "./style.scss";
import {searchBarIcon} from "../../common/Images";
import * as service from "../../common/services";
import {CloseButton, CustomButton, SelectVehicleForSearchbar} from "..";
import {CircularProgress, IconButton} from "@material-ui/core";
import {brand, category, clearHistory, product, resultNot, selectYourvehicle, showMoreCategory, showMoreProducts, tryOthersKaywords, brandText, Include} from "../../common/constants";
import CloseIcon from "@material-ui/icons/Close";
import SearchProduct from "./searchProduct";
import {colors} from "../../common/theme";
import useForm from "./useForm";
import Badges from "../../Sections/sorted-products/badge";
import {regexImg, getAllHistory, removeAllHistory} from "../../common/services";
import {closeSearchBar, selectSearchProduct} from "../../Redux/reducers/alertSlice";
import {makeVehicle} from "../../Redux/reducers/vehicleSlice";
import SearchSkelton from "./searchSkelton";
import {Skeleton} from "@material-ui/lab";
import {useSelector} from "react-redux";
import {UserSearchLogMaintain, getKeywordExplorer, getKeywordWithVehicle, getModelByMaker, getYearByModelandMaker} from "../../utils/api";
import ApplicationContext from "../../context-api/context";

function SearchBar({placeholder, data, onClose, CloseIconSearchBar}) {
  const {history, searchData, handleSearch, dispatch, vehicleCreate, setVehicle, historyData, sethistoryData, empty, openSelectVehicles} = useForm({data});
  const {makersList, setOpenSearchBar, selectSearchProduct, setSelectSearchProduct, setVehicleData} = useContext(ApplicationContext);

  const selectProduct = selectSearchProduct;
  // const selectProduct = useSelector((state) => state.alert.productSelect);
  let firstCategoryUrl;
  let firstCategory = searchData.category.length != 0 && searchData.category[0];
  if (firstCategory.url !== null || firstCategory.url !== undefined) {
    if (vehicleCreate !== "") {
      firstCategoryUrl = vehicleCreate.replaceAll(" ", "-") + "-" + firstCategory.url;
    } else {
      firstCategoryUrl = firstCategory.url;
    }
  }

  let allVehicles = service.getAllVehicle();
  let lastElement = allVehicles[allVehicles.length - 1];

  let row = [];
  let yearsListArray = [];
  let modelsListArray = [];

  const [loader, setLoader] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [nothingFound, setNothingFound] = useState(false);
  const [openSelectVehicle, setOpenSelectVehicle] = useState(false);
  const [wordEntered, setWordEntered] = useState("");
  const [selectedMakerForDropDown, setSelectedMakerForDropDown] = useState({});
  const [selectedModelForDropDown, setSelectedModelForDropDown] = useState({});
  const [vehicleTagInSearch, setVehicleTagInSearch] = useState(
    service.getAllVehicle().length != 0 ? `${lastElement?.make?.value}${" "}${lastElement?.model?.value}${" "}${lastElement?.year?.value}` : ""
  );
  const showMoreProductHandler = firstCategory => {
    let categoryURL = "";
    if (vehicleTagInSearch !== "") {
      categoryURL = vehicleTagInSearch.replaceAll(" ", "-") + "-" + firstCategory.url;
    } else {
      categoryURL = firstCategory.url;
    }
    return categoryURL;
  };

  const textSplitor = input => {
    let newRow = [];
    return (newRow = input.split(/(\s+)/).filter(function (e) {
      return e.trim().length > 0;
    }));
  };
  // main handler of searchbar
  const searchHandler = async e => {
    let input = e;
    console.log(input);
    let isVehicleAvaialable = -1;
    input = input ? input.replace(/[^a-zA-Z0-9-]/g, " ").trim() : "";
    if (input != "") {
      setLoader(true);

      try {
        //store value in history object
        input = input.trim();
        service.insertSearchData(input);
        if (vehicleTagInSearch === "") {
          //check if there is a part of Makers of Vehicle
          if (textSplitor(input).length >= 1) {
            row = textSplitor(input);
            isVehicleAvaialable = await CheckWordsSeperate(row);
            if (isVehicleAvaialable == -1) {
              onKeywordAPICall(input, vehicleTagInSearch);
            }
          }
        } else {
          setLoader(false);
          onKeywordAPICall(input, vehicleTagInSearch);
        }
      } catch (error) {
        alert(error);
      }
    } else {
      setNothingFound(true);
    }
  };

  async function fetchModelsList(makerId) {
    if (makerId != undefined) {
      await getModelByMaker(makerId)
        .then(response => {
          if (response.status === 200) {
            modelsListArray = response.data.content;
          } else {
            console.log("Error in fetching Data");
          }
        })
        .catch(error => {
          console.log("Error in fetching Data", error);
        });
    }
  }
  async function fetchYearList(makerId, modalId) {
    if (modalId !== undefined && makerId !== undefined) {
      await getYearByModelandMaker(modalId, makerId)
        .then(response => {
          if (response.status === 200) {
            yearsListArray = response.data.content;
          } else {
            console.log("Error in fetching Data");
          }
        })
        .catch(error => {
          console.log("Error in fetching Data", error);
        });
    }
  }

  const isListContainingWord = (searchTextArray, listArray) => {
    let result = undefined;
    // this loop will find typed text array ["corolla","2018","balatas"] comapring with years["2019","208"..] if it found then result contains desired year if not then result contains undefined
    for (let i = 0; i < searchTextArray.length; i++) {
      let e = searchTextArray[i];
      result = listArray.find(o => o.name.toLowerCase() === e.toLowerCase());
      if (result != undefined) {
        break;
      }
    }
    return result;
  };
  const showYearDropdownList = (makerName, makerId, modelName, modelId) => {
    const makerArray = {
      maker: makerName,
      makerID: makerId
    };
    const modelArray = {
      model: modelName,
      modelId: modelId,
      modelsListArray: modelsListArray
    };
    setSelectedModelForDropDown(modelArray);
    setSelectedMakerForDropDown(makerArray);
    setOpenSelectVehicle(true);
    setLoader(false);
  };
  const showModelDropdownList = (makerName, makerId) => {
    const array = {
      maker: makerName,
      makerID: makerId
    };
    setSelectedMakerForDropDown(array);
    setSelectedModelForDropDown(null);
    setOpenSelectVehicle(true);
    setLoader(false);
  };

  const CheckWordsSeperate = async row => {
    var isMakeWordFound = false;
    for (let index = 0; index < row.length; index++) {
      const i = row[index];
      const capitalizeWord = i.charAt(0).toUpperCase() + i.slice(1);
      const makeIndex = makersList.findIndex(item => item.name.toLowerCase().split(" ").indexOf(capitalizeWord.toLowerCase()) > -1);
      if (makeIndex != -1) {
        // maker found
        isMakeWordFound = true;
        let makerId = makersList?.[makeIndex].id;
        let makerName = makersList?.[makeIndex].name;
        await fetchModelsList(makerId);
        let result = isListContainingWord(row, modelsListArray); //finding the model
        if (result != undefined) {
          let modelId = result.id;
          let modelName = result.name;
          await fetchYearList(makerId, modelId);
          let isSearchTextContainYear = isListContainingWord(row, yearsListArray); // finding the year
          if (isSearchTextContainYear != undefined) {
            let year = isSearchTextContainYear.name;
            //auto creation batch in searchbar
            let newVehicle = `${makerName} ${modelName} ${year}`; //creating new vehicle badge in the searchbar if we find the year and model in user typed search result!=undefined
            setVehicleTagInSearch(newVehicle); // create badge in the searchbar
            let searchInputlocal = row.join(" ").toString(); // getting the user typed text in searchbar
            let wordReplace = replaceWordHandler(newVehicle, searchInputlocal);
            setWordEntered(wordReplace);
            if (newVehicle != "" && wordReplace != "") {
              onKeywordAPICall(wordReplace, newVehicle);
            }
          } else {
            showYearDropdownList(makerName, makerId, modelName, modelId);
          }
        } else {
          showModelDropdownList(makerName, makerId);
        }
      }
    }

    if (isMakeWordFound != true) {
      setLoader(false);
      return -1;
    }
    setLoader(false);
  };
  const getSelectedModalId = arrayList => {
    let result = arrayList.find(e => e.selected === true);
    return result;
  };
  const onKeywordAPICall = async (input, vehicleInSearch) => {
    input = input ? input.replace(/[^a-zA-Z0-9-]/g, " ").trim() : "";
    setLoader(true);
    let result;
    if (vehicleInSearch != "") {
      result = await getKeywordWithVehicle(input.trim(), vehicleInSearch); //if vehicle is already selected and available in searchbar
    } else {
      result = await getKeywordExplorer(input.trim()); //if there is no vehicle in searchbar
    }
    const data = result.data.content;
    if (!data.isVehicle) {
      const brand = data.brands;
      const categories = data.categories;
      const products = data.products;
      if (brand.length != 0 && categories.length != 0 && products.length != 0) {
        setBrandList(brand);
        setCategoryList(categories);
        setProductList(products);
        setNothingFound(false);
        setLoader(false);
      } else {
        setNothingFound(true);
        setLoader(false);
      }
    } else {
      //create vehicle here comming from API
      // alert("V3 of searchbar is remaing to create vehicle");
      let makerId = data.vehicle.makerID;
      let makerName = data.vehicle.maker;
      let modelName = getSelectedModalId(data.vehicle.models).model;
      let modelId = getSelectedModalId(data.vehicle.models).modelID;
      await fetchModelsList(makerId);
      if (row.length > 1) {
        // if user types more than one word
        await fetchYearList(makerId, modelId);
        let result = isListContainingWord(row, yearsListArray);

        if (result != undefined) {
          let year = result.name;
          let newVehicle = `${makerName} ${modelName} ${year}`; //creating new vehicle badge in the searchbar if we find the year and model in user typed search result!=undefined
          setVehicleTagInSearch(newVehicle); // create badge in the searchbar
          let searchInputlocal = row.join(" ").toString(); // getting the user typed text in searchbar
          let newSearchText = replaceWordHandler(newVehicle, searchInputlocal);
          setWordEntered(newSearchText);
          if (newVehicle != "" && newSearchText != "") {
            onKeywordAPICall(newSearchText, newVehicle);
          }
        } else {
          showYearDropdownList(makerName, makerId, modelName, modelId);
        }
        setLoader(false);
      } else {
        showYearDropdownList(makerName, makerId, modelName, modelId);
      }
    }
  };
  const replaceWordHandler = (newVehicle, input) => {
    var carName = newVehicle;
    let reg1 = new RegExp(" ", "gi");
    let arr = carName.replace(reg1, "|");
    var reg2 = new RegExp(arr, "gi");
    let wordReplace = input.replace(reg2, "");
    return wordReplace.trim();
  };

  const clearInput = () => {
    setWordEntered("");
  };
  const emptyDisplayedResult = () => {
    setCategoryList([]);
    setBrandList([]);
    setProductList([]);
    setNothingFound(false);
  };
  const filterSpecialCharacters = txt => {
    txt = txt ? txt.replace(/[^a-zA-Z0-9-]/g, " ").trim() : "";
    return txt;
  };
  const onVehicleCreatSubmit = vehicle => {
    let newVehicle = `${vehicle.maker} ${vehicle.model} ${vehicle.year}`;
    setVehicleTagInSearch(newVehicle);

    let searchTemp = filterSpecialCharacters(wordEntered);
    if (searchTemp != "" && searchTemp.match(/(\w+)/g).length >= 1) {
      var carName = newVehicle;
      let reg1 = new RegExp(" ", "gi");
      let arr = carName.replace(reg1, "|");
      var reg2 = new RegExp(arr, "gi");
      let wordReplace = searchTemp.replace(reg2, "");
      setWordEntered(wordReplace.trim());
      if (newVehicle != "" && wordReplace.trim() != "") {
        onKeywordAPICall(wordReplace.trim(), newVehicle);
      }
    }
    setOpenSelectVehicle(false);
  };
  const allowOnlyOneSpace = str => {
    return str.endsWith(" ") ? str.trim() + " " : str.trim();
  };

  const postUserSearchLog = async txt => {
    await UserSearchLogMaintain(txt);
  };

  return (
    <>
      <div className='searchBar'>
        <div className='searchInputs'>
          {(vehicleCreate.length != 0 || openSelectVehicle == false || vehicleCreate != null) && (
            <>
              <Badges
                couponCode={vehicleTagInSearch}
                onClick={() => setVehicleTagInSearch("")}
                onKeyDown={e => {
                  if (e.keyCode == 8) {
                    setVehicle("");
                    console.log("Add Vehicle Press...");
                  }
                }}
              />
            </>
          )}
          <input
            type='text'
            placeholder={empty == false ? placeholder : "Por favor complete el campo "}
            value={wordEntered}
            // onChange={handleFilter}
            onChange={e => {
              let value = e.target.value;
              setWordEntered(allowOnlyOneSpace(value));
              emptyDisplayedResult();
              setNothingFound(false);
              setOpenSelectVehicle(false);
            }}
            autoFocus
            // onKeyDown={(e) => handlePress(e)}
            onKeyDown={e => {
              let input = e.target.value;
              if (e.key === "Enter") {
                searchHandler(input);
                const searchedText = vehicleTagInSearch === "" ? `${input}` : `${vehicleTagInSearch} - ${input}`;
                postUserSearchLog(searchedText);
              }
            }}
          />

          <div className='searchIcons'>
            {loader ? (
              <IconButton className='iconButton'>
                <CircularProgress
                  size={28}
                  style={{color: "black"}}
                />
              </IconButton>
            ) : wordEntered.length >= 1 ? (
              <CloseIcon
                style={{color: "black", fontSize: "2rem"}}
                onClick={() => {
                  clearInput();
                  emptyDisplayedResult();
                }}
                id='clearBtn'
              />
            ) : (
              <IconButton
                onClick={() => handleSearch()}
                className='iconButton'>
                <img
                  src={searchBarIcon}
                  onClick={() => handleSearch()}
                />
              </IconButton>
            )}
          </div>
        </div>
        <div className='closeIconBox'>
          <CloseButton
            onClick={onClose}
            color={colors.lightGray}
            fontSize='2.4rem'
          />
        </div>
      </div>
      <div className='serachBarBottom'>
        <span>{Include}</span>
      </div>
      {loader == true ? (
        <>
          <div className='dataResult mt-2'>
            <div className='_result'>
              <div className='heading mt-2 ml-3'>
                {" "}
                <Skeleton
                  animation='wave'
                  variant='text'
                  height={16}
                  width={120}
                />{" "}
              </div>
              <div className='resultContent mb-4'>
                {[1, 2, 3, 4, 5, 6, 7].map((item, index) => {
                  return (
                    <SearchSkelton
                      key={index}
                      row={true}
                      image={true}
                      title={true}
                      rating={"item.rating"}
                      price={true}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {categoryList.length != 0 || brandList.length != 0 || productList.length != 0 || searchData.error.length != 0 ? (
            <div className='dataResult mt-2'>
              <div className='_result'>
                {categoryList.length != 0 && (
                  <>
                    <div>
                      <p className='heading'>{category} </p>
                    </div>
                    <div className='resultContent'>
                      {categoryList.map((item, index) => {
                        const categoryURL = vehicleTagInSearch !== "" ? vehicleTagInSearch.replaceAll(" ", "-") + "-" + item.url : item.url;
                        return (
                          <SearchProduct
                            key={index}
                            image={regexImg(item.imageURL)}
                            row={true}
                            category={item.name}
                            onClick={() => {
                              history.push("/" + categoryURL);
                              setOpenSearchBar(false);
                              setSelectSearchProduct(!selectProduct);
                            }}
                          />
                        );
                      })}
                    </div>
                    {categoryList.length > 5 && vehicleTagInSearch != "" && (
                      <div className='show_Data'>
                        <CustomButton
                          onClick={() => history.push(`/${vehicleTagInSearch.replaceAll(" ", "-")}.html`)}
                          title={showMoreCategory}
                          className='show_btn'
                        />
                      </div>
                    )}
                  </>
                )}
                {brandList.length != 0 && (
                  <div className={productList.length == 0 ? null : "brandBorder"}>
                    <div>
                      <p className='heading'>{brand} </p>
                    </div>
                    <div className='resultContent'>
                      {brandList.map((value, key) => {
                        const {url, name} = value || {};
                        const brandURL = vehicleTagInSearch !== "" ? `/${vehicleTagInSearch.replaceAll(" ", "-")}-${url}?${brandText}=${name}` : `/${url}?${brandText}=${name}`;

                        return (
                          <SearchProduct
                            image={value.imageURL}
                            row={true}
                            brand={value.name}
                            onClick={() => {
                              history.push(brandURL);
                              setOpenSearchBar(false);
                              setSelectSearchProduct(!selectProduct);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                {productList.length != 0 && (
                  <>
                    <div>
                      <p className='heading'>{product} </p>
                    </div>
                    <div className='resultContent'>
                      {productList.map((item, index) => {
                        return (
                          <SearchProduct
                            onClick={() => {
                              history.push({
                                pathname: "/" + item.url,
                                search: "?id=" + item.encryptedProductID
                              });
                              // dispatch(closeSearchBar());
                              setOpenSearchBar(false);
                            }}
                            key={item.productID}
                            image={item.image.replaceAll("https//", "https://")}
                            title={item.productName}
                            brand={item.brand + " " + item.partNo}
                            rating={item.rating}
                            price={`$${Number(item.price).toFixed(2)}`}
                            basePrice={`$${Number(item.basePrice).toFixed(2)}`}
                          />
                        );
                      })}
                    </div>
                    {productList.length > 5 && (
                      <div className='show_Data'>
                        <CustomButton
                          onClick={() => {
                            history.push(`/${showMoreProductHandler(categoryList[0])}`);
                            setVehicleData([]);
                            setOpenSearchBar(false);
                          }}
                          title={showMoreProducts}
                          className='show_btn'
                        />
                      </div>
                    )}
                  </>
                )}
                {wordEntered != "" && searchData.error.length != 0 && (
                  <>
                    <span className='error mb-2'>{searchData.error}</span>
                  </>
                )}
              </div>
            </div>
          ) : wordEntered == "" ? (
            <>
              {(historyData.length != 0 || getAllHistory().length != 0) && (
                <>
                  <div className='dataResult mt-2'>
                    <div className='_result mt-2'>
                      {getAllHistory()
                        .map(item => {
                          // item = item
                          //   .replace(/[&\/\\#,@+()$~%.'":*?<>{}]/g, "")
                          //   .trim();
                          // item = item.replace(/ +(?= )/g, "").trim();
                          return (
                            <>
                              <p
                                className='headText'
                                key={item}
                                onClick={() => {
                                  setWordEntered(item);
                                  searchHandler(item);
                                  const searchedText = vehicleTagInSearch === "" ? `${item}` : `${vehicleTagInSearch} - ${item}`;
                                  postUserSearchLog(searchedText);

                                  // handleSearch(item);
                                }}>
                                <i class='fas fa-search searchHistory'></i> <span className='wordHistory'>{item}</span>
                              </p>
                            </>
                          );
                        })
                        .reverse()}
                      {getAllHistory().length != 0 && (
                        <span
                          className='clearHistory mb-2'
                          onClick={() => {
                            sethistoryData([]);
                            removeAllHistory();
                          }}>
                          <CloseIcon
                            className='crossIcons'
                            id='clearBtn'
                          />{" "}
                          {clearHistory}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          ) : //wordError == true &&
          // wordEntered.length >= 2 &&
          //openSelectVehicle == false ?
          nothingFound === true ? (
            <>
              <div className='dataResult mt-2'>
                <div className='_result mt-2'>
                  <p className='heading'>
                    {resultNot} "<span className='wordEntr'>{wordEntered}</span>"{" "}
                  </p>
                  <span className='error mb-2'>{tryOthersKaywords}</span>
                </div>
              </div>
            </>
          ) : openSelectVehicle == true ? (
            <>
              <div
                className='dataResult mt-2'
                style={{height: "30rem"}}>
                <div className='_result mt-2'>
                  <div>
                    <p className='heading'>{selectYourvehicle}</p>
                  </div>
                  <div
                    className='resultContent'
                    style={{height: "35%", alignItems: "center"}}>
                    <SelectVehicleForSearchbar
                      carModel={true}
                      wordEntered={wordEntered}
                      makersList={makersList}
                      modelsList={modelsListArray}
                      yearsList={yearsListArray}
                      disableModal
                      disableYear
                      onSubmit={e => onVehicleCreatSubmit(e)}
                      defaultSelectedMaker={selectedMakerForDropDown}
                      defaultSelectedModel={selectedModelForDropDown}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
}

export default SearchBar;
