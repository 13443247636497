import React from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ReactComponent as LogoIcon } from "../../images/logoWhite.svg";
import { ReactComponent as WhiteLogo } from "../../images/whiteLogo.svg";
import { mainLogo } from "../../common/Images";
import Images from "../imgLoader";

import { path } from "../../common/routes-names";
const DiscoverPart = ({ className, line1, line2, forgetPass }) => {
  return (
    <Grid item xs={5} className={className}>
      <div className="logoIcon mt-3 ml-3">
        <Link to={path.home}>
          <div className="imgHolder">
            <Images src={mainLogo} />
            {/* {forgetPass ? <WhiteLogo /> : <LogoIcon />} */}
          </div>
        </Link>
      </div>
      <div className="discover">
        <span>
          {line1}
          <br />
          {line2}
        </span>
      </div>
    </Grid>
  );
};

export default DiscoverPart;
