import React from "react";
import {
  Grid,
  Container,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import "./style.scss";
import { ReactComponent as FbIcon } from "../../../images/fb.svg";
import { ReactComponent as Google } from "../../../images/google.svg";
import {
  discoverTheParts,
  enterWrong,
  incorretPassword,
  login,
  yourFav,
} from "../../../common/constants";
import { Formik, Form } from "formik";
import { TextInput, CustomButton } from "../../../Component/index";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import {
  Email,
  forgotPassword,
  Password,
  registerNow,
  signIn,
} from "../../../common/constant/formConstants";
import { path } from "../../../common/routes-names";
import { loginValidation } from "../../../common/formValidation";
import DiscoverPart from "../../../Component/dicoverParts";
import CustomizedDialogs from "../../../Component/customModal/Modal";
import { Confirm_Alert } from "../../../Component/confirm-alert";
import useForm from "./useForm";
import MetaTitle from "../../../Component/metaTag";
import AlertBox from "../../../Component/confirm-alert/alertBox";

const SignIn = (prop) => {
  const { show, setShow, loader, openModal, setOpenModal, handleForm } =
    useForm(prop);

  return (
    <>
      <MetaTitle title={login} />
      <Grid container xs={12} style={{ height: "100vh" }}>
        <DiscoverPart
          className="bgImg"
          line1={discoverTheParts}
          line2={yourFav}
        />
        <Grid item xs={12} sm={7}>
          <Container className="signIn">
            <div className="register">
              <Link
                to={{
                  pathname: path.signUp,
                  state: {
                    from: path.login,
                  },
                }}
                className="register"
              >
                {registerNow}
              </Link>
            </div>
            <Grid xs={12} className="formDiv">
              <Grid xs={10} sm={7} className="content">
                <Formik
                  enableReinitialize
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={loginValidation}
                  onSubmit={handleForm}
                >
                  {({ handleSubmit }) => (
                    <Form autoComplete="off">
                      <div className="loginTitle">{signIn}</div>
                      <TextInput
                        className={"inputText"}
                        name="email"
                        type="email"
                        labelName={Email}
                        labelClass="label"
                        placeholder={Email}
                      />
                      <TextInput
                        className={"passwordText"}
                        name="password"
                        type={show ? "text" : "password"}
                        labelName={Password}
                        placeholder={Password}
                        labelClass="label"
                        showEye={show}
                        onEye={() => setShow(false)}
                        onEyeOff={() => setShow(true)}
                        colorEye="primary"
                        colorEyeOff="primary"
                      />
                      <div className="forgot">
                        <Link to={path.forgetPassword} className="forgetBtn">
                          {forgotPassword}
                        </Link>
                      </div>
                      {loader ? (
                        <CustomButton
                          className="submitBtn marginTop"
                          onClick={handleSubmit}
                        >
                          <CircularProgress size={26} color="white" />
                        </CustomButton>
                      ) : (
                        <CustomButton
                          title={signIn}
                          className="submitBtn marginTop"
                          onClick={handleSubmit}
                        />
                      )}

                      {/*  <div className="socialBtns">
                      // <IconButton className="bg">
                      //   <FbIcon />
                      // </IconButton>
                      // <IconButton className="bg">
                      //   <Google />
                      // </IconButton> 
                    </div> */}
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        {/* {
          <CustomizedDialogs onClose={openModal} open={openModal}>
            <Confirm_Alert
              del={false}
              onCancel={() => setOpenModal(false)}
              heading={incorretPassword}
              text={enterWrong}
            />
          </CustomizedDialogs>
        } */}
        <AlertBox
          del={false}
          onCancel={() => setOpenModal(false)}
          heading={incorretPassword}
          text={enterWrong}
          onClose={openModal}
          open={openModal}
        />
      </Grid>
    </>
  );
};

export default SignIn;
