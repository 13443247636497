import { mispartes } from "./constants";

export const KEYS = {
  address: "address", //remove in future
  addressId: "addressId", //
  vehicle: "vehicle", // garade local storage
  vehicleId: "vehicleId",
  search: "search", // store serch history
};
export function insertData(data) {
  let address = getAllData();
  data["id"] = generateDataId();
  address.push(data);
  localStorage.setItem(KEYS.address, JSON.stringify(address));
}
export function updateData(data) {
  let address = getAllData();
  let recordIndex = address.find((x) => x.id === data.id);
  address = address.map((i) => {
    if (i === recordIndex) {
      return {
        ...i,
        name: data.name,
        companyName: data.companyName,
        email: data.email,
        phone: data.phone,
        postalCode: data.postalCode,
        address: data.address,
        city: data.city,
        express: data.express,
      };
    }
    return i;
  });

  localStorage.setItem(KEYS.address, JSON.stringify(address));
}
export function generateDataId() {
  if (localStorage.getItem(KEYS.addressId) === null)
    localStorage.setItem(KEYS.addressId, "0");
  var id = parseInt(localStorage.getItem(KEYS.addressId));
  localStorage.setItem(KEYS.addressId, (++id).toString());
  return id;
}

export function getAllData() {
  if (localStorage.getItem(KEYS.address) == null)
    localStorage.setItem(KEYS.address, JSON.stringify([]));
  return JSON.parse(localStorage.getItem(KEYS.address));
}
export function removeData(id) {
  let items = JSON.parse(localStorage.getItem(KEYS.address));
  items = items.filter((item) => item.id !== id);
  localStorage.setItem(KEYS.address, JSON.stringify(items));
  if (items.length === 0) {
    localStorage.removeItem(KEYS.address);
  }
}
// garage Vehicle History
export function insertVehicle(data) {
  let vehicle = getAllVehicle();
  //check if the selected item already exist in garage so don't add it again in localStorage
  const isMatched =
    vehicle &&
    vehicle?.find(
      (item) =>
        item?.make?.value === data?.make?.value &&
        item?.model?.value === data.model?.value &&
        item?.year?.value === data.year?.value
    );
  data["id"] = generateVehicleId();

  let existItemIndex =
    vehicle &&
    vehicle?.findIndex(
      (item) =>
        item?.make?.value === data?.make?.value &&
        item?.model?.value === data.model?.value &&
        item?.year?.value === data.year?.value
    );
  // garge length should be 3 only
  if (!isMatched) {
    if (vehicle.length > 2) {
      vehicle.shift();
      vehicle.push(data);
    } else {
      vehicle.push(data);
    }
    localStorage.setItem(KEYS.vehicle, JSON.stringify(vehicle));
  } else {
    vehicle.splice(existItemIndex, 1);
    vehicle.push(data);
  }
  localStorage.setItem(KEYS.vehicle, JSON.stringify(vehicle));
}
export function getAllVehicle() {
  if (localStorage.getItem(KEYS.vehicle) === null)
    localStorage.setItem(KEYS.vehicle, JSON.stringify([]));

  return JSON.parse(localStorage.getItem(KEYS.vehicle));
}
export function generateVehicleId() {
  if (localStorage.getItem(KEYS.vehicleId) === null)
    localStorage.setItem(KEYS.vehicleId, "-21");
  var id = parseInt(localStorage.getItem(KEYS.vehicleId));
  localStorage.setItem(KEYS.vehicleId, (++id).toString());
  return id;
}
export function removeVehicle(id) {
  let items = JSON.parse(localStorage.getItem(KEYS.vehicle));
  items = items.filter((item) => item.id !== id);
  localStorage.setItem(KEYS.vehicle, JSON.stringify(items));
  if (items.length === 0) {
    localStorage.removeItem(KEYS.vehicle);
  }
}
export function removeAllVehicle() {
  localStorage.removeItem(KEYS.vehicle);
}
// search History
export function insertSearchData(data) {
  let search = getAllHistory();
  if (
    search.find((item) => item.toUpperCase() === data.toUpperCase()) !==
    undefined
  ) {
  } else {
    // history array length would be 10
    if (search.length > 10) {
      search.shift();
      search.push(data);
    } else {
      search.push(data);
    }
    localStorage.setItem(KEYS.search, JSON.stringify(search));
  }
}
export function getAllHistory() {
  if (localStorage.getItem(KEYS.search) === null)
    localStorage.setItem(KEYS.search, JSON.stringify([]));
  return JSON.parse(localStorage.getItem(KEYS.search));
}
export function removeAllHistory() {
  localStorage.removeItem(KEYS.search);
}
// meta tags
export const metaPath = () => {
  var currentLocation = window.location.pathname;
  const i = currentLocation
    .substring(1)
    .replaceAll("/", " ")
    .replaceAll("-", " ")
    .replaceAll("%2B", "-")
    .replaceAll("+", " ");
  const uperCase = i.charAt(0).toUpperCase();
  const r = i.replace(i.charAt(0), uperCase);
  const title = `${r}${"  -  "}${mispartes}`;

  return decodeURI(title);
};
// regexImg
export const regexImg = (img) => {
  if (
    img ===
      "https://mcapimages.blob.core.windows.net/imagenes/Denso/P30024.jpg" ||
    img ===
      "https//mcapimages.blob.core.windows.net/imagenes/Denso/P06017.jpg" ||
    img ===
      "https//mcapimages.blob.core.windows.net/imagenes/Denso/P06020.jpg" ||
    img ===
      "https//mcapimages.blob.core.windows.net/imagenes/Denso/P06010.jpg" ||
    img ===
      "https//mcapimages.blob.core.windows.net/imagenes/Denso/P02001.jpg" ||
    img ===
      "https//mcapimages.blob.core.windows.net/imagenes/Denso/P02001.jpg" ||
    img ===
      "https//mcapimages.blob.core.windows.net/imagenes/Denso/P06001.jpg" ||
    img === "https//mcapimages.blob.core.windows.net/imagenes/Denso/P06019.jpg"
  ) {
    // setting image default bcz these image are not working right now
    const newImg =
      "https//mcapimages.blob.core.windows.net/imagenes/MarcaLogos/Brembo_logo.png";
    const image = newImg.replace("https:", "https");
    const pair = Array.from(image);
    pair.splice(5, 0, ":");
    return pair.join("");
  } else {
    const image = img.replace("https:", "https");
    const pair = Array.from(image);
    pair.splice(5, 0, ":");
    return pair.join("");
  }
};
