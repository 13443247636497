import React from "react";
import { useField } from "formik";
import "./style.scss";
import {
  IconButton,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { colors } from "../../../common/theme";
const TextInput = ({ ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  const id = props.labelName ? props.name : "";
  const labelFor = props.labelName ? props.name : "";
  return (
    <>
      <span className={props.forgetPs ? "forgetInput" : "inputStyle"}>
        {props.labelName && (
          <label className={props.labelClass} for={labelFor}>
            {props.labelName}
          </label>
        )}
        {props.name === "password" ||
        props.name === "cPassword" ||
        props.name === "newPassword" ? (
          <TextField
            id={props.name}
            className={props.className}
            {...field}
            {...props}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {props.showEye ? (
                    <IconButton onClick={props.onEye}>
                      <Visibility color={props.colorEye} />
                    </IconButton>
                  ) : (
                    <IconButton onClick={props.onEyeOff}>
                      <VisibilityOff color={props.colorEyeOff} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <input
                autoComplete="off"
                autoFocus={false}
                className={props.className}
                {...field}
                {...props}
                id={id}
                onBlur={props.onBlur ? (e) => props.onBlur(e.target.value) : ""}
              />
              {props.loading && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <CircularProgress color={colors.lightGray} size={20} />
                </div>
              )}
            </div>
            {props.error ? <div className="error">{props.error}</div> : null}
          </div>
        )}
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </span>
    </>
  );
};
export default TextInput;
