export const colors = {
  primaryColor: "#003D97",
  secondaryColor: "#FF3400",
  baseForground: "#000",
  white: "white",
  green: "#31932A",
  skyBlue: "#32BBFF",
  yellow: "#FC9605",
  borderColor: "#C4C4C4",
  gray: "#F6F6F6",
  black: "black",
  lightGray: "#d8d8d8",
  blockForground: "#828282",
  tableGray: "#EFEFEF",
};
