import React, { useEffect, useState, useContext } from "react";
import { error, mispartes } from "../../common/constants";
import SelectVehicle from "../../Component/SelectVehicle";
import {
  SmallSlider,
  Products,
  BrandSlider,
  MainSlider,
} from "../../Component/index";
import MainHeader from "../../Sections/header";
import { getHomeResponse, getMakers } from "../../utils/api";
import MainLayout from "../../Component/layout/layout";
import { emailCredential, passCredential } from "../../common/constants";
import { useHistory } from "react-router";
import { path } from "../../common/routes-names";
import ApplicationContext from "../../context-api/context";
import support from "../../images/support.png";
import { width } from "@mui/system";
// import Partners from "../partners-image";
import Addition from "../adition";
import { PageView } from "../../utils/fbPixelEvent";

const Home = () => {
  const {
    setMakersList,
    setSearchbarActive,
    alertMsg,
    setAlertMsg,
    setOpenAlert,
    setShowLogInPopModel,
    checkOut,
    setIsLoggedIn,
    setAddMakeList,
  } = useContext(ApplicationContext);
  const history = useHistory();
  const alert_Msg = alertMsg;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(false);
  const check = checkOut;

  const [data, setData] = useState({
    banner: [],
    categories: [],
    brands: [],
    text: "",
    // partnersLogo: [],
    addition: [],
  });
  useEffect(() => {
    PageView();
    getData();
    getMakerList();
    setSearchbarActive(true);
    // setTimeout(() => {}, 6000);
    console.log("useeffect in home ");
  }, []);

  const getMakerList = async () => {
    await getMakers()
      .then((response) => {
        if (response.status === 200) {
          setMakersList(response.data.content);
        }
      })
      .catch((error) => {
        setAlertMsg(error);
        setOpenAlert(true);
      });
  };

  async function getData() {
    await getHomeResponse()
      .then((response) => {
        if (response.status === 200) {
          setData({
            ...data,
            banner: response.data.content.banners,
            categories: response.data.content.categories,
            brands: response.data.content.brands,
            // // partnersLogo: response.data.content.partnerLogos,
            addition: response.data.content.additions,
          });
          setAddMakeList([response.data.content.makers]);
        } else {
          setOpenAlert(true);
          setAlertMsg(response.ExceptionMessage);
        }
      })
      .catch((error) => {
        setOpenAlert(true);
        setAlertMsg(error);
      });
  }

  const checkUserauthentication = () => {
    if (email === emailCredential && password === passCredential) {
      setLoadingLogin(true);
      setTimeout(() => {
        setLoadingLogin(false);
        setShowLogInPopModel(false);
        setIsLoggedIn(true);
        check === "checkout"
          ? history.push(path.checkout)
          : history.push(path.home);
      }, 1500);
    } else {
      console.log("not match ");
    }
  };

  return (
    <div>
      <MainLayout
        metaTitle={`${mispartes}`}
        errorHeading={error}
        errorText={alert_Msg}
      >
        <MainHeader isMenu={true} otherIconOnHeader={true} logoBig={true} />
        <MainSlider array={data.banner} />
        <SelectVehicle
          disableModal
          disableYear
          modal={false}
          carModel={false}
        />
        <SmallSlider array={data.categories} />
        <Products />
        <Addition array={data.addition} />
        <BrandSlider array={data.brands} />
        {/* <Partners array={data.partnersLogo} /> */}
      </MainLayout>
    </div>
  );
};
export default Home;
