import Home from "../Pages/home/index";
import CheckOut from "../Pages/checkout/index";
import SelectedVehicle from "../Pages/product-listing/index";
import ProDescriptionPage from "../Pages/pro-description/index";
import MiCuenta from "../Pages/my-account-screens/mi-cuenta";
import Security from "../Pages/my-account-screens/security/Security";
import Orders from "../Pages/my-account-screens/orders/Orders";
import Billing from "../Pages/my-account-screens/billing/Billing";
import Favourite from "../Pages/my-account-screens/favourite/Favourite";
import Adresses from "../Pages/my-account-screens/adresses/index";
import SignIn from "../Pages/login/signIn";
import SignUp from "../Pages/login/signUp";
import Token from "../Pages/token/index";
import ForgetPassword from "../Pages/login/forgetPass";
import ResetPassword from "../Pages/login/resetPass";
import OrderConfirmation from "../Pages/order-confirmation/index";
import MercadoPago from "../Pages/mercado-pago/index";
import TokenForMobile from "../Pages/token/index";
import MobileCheckout from "../Pages/mobile-checkout";
import EmailVerification from "../Pages/email-verification";
import TermsAndCondition from "../Pages/terms-and-condition";
import NoticeOfPrivacy from "../Pages/notice-of-privacy";
import AboutUs from "../Pages/about-us";
import WelcomePage from "../Pages/welcome";
import { whyMyParts } from "./constants";
import stickyBar from "../Pages/sticky-bar";
import Faq from "../Pages/faq";
import PageNotFound from "../Component/confirm-alert/error404";
export const path = {
  home: "/",
  emailVerification: "/email-verification",
  checkout: "/checkout",
  categoryName: "/:categoryName.html",
  subVehicle: ":categoryName-:subName.html",
  vehicleName: ":categoryName-:make-:model-:year.html",
  allVehicle: ":categoryName-:subName-:make-:model-:year.html",
  proDescription: "/:brandName/:productName-:partNum.html", // for feature product
  productDetail: "/:vehicle/:category/:productName-:partNum.html",
  myAccount: "/cuenta",
  security: "/cuenta/security",
  orders: "/cuenta/orders",
  billing: "/cuenta/billing",
  address: "/cuenta/address",
  favourite: "/cuenta/favourite",
  login: "/sign-in",
  signUp: "/sign-up",
  forgetPassword: "/forget-password",
  resetPassword: "/reset-password",
  orderConfirmation: "/order-confirmation",
  mercadoPago: "/mercadoPago",
  token: "/createCardToken",
  mobileCheckout: "/mobilecheckout",
  termsAndCondition: "/términos-y-Condiciones",
  noticeOfPrivacy: "/aviso-de-privacidad",
  aboutUs: "/Quienes-Somos",
  whyMyParts: "/Quienes-Somos",
  stickyBar: "/propuesta-de-valor",
  faq: "/PreguntasFrecuentes",
  notFound: "/404",
  welcome: "/welcome",
};
export const routes = {
  home: "Home",
  emailVerification: "emailVerification",
  vehicleName: "VehicleName",
  subVehicle: "SubVehicle",
  proDescription: "Pro-description",
  signIn: "SignIn",
  signUp: "SignUp",
  forgetPassword: "ForgetPassword",
  resetPassword: "ResetPassword",
  checkout: "Checkout",
  myAccount: "Cuenta",
  security: "Security",
  orders: "Orders",
  billing: "Billing",
  address: "Address",
  favourite: "Favourite",
  categoryName: "CategoryName",
  allVehicle: "AllVehicle",
  productDetail: "ProductDetail",
  orderConfirmation: "OrderConfirmation",
  mercadoPago: "MercadoPago",
  token: "Token",
  mobileCheckout: "mobilecheckout",
  termsAndCondition: "termsAndCondition",
  noticeOfPrivacy: "noticeOfPrivacy",
  aboutUs: "aboutUs",
  stickyBar: "stickyBar",
  faq: "Faq",
  error: "Error",
  welcome: "Welcome",
};
export const privateRoute = {
  [routes.home]: {
    name: "Home",
    path: path.home,
    component: Home,
  },
  [routes.vehicleName]: {
    name: "VehicleName",
    path: path.vehicleName,
    component: SelectedVehicle,
  },
  [routes.subVehicle]: {
    name: "SubVehicle",
    path: path.subVehicle,
    component: SelectedVehicle,
  },
  [routes.categoryName]: {
    name: "CategoryName",
    path: path.categoryName,
    component: SelectedVehicle,
  },
  [routes.allVehicle]: {
    name: "AllVehicle",
    path: path.allVehicle,
    component: SelectedVehicle,
  },
  [routes.proDescription]: {
    name: "Pro-description",
    path: path.proDescription,
    component: ProDescriptionPage,
  },
  [routes.productDetail]: {
    name: "ProductDetail",
    path: path.productDetail,
    component: ProDescriptionPage,
  },
  [routes.checkout]: {
    name: "Checkout",
    path: path.checkout,
    component: CheckOut,
  },
  [routes.security]: {
    name: "Security",
    path: path.security,
    component: Security,
  },
  [routes.billing]: {
    name: "Billing",
    path: path.billing,
    component: Billing,
  },
  [routes.address]: {
    name: "Address",
    path: path.address,
    component: Adresses,
  },
  [routes.favourite]: {
    name: "Favourite",
    path: path.favourite,
    component: Favourite,
  },
  [routes.orders]: {
    name: "Orders",
    path: path.orders,
    component: Orders,
  },
  [routes.orderConfirmation]: {
    name: "OrderConfirmation",
    path: path.orderConfirmation,
    component: OrderConfirmation,
  },
  [routes.myAccount]: {
    name: "Cuenta",
    path: path.myAccount,
    component: MiCuenta,
  },
  [routes.mercadoPago]: {
    name: "mercadoPago",
    path: path.mercadoPago,
    component: MercadoPago,
  },
  [routes.token]: {
    name: "Token",
    path: path.token,
    component: TokenForMobile,
  },
  [routes.mobileCheckout]: {
    name: "mobileCheckout",
    path: path.mobileCheckout,
    component: MobileCheckout,
  },
  [routes.emailVerification]: {
    name: "EmailVerification",
    path: path.emailVerification,
    component: EmailVerification,
  },
  [routes.resetPassword]: {
    name: "ResetPassword",
    path: path.resetPassword,
    component: ResetPassword,
  },
  [routes.termsAndCondition]: {
    name: "termsAndCondition",
    path: path.termsAndCondition,
    component: TermsAndCondition,
  },
  [routes.noticeOfPrivacy]: {
    name: "noticeOfPrivacy",
    path: path.noticeOfPrivacy,
    component: NoticeOfPrivacy,
  },
  [routes.aboutUs]: {
    name: "aboutUs",
    path: path.aboutUs,
    component: AboutUs,
  },
  [routes.stickyBar]: {
    name: "stickyBar",
    path: path.stickyBar,
    component: stickyBar,
  },
  [routes.faq]: {
    name: "faq",
    path: path.faq,
    component: Faq,
  },
  [routes.error]: {
    name: "error",
    path: path.notFound,
    component: PageNotFound,
  },
  [routes.welcome]: {
    name: "welcome",
    path: path.welcome,
    component: WelcomePage,
  },
};

export const publicRoute = {
  [routes.home]: {
    name: "Home",
    path: path.home,
    component: Home,
  },
  [routes.emailVerification]: {
    name: "EmailVerification",
    path: path.emailVerification,
    component: EmailVerification,
  },
  [routes.vehicleName]: {
    name: "VehicleName",
    path: path.vehicleName,
    component: SelectedVehicle,
  },
  [routes.subVehicle]: {
    name: "SubVehicle",
    path: path.subVehicle,
    component: SelectedVehicle,
  },
  [routes.categoryName]: {
    name: "CategoryName",
    path: path.categoryName,
    component: SelectedVehicle,
  },
  [routes.allVehicle]: {
    name: "AllVehicle",
    path: path.allVehicle,
    component: SelectedVehicle,
  },
  [routes.proDescription]: {
    name: "Pro-description",
    path: path.proDescription,
    component: ProDescriptionPage,
  },
  [routes.productDetail]: {
    name: "ProductDetail",
    path: path.productDetail,
    component: ProDescriptionPage,
  },
  [routes.signIn]: {
    name: "SignIn",
    path: path.login,
    component: SignIn,
  },
  [routes.signUp]: {
    name: "SignUp",
    path: path.signUp,
    component: SignUp,
  },
  [routes.forgetPassword]: {
    name: "ForgetPassword",
    path: path.forgetPassword,
    component: ForgetPassword,
  },
  [routes.resetPassword]: {
    name: "ResetPassword",
    path: path.resetPassword,
    component: ResetPassword,
  },
  [routes.orderConfirmation]: {
    name: "OrderConfirmation",
    path: path.orderConfirmation,
    component: OrderConfirmation,
  },
  [routes.mercadoPago]: {
    name: "mercadoPago",
    path: path.mercadoPago,
    component: MercadoPago,
  },
  [routes.token]: {
    name: "Token",
    path: path.token,
    component: Token,
  },
  [routes.mobileCheckout]: {
    name: "mobileCheckout",
    path: path.mobileCheckout,
    component: MobileCheckout,
  },
  [routes.termsAndCondition]: {
    name: "termsAndCondition",
    path: path.termsAndCondition,
    component: TermsAndCondition,
  },
  [routes.noticeOfPrivacy]: {
    name: "noticeOfPrivacy",
    path: path.noticeOfPrivacy,
    component: NoticeOfPrivacy,
  },
  [routes.aboutUs]: {
    name: "aboutUs",
    path: path.aboutUs,
    component: AboutUs,
  },
  [routes.whyMyParts]: {
    name: "whyMyparts",
    path: path.whyMyParts,
    component: AboutUs,
  },
  [routes.stickyBar]: {
    name: "stickyBar",
    path: path.stickyBar,
    component: stickyBar,
  },
  [routes.faq]: {
    name: "faq",
    path: path.faq,
    component: Faq,
  },
  [routes.error]: {
    name: "error",
    path: path.notFound,
    component: PageNotFound,
  },
  [routes.welcome]: {
    name: "welcome",
    path: path.welcome,
    component: WelcomePage,
  },
};
