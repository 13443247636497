import React from "react";
import { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { registerUser } from "../../../utils/api";
import { path } from "../../../common/routes-names";
import ApplicationContext from "../../../context-api/context";
import { completeRegistraionPixel } from "../../../utils/fbPixelEvent";

function useForm() {
  const { setIsFromRegisteration, setIsLoggedIn } =
    useContext(ApplicationContext);
  const [loading, setloading] = React.useState(false);
  const [errorMsg, setError] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);

  const history = useHistory();
  const location = useLocation();
  const handleForm = async (values) => {
    try {
      setloading(true);
      let registerData = {
        userName: values.email,
        password: values.password,
        name: values.name,
        phoneNumber: values.phoneNumber.toString(),
      };

      if (registerData) {
        const response = await registerUser(registerData);
        if (response && response?.data?.success === true) {
          setIsFromRegisteration(true);
          completeRegistraionPixel();
          const {
            data: {
              content: { token, user },
            },
          } = response;
          localStorage.setItem("sessionToken", `bearer ${token}`);
          localStorage.setItem("UserData", JSON.stringify(user));
          const { state } = location;
          if (state) {
            if (state.from === path.login) {
              history.go(-2);
              setIsLoggedIn(true);
            } else {
              history.goBack();
              setIsLoggedIn(true);
            }
          } else {
            history.goBack();
            setIsLoggedIn(true);
          }
          setloading(false);
        } else if (response && response?.data?.success === false) {
          setError(response.data.description);
          setOpenModal(true);
          setloading(false);
        }
      }
    } catch (error) {
      alert(error);
      console.log(error);
    }
  };
  return {
    handleForm,
    loading,
    setloading,
    errorMsg,
    openModal,
    setOpenModal,
  };
}

export default useForm;
