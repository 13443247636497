import React, { useState, useEffect, useContext } from "react";
import { path } from "../../../common/routes-names";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../Redux/reducers/authSlice";
import ApplicationContext from "./../../../context-api/context";

import { emailCredential, passCredential } from "../../../common/constants";
import { loginUser } from "./../../../utils/api/index";

const useForm = (prop) => {
  const {
    setIsLoggedIn,
    isFromRegisteration,
    setIsFromRegisteration,
    checkOut,
  } = useContext(ApplicationContext);
  const check = checkOut;
  // const check = useSelector((state) => state.alert.checkOutTrue);
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleForm = async (values, setLoginError) => {
    setLoader(true);
    if (!values.email == "" && !values.password == "") {
      const response = await loginUser({
        userName: values.email,
        password: values.password,
      });
      if (response && response.data) {
        const {
          data: { success, description, content },
        } = response;
        if (!success) {
          setLoader(false);
          setOpenModal(true);
          return false;
        }
        if (content) {
          const { token, user } = content;
          localStorage.setItem("sessionToken", `bearer ${token}`);
          localStorage.setItem("UserData", JSON.stringify(user));
          setLoader(false);
          if (isFromRegisteration) {
            history.go(-2);
          } else {
            const { state } = location;
            if (state?.from === path.signUp) {
              history.push(path.home);
            } else {
              history.goBack();
            }
          }
          // setIsLoggedIn(true);
        }
      }
    } else {
      setLoader(false);
    }
  };

  return {
    show,
    setShow,
    loader,
    setLoader,
    openModal,
    setOpenModal,
    handleForm,
  };
};

export default useForm;
