import React, { useState } from "react";
import "../Header.scss";
import { CustomButton } from "../../index";
import {
  addVehicle,
  clearAll,
  myGarage,
  findParts,
  receiveProduct,
  assigned,
} from "../../../common/constants";
import { arrowRight } from "../../../common/Images";
import { ReactComponent as FindIcon } from "../../../images/findParts.svg";
import { ReactComponent as RecommandIcon } from "../../../images/recommand.svg";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { add_VehicleIntoUrl } from "../../../Redux/reducers/filterSlice";

const GarageMenu = ({
  onAddVehicle,
  isLogin,
  garageList,
  onDelete,
  clearGarage,
  setGarageLength,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let uniqueGarageList;
  if (garageList && garageList !== null && garageList !== undefined) {
    uniqueGarageList =
      garageList &&
      garageList?.reduce((previous, current) => {
        const isMatched = previous.find(
          (item) =>
            item?.make?.value === current?.make?.value &&
            item?.model?.value === current?.model?.value &&
            item?.year?.value === current?.year?.value
        );
        if (!isMatched) {
          return previous.concat([current]);
        } else {
          return previous;
        }
      }, []);
    setGarageLength(uniqueGarageList.length);
  }

  return (
    <>
      {isLogin ? (
        <div className="userPopUp wLogin">
          <div className="inner pb-1">
            <div className="garageWrapper">
              <div className="garageHeader">
                <span className="heading">{myGarage}</span>
                <CustomButton
                  title={clearAll}
                  className="text"
                  onClick={clearGarage}
                />
              </div>
              <div className="scrollGarage">
                {uniqueGarageList
                  .map((i, index, array) => {
                    const encodeMake = i?.make?.value?.replaceAll("-", "+");
                    const encodeModel = i?.model?.value?.replaceAll("-", "+");
                    return (
                      <div
                        className="SelectedProduct"
                        key={index}
                        tabIndex="-1"
                      >
                        {index === array && array.length - 1 && (
                          <div className="tagg">{assigned}</div>
                        )}
                        <a
                          className="name"
                          onClick={() => {
                            dispatch(add_VehicleIntoUrl(i));
                            history.push(
                              `/${encodeURIComponent(
                                encodeMake
                              )}-${encodeURIComponent(
                                encodeModel
                              )}-${encodeURIComponent(i?.year?.value)}.html`
                            );
                          }}
                        >
                          {`${i?.make?.value}${" "}${i?.model?.value}${" "}${
                            i?.year?.value
                          }`}{" "}
                        </a>
                        <div
                          style={{ width: "1rem", marginRight: ".5rem" }}
                          onClick={() => {
                            dispatch(add_VehicleIntoUrl(i));
                            history.push(
                              `/${encodeURIComponent(
                                encodeMake
                              )}-${encodeURIComponent(
                                i.model.value
                              )}-${encodeURIComponent(i.year.value)}.html`
                            );
                          }}
                        >
                          <img className="arrowRight" src={arrowRight}></img>
                        </div>
                        <div className="trashBtn">
                          <span
                            onClick={() => onDelete(i.id)}
                            className="delIcon far fa-trash-alt"
                          ></span>
                        </div>
                      </div>
                    );
                  })
                  .reverse()}
              </div>
            </div>
            <CustomButton
              title={addVehicle}
              className="registrate mt-4"
              onClick={onAddVehicle}
            />
          </div>
        </div>
      ) : (
        <div className="userPopUp">
          <div className="inners">
            <CustomButton
              title={addVehicle}
              className="registrate"
              onClick={onAddVehicle}
            />
            <p className="nonLoginText border-bottom">
              <span>
                <FindIcon />
              </span>
              <span className="findText">{findParts}</span>
            </p>
            <p className="nonLoginText mb-0 pb-0">
              <span>
                <RecommandIcon />
              </span>
              <span className="findText">{receiveProduct}</span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default GarageMenu;
