import React from 'react';
import Images from '../imgLoader';
import Rating_Star from '../ratingStar';
import './style.scss';

const RatingCards = ({
  userImg,
  userName,
  country,
  postedDate,
  remarksHeading,
  remarksDetail,
  ratingPoints,
}) => {
  return (
    <>
      <div className="ratingCard">
        <div className="ratingCardInner">
          <div className="ratingCardHeader">
            <div className="left">
              <div className="userProfile">
                <Images src={userImg} />
              </div>
              <div className="userDetail">
                <p className="userName">{userName}</p>
                <p className="subHeading">{country}</p>
              </div>
            </div>
            <span className="postedDate">{postedDate}</span>
          </div>
          <div className="ratingStars">
            <Rating_Star _ratingValue={ratingPoints} />
          </div>
          <h4 className="remarksHeading">{remarksHeading}</h4>
          <p className="remarksDetails">{remarksDetail}</p>
        </div>
      </div>
    </>
  );
};
export default RatingCards;
