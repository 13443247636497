import React from 'react';
import { Rating } from '@material-ui/lab';
import StarIcon from '@material-ui/icons/Star';
const Rating_Star = ({ _ratingValue, className }) => {
  return (
    <>
      <Rating
        className={className}
        name="half-rating"
        defaultValue={_ratingValue}
        precision={0.5}
        readOnly
        emptyIcon={<StarIcon style={{ opacity: 0.8 }} fontSize="inherit" />}
      />
    </>
  );
};

export default Rating_Star;
