import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { error, featureProduct } from "../../common/constants";
import { getHomeResponse } from "../../utils/api";
import AlertBox from "../confirm-alert/alertBox";
import ProductSkelton from "./productSkelton";
import Product from "./sigleProduct";
import "./style.scss";

const Products = ({ data }) => {
  let history = useHistory();
  const [products, setProducts] = useState([]);
  const [errorMsg, setError] = useState("");
  const [load, setload] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    if (data) {
      setProducts(data);
      setload(false);
    } else {
      getproducts();
    }
  }, []);
  async function getproducts() {
    setload(true);
    await getHomeResponse()
      .then((response) => {
        if (response.status === 200) {
          setload(false);
          setProducts(response.data.content.products);
        } else {
          setOpenModal(true);
          setError(response.ExceptionMessage);
        }
      })
      .catch((error) => {
        setOpenModal(true);
        setError(error);
      });
  }
  return (
    <>
      <section className="container add productDeshboard">
        <h1>{featureProduct}</h1>
        {!load ? (
          <>
            {products.map((i, index) => {
              return (
                <Product
                  productObject={i}
                  productId={i.productID}
                  key={index}
                  deal={i.hasDeal}
                  offer={i.dealText}
                  productImg={i.productImageURL}
                  desText={i.productDescription}
                  orignalPrice={`${"$"}${i.regularPrice}`}
                  discountPrice={`${"$"}${i.price}`}
                  serialNumber={i.partNumber}
                  shippingCost={`${"$"}${i.shippingCost}`}
                  availablePices={i.stock}
                  brandLogo={i.brandURL_Small}
                  uom={i.uom}
                  quantity={i.quantity ?? 0}
                  onClick={() => {
                    if (!isDisabled) {
                      history.push({
                        pathname: `/${"yakima"}/${i.productDescription.replaceAll(
                          " ",
                          "-"
                        )}-${i.partNumber}.html`,
                        search: "?id=" + "xsXTAQBwBJ7eQkOLSppubg==",
                        state: {
                          data: "xsXTAQBwBJ7eQkOLSppubg==",
                          // data: i.productID,
                        },
                      });
                    }
                  }}
                />
              );
            })}
          </>
        ) : (
          <>
            {[1, 2, 3, 4, 5].map((i) => {
              return <ProductSkelton key={i} />;
            })}
          </>
        )}
        <AlertBox
          del={false}
          onCancel={() => setOpenModal(false)}
          heading={error}
          text={errorMsg}
          onClose={openModal}
          open={openModal}
        />
      </section>
    </>
  );
};
export default Products;
