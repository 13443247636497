import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, product } from "../../common/constants";
import "./styles.scss";
import { CustomButton } from "..";
import { numericOnly } from "../../common/formValidation";
import { BiPlus } from "react-icons/bi";
import { IconButton } from "@material-ui/core";
import { FaRegTrashAlt } from "react-icons/fa";
import { HiMinus } from "react-icons/hi";
import { addItemToCart } from "../../utils/api";
import ApplicationContext from "./../../context-api/context";
import { CircularProgress } from "@material-ui/core";
import { addToCartPixel } from "../../utils/fbPixelEvent";

export const QuantityButton = ({
  quantity,
  productId,
  onQuantityIncreased,
  onQuantityDecreased,
  quantityChanged,
  onQuantityChange,
  fullWidth,
  disableTrash,
  detailView,
  productObject,
  openLoginPopup,
  isDisabled,
  favrtView,
  favrtMenuView,
}) => {
  const dispatch = useDispatch();
  const {
    isLoggedIn,
    addUpdateCartItem,
    getTheUpdateProduct,
    cartList,
    setIsOpenLoginModel,
    alertMsg,
    setAlertMsg,
    openAlert,
    setOpenAlert,
  } = useContext(ApplicationContext);
  // if (productObject) productObject.quantity = productObject.quantity || 0;
  // else productObject = {};
  const [productDetail, setProductDetail] = useState(
    getTheUpdateProduct(productId) || productObject
  );
  const [focus, setFocus] = useState(false);
  const [cartActionBtnClickable, setCartActionBtnClickable] = useState(true);
  const [cartQuantity, setCartQuantity] = useState(quantity);
  const [chracter, setChracter] = useState(null);
  let cartObj = {};

  const handleOnChange = (e) => {
    const { value } = e.target;
    if (numericOnly.test(value) || value === "") {
      if (!chracter) {
        setChracter(value);
      }
      setCartQuantity(value);
    }
  };

  const disabledQuantity = !cartActionBtnClickable
    ? " QuantityNonClickable"
    : "";

  const OnCartClick = (opType) => {
    addToCartPixel(productId);
    getTheUpdateProduct(productId);
    if (!isLoggedIn) {
      setIsOpenLoginModel(true);
      return false;
    }

    cartObj = {
      productID: parseInt(productId),
      quantity: -1,
      operationType: opType,
      vehicleID: 0,
    };
    setCartActionBtnClickable(false);
    addCartItem(false, opType);
  };
  const handleOnBlur = async (e) => {
    if (cartQuantity === "") {
      setCartQuantity(quantity);
      return;
    }
    if (onQuantityChange) {
      onQuantityChange(parseInt(cartQuantity), productId);
    }
    cartObj = {
      productID: parseInt(productId),
      quantity: parseInt(cartQuantity ? cartQuantity : 0),
      operationType: 1,
      vehicleID: 0,
    };

    setCartActionBtnClickable(false);
    addCartItem(true, cartObj);
    setFocus(false);
  };
  const addCartItem = async (onblur, opType) => {
    return await addItemToCart(cartObj)
      .then((response) => {
        if (response.status === 200) {
          if (onblur) {
            quantityChanged(cartObj.quantity);
            const temp = { ...productDetail };
            temp.quantity = cartObj.quantity;
            // setCartActionBtnClickable(true);
            setProductDetail(temp);
            addUpdateCartItem(temp);
          } else {
            if (quantity === "") {
              quantityChanged(1);
            } else {
              if (opType === 0) {
                onQuantityDecreased(parseInt(quantity) - 1);
              } else {
                onQuantityIncreased(parseInt(quantity) + 1);
              }
            }
            if (response && response.data) {
              const { data } = response;
              if (data.success) {
                const temp = { ...productDetail };
                if (opType === 0) {
                  temp.quantity = parseInt(quantity) - 1;
                } else {
                  temp.quantity = parseInt(quantity) + 1;
                }
                // setCartActionBtnClickable(true);
                setProductDetail(temp);
                addUpdateCartItem(temp);
              }
            }
          }
          setCartActionBtnClickable(true);
        } else {
          setOpenAlert(true);
          setAlertMsg(response.exceptionMessage);
          // dispatch(openAlert());
          // dispatch(alertMsg(response.exceptionMessage));
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setProductDetail(getTheUpdateProduct(productId) || productObject);
  }, []);

  useEffect(() => {
    setCartQuantity(quantity);
  }, [cartList, quantity]);

  return (
    <div>
      {!focus &&
      (cartList.length === 0 || quantity == 0 || quantity === undefined) ? (
        <CustomButton
          onClick={() => {
            OnCartClick(1);
          }}
          className={
            detailView === true
              ? "addToCartBtnOutline"
              : fullWidth === true
              ? "addToCartBtn"
              : favrtView === true
              ? "favrtAddToCartButton"
              : favrtMenuView === true
              ? " buyTo links"
              : "addToCart"
          }
          title={
            !cartActionBtnClickable ? <CircularProgress size={18} /> : addToCart
          }
          isDisabled={isDisabled}
        ></CustomButton>
      ) : (
        <div
          className={
            detailView === true
              ? "proQuantityBtn2"
              : fullWidth === true
              ? "proQuantityBtn2 "
              : favrtView === true
              ? "favrtQuantityBtn"
              : favrtMenuView === true
              ? "favrtMenuQuantityBtn"
              : "proQuantityBtn3" + disabledQuantity
          }
        >
          {isLoggedIn && cartQuantity === 1 ? (
            <IconButton
              onClick={() => (cartActionBtnClickable ? OnCartClick(0) : "")}
              className="decBtn2"
            >
              <FaRegTrashAlt className="_trash" />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => (cartActionBtnClickable ? OnCartClick(0) : "")}
              className="decBtn2"
            >
              <HiMinus className="_icons" />
            </IconButton>
          )}
          <span className="qty2">
            {cartActionBtnClickable ? (
              <input
                type="number"
                name="cartQuantity"
                min={0}
                value={cartQuantity}
                onChange={handleOnChange}
                onKeyDown={(e) =>
                  (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
                }
                onBlur={handleOnBlur}
                onFocus={() => setFocus(true)}
              />
            ) : (
              <CircularProgress size={16} color="white" />
            )}
          </span>
          <IconButton
            onClick={() => (cartActionBtnClickable ? OnCartClick(1) : "")}
            className="incBtn2"
          >
            <BiPlus className="_icons" />
          </IconButton>
        </div>
      )}
    </div>
  );
};
