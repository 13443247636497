import React from "react";
import CustomizedDialogs from "../../Component/customModal/Modal";
import { LoginModelPop } from "../index";
import "./style.scss";
import { useSelector } from "react-redux";

export const ModelLogin = ({ email, password, onPress, loading, showPopup , error}) => {
  return (
    <CustomizedDialogs open={showPopup} className="Model">
      <LoginModelPop
        email={email}
        pass={password}
        onPress={onPress}
        loading={loading}
        error={error}
      />
    </CustomizedDialogs>
  );
};
