import React, { useContext, useEffect } from "react";
import Content from "./content";
import { Footer, MetaTitle } from "../../Component";
import MainHeader from "../../Sections/header";
import { metaPath } from "../../common/services";
import ApplicationContext from "../../context-api/context";

export default function TermsAndCondition() {
  const { setIsSticky } = useContext(ApplicationContext);
  useEffect(() => {
    setIsSticky(false);
  }, []);
  return (
    <div>
      <MetaTitle title={metaPath()} />
      <MainHeader otherIconOnHeader={true} isMenu={false} logoBig={false} />
      <Content />
      <Footer />
    </div>
  );
}
