// select- block is basically 3 boxes with add/edit button on checkout screen (route = /checkout)
//Dirección De Envío:
// method the pago
// Factuation

import React, { useContext, useEffect, useState, useRef } from "react";
import "./style.scss";
import { CustomButton } from "..";
import {
  addAddressBtn,
  addBillingBtn,
  addPaymentBtn,
  debitCardPayment,
  invoicesRquired,
  paymentMethodRquired,
  shipmentAddressRquired,
  TokenKey,
} from "../../common/constants";
import { cvv } from "../../common/constant/formConstants";
import ApplicationContext from "../../context-api/context";
import { Formik, Form, Field } from "formik";
import { TextInput } from "..";
import {
  cvvValidation,
  taxRegimenValidation,
} from "../../common/formValidation";
import { useMercadopago } from "react-sdk-mercadopago";

const SelectBlock = ({
  defaultAddress,
  defaultCard,
  defaultInvoice: billingAddress,
  icon,
  onClick,
  btnName,
  marcadoImg,
  ...props
}) => {
  const formRef = useRef();
  const cvvFormRef = useRef();

  const mercadopago = useMercadopago.v2(TokenKey, {
    locale: "en-US",
  });

  const updateCVV = async (input) => {
    const newPaymentMethod = {
      ...selectedCheckoutPayment,
    };

    if (input) {
      const cardObject = {
        cardId: selectedCheckoutPayment.id,
        securityCode: input,
      };
      const cardObj = await createToken(cardObject);
      if (cardObj) {
        newPaymentMethod.CardToken = cardObj;
        newPaymentMethod.CardPaymentTypeID = selectedCheckoutPayment.cardBrand;
      }
    } else {
      newPaymentMethod.CardToken = "";
      newPaymentMethod.CardPaymentTypeID = "";
    }

    setSelectedCheckoutPayment(newPaymentMethod);
  };

  const handleTaxRegimen = (input) => {
    const newUserInfo = {
      ...selectedCheckoutBillingAddreess,
    };
    newUserInfo.name = input;
    setSelectedCheckoutBillingAddreess(newUserInfo);
  };

  const [isShipmentAddressEmpty, setIsShipmentAddressEmpty] = useState(false);
  const [isInvoicesEmpty, setIsInvoicesEmpty] = useState(false);
  const [isPaymentMethodEmpty, setIsPaymentMethodEmpty] = useState(false);
  const {
    purchaseReasonInvoiceList,
    checkoutFormValidation,
    setCheckoutFormValidation,
    setSelectedCheckoutPayment,
    selectedCheckoutPayment,
    setSelectedCheckoutBillingAddreess,
    selectedCheckoutShippingAddreess,
    selectedCheckoutBillingAddreess,
    setAlertMsg,
    setOpenAlert,
  } = useContext(ApplicationContext);

  useEffect(() => {
    // validation on checkout screen (ShipmenAdderess , payment method , Invoices should not be empty )
    if (checkoutFormValidation) {
      if (selectedCheckoutBillingAddreess?.id !== -1) {
        // Check Form for Purchase Reason
        if (formRef.current) {
          const checkoutBillingAddress = {
            ...selectedCheckoutBillingAddreess,
          };
          if (formRef.current.values) {
            checkoutBillingAddress.UsoCFDI = formRef.current.values.taxregimen; // if drop down contains value then should set the drop down state
          } else {
            checkoutBillingAddress.UsoCFDI = "";
          }
          setSelectedCheckoutBillingAddreess(checkoutBillingAddress);
          formRef.current.handleSubmit();
          if (formRef.current.errors) {
            // if drop down doesnot contains value then it dispalys error
            setCheckoutFormValidation(false);
          }
        }
      }
      // validation for shipment address not send an Empty
      if (selectedCheckoutShippingAddreess === null) {
        setIsShipmentAddressEmpty(true);
        setCheckoutFormValidation(false);
      } else setIsShipmentAddressEmpty(false);
      // validation for Invoices  not send an Empty
      if (selectedCheckoutBillingAddreess === null) {
        setIsInvoicesEmpty(true);
        setCheckoutFormValidation(false);
      } else setIsInvoicesEmpty(false);
      // check if there is no payment method selected
      if (selectedCheckoutPayment === null) {
        setIsPaymentMethodEmpty(true);
        setCheckoutFormValidation(false);
      } else setIsPaymentMethodEmpty(false);
      // check for CVV
      if (
        selectedCheckoutPayment?.paymentType === 1 &&
        !selectedCheckoutPayment?.CardToken
      ) {
        if (cvvFormRef.current) {
          cvvFormRef.current.handleSubmit();
          if (cvvFormRef.current.errors) {
            setCheckoutFormValidation(false);
          }
        }
      }
    }
  }, [
    checkoutFormValidation,
    selectedCheckoutPayment,
    setCheckoutFormValidation,
  ]);

  const createToken = async (paymentObject) => {
    try {
      const cardToken = await mercadopago.createCardToken(paymentObject);
      return cardToken.id;
    } catch (error) {
      setAlertMsg(error[0]?.message || "something went wrong with payment");
      setOpenAlert(true);
    }
  };

  return (
    <div className="tileBody">
      <div className="icon">
        <img src={icon} />
      </div>
      <div className="itemDiv">
        {defaultAddress && (
          <div className="detailText">
            <div>
              <span className="title">
                {defaultAddress.name} ({defaultAddress.zipCode}){" "}
              </span>
            </div>
            <div>
              <span> {defaultAddress.fullAdress}</span>
            </div>
          </div>
        )}

        {billingAddress && (
          <div className="detailText">
            {billingAddress.id === -1 && (
              <div>
                <span className="title">{billingAddress.name}</span>
              </div>
            )}
            {/* Invoices box on the Checkout-Screen  with dropdown of purchase reason */}
            {billingAddress.id !== -1 && (
              <div>
                <div>
                  <span className="title">{"RFC: "}</span>{" "}
                  {billingAddress.taxID}
                </div>
                <div>
                  <span className="title">{"Razon Social: "}</span>{" "}
                  {billingAddress.name}
                </div>
                <div>
                  <span className="title">{"Direccion: "}</span>{" "}
                  {billingAddress.taxAddress}
                </div>
                <div>
                  <span className="title">{"Uso De CFDI: "}</span>
                  {
                    <Formik
                      validateOnChange={true}
                      validateOnBlur={true}
                      initialValues={{
                        id: new Date().getTime().toString(),
                        taxregimen: "",
                      }}
                      validationSchema={taxRegimenValidation}
                      onSubmit={(values, actions) => {}}
                      innerRef={formRef}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        setFieldValue,
                      }) => (
                        <Form>
                          <div
                            className={
                              props.checkout ? "checkoutRow" : "inputRow"
                            }
                          >
                            <div>
                              <Field name="taxregimen">
                                {({ field, form, meta }) => (
                                  <select
                                    name="taxregimen"
                                    value={values.taxregimen}
                                    className="custom-select-checkout custom-select custom-select-md"
                                    onChange={(e) => {
                                      const checkoutBillingAddress = {
                                        ...selectedCheckoutBillingAddreess,
                                      };
                                      handleChange(e);
                                      setFieldValue(
                                        "taxregimen",
                                        e.target.value
                                      );
                                      if (e.target.value) {
                                        checkoutBillingAddress.UsoCFDI =
                                          e.target.value;
                                      } else {
                                        checkoutBillingAddress.UsoCFDI = "";
                                      }
                                      setSelectedCheckoutBillingAddreess(
                                        checkoutBillingAddress
                                      );
                                    }}
                                  >
                                    <option value="" disabled selected hidden>
                                      Seleccione
                                    </option>
                                    {purchaseReasonInvoiceList &&
                                      purchaseReasonInvoiceList.map(
                                        (option) => {
                                          return (
                                            <option
                                              key={option.id}
                                              value={option.id}
                                            >
                                              {option.name}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                )}
                              </Field>
                              <br />
                              {errors.taxregimen && (
                                <span className="error">
                                  {errors.taxregimen}
                                </span>
                              )}
                            </div>

                            {/* <GenricDropDown name="taxregimen" dataSet={purchaseReasonInvoiceList} handleValue={handleTaxRegimen}  /> */}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  }
                </div>
              </div>
            )}
          </div>
        )}
        {/* Payment type  box on the Checkout-Screen  with CVV option */}
        {defaultCard && (
          <div className="detailText">
            <div className="mt-2">
              {defaultCard.paymentType === 1 && (
                <div>
                  <span className="title">
                    {defaultCard.paymentType === 1
                      ? "Tarjeta - " + defaultCard.name
                      : defaultCard.name}
                  </span>
                  {!defaultCard.CardToken && (
                    <Formik
                      validateOnChange={true}
                      validateOnBlur={true}
                      initialValues={{
                        id: new Date().getTime().toString(),
                        cvv: "",
                      }}
                      validationSchema={cvvValidation}
                      innerRef={cvvFormRef}
                    >
                      {({ handleSubmit, handleChange }) => (
                        <Form>
                          <div
                            className={
                              props.checkout ? "checkoutRow" : "inputRow"
                            }
                          >
                            <TextInput
                              className={
                                props.checkout
                                  ? "inputCheckout"
                                  : "textInputCheckout"
                              }
                              name="cvv"
                              type="number"
                              placeholder={cvv}
                              maxlength="4"
                              onBlur={updateCVV}
                              onInput={(e) => {
                                if (e.target.value.length == 4)
                                  updateCVV(e.target.value);
                                return (e.target.value = e.target.value.slice(
                                  0,
                                  4
                                )); // vaidation to get only numbers from the user
                              }}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              )}
              {defaultCard.paymentType === 3 && (
                <span className="title">
                  {defaultCard.paymentType === 3
                    ? "Efectivo en puntos de pago - " + defaultCard.name
                    : defaultCard.name}
                </span>
              )}
              {defaultCard.paymentType === 2 && (
                <span className="title">
                  {defaultCard.paymentType === 2
                    ? "Transfericia electrónica - " + defaultCard.name
                    : defaultCard.name}
                </span>
              )}
              {defaultCard.paymentType === 4 && [
                debitCardPayment === defaultCard.name ? (
                  <span>
                    <span className="title">{defaultCard.name}</span>
                    <div className="cardPaymentIcon">
                      <img src={marcadoImg} />
                    </div>
                  </span>
                ) : (
                  <span className="title">{defaultCard.name}</span>
                ),
              ]}
            </div>
          </div>
        )}

        <div
          className="buttonDiv"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <CustomButton
            title={btnName}
            className="btnOutLine"
            onClick={onClick}
          />
          {/* displaying error if shipmentadress or invoice is empty */}
          {btnName === addAddressBtn && isShipmentAddressEmpty ? (
            <div className="error">{shipmentAddressRquired}</div>
          ) : null}
          {btnName === addBillingBtn && isInvoicesEmpty ? (
            <div className="error">{invoicesRquired}</div>
          ) : null}
          {btnName === addPaymentBtn && isPaymentMethodEmpty ? (
            <div className="error">{paymentMethodRquired}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SelectBlock;
