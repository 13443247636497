import React from 'react';
import { components } from 'react-select';
import MySelect from './mySelect';
import { useDispatch, useSelector } from 'react-redux';
import { setRatingBox } from '../../../Redux/reducers/filterSlice';
import './style.scss';
import { Radio } from '@material-ui/core';
import { search } from '../../../common/constants';
import Rating_Star from '../../ratingStar';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { removeRating } from '../../../common/queryString';

export default function SingleSelect({ options }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryStrings = queryString.parse(useLocation().search);
  const { calificaciones } = queryStrings;
  const _ratingCheckedState = useSelector(
    (state) => state.filter.ratingCheckState
  );

  const handleChanges = (selected) => {
    dispatch(setRatingBox(selected.data));
    filterParam(selected.isSelected, selected.value);
  };
  const filterParam = (selected, value) => {
    const newQueryParam = {
      ...queryStrings,
      calificaciones: value,
    };
    history.push({
      search: queryString.stringify(newQueryParam),
    });
  };
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <div
            onClick={(e) => {
              handleChanges(props);
            }}
            className="listItem"
          >
            <Radio
              name={"rating-label"}
              value={props.value}
              checked={props.isSelected}
            />
            
            <label className="checkbox-label">
              {props.value > 0 && <Rating_Star _ratingValue={props.value} />}
              {props.label}
            </label>
          </div>
        </components.Option>
      </div>
    );
  };

  return (
    <MySelect
      className="_multiSelect single"
      options={options}
      isMulti
      placeholder={search}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ Option }}
      allowSelectAll={false}
      menuIsOpen={true}
      value={_ratingCheckedState}
    />
  );
}
