import React from "react";
import "./style.css";
import "./style.scss";
import { useHistory } from "react-router";
import { additions } from "../../common/constants";
import Carousel from "react-elastic-carousel";
import { Skeleton } from "@material-ui/lab";
import Images from "../../Component/imgLoader";
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 440, itemsToShow: 2 },
  { width: 550, itemsToShow: 3 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 6 },
];

const Addition = (props) => {
  const history = useHistory();
  const redirect = (url) => {
    window.location.href = url;
  };
  return (
    <>
      <div className="container add">
        <div className="addition-header">
          <h1>{additions}</h1>
          <div className="row">
            {props.array.length != 0 ? (
              <Carousel enableAutoPlay={false} breakPoints={breakPoints}>
                {props.array.map((item, index) => {
                  return (
                    <div className=" itm" key={index}>
                      <div
                        className="brandHolder"
                        onClick={() => redirect(item.url)}
                        style={{ cursor: "pointer" }}
                        // onClick={() => {
                        //   history.push(item.url);
                        // }}
                      >
                        <Images src={item.imageURL} alt="" />
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            ) : (
              <Carousel breakPoints={breakPoints}>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((i, index) => {
                  return (
                    <div key={index}>
                      <div className="brandSkelton">
                        <Skeleton
                          animation="wave"
                          height={268}
                          className="skeltonImgs"
                          style={{ marginBottom: 25 }}
                        />
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Addition;
