import { Collapse } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import BillBox from "../../../Component/billingBox";
import MiCuenta from "../mi-cuenta/index";
import "../billing/style.scss";
import {
  AddNew,
  addresses,
  ShippingAddres,
  contAddress,
  postCode,
} from "../../../common/constants";
import { AddressForm, BillForm, Confirm_Alert } from "../../../Component";
import CustomizedDialogs from "../../../Component/customModal/Modal";
import { Skeleton } from "@material-ui/lab";
import { DeleteUserAddress, GetUserAddresses } from "../../../utils/api";
import ApplicationContext from "../../../context-api/context";
import { useToasts } from "react-toast-notifications";

const Adresses = () => {
  useEffect(() => {
    setLoading(true);
    getUserAddressData();
  }, []);
  const { addToast } = useToasts();
  const { setSingleAddressData } = useContext(ApplicationContext);
  const getUserAddressData = async () => {
    setAddressesData([]);
    try {
      let result = await GetUserAddresses();
      const {
        data: { success, content },
      } = result || {};
      if (result && success) {
        const arr = content;
        let newArr = arr.map((obj, ind) => ({
          ...obj,
          isOpened: false,
        }));
        setAddressesData(newArr);
        setLoading(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [ismodalOpens, setIsmodalOpens] = useState(false);
  const [addressesData, setAddressesData] = useState([]);

  const handleDelete = async (id) => {
    setOpenModal(false);
    const res = await DeleteUserAddress(id);
    if (res && res.data.success) {
      showToast(res?.data?.description);
      let tempInvoiceList = [...addressesData];
      let index = tempInvoiceList.findIndex((e) => e.id == id);
      if (index !== -1) {
        tempInvoiceList.splice(index, 1);
        setAddressesData(tempInvoiceList);
      }
    }
  };
  const showToast = (message) => {
    addToast(message, { appearance: "success" });
  };
  return (
    <MiCuenta title={addresses}>
      <div className="Billingmain">
        <div className="mainScreen">
          <p className="billText">{ShippingAddres}</p>
          {loading == true
            ? [1, 2, 3].map((e) => (
                <Skeleton
                  width={"100%"}
                  height={150}
                  style={{ marginBottom: -3, marginTop: -7 }}
                />
              ))
            : addressesData?.map((i, index) => {
                return (
                  <BillBox
                    index={index}
                    shipAddress
                    key={i.id}
                    addressId={i.id}
                    fullName={i.name}
                    zip={i.zipCode}
                    contactAddress={i.fullAdress}
                    // onEdit={() => editItem(i)}
                    onDelete={() => {
                      setAddressId(i.id);
                      setOpenModal(true);
                    }}
                    addOrEdit={() => {
                      console.log("Edit press");
                    }}
                    isOpened={i.isOpened}
                    ismodalOpens={ismodalOpens}
                    setIsmodalOpens={setIsmodalOpens}
                    addressData={addressesData}
                    setAddressData={setAddressesData}
                    onClick={() => {}}
                    fromAccountSection
                    shipAddressForm
                    onSuccessEdited={(e) => {
                      showToast(e);
                    }}
                  />
                );
              })}
          {/* Add new Card is Aggregar */}
          {!loading && (
            <BillBox
              fromAccountSection
              shipAddressForm
              addNew={"nueva dirección de envío"}
              // addOrEdit={addOrEdit}
              addNewInvoices={true}
              ismodalOpens={ismodalOpens}
              setIsmodalOpens={setIsmodalOpens}
              addressData={addressesData}
              setAddressData={setAddressesData}
              isOpen={true}
              onClick={() => {
                const arr = addressesData;
                let newArr = arr.map((obj, ind) => ({
                  ...obj,
                  isOpened: false,
                }));
                setAddressesData(newArr);
                setSingleAddressData([]);
              }}
            />
          )}
        </div>
      </div>
      {
        <CustomizedDialogs onClose={openModal} open={openModal}>
          <Confirm_Alert
            del={true}
            onCancel={() => setOpenModal(false)}
            onConfirm={() => handleDelete(addressId)}
          />
        </CustomizedDialogs>
      }
    </MiCuenta>
  );
};

export default Adresses;
