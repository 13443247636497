import React, {
  useState,
  useEffect,
  useContext,
  useImperativeHandle,
  forwardRef,
} from "react";
import { RadioBox } from "../index";
import { Collapse } from "@material-ui/core";
import "./style.scss";
import { BillForm, AddressForm } from "../index";
import BillBox from "../billingBox";
import {
  addBillingBtn,
  AddNew,
  billingAddres,
  Continue,
  creditDebit,
  paymentMethod,
  payWithMercadoPago,
  payMethod,
  ShippingAddres,
  noInvoiceWant,
  mercadoPagoId,
  defaultPaymentMethodId,
  previouseSelectedId,
  debitCardPayment,
  payWithMarcadoCardId,
} from "../../common/constants";
import useForm from "./useForm";
import PaymentForm from "../addressForm/paymentForm";
import { CustomButton, CloseButton } from "../index";
import {
  getCheckoutAddresses,
  getCheckoutCards,
  getCheckoutInvoices,
} from "./../../utils/api";
import ApplicationContext from "../../context-api/context";
import SkeletonBox from "./SkeletonBox";

export const CustomModal = forwardRef((props, ref) => {
  const {
    close,
    shipping,
    payment,
    billAddress,
    fetchDataToggle,
    state,
    setState,
    setIsSubmit,
    openPop,
    setClosePop,
    show,
    setShow,
    // ref,
  } = props || {};

  useImperativeHandle(ref, () => ({
    childMethod() {
      childMethod();
    },
  }));
  function childMethod() {
    onModelCloseHandler();
  }
  const { selectedId } = state || {};
  const { addAddress } = useForm();
  const [shippingAddressList, setShippingAddressList] = useState([]);
  const [paymentDataList, setPaymentDataList] = useState([]);
  const [billingAddressList, setBillingAddressList] = useState([]);
  const [showBankModal, setshowBankModal] = useState(false);
  const [showStoreModal, setshowStoreModal] = useState(false);
  const [paymentSelectedId, setPaymentSelectedId] = useState(0);
  const [invoiceId, setInvoiceId] = useState(0);
  const [paymentSelectedType, setPaymentSelectedType] = useState(0);
  const [creditCardWithMarcado, setcreditCardWithMarcado] = useState(false);
  const [loader, setLoader] = useState(false);

  const {
    selectedCheckoutBillingAddreess,
    setSelectedCheckoutBillingAddreess,
    selectedCheckoutShippingAddreess,
    setSelectedCheckoutShippingAddreess,
    selectedCheckoutPayment,
    setSelectedCheckoutPayment,
    banksCheckoutList,
    storesCheckoutList,
  } = useContext(ApplicationContext);

  const handleClose = () => {
    setShow(false);
    close();
  };

  const handleSubmit = () => {
    setState((prev) => ({
      prev,
      selectedId: prev.selectedId,
    }));
    localStorage.removeItem(previouseSelectedId);
    // setClosePop(false);
  };

  const closeOthers = () => {
    setshowBankModal(false);
    setshowStoreModal(false);
    setShow(false);
  };

  const handleBanksModal = () => {
    localStorage.setItem(previouseSelectedId, selectedId);
    setshowBankModal(!showBankModal);
    setshowStoreModal(false);
    setShow(false);
  };

  const handleStoresModal = () => {
    localStorage.setItem(previouseSelectedId, selectedId);
    setshowBankModal(false);
    setshowStoreModal(!showStoreModal);
    setShow(false);
  };

  const clo = () => {
    setPaymentSelectedId(0);
    setshowBankModal(false);
    setshowStoreModal(false);
    setShow(!show);
  };

  const closePaymentModals = (selectedPaymentType) => {
    if (selectedPaymentType === 2) {
      setshowBankModal(true);
      setshowStoreModal(false);
      setShow(false);
    } else if (selectedPaymentType === 3) {
      setshowStoreModal(true);
      setshowBankModal(false);
      setShow(false);
    } else {
      closeOthers();
    }
  };

  const getListData = async () => {
    // ===== It is use to get shipping addresses for the checkout page ===== //
    if (shipping) {
      const response = await getCheckoutAddresses();
      if (response) {
        const {
          data: { content },
        } = response;

        if (selectedCheckoutShippingAddreess) {
          changeIsDefaultToOther(
            content,
            setShippingAddressList,
            selectedCheckoutShippingAddreess.id
          );
        } else {
          setShippingAddressList(content);
        }
      }
    }

    // ===== It is use to get cards for the checkout page ===== //
    if (payment) {
      setLoader(true);
      const response = await getCheckoutCards();
      if (response) {
        const {
          data: { content },
        } = response;

        if (selectedCheckoutPayment) {
          closePaymentModals(selectedCheckoutPayment.paymentType);
          changeIsDefaultToOther(
            content,
            setPaymentDataList,
            selectedCheckoutPayment.id
          );
        } else {
          setPaymentDataList(content);
        }
      }

      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
    // ===== It is use to get billing addresses for the checkout page ===== //
    if (billAddress) {
      const response = await getCheckoutInvoices();
      if (response) {
        const {
          data: { content },
        } = response;

        if (selectedCheckoutBillingAddreess) {
          changeIsDefaultToOther(
            content,
            setBillingAddressList,
            selectedCheckoutBillingAddreess.id ||
              selectedCheckoutBillingAddreess.invoicingID
          );
        } else {
          setBillingAddressList(content);
        }
      }
    }
  };

  const changeIsDefaultToOther = (dataList, setDatalist, selectedId) => {
    dataList.forEach((value, index) => {
      dataList[index].isDefault = value.id == selectedId;
    });
    setDatalist(dataList);
  };

  const newAddressInsertion = (data) => {
    if (data) {
      setSelectedCheckoutShippingAddreess(data);
      setShippingAddressList([...shippingAddressList, data]);

      handleClose();
    }
  };

  const newInsertedCard = (data, lastdigits, issuerName) => {
    data.name = `${issuerName}, *****${lastdigits}`;
    setSelectedCheckoutPayment(data);
    setPaymentSelectedType(0);
    handleClose();
  };

  const newInvoiceInsertion = (data) => {
    let result = data.data;
    if (result.success) {
      setSelectedCheckoutBillingAddreess(result.content);
      handleClose();
    }
  };

  useEffect(() => {
    getListData();
    if (!selectedId) {
      setState((prev) => ({
        ...prev,
        selectedId: defaultPaymentMethodId,
      }));
    }
  }, [fetchDataToggle]);

  const handleSelection = (id) => {
    if (id !== selectedId) {
      return setState((prev) => ({
        selectedId: id,
        previouse: prev.selectedId,
      }));
    }
  };
  const onModelCloseHandler = () => {
    if (billAddress) {
      if (invoiceId !== 0) {
        const Obj = {
          id: -1,
          name: noInvoiceWant,
        };
        setSelectedCheckoutBillingAddreess(Obj);
      } else {
        let billAddress = billingAddressList.filter(
          (item) => item.isDefault
        )[0];
        if (billAddress) {
          setSelectedCheckoutBillingAddreess(billAddress);
        }
      }
      handleSubmit();
      return;
    }
    if (payment) {
      if (paymentSelectedType === 2 || paymentSelectedType === 3) {
        const getPaymentSelectedId = paymentSelectedId.split("_");
        let selectedPaymentData;
        const selectedId = getPaymentSelectedId[0];
        // check Payment Type
        if (parseInt(getPaymentSelectedId[1]) === 2) {
          selectedPaymentData = banksCheckoutList.filter(
            (item) => item.id === selectedId
          )[0];
        } else {
          selectedPaymentData = storesCheckoutList.filter(
            (item) => item.id === selectedId
          )[0];
        }
        setSelectedCheckoutPayment(selectedPaymentData);
      } else if (paymentSelectedType === 4 || paymentSelectedType === 5) {
        let Obj;
        if (creditCardWithMarcado) {
          Obj = {
            id: 4,
            name: debitCardPayment,
            paymentType: 4,
          };
        } else {
          Obj = {
            id: 4,
            name: payWithMercadoPago,
            paymentType: 4,
          };
        }

        setSelectedCheckoutPayment(Obj);
      } else {
        let tempPaymentList = paymentDataList.filter(
          (item) => item.isDefault
        )[0];
        if (tempPaymentList == undefined) {
          if (selectedCheckoutPayment?.CardToken === "") {
            paymentDataList[0] &&
              setSelectedCheckoutPayment(paymentDataList[0]);
          }
        } else {
          setSelectedCheckoutPayment(tempPaymentList);
        }
      }
      handleSubmit();
      return;
    }
    if (shippingAddressList.length) {
      let shippingAddress = shippingAddressList.filter(
        (item) => item.isDefault
      )[0];
      if (shippingAddress) {
        setSelectedCheckoutShippingAddreess(shippingAddress);
      }
      handleSubmit();
      return;
    }
  };

  return (
    <>
      <div className="custom-wrapper-scroll">
        <div className="headline">
          <text className="texthead">
            {payment
              ? paymentMethod
              : billAddress
              ? billingAddres
              : ShippingAddres}
          </text>
          <CloseButton
            onClick={() => {
              onModelCloseHandler();
              setClosePop(false);
            }}
          />
        </div>

        <div className="upperFormContainer">
          {billAddress && (
            <div className="formContainers">
              <div className="upperContainer">
                {/* <RadioBox onClick={() => setShow(true)}> */}
                <BillBox addNew={addBillingBtn} onClick={clo}>
                  <Collapse
                    in={show}
                    className="collapseOpen"
                    classes={{
                      wrapper: "wrapperCollapse",
                    }}
                  >
                    <div className="formStyle">
                      <BillForm
                        checkout
                        addOrEdit={addAddress}
                        newInvoice={newInvoiceInsertion}
                        openPop={openPop}
                      />
                    </div>
                  </Collapse>
                </BillBox>
                {/* </RadioBox> */}
              </div>
            </div>
          )}

          {payment &&
            (loader ? (
              <div className="formContainers">
                <SkeletonBox />
              </div>
            ) : (
              <div className="formContainers">
                {/* For the Time Being it commenting 4-Nov-2022 */}
                {/* <RadioBox onClick={() => setShow(!show)}> */}
                <div className="upperContainer">
                  {/* <BillBox
                    payment={creditDebit}
                    icon={3}
                    paymentType={3}
                    onClick={clo}
                  >
                    <Collapse
                      in={show}
                      className="collapseOpen"
                      classes={{
                        wrapper: "wrapperCollapse",
                      }}
                    >
                      <div className="formStyle">
                        <PaymentForm
                          checkout
                          addOrEdit={addAddress}
                          newCard={newInsertedCard}
                        />
                      </div>
                    </Collapse>
                  </BillBox> */}
                </div>
                {/* </RadioBox> */}
              </div>
            ))}

          {shipping && (
            <div className="formContainers">
              {/* <RadioBox > */}
              {/* onClick={() => setShow( !show ) } */}
              <div className="upperContainer">
                <BillBox addNew={AddNew} onClick={clo}>
                  <Collapse
                    in={show}
                    className="collapseOpen"
                    classes={{
                      wrapper: "wrapperCollapse",
                    }}
                  >
                    <div className="formStyle">
                      <AddressForm
                        checkout
                        addOrEdit={addAddress}
                        newAddress={newAddressInsertion}
                        openPop={openPop}
                      />
                    </div>
                  </Collapse>
                </BillBox>
                {/* </RadioBox> */}
              </div>
            </div>
          )}

          <div className="actualAddress">
            {billAddress && (
              <RadioBox
                onClick={() => {
                  setInvoiceId(-1);
                }}
                select={invoiceId === -1 ? true : false}
              >
                <BillBox noInvoiceWant={noInvoiceWant} />
              </RadioBox>
            )}

            {billAddress &&
              billingAddressList.map((item) => {
                const { id, taxID, name, taxAddress, isDefault } = item;
                return (
                  <RadioBox
                    key={id}
                    onClick={() => {
                      setInvoiceId(0);
                      changeIsDefaultToOther(
                        [...billingAddressList],
                        setBillingAddressList,
                        id
                      );
                    }}
                    select={isDefault}
                  >
                    <BillBox
                      billAddress={item}
                      addNew
                      name={taxID}
                      address={name}
                      isCheckout
                    />
                  </RadioBox>
                );
              })}
            {/* For the Time Being it commenting 4-Nov-2022 */}
            {/* {loader
              ? [1, 2].map((item) => <SkeletonBox />)
              : payment &&
                paymentDataList.map((item) => {
                  const { id, name, cardBrand, isDefault, paymentType } = item;
                  return (
                    <RadioBox
                      key={id}
                      onClick={() => {
                        setPaymentSelectedType(1);
                        changeIsDefaultToOther(
                          [...paymentDataList],
                          setPaymentDataList,
                          id
                        );
                        handleSelection(id);
                      }}
                      select={selectedId === id || isDefault}
                    >
                      <BillBox
                        payment={name}
                        icon={cardBrand}
                        paymentType={paymentType}
                        onClick={closeOthers}
                      />
                    </RadioBox>
                  );
                })} */}
            {/* {loader ? (  //  
              <SkeletonBox />
            ) : (
              payment && (
                <RadioBox
                  onClick={() => {
                    setPaymentSelectedType(5);
                    handleSelection(payWithMarcadoCardId);
                    setcreditCardWithMarcado(true);
                  }}
                  select={payWithMarcadoCardId === selectedId}
                >
                  <BillBox
                    bank={debitCardPayment}
                    icon={"images/wallet.png"}
                    onClick={closeOthers}
                    marcadoCardPayment
                    marcadoImage={"images/marcadoCard.png"}
                  />
                </RadioBox>
              )
            )} */}
            {/* {loader
              ? [1, 2].map((i) => <SkeletonBox />)
              : payment &&
                payMethod.map((item) => {
                  const { id, name, paymentType } = item;
                  return (
                    <div className="upperContainer">
                      <BillBox
                        payment={name}
                        icon={id}
                        paymentType={paymentType}
                        onClick={
                          id === 2 ? handleBanksModal : handleStoresModal
                        }
                      >
                        <Collapse
                          in={id === 2 ? showBankModal : showStoreModal}
                          className="collapseOpen"
                          classes={{
                            wrapper: "wrapperCollapse",
                          }}
                        >
                          {id === 2 ? (
                            <div className="formStyle banksubchild">
                              {banksCheckoutList.map((bankitem) => {
                                const { image, name, id, paymentType } =
                                  bankitem;
                                const newId = id + "_" + paymentType;
                                return (
                                  <RadioBox
                                    key={id + paymentType}
                                    onClick={() => {
                                      //setSelectedCheckoutPayment(bankitem);
                                      setPaymentSelectedId(newId);
                                      setPaymentSelectedType(2);
                                      handleSelection(newId);
                                    }}
                                    select={newId === selectedId}
                                  >
                                    <BillBox bank={name} icon={image} />
                                  </RadioBox>
                                );
                              })}
                            </div>
                          ) : (
                            <div className="formStyle banksubchild">
                              {storesCheckoutList.map((item) => {
                                const { image, name, id, paymentType } = item;
                                const newId = id + "_" + paymentType;
                                return (
                                  <RadioBox
                                    key={newId}
                                    onClick={() => {
                                      //setSelectedCheckoutPayment(item);
                                      setPaymentSelectedType(3);
                                      setPaymentSelectedId(newId);
                                      handleSelection(newId);
                                    }}
                                    select={newId === selectedId}
                                  >
                                    <BillBox bank={name} icon={image} />
                                  </RadioBox>
                                );
                              })}
                            </div>
                          )}
                        </Collapse>
                      </BillBox>
                    </div>
                  );
                })} */}

            {/* {loader ? (
              <SkeletonBox />
            ) : (
              payment && (
                <RadioBox
                  onClick={() => {
                    setPaymentSelectedType(4);
                    handleSelection(mercadoPagoId);
                    setcreditCardWithMarcado(false);
                  }}
                  select={mercadoPagoId === selectedId}
                >
                  <BillBox
                    bank={payWithMercadoPago}
                    icon={"images/MercadoPago.png"}
                    onClick={closeOthers}
                  />
                </RadioBox>
              )
            )} */}
            {shipping &&
              shippingAddressList.map((item) => {
                const { id, zipCode, fullAdress, addressContact, isDefault } =
                  item;
                return (
                  <RadioBox
                    key={id}
                    onClick={() => {
                      changeIsDefaultToOther(
                        [...shippingAddressList],
                        setShippingAddressList,
                        id
                      );
                    }}
                    select={isDefault}
                  >
                    <BillBox
                      shippingAddress={item}
                      addNew
                      zipCode={zipCode}
                      name={addressContact}
                      address={fullAdress}
                    />
                  </RadioBox>
                );
              })}

            <div className="genralBtn"></div>
          </div>
        </div>
        <br></br>
        <div
          className={
            shipping
              ? show === false
                ? "modal-footer modal-footer--ship"
                : "modal-footer modal-footer--sticky"
              : billAddress
              ? show === false
                ? "modal-footer modal-footer--bill"
                : "modal-footer modal-footer--sticky"
              : "modal-footer modal-footer--sticky"
          }
        >
          <CustomButton
            title={Continue}
            onClick={() => {
              onModelCloseHandler();
              setClosePop(false);
            }}
            className="innerbtn "
          />
        </div>
      </div>
    </>
  );
});
