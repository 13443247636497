import React, { useEffect, useState, useContext } from "react";
import "./style.scss";
import { SwipeableDrawer } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "../../Component/confirm-alert/alertBox";
import { error, errMsgInput } from "../../common/constants";
import SideMenuCart from ".";
import { cartIcon } from "../../common/Images";
import { getCart } from "../../utils/api";
import ApplicationContext from "./../../context-api/context";
import { CircularProgress } from "@material-ui/core";

const Cart = ({
  openCartPanel,
  quantity,
  price,
  openLoginPopup,
  isLoggedIn,
}) => {
  const {
    setCartList,
    isCartMenu,
    setIsCartMenu,
    isDeleted,
    cartList,
    isIncrement,
  } = useContext(ApplicationContext);

  const dispatch = useDispatch();
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsCartMenu(open);
  };
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [errorMsg, setError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({
    cartObj: null,
  });

  useEffect(() => {
    if (isLoggedIn) {
      getData();
    }
  }, [isLoggedIn, isIncrement]);

  useEffect(() => {
    if (openCartPanel) setIsCartMenu(true);
  }, [openCartPanel]);

  const onItemClickedItem = () => {
    if (isLoggedIn == true) {
      console.log(isLoggedIn);
      setIsCartMenu(true);
    } else {
      console.log(isLoggedIn);
      openLoginPopup();
    }
  };

  async function getData() {
    await getCart()
      .then((response) => {
        if (response.status === 200) {
          setData({
            ...data,
            cartObj: response.data.content,
          });
          setCartList(response.data.content.items);
        } else {
          setError(errMsgInput);
          setOpenModal(true);
        }
      })
      .catch((error) => {});
  }
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <>
      <div className="cartBoxClass" onClick={() => onItemClickedItem()}>
        {price === "NaN" ? (
          <div
            style={{
              width: quantity > 10 ? 141 : 134.5,
              justifyContent: "center",
              display: "flex",
            }}
          >
            <CircularProgress size={18} />
          </div>
        ) : (
          <>
            <div className="leftSide">
              <img src={cartIcon} />
              <span className="tagPrice">{quantity}</span>
            </div>
            <div className="rigtSide">
              <span className="tagPrice">{"$" + numberWithCommas(price)}</span>
            </div>
          </>
        )}
      </div>
      <SwipeableDrawer
        style={{ overflowX: "hidden" }}
        anchor={"right"}
        open={isCartMenu}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        transitionDuration={{ enter: 1000, exit: 500 }}
        autoWidth={false}
        swipeAreaWidth={"auto"}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <SideMenuCart closeBtn={toggleDrawer(false)} cart={data.cartObj} />
      </SwipeableDrawer>

      <AlertBox
        del={false}
        onCancel={() => setOpenModal(false)}
        heading={error}
        text={errorMsg}
        onClose={openModal}
        open={openModal}
      />
    </>
  );
};

export default Cart;
