import React from "react";
import { usePagination } from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import "./style.scss";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { Next, prev } from "../../common/constants";
import { colors } from "../../common/theme";
import { useDispatch } from "react-redux";
import { setPageNum } from "../../Redux/reducers/filterSlice";

const useStyles = makeStyles({
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
  },
});

export default function Use_Pagination({count,page}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const onChange=(event,page)=>{

    dispatch(setPageNum(page))
    }
   
  const { items } = usePagination({
    count: count,
    page:page,
    onChange: onChange
  });

  return (
    <div className="page-root">
      <nav className="page-shadow">
        <ul className={classes.ul}>
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;
            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = "…";
            } else if (type === "page") {
              children = (
                <IconButton
                  className="steper"
                  style={{
                    backgroundColor: selected ? colors.primaryColor: colors.white,
                    color: selected ? colors.white : colors.baseForground,
                    padding: "0rem .1rem",
                  }}
                  {...item}
                  
                >
                  {page}
                </IconButton>
              );
            } else {
              children = (
                <IconButton
                  {...item}
                  className={type === "previous" ? "prevBtn" : "nextBtn"}
                >
                  {type === "previous" ? (
                    <>
                      {" "}
                      <BsArrowLeft /> <span>{prev}</span>
                    </>
                  ) : (
                    <>
                      <span>{Next}</span>
                      <BsArrowRight />
                    </>
                  )}
                </IconButton>
              );
            }
            return (
              <li key={index} style={{ margin: "auto 0" }}>
                {children}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}
