import { CircularProgress, IconButton } from "@material-ui/core";
import React from "react";
import "./style.scss";
const Badges = ({
  categoryName,
  value,
  onClick,
  type,
  minValue,
  loading,
  maxValue,
  couponCode,
  onKeyDown,
}) => {
  return (
    <>
      {couponCode && (
        <span className="tag" onKeyDown={onKeyDown} tabIndex={0}>
          <span className="span">{couponCode}</span>
          {loading ? (
            <CircularProgress size={30} color="white" />
          ) : (
            <IconButton style={{ color: "black" }} className="p-0">
              <span
                className="fas fa-times-circle crossIcon"
                onClick={onClick}
              ></span>
            </IconButton>
          )}
        </span>
      )}
      {categoryName && (
        <span className="tag">
          {categoryName}:
          {type === "Precio" ? `${minValue} to ${maxValue}` : value}
          <span
            className="fas fa-times-circle crossIcon"
            onClick={onClick}
          ></span>
        </span>
      )}
    </>
  );
};

export default Badges;
