import React from "react";
import { Confirm_Alert } from "..";
import CustomizedDialogs from "../../Component/customModal/Modal";

const AlertBox = ({
  open,
  onCancel,
  onClose,
  heading,
  text,
  del,
  className,
  isSuccess,
  requestProduct,
  onSubmit,
  btnLoader,
}) => {
  return (
    <CustomizedDialogs onClose={onClose} open={open}>
      <Confirm_Alert
        del={del}
        onCancel={onCancel}
        heading={heading}
        text={text}
        isSuccess={isSuccess}
        requestProduct={requestProduct}
        onConfirm={onSubmit}
        btnLoader={btnLoader}
      />
    </CustomizedDialogs>
  );
};

export default AlertBox;
