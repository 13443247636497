import React from "react";
import "../../../Sections/side-bar/style.scss";
const Sub_Expand = ({ children, title, expand, id }) => {
  function makeIDSpaceFree(ID) {
    return ID.replace(/ /g, "-");
  }
  return (
    <div className="card">
      <div className="card-header" id={`heading${id}`}>
        <h5 className="mb-0">
          <button
            className="btn btn-link d-flex align-items-center"
            data-toggle="collapse"
            data-target={`#collapse${makeIDSpaceFree(id)}`}
            aria-expanded={expand}
            aria-controls={`collapse${id}`}
          >
            <span className="fas fa-expand-arrows-alt CIcon"></span>
            <span className="fas fa-expand-alt EIcon"></span>
            {title}
          </button>
        </h5>
      </div>
      <div
        id={`collapse${makeIDSpaceFree(id)}`}
        className={expand == true ? "collapse show" : "collapse"}
        aria-labelledby={`heading${id}`}
        data-parent="#accordion"
      >
        <div className="card-body">
          <ul className="subCategory">{children}</ul>
        </div>
      </div>
    </div>
  );
};

export default Sub_Expand;
