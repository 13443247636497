import * as React from "react";
import { Dialog, styled, Fade } from "@material-ui/core";

const SearchDialogBox = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: theme.spacing(0),
    backgroundColor: "transparent",
  },
  "& .MuDialogContent-root": {
    padding: theme.spacing(0),
    border: "none",
  },
  "& .MuiDialogContent-dividers ": {
    padding: theme.spacing(0),
    border: "none",
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialog-paperWidthSm": {
    minWidth: "sm",
    maxWiidth: "md",
  },
  "& .MuiDialog-scrollPaper ": {
    alignItems: "baseline",
  },
  "& .MuiPaper-elevation24": {
    boxShadow: "none",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="left" ref={ref} {...props} />;
});

export default function SearchDialog(props) {
  const { children, openPopup, setOpenPopup } = props;

  return (
    <>
      <SearchDialogBox
        open={openPopup}
        fullWidth
        keepMounted
        TransitionComponent={Transition}
        onBackdropClick={() => {
          setOpenPopup(false);
        }}
        maxWidth={"lg"}
        fullWidth={true}
      >
        {children}
      </SearchDialogBox>
    </>
  );
}
