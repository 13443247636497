import React, { useState } from "react";
import "./style.scss";
import Popup from "../popup";
import AttributeTabs from "../attributeTabs";
import { Link } from "react-router-dom";
import { CloseButton } from "..";
import { opinion } from "../../common/constants";
import Rating_Star from "../ratingStar";
import { Skeleton } from "@material-ui/lab";

const GridSkelton = ({
  imag,
  gridView,
  visibleTwoButtons,
  detailView,
  _ratingValue,
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [tabIndex, settabIndex] = useState(1);
  return (
    <>
      <div className={gridView}>
        <div className={detailView ? "proInner p-4" : "proInner innerBorder"}>
          <div className="ProImgWrapper ">
            <div style={{ marginTop: 0 }}>
              <Skeleton
                animation="wave"
                variant="rect"
                className={"proImgSeklton"}
              />
            </div>
          </div>
          <div
            className={
              detailView
                ? "description d-inline-block"
                : "description descriptionBorder d-inline-block"
            }
          >
            <div className="ratingArea">
              <div className="left">
                <Link className="proName">
                  {" "}
                  <h3>
                    {" "}
                    <Skeleton animation="wave" width="220px" />
                  </h3>
                </Link>
                <div className="d-flex">
                  <Rating_Star _ratingValue={_ratingValue} />
                  {detailView ? (
                    <span className="opinion">
                      {" "}
                      <Skeleton animation="wave" width="40px" />
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="right">
                <span className={detailView ? "discounted" : "discountedPrice"}>
                  <Skeleton animation="wave" width="80px" />
                </span>
                <span
                  className={detailView ? "orignal" : "orignalPrice"}
                  style={{ textDecoration: "none" }}
                >
                  <Skeleton animation="wave" height={12} width="60px" />
                </span>
              </div>
            </div>
            <div className={detailView ? "detail mt-2" : "detail"}>
              {detailView ? (
                <>
                  <div>
                    <div className="info mt-5">
                      <Skeleton animation="wave" height={12} width="60px" />
                    </div>
                    <div className="info mt-1">
                      <Skeleton animation="wave" height={12} width="130px" />
                    </div>
                    <div className="info mt-1">
                      <Skeleton animation="wave" height={12} width="90px" />
                    </div>
                    <div className="info">
                      <Skeleton animation="wave" height={12} width="70px" />
                    </div>
                    <div className="info">
                      <Skeleton animation="wave" height={12} width="50px" />
                    </div>
                    <div className="info mb-4">
                      <Skeleton animation="wave" height={12} width="90px" />
                      <Skeleton animation="wave" height={12} width="70px" />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <div className="info">
                      <Skeleton animation="wave" height={12} width="160px" />
                    </div>
                    <div className="info">
                      <Skeleton animation="wave" height={12} width="120px" />
                    </div>
                    <div className="info">
                      <Skeleton animation="wave" height={12} width="120px" />
                    </div>
                    <div className="info">
                      <Skeleton animation="wave" height={12} width="180px" />
                      <Skeleton animation="wave" height={12} width="100px" />
                    </div>
                    <div className="info">
                      <Skeleton animation="wave" height={12} width="80px" />
                    </div>
                  </div>
                  <div className="extraInfo">
                    <div className="info add">
                      <Skeleton animation="wave" height={12} width="80px" />
                    </div>
                    <div className="info add">
                      <Skeleton animation="wave" height={12} width="40px" />
                    </div>
                    <div className="info add">
                      <Skeleton animation="wave" height={12} width="70px" />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="btnArea">
              {detailView ? <Skeleton className="btn addSkelton" /> : null}
              {visibleTwoButtons === false ? null : (
                <div className="btnInner d-flex justify-content-between">
                  <Skeleton
                    animation="pulse"
                    className="btn mr-2"
                    width="150px"
                    height={"50px"}
                  />
                  <Skeleton
                    animation="pulse"
                    className="btn "
                    width="150px"
                    height={"50px"}
                  />
                </div>
              )}
              {detailView ? (
                <Skeleton animation="pulse" className="btn addSkelton" />
              ) : (
                <Skeleton animation="pulse" className="btn addSkelton" />
              )}
            </div>
          </div>
        </div>
      </div>
      <Popup
        popupSize="md"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        headerVisible={false}
        overFlowClass="overflow-hidden"
      >
        <div className="closeIcon">
          <CloseButton
            onClick={() => {
              setOpenPopup(false);
            }}
            color="black"
          />
        </div>
        <AttributeTabs tabIndex={tabIndex} />
      </Popup>
    </>
  );
};
export default GridSkelton;
