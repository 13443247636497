import React, { useState, useContext, useEffect } from "react";
import { QuantityButton } from "..";
import {
  available,
  coln,
  partNum,
  perOff,
  shippingCost,
} from "../../common/constants";
import ApplicationContext from "../../context-api/context";
import Images from "../imgLoader";
import "./style.scss";

const Product = (props) => {
  const { productId, productObject } = props;
  const { cartList } = useContext(ApplicationContext);

  const [quantity, setQuantity] = useState(
    cartList.length > 0 ? props.quantity : 0
  );
  useEffect(() => {
    if (cartList.length === 0) {
      setQuantity(0);
    } else {
      const productItem = cartList.find(
        (item) => item.productID == productObject.productID
      );
      if (productItem) {
        setQuantity(productItem.quantity);
      }
    }
  }, [cartList]);

  // let strPrice = parseFloat(props.orignalPrice);
  // let orignalPrice = props.orignalPrice.toFixed(2);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <>
      <div className="productOuter">
        <div className="product">
          <div className="imgholder" onClick={props.onClick}>
            {props.deal && props.offer !== "" && (
              <div className="offers">
                <span className="inner">{props.offer}</span>
              </div>
            )}
            <Images
              src={props.productImg}
              className="img-fluid img-Index"
              skeltonStyle="img-skelton"
            />
            <span className="brandLogo">
              <Images
                src={props.brandLogo}
                className=" img-fluid"
                skeltonStyle="brand-Skelton"
              />
            </span>
          </div>
          <div className="description">
            <div onClick={props.onClick}>
              <p className="desText">{props.desText}</p>
              <div className="priceouter">
                <span className="discountedPrice">
                  {numberWithCommas(props.discountPrice)}
                </span>
                <span className="orignalPrice">
                  $
                  {numberWithCommas(
                    parseFloat(props.orignalPrice.replace("$", "")).toFixed(2)
                  )}
                </span>
              </div>
              <div className="detail">
                <span className="key">
                  {partNum}
                  {coln}
                </span>
                <span className="value">{props.serialNumber}</span>
              </div>
              <div className="detail">
                <span className="key">{"Envio"} </span>
                <span className="value">
                  {numberWithCommas(props.shippingCost)}
                </span>
              </div>
              <div className="detail">
                <span className="key">
                  {available}
                  {coln}
                </span>
                <span className="value">
                  {props.availablePices} {props.uom}
                </span>
              </div>
            </div>
            <QuantityButton
              productId={productId}
              fullWidth
              onQuantityDecreased={() => {
                setQuantity(parseInt(quantity) - 1);
              }}
              onQuantityIncreased={() => {
                setQuantity(parseInt(quantity) + 1);
              }}
              quantityChanged={setQuantity}
              quantity={quantity}
              productObject={productObject}
              // isDisabled={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Product;
