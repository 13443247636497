import React from "react";
import "./style.scss";
import Images from "../imgLoader";
import { Skeleton } from "@material-ui/lab";
const SkeletonProductBox = (props) => {
  return (
    <div
      className={
        props.status === "checkout"
          ? "checkoutDiv"
          : props.status === "shift"
          ? "shiftBox"
          : "productBox"
      }
    >
      <div className="contentDiv">
        {/* {props.status === "checkout" ? (
          <div className="imgArea">
            <Images src={props.Img} skeltonStyle="imgProducts" />
          </div>
        ) : (
          <Images src={props.Img} skeltonStyle="imgProducts" />
        )} */}
        {props.status === "shift" ? (
          <div className="item-detail">
            <p className="itemName">
              <Skeleton height={12} width={"100%"} />
            </p>
            <p className="mt-4">
              <Skeleton skeltonStyle="view_Item" width={"45px"} />
            </p>
          </div>
        ) : (
          <>
            <div className="item-detail">
              <p className="itemName">
                <Skeleton height={12} width={"220px"} />
              </p>
              <p className="categoryName mb-3">
                <Skeleton height={12} width={"40%"} />
              </p>
              <Images src={props.brandImg} skeltonStyle="brandLogo" />
              <p className="categoryName mt-2">
                <Skeleton height={12} width={"70%"} />
              </p>
            </div>
          </>
        )}
      </div>
      <div className="priceBox">
        {props.status === "shipped" ? (
          <>
            <div>
              <p>
                <Skeleton height={12} width={"45px"} />
              </p>
            </div>
            <Skeleton skeltonStyle="Guidedbutton" width={"45px"} />
          </>
        ) : props.status === "checkout" ? (
          <>
            <div className="checkout">
              <p>
                <Skeleton height={12} width={"45px"} />
              </p>
              <span className="totalPrice">
                <Skeleton height={12} width={"35px"} />
              </span>
            </div>
            <div className="totalPrice">
              <Skeleton height={12} width={"55px"} />
            </div>
          </>
        ) : props.status === "shift" ? (
          <>
            <div>
              <p className="shift_Price">
                <Skeleton height={12} width={"25px"} />
              </p>
            </div>

            <Skeleton skeltonStyle="checkbutton" width={"45px"} />
          </>
        ) : (
          <>
            <div>
              <p>
                <Skeleton height={12} width={"40px"} />
              </p>
            </div>
            <Skeleton
              skeltonStyle="checkbutton"
              width={"6rem"}
              height={"2.6em"}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SkeletonProductBox;
