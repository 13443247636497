import React from "react";
import "./style.scss";
import Carousel from "react-elastic-carousel";
import { useHistory } from "react-router";
// import Images from "../imgLoader";
import { Skeleton } from "@material-ui/lab";

const breakPoints = [
  { width: 1, itemsToShow: 2 },
  { width: 440, itemsToShow: 3 },
  { width: 550, itemsToShow: 5 },
  { width: 768, itemsToShow: 6 },
  { width: 1200, itemsToShow: 9 },
];

const SmallSlider = (props) => {
  let history = useHistory();
  return (
    <>
      <div className="container add smallSlider">
        <div className="sliderHolder">
          {props.array.length != 0 ? (
            <>
              <Carousel breakPoints={breakPoints}>
                {props.array.map((i, index) => {
                  // console.log("image : ", i.image);
                  return (
                    <div key={index}>
                      <div
                        onClick={() => {
                          history.push(`/${i.name}-${i.productType}.html`);
                        }}
                        className="ProductWrapper"
                      >
                        <div className="product">
                          <div
                            className="imge"
                            style={{ backgroundImage: `url('${i.image}')` }}
                          >
                            {/* <Images src={i.image} height={"70px"} /> */}
                          </div>
                          <span className="ProductDes">{i.name}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </>
          ) : (
            <>
              <Carousel breakPoints={breakPoints}>
                {[1, 2, 3, 4, 5, 6, 7].map((i, index) => {
                  return (
                    <div key={index}>
                      <div className="ProductWrapper">
                        <div className="product skeltonImgs">
                          <div className="imge" style={{ marginBottom: 7 }}>
                            <Skeleton
                              animation="wave"
                              height={105}
                              width="100%"
                            />
                          </div>
                          <span className="ProductDes">
                            {" "}
                            <Skeleton
                              height={16}
                              width="100%"
                              animation="wave"
                              style={{ marginBottom: 2 }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default SmallSlider;
// style={{
//   backgroundImage: `url(${i.image})`,
// }}
