import React from "react";
import "./style.scss";
import { productDescription } from "../../common/constants";
import AttributeTable from "../../Component/tables/attributeTable";
import CompatibleTable from "../../Component/tables/compatTable";

const PropertyTable = ({ attributeBody, compatibiltyBody }) => {
  return (
    <div className="tableArea">
      <div className="attrTableOuter">
        <h2 className="attrHeading">
          {productDescription.TblAttributeHeading}
        </h2>
        <div className="attrTable tbl-height-adjust">
          <AttributeTable>{attributeBody}</AttributeTable>
        </div>
      </div>
      <div className="CompatibilidadesTableOuter">
        <h2 className="attrHeading">
          {productDescription.TblCompatibilidadesHeading}
        </h2>
        <div className="CompatibilidadesTable tbl-height-adjust">
          <CompatibleTable>{compatibiltyBody}</CompatibleTable>
        </div>
      </div>
    </div>
  );
};

export default PropertyTable;
