import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as service from "../../common/services";
import { alertMsg, openAlert } from "../../Redux/reducers/alertSlice";
import {
  isVehicleSelect,
  makeVehicle,
  vehicleSubmit,
} from "../../Redux/reducers/vehicleSlice";
import {
  getKeywordExplorer,
  getKeywordWithVehicle,
  getMakers,
} from "../../utils/api";
import ApplicationContext from "../../context-api/context";

const useForm = () => {
  const {
    alertMsg,
    setAlertMsg,
    openAlert,
    setOpenAlert,
    openSearchBar,
    setVehicleData,
    openSelectVehicle,
    setOpenSelectVehicle,
    vehicleSubmit,
    setVehicleSubmit,
    addMakeList,
  } = useContext(ApplicationContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const [empty, setEmpty] = useState(false);
  const [wordEntered, setWordEntered] = useState("");
  const [historyData, sethistoryData] = useState(service.getAllHistory());
  const [searchData, setSearchData] = useState({
    category: [],
    brand: [],
    product: [],
    error: "",
  });
  const [wordError, setWordError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isMakeWord, setIsMakeWord] = useState("");
  const openSearch = openSearchBar;
  // const openSearch = useSelector((state) => state.alert.openSearch);
  const openSelectVehicles = openSelectVehicle;

  // const openSelectVehicle = useSelector(
  //   (state) => state.vehicleCreate.openSelectVehicle
  // );
  const vehicleSend = vehicleSubmit;
  // const vehicleSend = useSelector((state) => state.vehicleCreate.vehicleSend);

  //it is commented because when whe refresh the page all the makers list become vanish
  //so i decide to call Api Every Time it renders
  // const makeList = useSelector((state) => state.vehicleCreate.makeList);
  //or
  // const makeList = addMakeList

  const [makeList, setMakeList] = useState([]);
  let allVehicles = service.getAllVehicle();
  let lastElement = allVehicles[allVehicles.length - 1];
  // let lastElement = service.getAllVehicle()[service.getAllVehicle().length - 1];
  // const getLastVehicle = (params) => {
  // }

  const [vehicleCreate, setVehicle] = useState(
    service.getAllVehicle().length != 0
      ? `${lastElement?.make?.value}${" "}${lastElement?.model?.value}${" "}${
          lastElement?.year?.value
        }`
      : ""
  );

  // const getMakerList = async () => {
  //   await getMakers()
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setMakeList(response.data.content);
  //       }
  //     })
  //     .catch((error) => {
  //       dispatch(alertMsg(error));
  //       dispatch(openAlert());
  //     });
  // };

  // useEffect(() => {
  //   getMakerList();
  // }, []);

  useEffect(() => {
    if (vehicleSend == true) {
      let wordReplace = "",
        spaceReplacer = "";
      let make = lastElement.make.value;
      let modal = lastElement.model.value;
      let year = lastElement.year.value;
      //regex that remove vehicle name from the word enterd if it exists
      var regex = new RegExp(make + "|" + modal + "|" + year, "gi");
      if (wordEntered != "" && wordEntered.match(/(\w+)/g).length > 1) {
        wordReplace = wordEntered.replace(regex, "");
        spaceReplacer = wordReplace.trim();

        // setTimeout(() => {
        //   setWordEntered('');
        //   handleSearch();
        // }, 1000);
      }
      let vehicleCreate = `${lastElement.make.value}${" "}${
        lastElement.model.value
      }${" "}${lastElement.year.value}`;
      return (
        setWordEntered(spaceReplacer),
        handleSearch(spaceReplacer, vehicleCreate),
        // handleSearch(true),
        setVehicle(
          `${lastElement.make.value}${" "}${lastElement.model.value}${" "}${
            lastElement.year.value
          }`
        ),
        setVehicleSubmit(false),
        setOpenSelectVehicle(false)
        // dispatch(vehicleSubmit(false)),
        // dispatch(isVehicleSelect(false))
      );
    } else {
      setOpenSelectVehicle(false);
      // dispatch(isVehicleSelect(false));
    }
  }, [vehicleCreate, vehicleSend]);

  const handleFilter = (event) => {
    let searchWord = event.target.value;
    searchWord = searchWord.replace(/ +(?= )/g, "");
    setWordEntered(searchWord);
    if (searchWord === "") {
      setOpenSelectVehicle(false);
      // dispatch(isVehicleSelect(false));
    }
  };

  const clearInput = () => {
    setWordEntered("");
    setIsMakeWord("");
    // dispatch(isVehicleSelect(false));
    setOpenSelectVehicle(false);
  };

  const getKeyWordSearch = async (enteredWord, isSelectVehicle) => {
    {
      await getKeywordExplorer(enteredWord)
        .then((response) => {
          if (response.status === 200) {
            setLoader(false);
            if (response.data.content.isVehicle === true) {
              setSearchData({
                ...searchData,
                category: [],
                brand: [],
                product: [],
                error: "",
              });
              setVehicleData([response.data.content.vehicle]);
              // dispatch(makeVehicle(response.data.content.vehicle)); //sending make &model info to selectVehicle compo
              const findIndex = response.data.content.vehicle.models.findIndex(
                (item) => item.selected == true
              );
              //
              setIsMakeWord(
                response.data.content.vehicle.models[findIndex].model
              );
              setLoader(false);
              if (isSelectVehicle != true) {
                setOpenSelectVehicle(true);
                // dispatch(isVehicleSelect(true)); // vehicle whould be open if vehicle searched
              }
            } else {
              setWordError(false);
              setSearchData({
                ...searchData,
                category: response.data.content.categories,
                brand: response.data.content.brands,
                product: response.data.content.products,
                error: "",
              });
              if (
                response.data.content.categories.length == 0 &&
                response.data.content.brands.length == 0 &&
                response.data.content.products.length == 0
              ) {
                setWordError(true);
              }
              setOpenSelectVehicle(false);
              // dispatch(isVehicleSelect(false));
            }
          } else {
            setLoader(false);
            setAlertMsg(response.exceptionMessage);
            setOpenAlert(true);
            // dispatch(alertMsg(response.exceptionMessage));
            // dispatch(openAlert());
          }
        })
        .catch((error) => {
          setLoader(false);
          setAlertMsg(error);
          setOpenAlert(true);

          // dispatch(alertMsg(error));
          // dispatch(openAlert());
        });
    }
  };

  const CheckWordsSeperate = (row) => {
    var isMakeWordFound = row.some(function (i) {
      const capitalizeWord = i.charAt(0).toUpperCase() + i.slice(1);
      // const makeIndex = makeList[0].findIndex(
      const makeIndex = makeList.findIndex(
        (item) => item.name == capitalizeWord
      );
      if (makeIndex != -1) {
        setIsMakeWord(i);
        setLoader(false);
        const array = {
          maker: makeList?.[makeIndex].name,
          makerID: makeList?.[makeIndex].id,
        };
        setVehicleData([array]);
        // dispatch(makeVehicle(array));
        setSearchData({
          ...searchData,
          category: [],
          brand: [],
          product: [],
          error: "",
        });
        setOpenSelectVehicle(true);
        // dispatch(isVehicleSelect(true)); // vehicle whould be open if vehicle searched
        return makeIndex;
      }
    });
    if (isMakeWordFound != true) {
      getKeyWordSearch(row.join(" ").trim());
    }
  };

  const handleSearch = async (wordEntered, isVehicleCreate) => {
    setLoader(true);
    wordEntered = wordEntered
      ? wordEntered.replace(/[&\/\\#,@+()$~%.'":*?<>{}]/g, "").trim()
      : "";
    wordEntered = wordEntered.replace(/ +(?= )/g, "").trim();

    if (!wordEntered) {
      setLoader(false);
      setEmpty(true);
      return;
    }
    if (wordEntered != "") {
      wordEntered = wordEntered.trim();
      service.insertSearchData(wordEntered);
      setOpenSelectVehicle(false);
      // dispatch(isVehicleSelect(false));
    } else {
      setOpenSelectVehicle(false);
      // dispatch(isVehicleSelect(false));
    }

    // show error if characters are less then 3
    if (
      wordEntered.length > 0 &&
      wordEntered.length < 2 &&
      wordEntered.length != ""
    ) {
      setLoader(false);
      setSearchData({
        ...searchData,
        error: "Ingrese al menos 2 caracteres",
      });
    } else if (wordEntered == "") {
      setLoader(false);
      setWordError(false);
      setSearchData({
        ...searchData,
        category: [],
        brand: [],
        product: [],
        error: "",
      });
    } else if (vehicleCreate != "" || isVehicleCreate != undefined) {
      await getKeywordWithVehicle(
        wordEntered,
        vehicleCreate === "" ? isVehicleCreate : vehicleCreate
      )
        .then((response) => {
          if (response.status === 200) {
            setLoader(false);
            setOpenSelectVehicle(false);
            // dispatch(isVehicleSelect(false));
            setWordError(false);
            setSearchData({
              ...searchData,
              category: response.data.content.categories,
              brand: response.data.content.brands,
              product: response.data.content.products,
              error: "",
            });
            if (
              response.data.content.categories.length == 0 &&
              response.data.content.brands.length == 0 &&
              response.data.content.products.length == 0
            ) {
              setWordError(true);
            }
          } else {
            setLoader(false);
            setAlertMsg(response.exceptionMessage);
            setOpenAlert(true);
            // dispatch(alertMsg(response.exceptionMessage));
            // dispatch(openAlert());
          }
        })
        .catch((error) => {
          setLoader(false);
          setAlertMsg(error);
          setOpenAlert(true);
          // dispatch(alertMsg(error));
          // dispatch(openAlert());
        });
    } else {
      setLoader(true);
      let row = [];
      if (wordEntered.split(" ").length > 1) {
        row = wordEntered.split(" ");
        // for (var i = 0; i < wordEntered.match(/(\w+)/g).length; i++) {
        //   //check words one by one :)
        //   row.push(wordEntered.split(" ")[i]);
        // }
        CheckWordsSeperate(row);
      } else {
        const capitalizeWord =
          wordEntered.charAt(0).toUpperCase() + wordEntered.slice(1);
        const makeIndex = makeList.findIndex((item) =>
          item.name.includes(capitalizeWord)
        );
        // const makeIndex = makeList.findIndex(
        //   (item) => item.name == capitalizeWord
        // );
        if (makeIndex != -1) {
          setLoader(false);
          const array = {
            maker: makeList?.[makeIndex].name,
            makerID: makeList?.[makeIndex].id,
          };
          setVehicleData([array]);
          // dispatch(makeVehicle(array));
          setSearchData({
            ...searchData,
            category: [],
            brand: [],
            product: [],
            error: "",
          });
          setOpenSelectVehicle(true);
          // dispatch(isVehicleSelect(true)); // vehicle whould be open if vehicle searched
        } else {
          getKeyWordSearch(wordEntered);
        }
      }
    }
  };
  const emptyDisplayedResult = () => {
    setSearchData({
      category: [],
      brand: [],
      product: [],
      error: "",
    });
  };

  const handlePress = async (e) => {
    setEmpty(false);
    if (wordEntered.length <= 1) {
      // making the search bar empty when the field is empty
      emptyDisplayedResult();
    }
    if (e.key === "Enter") {
      handleSearch(wordEntered);
    }
  };

  useEffect(() => {
    wordEntered.trim();
    if (wordEntered == " " || wordEntered == null) {
      setIsMakeWord("");
      setOpenSelectVehicle(false);
      // dispatch(isVehicleSelect(false));
    }
  }, [wordEntered]);
  return {
    emptyDisplayedResult,
    searchData,
    handlePress,
    handleFilter,
    clearInput,
    wordEntered,
    setWordEntered,
    history,
    handleSearch,
    dispatch,
    loader,
    setLoader,
    vehicleCreate,
    setVehicle,
    lastElement,
    setWordEntered,
    wordError,
    historyData,
    sethistoryData,
    openSelectVehicles,
    empty,
    setEmpty,
  };
};

export default useForm;
