import React from "react";
import './style.css'
export default function Content() {
  return <div className="termsandcontion">
    <h3>
    Términos y condiciones de uso Ok Autopartes.com 2.0 <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a>
    </h3>
    <p>
    A los usuarios les informamos los siguientes Términos, Condiciones de Uso y Privacidad, que le son aplicables por el siempre hecho del uso de la pagina 
<a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a>
  de Radiadores Frontera S.A. de C.V.  por lo que entenderemos que los aceptan y están de acuerdo en obligarse a su cumplimiento. 
    </p>
    <ul>
      <li>
        <h5>Disposiciones Generales.</h5>
      </li>
      <li>
        <h5>Información y obligación del vendedor.</h5>
        <p>Radiadores Frontera S.A de C.V con domicilio en Avenida Valentín Fuentes, número 32510, en el fraccionamiento Villa Hermosa, Ciudad Juárez Chihuahua México, es quien vende de manera directa, los productos que se ofrecen en la página.  Todas las transacciones realizadas a través de la página, se sujetarán a los términos y condiciones aquí expresados.</p>
      </li>
      <li>
        <h5>Aceptación de los términos y condiciones. </h5>
        <p>Cada vez que un usuario ingrese o tenga al acceso al portal de <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> en adelante la página proporcionada por Radiadores Frontera S.A de C.V y haga uso de la misma como cliente, aceptas los términos y condiciones aquí expresados, por lo que se pide que se revise con detenimiento antes de realizar cualquier transacción. En caso de que no se esté de acuerdo con los presentes términos y condiciones, deberá abstenerse de llevar a cabo cualquier transacción, así como brindar información personal dentro de esta página. Estos términos y condiciones podrán ser modificados ocasionalmente por Radiadores Frontera S.A de C.V</p>
      </li>
      <li>
        <h5>Objeto de la página.</h5>
        <p>El uso de esta página será exclusivamente para que, como cliente consultes de manera personal la información relacionado con los productos de Ok autopartes.com, que están ofertados a la venta a través de la misma. Por lo tanto, no se le puede dar otro uso a la página. La página está protegida con las siguientes características: No se podre copiar, enviar, reproducir, vender, trasmitir, distribuir, bajar, otorgar licencia, modificar, publicar o usar de alguna otra manera al contenido de la Página para fines públicos o comerciales. En ningún caso se podrá entender o interpretar que se está entregando u otorgando alguna concesión o licencia respecto de algún derecho de propiedad intelectual.</p>
      </li>
      <li>
        <h5>Marca y propiedad Industrial.</h5>
        <p>Los derechos de marca y propiedad intelectual respecto de los servicios, contenidos, los Signos distintivos y Dominios de la Página o el Portal, así como los derechos de uso y explotación de los mismos, incluyendo su divulgación, publicación, reproducción, son propiedad exclusiva de Radiadores Frontera S.A de C.V. El usuario no adquiere ningún derecho de propiedad intelectual por el simple uso de los servicios y contenidos de la página y en ningún momento dicho uso será considerado como una autorización o licencia para utilizar los contenidos con fines distintas a los que se contemplan en los presentes términos y condiciones de uso de privacidad. </p>
      </li>
      <li>
        <h5>Obligaciones del cliente.</h5>
        <p>El usuario entendido así, como aquella persona que realiza el acceso mediante equipo de cómputo y /o cualquier dispositivo móvil, conviene a:</p>
        <p>Cada vez que se haga uso de esta Página, se obliga a través de estos Términos y Condiciones a:</p>
        <p>5.1 Hacer uso de esta Página de conformidad con los Términos y Condiciones aquí establecidos, así como las Condiciones de Uso de La Página que podrás encontrar dentro de la misma. </p>
        <p>5.2 Realizar operaciones legítimas, ya sea de manera personal o a nombre de quien esté autorizado para representar legalmente. </p>
        <p>5.3 Ser responsable de todos los cargos, cuotas e impuestos derivados de las transacciones realizadas. </p>
        <p>5.4 Proporcionar información veraz, precisa y legitima que se solicite para llevar a cabo la transacción de compraventa de los productos aquí ofrecidos.</p>
        <p>5.5 No permitir que terceros no autorizados realicen las operaciones a través de la Página sin tu consentimiento.</p>
        <p>5.6 No suplantar a ninguna persona Física o Moral, ni utilizar o proporcionar una dirección de correo falsa o que no corresponda a su persona.</p>
      </li>
      <li>
        <h5>Actualización de información.</h5>
        <p>Radiadores Frontera S. A de C.V., podrá modificar el contenido de la Página en cualquier momento y no asume ningún compromiso de actualización en determinado momento. Como cliente, tiene la posibilidad de consultar, verificar o ratificar la información a través de los datos de contacto que para ese efecto se proporcionan en la página <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a></p>
        <p>6.1 Comunicación con el cliente: Como cliente usted acepta utilizar el correo electrónico como medio de comunicación, por lo que todos los anuncios comerciales, avisos, información o comunicados que se le envíen, se consideraran que reúnen los requisitos legales.</p>
      </li>
      <li>
        <h4>DISPOSICIONES SOBRE LA COMPRA DE PRODUCTOS:</h4>
      </li>
      <li><h5>Condiciones de compra.</h5>
      <p>Los productos comprados por el cliente a través de la página <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> se regirán por las condiciones generales de contratación para la compra venta de productos al por menor, así como por las disposiciones fiscales aplicables. </p>
      </li>
      <li><h5>Moneda de transacción</h5>
      <p>La moneda que se utilizará para las operaciones que se realicen a través de la página <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> será el peso, moneda del curso legal en los Estados Unidos Mexicanos. </p></li>
    <li>
      <h5>Características   calidad de los productos ofrecidos y disponibilidad.</h5>
    <p><a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> se obliga a entregar el producto con las características indicadas en su descripción.  Al igual se hará el mejor esfuerzo por siempre surtir los productos ofertados en la página, sin embargo, no garantiza la disponibilidad de ningún producto ofrecido en la página. <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> se reserva el derecho de descontinuar la venta de cualquier producto ofrecido sin notificación previa.</p>
    <p><a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> informa a todos los usuarios que la cantidad de unidades disponible de cada uno de los auto productos es de carácter estimativo. Es importante señalar que existen errores humanos en los que la Logistica se pueda ver afectada o incidencias de carácter tecnológico y/o informático, lo cual puede hacer que nuestra cantidad ofrecida varié. </p>
    <p>Para el supuesto caso de que el producto nos esté disponible después de haberse efectuado el pago, el equipo de servicio al cliente de <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> se pondrá en contacto vía telefónica y correo con el cliente, explicando las causas y como procederá la cancelación del producto, al igual ofreciéndole un producto alternativo.</p>
    </li>
    <li>
      <h5>Del precio de los productos ofrecidos.</h5>
      <p>El precio ofrecido de cada uno de los productos, y en su caso, las promociones aplicables a los mismos, a través de la página <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> , serán publicados dentro de la misma; dicho precio será perfectamente identificable y atribuible al producto del que se trate. </p>
      <p>Los precios ofrecidos en la página serán únicamente aplicables a las transacciones de compraventa realizadas por este medio. Todos los precios publicados son netos e incluyen IVA.</p>
    </li>
    <li>
    <h5>Del pago de los productos.</h5>
    <p>Para poder llevar a cabo la transacción de compra venta mediante la Página <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> , como cliente deberá contar con:  una tarjeta de crédito o débito debidamente expedida por una Institución Bancaria legalmente constituida bajo las leyes Mexicanas, o bien, depósitos a cuentas bancarias, transferencias electrónicas y depósitos en tiendas de conveniencia,  y que se autorice el cargo que se pretende hacer derivado de la compra de productos. <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> estará en el derecho de variar las tarjetas de instituciones Bancarias que serán aceptadas en el portal.</p>
    <p> En OK autopartes.com ponemos a su disposición el siguiente método de pago: </p>
    </li>
    <li>
      
      <ul>
      <li><h6>Mercado Pago:</h6></li>
      <ul>
      
        <li>
        	  Tarjeta de Crédito: Visa, Master Card
        </li>
        <li>
            Tarjeta de Débito: Banamex, IX, BBVA, Banorte, HSBC, Santander
        </li>
        <li>
            Efectivo: OXXO, 7-Eleven, TELECOMM, BBVA, CITIBANAMEX y SANTANDER
        </li>
        <li>
            Transferencia: BBVA, CITIBANAMEX, SANTANDER, BANORTE Y HSBC
        </li>
        <li>
            Tarjeta Mercado Pago 
        </li>
        <li>
            BNPL
        </li>
      </ul>
     
        </ul> 
    </li>
    <li>
      <h5>Comprobante de compra </h5>
      <p>Una vez realizada la transacción de compraventa a través de la Página <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a>, y que el pago haya sido autorizado por las Institución Bancaria emisora de la tarjeta de crédito o débito, o bien acreditado según corresponda a otro medio de pago, aparecerá una pantalla que contendrá la confirmación y comprobante de compra, al igual se te enviara un correo electrónico En el que se confirme la transacción de compraventa. </p>
      <p>Cada comprobante de compra contiene información indispensable para la entrega de los productos, por lo que no deberás alterar, rayar, modificar o falsificar la información contenida en este documento.</p>
    </li>
    <li>
      <h5>Facturación </h5>
      <p>Para solicitar una factura es necesario que la compra este pagada y el usuario tendrá que introducir sus datos fiscales al momento de la compra. En caso que alguno de los datos esté incorrectos les pedimos se comunique con nosotros para poder solicitar el cambio de sus datos de facturación al teléfono 657 101 3026 de lunes a viernes en un horario de 10:00 am a 5:00pm, para ser atendido. </p>
      <p>Para solicitar una refactura deberá realizarla durante el mes vigente de su compra, Si la compra se realizo durante el último día del mes, tendrá la oportunidad de solicitarla un cambio 2 días posteriores al mes anterior. En caso de caer en días NO laborales la factura saldrá con fecha del siguiente mes.</p>
    </li>
     <li>
      <h5>Entrega y recepción de productos.</h5>
      <p>Cada vez que compres un producto a través de la página <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> , la entrega será de la siguiente manera:</p>
    </li> 
    <h5>Entrega a domicilio </h5>
    <li>
    <p>el tiempo de despacho de la mercancía se realizar en un máximo de 2 días hábiles, notificando al cliente de que su paquete se encuentra listo para ser enviado. Si es un producto extranjero se indica el tiempo de entrega previo a la compra</p>
    </li>
    <li><p>Se te indicará el tiempo en el cual tu compra puede ser entregada, posterior al despacho de la mercancía, la información se podrá revisar en el seguimiento de tu entrega con un código de rastreo acompañado de la compañía que entregará tu producto. 
    </p></li>
    
    <li><p>EL plazo de entrega para servicios con guía aérea es de 1 a 2 días hábiles en toda la república una vez sea despachado el producto. El servicio terrestre es de entre 2 a 5 dias de entrega una vez despachado el producto.  Estimado cliente recuerde que hay entidades remotas en donde la paquetería solo llega ciertos días de la semana. (Estos plazos son promedio, por lo cual es posible que los tiempos de entrega varíen)  </p>
    </li>
    <li><p>Los productos únicamente podrán ser entregados en el domicilio expresado para tal efecto en la transacción. Asimismo, la recepción de productos deberá ser hecha por el cliente, o bien, por la persona autorizada para recibir los productos en la transacción, deberán presentar una identificación oficial con fotografía. </p>
    </li>
    <li><p>Al recibir tus productos, deberás firmar de que recibiste el producto, dicha firma puede ser en papel o digital.</p>
    </li>
    <li><p>Si al momento de la entrega el cliente se encuentra ausente, la paquetería dejara un comprobante indicando como generar una nueva entrega.</p>
    </li>
    <li><p>Si el paquete después de un lapso no es reclamado por el cliente, será regresado a nuestro almacén y generaremos el rembolso. </p>
    </li>
    <li><p>Si el paquete no se entrego por extravió, la compañía de paquetería iniciara una investigación, en estos casos no aplicara un reembolso. </p>
    </li>
    <li><p>Los plazos de entrega fijados en la página están sujetos a demoras de entrega por parte del servicio de mensajería. El cliente usuario podrá contactar al número de atención a clientes de la paqueteria a cargo de su entrega y con el numero de rastro pedir información sobre el status de su paquete. </p>
    </li>
    <li>
    <h5>De las Garantías </h5>
    </li>
    <p>La garantía de cada producto será entregada en el paquete del mismo especificando el tiempo de la garantía correspondiente a las auto partes. Para hacer valida la garantía es necesario que presenten factura, algunos productos incluyen números de serie y tendrán que coincidir con factura, de lo contrario no procederá la garantía. </p>
    <p>En caso de que el producto presente fallas o no cumpla con las expectativas del cliente, el cliente gozará de 3 días naturales para solicitar cualquier devolución correspondiente por fallas en el producto. Dicha reclamación deberá solicitarse en primera instancia por medio de la plataforma <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> seleccionando la opcion “tengo un problema” donde se dará dicha atención a la atención de su garantía en dado caso de no obtener el seguimiento correspondiente ponemos a su disposición vía correo electrónico al correo<a href='https://dev-mispartes.azurewebsites.net/'>garantiasydevoluciones@okautopartes.com</a>  en donde expresará detalladamente y con las evidencias necesarias, el motivo de la devolución. Nuestro equipo de servicio al cliente le indicará como proceder para realizar la devolución. Una vez que el producto se encuentre en las instalaciones de <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> , se analizara que el daño del producto no haya sido provocado por negligencia o mal uso del cliente.  En caso de que el producto fue dañado por el cliente, no se podrá validar dicha garantía. Si el caso fuese una falla de la pieza o bien defecto de fabricación descartando mala manipulación o bien mala instalación, se le enviará al cliente la reposición del producto sin costo adicional. Las garantías de los productos son las mismas que los fabricantes ofrezcan. </p>
    <li>
      <p>La devolución será realizada únicamente por el valor de los productos, los gastos de envió no son reembolsables</p>
    </li>
    <li>
    <p>La devolución será aplicada únicamente a la tarjeta con la que se haya realizado la compra, si es el caso. <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> lo guiara en el proceso de devolución, para asegurarnos de que el efectivo regrese de forma correcta.</p>
    </li>
    <li>
      <p>Si su cuenta fue por depósito bancario o en una tienda de conveniencia, se le realizara el deposito a la cuenta a convenir con el cliente.</p>
    
    </li>
    <h6>8.1 De los cambios.</h6>
    <p>Para los casos de cambios físicos de mercancía, el cliente contará con 3 días naturales a partir de la recepción de su producto notificar en su compra en la sección “tengo un problema con mi producto” donde de manera auto gestionable indicara el cambio que solicite hacer para realizar el cambio de producto en cuyo caso el cliente deberá enviar el producto por su cuenta y riesgo, asumiendo los gastos que esto implique. El cambio se realizará siempre y cuando el producto se encuentre: Nuevo, sin abrir, en su empaque original y sin señales de haber sido usado o probado.  <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> podrá solicitar previo al envió del producto, fotografías y cualquier información que se crea pertinente para analizar el estado del producto. </p>
    <h6>8.2 De la cancelación </h6>
    <p>La cancelación de un producto que ha sido pagado procederá solamente antes de que el cliente sea notificado que el producto ya se encuentra en camino para su entrega, el cliente deberá notificar la razón de la cancelación y el monto será devuelto íntegramente por el medio por el cual se realizo el pago.  En dado caso
Si la cancelación es solicitada posteriormente a que el cliente haya sido notificado de que sus productos se encuentran en camino, los costos de envió de mercancía correrán a cuenta del cliente, el rembolso del monto pagado aplicará únicamente para el producto y no para el pago del servicio de envión. 
</p>
<li>
  <h5>Sobre el uso de promociones. </h5>
  <p>El cliente tiene el derecho de solicitar el uso de todo aquel descuento promocional, ofertado por los medios de comunicación que utilice la empresa. Aplicara al momento de realizar su compra a través de <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> </p>
  <p>Las promociones ofertadas, solo tendrán validez de un periodo que será descrito en dicha promoción y no son de carácter acumulable. </p>
</li>
<li><h5>Confidencialidad </h5></li>
<li><p><a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> se obliga a mantener confidencial la información que reciba del Usuario y que tenga dicho carácter conforme a las disposiciones legales aplicables en los Estados Unidos Mexicanos. </p></li>
<li><p><a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> no asume ninguna obligación de mantener confidencial cualquier otra información que el usuario le proporcione, ya sea al inscribirse al portal o cualquier otro momento posterior, incluyendo aquella información que el usuario proporcione a través de los métodos de comunicación; así como la información que obtenga ⦁	<a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a>  a través de las cookies, llamadas o conversaciones por medios de chat y mensajería electrónica. </p></li>   
<li><h5>Información adicional sobre nuestro sitio </h5></li> 
<p>Puede visitar nuestro sitio sin la necesidad de identificarse o proporcionarnos información. Sin embargo, recopilamos las direcciones de IP de todas las personas que visitan nuestra página y cualquier otro tipo de información como solicitudes de página, tipo de buscador, sistema operativo y el tiempo promedio en el que navegó en nuestro sitio. Utilizamos esta información para monitorear y mejorar nuestra página. </p>   
<li><h5>Medidas de seguridad en nuestro sitio </h5></li>
<p>Contraseñas: Para proporcionarle un mayor nivel de seguridad, el acceso en línea a su información personal está protegido con una contraseña seleccionada por usted. Le recomendamos ampliamente que no divulgue su contraseña con nadie. <a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a> nunca le pedirá su contraseña en cualquier tipo de comunicación que no solicite, (llamadas telefónicas o mensajes electrónicos, mensajería interna). </p>   
<li><h5>Modificaciones </h5></li> 
<p><a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a>, tendrá el derecho de modificar en cualquier momento los Términos y Condiciones de Uso y Privacidad. En consecuencia, el Usuario deber leer atentamente los Términos y Condiciones de Uso y Privacidad cada vez que pretenda utilizar el Portal.   </p>  
<li><h5>Leyes aplicables y jurisdicciones 
</h5></li>   
<p>Para la interpretación, cumplimiento y ejecución de los presentes Términos y Condiciones de Uso y Privacidad, el Usuario está de acuerdo den que serán aplicables las Leyes Federales de los Estados Unidos Mexicanos y competentes los tribunales de la Ciudad de México, renunciando expresamente a cualquier otro fuero o jurisdicción que pudiera corresponderles en razón de sus domicilios presentes o futuros o cualquier otra causa. </p>
<p>
Responsable: Radiadores Frontera S. A de C.V.  (<a href='https://dev-mispartes.azurewebsites.net/'> www.okautopartes.com </a>) Direcciones: Avenida Valentín Fuentes #1643, Fraccionamiento Villa Hermosa CP.32510, Ciudad Juárez Chihuahua México</p> 
    
    
    
    </ul>
   
    
  


  </div>;
}
