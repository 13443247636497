import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ApplicationContext from "../../context-api/context";
import { alertMsg, openAlert } from "../../Redux/reducers/alertSlice";
import { resetFilter, checkVehicle } from "../../Redux/reducers/filterSlice";
import { getProducts } from "../../utils/api";
import queryString from "query-string";

export default function useForm() {
  const { setAlertMsg, setOpenAlert, alertMsg } =
    useContext(ApplicationContext);
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const errorMsg = alertMsg;
  // const errorMsg = useSelector((state) => state.alert.msg);
  const selected_dynamicFilter = useSelector(
    (state) => state.filter.dynamicState
  );
  const addCar = useSelector((state) => state.filter.addCar);
  const selected_Badge = useSelector((state) => state.filter.SelectedBtn);
  const selected_Link = useSelector((state) => state.filter.linkState);
  const selected_CheckBadge = useSelector((state) => state.filter.checkedState);
  const pageSize = useSelector((state) => state.filter.pageSize);
  const pageNum = useSelector((state) => state.filter.pageNum);
  const orderby = useSelector((state) => state.filter.orderby);
  const _ratingCheckedState = useSelector(
    (state) => state.filter.ratingCheckState
  );
  const _isVehicleChange = useSelector((state) => state.filter.isVehicleChange);
  const _isCategoryChange = useSelector(
    (state) => state.filter.isCategoryChange
  );

  const [productItem, setProductItem] = useState({
    products: [],
    brands: [],
    dynamicFilter: [],
    categories: [],
  });
  const [loader, setloader] = useState(false);
  const [pageProduct, setpageProduct] = useState([]);
  const [requestHit, setRequestHit] = useState(false);
  const category = selected_Link.map((item) => decodeURI(item.type));
  const subCategory = selected_Link.map((item) => decodeURI(item.value));
  const priceMax = selected_Badge.map((item) => item.max);
  const priceMin = selected_Badge.map((item) => item.min);
  const brands = selected_CheckBadge.map((item) =>
    item.type == "Marca" && item.name != null ? item.name : []
  );
  // if rating is not set send -1 as all
  const ratingValue =
    _ratingCheckedState != null ? _ratingCheckedState.value : -1;

  const history = useHistory();
  // for reset filters incase category or vehicle change

  const brandsFromURL = () => {
    let brand = queryString.parse(window.location.search);
    if (brand && brand.marca) {
      return {
        brand: Array.isArray(brand.marca) ? brand.marca : [brand.marca],
      };
    } else {
      return [];
    }
  };

  const urlDecoded = (string) => {
    const newText = decodeURI(string);
    return newText;
  };
  const PathFromUrl = () => {
    let path = urlDecoded(window.location.pathname);
    let withoutHtml = path?.replace(/.html+/g, "");
    let stringPath = withoutHtml?.replace(/[/]/g, "");
    let arr = stringPath?.split("-");
    if (arr.length === 3) {
      // it means its vehicle from url
      return {
        vehicle: `${arr[0]}+${arr[1]}+${arr[2]}`,
        category: "",
        subCategory: "",
      };
    } else if (arr.length === 2) {
      // it means its category and subCategory
      return {
        vehicle: "",
        category: arr[0],
        subCategory: arr[1],
      };
    } else if (arr.length === 5) {
      // it means its category,subCategory and vehicle
      return {
        vehicle: `${arr[0]}+${arr[1]}+${arr[2]}`,
        category: arr[3],
        subCategory: arr[4],
      };
    }
  };
  // create array formate for dynamic filter
  const findType = () => {
    let array = [];
    const arrayFor = selected_dynamicFilter.forEach((element) => {
      if (array.length) {
        for (let index = 0; index != array.length; index++) {
          const item = array[index];
          // find the index of array type
          let myIndex = array.findIndex((item) => item.name === element.type);
          if (myIndex != -1) {
            if (element.type == array[myIndex].name) {
              return array[myIndex].types.push(element.name);
            }
          } else {
            return array.push({
              name: element.type,
              types: [element.name],
            });
          }
        }
      } else {
        return array.push({
          name: element.type,
          types: [element.name],
        });
      }
    });
    return array;
  };
  const encodeMake = addCar.make?.value.replaceAll("%2B", "-");
  const encodeModel = addCar.model?.value.replaceAll("%2B", "-");
  const brandFromURL = PathFromUrl(); // used to get category and subCategory properties

  const array = {
    pageNo: parseInt(pageNum),
    pageSize: parseInt(pageSize),

    // category: category[0] != null ? category[0] : "",
    // subCategory:
    //   subCategory[0] != "null" && subCategory[0] != undefined
    //     ? subCategory[0]
    //     : "",
    // brands: brands,
    // vehicle:
    //   addCar.length != 0 && addCar.make != null
    //     ? `${decodeURI(encodeMake)}${"+"}${decodeURI(
    //         encodeModel
    //       )}${"+"}${decodeURI(addCar.year?.value)}`
    //     : "",

    vehicle: brandFromURL.vehicle ?? "", //FIX:Temporary handling of more API callings by direct fetching from the url
    category: brandFromURL.category ?? "", //FIX:Temporary handling of more API callings by direct fetching from the url
    subCategory: brandFromURL.subCategory ?? "", //FIX:Temporary handling of more API callings by direct fetching from the url
    brands: brandsFromURL().brand || [], //FIX:Temporary handling of more API callings by direct fetching from the url

    priceMin: priceMin[0] == undefined ? 0 : parseInt(priceMin[0]),
    priceMax: priceMax[0] == undefined ? 5000 : parseInt(priceMax[0]),
    rating: ratingValue ? ratingValue : -1,
    isUpdatedVehicle: addCar.length != 0 && addCar.make != null ? true : false,
    updatedProducts:
      parseInt(pageNum) > 1 || parseInt(pageSize) > 10 ? true : false,
    orderby: orderby,
    attributes: findType().length != 0 ? findType() : null,
  };

  const checkinCategory = () => {
    const array = {
      type: "vehicle",
      data: addCar,
    };
    dispatch(checkVehicle(array));
    dispatch(checkVehicle(category[0]));
    if (_isCategoryChange == category[0]) {
      return;
    } else {
      return dispatch(resetFilter("All"));
      // , history.push({ search: "" });
    }
  };
  useEffect(() => {
    const getProductList = async () => {
      setloader(true);
      checkinCategory();
      if (category.length > 0) {
        await getProduct();
      }
    };
    getProductList();
  }, [
    pageNum,
    pageSize,
    selected_Badge,
    selected_Link,
    addCar,
    selected_CheckBadge,
    _ratingCheckedState,
    selected_dynamicFilter,
    orderby,
  ]);
  async function getProduct() {
    if (
      (array.category !== "" && array.subCategory !== "") ||
      array.vehicle != ""
    ) {
      try {
        let response = await getProducts(array);
        if (response && response?.status === 200) {
          setloader(false);
          // if only pagination then only products update
          if (parseInt(pageNum) != 1 || parseInt(pageSize) != 10) {
            setpageProduct(response.data.content.products);
          } else {
            // otherwise update all category,product
            setpageProduct(response.data.content.products);
            setProductItem({
              ...productItem,
              brands: response.data.content.brands,
              categories: response.data.content.categories,
              dynamicFilter: response.data.content.dynamicFilters,
            });
          }
        } else {
          setloader(false);
          setOpenAlert(true);
          setAlertMsg(response.exceptionMessage);
        }
      } catch (error) {
        setloader(false);
        setAlertMsg(error);
        setOpenAlert(true);
      }
    }
  }

  return {
    openPopup,
    setOpenPopup,
    errorMsg,
    addCar,
    pageProduct,
    productItem,
    setProductItem,
    loader,
    setloader,
    encodeMake,
    encodeModel,
  };
}
