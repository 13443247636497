import { combineReducers } from "@reduxjs/toolkit";
import alertSlice from "./alertSlice";
import authSlice from "./authSlice";
import filterSlice from "./filterSlice";
import vehicleSlice from "./vehicleSlice";

const rootReducer = combineReducers({
  filter: filterSlice,
  auth:authSlice,
  alert:alertSlice,
  vehicleCreate:vehicleSlice
});

export default rootReducer;
