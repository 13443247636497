import React from "react";
import MainLayout from "../../Component/layout/layout";
import MainHeader from "../../Sections/header";
import MetaTitle from "../../Component/metaTag";
import { metaPath } from "../../common/services";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { path } from "../../common/routes-names";
import { useMercadopago } from "react-sdk-mercadopago";
import { Confirm_Alert, CustomButton } from "../../Component";

import { error, mispartes, TokenKey } from "../../common/constants";
import "./style.scss";

const MercadoPago = (props) => {
  const obj1 = {
    cardNumber: "5031433215406351",
    cardholderName: "ANyName",
    securityCode: "123",
    cardExpirationMonth: "11",
    cardExpirationYear: "25",
  };

  const mercadopago = useMercadopago.v2(TokenKey, {
    locale: "en-US",
  });
  useEffect(() => {
    if (mercadopago) {
      console.log(mercadopago);
    }
  }, [mercadopago]);

  const createToken = async () => {
    const cardToken = await mercadopago.createCardToken(obj1);
    console.log(cardToken.id); //This is the token that you need
  };
  return (
    <div>
      <CustomButton
        title="title"
        className="primaryBtn"
        onClick={() => {
          createToken();
        }}
      ></CustomButton>
    </div>
  );
};

export default MercadoPago;
