import React from "react";
import { makeStyles, Drawer, List, ListItem } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { backToMain } from "../../common/constants";
import data from "../../common/Data.json";
import SearchProduct from "../searchBar/searchProduct";
import "./style.scss";
import Fade from "react-reveal/Fade";
import { companyLogo } from "../../common/Images";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  },
});

export default function MenuDrawer(props) {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState(false);
  const [menuName, setMenuName] = React.useState(null);
  const list = () => {
    let arr = menuName
      ? menuName === "Refacciones"
        ? props.array
        : data
      : props.sectionArray;
    const clickListener = (text) => {
      if (!menuName) {
        return setMenuName(text);
      }
    };
    return (
      <div
        className="drawer"
        role="presentation"
        onKeyDown={() => {
          setState(false);
        }}
      >
        <List className="listWrap">
          {arr.map((text, index) => (
            <ListItem
              button
              key={index}
              onClick={() => clickListener(text.name)}
              className={!menuName ? "" : "itemWrap"}
            >
              {!menuName ? (
                <>
                  <div className="list_Item" style={{ marginTop: ".5rem" }}>
                    <a>{text.name}</a>
                    <Fade left>
                      <ChevronRightIcon />
                    </Fade>
                  </div>
                </>
              ) : menuName === "Refacciones" ? (
                <>
                  <Fade right>
                    <div
                      key={text.id}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <SearchProduct
                        key={text.id}
                        image={text.image}
                        row={true}
                        category={text?.name}
                        onClick={() => {
                          history.push(`/${text.name}.html`);
                          setState(false);
                        }}
                      />
                    </div>
                  </Fade>
                </>
              ) : (
                <>
                  <>
                    <Fade right>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      ></div>
                    </Fade>
                  </>
                </>
              )}
            </ListItem>
          ))}
        </List>
      </div>
    );
  };

  return (
    <div>
      <>
        <button
          onClick={() => {
            setState(true);
            setMenuName(null);
          }}
          className="navbar-toggler"
          type="button"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Drawer
          anchor={"left"}
          open={state}
          onClose={() => {
            setState(false);
          }}
          transitionDuration={{ enter: 1000, exit: 500 }}
        >
          {!menuName && (
            <div>
              <img src={companyLogo} className="companyLogo" />
            </div>
          )}
          {menuName && (
            <>
              <ListItem
                button
                onClick={() => setMenuName(null)}
                className="list_Item  _back"
              >
                <ChevronLeftIcon />
                <a>{backToMain}</a>
              </ListItem>

              <p className="headings">{menuName}</p>
            </>
          )}
          {list()}
        </Drawer>
      </>
    </div>
  );
}
