import React from "react";
import "./style.scss";
import { ListGridViewProduct, GridSkelton } from "../../Component/index";
import {
  page_size,
  resultsNotFound,
  showItems,
  sortBy,
  sortByValue,
  _Home,
} from "../../common/constants";
import Badges from "./badge";
import { useDispatch } from "react-redux";
import {
  addLinks,
  removeBadge,
  setCheckBox,
  setRatingBox,
} from "../../Redux/reducers/filterSlice";
import { NavLink, Link } from "react-router-dom";
import { path } from "../../common/routes-names";
import Use_Pagination from "../../Component/pagination";
import ControlledSelect from "../../Component/controls/materialDropDown.js";
import useForm from "./useForm";
import { regexImg } from "../../common/services";
import { useHistory } from "react-router";
import queryString from "query-string";

const SortedProducts = ({ product, loader, categories, listView }) => {
  const {
    selected_Badge,
    selected_Check,
    selected_Link,
    Gridview,
    active,
    activeb,
    func1,
    handleChange,
    func3,
    pageSize,
    DynamicUrl,
    selected_rating,
    default_Category,
    setopen,
    open,
    defaultValue,
    setDefaultValue,
    selected_dynamicFilter,
    sorting,
    handleSort,
    newQueryParam,
    removeRatingParam,
    removeBrandParam,
    removeDynamicParam,
    removePriceBadge,
  } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <>
      <div className="sortedProductsWrapper">
        <div className="stagingLinks">
          <Link to={path.home} className="Links pl-0 first">
            {_Home}
          </Link>{" "}
          {selected_Link.length > 0 &&
            selected_Link.map((item) =>
              (item.make &&
                item.model &&
                item.year &&
                item.type &&
                item.value) ||
              item.type ? (
                <>- </>
              ) : null
            )}
          {selected_Link.length > 0 &&
            selected_Link.map((item) => (
              <NavLink
                to={
                  item.type && item.value && item.make
                    ? `${item.make}-${item.model}-${item.year}-${item.type}-${item.value}.html`
                    : item.type && item.make
                    ? `${item.make}-${item.model}-${item.year}-${item.type}-${item.value}.html`
                    : item.value
                    ? `${item.type}-${item.value}.html`
                    : `/${item.type}.html`
                }
                activeClassName="Links activeLinks"
                className="Links pl-0 first activeLinks"
              >
                {decodeURI(item.type)}
                <div className="moreOption">
                  <div className="moreOptionInner">
                    <ul>
                      {categories.length != 0 ? (
                        <>
                          {categories.map((type, index) => {
                            const array = {
                              type: type.name,
                              value: type.types[0].name,
                              id: item.id,
                              make: item.make,
                              model: item.model,
                              year: item.year,
                            };
                            return (
                              <>
                                <li>
                                  <Link
                                    key={index}
                                    id={index}
                                    to={
                                      type.value && item.make
                                        ? `${item.make}-${item.model}-${item.year}-${type.name}-${type.value}.html`
                                        : `${item.make}-${item.model}-${item.year}-${type.name}-${type.types[0].name}.html`
                                    }
                                    value={type.name}
                                    onClick={() => {
                                      dispatch(addLinks(array));
                                      setDefaultValue(type.types[0].name);
                                    }}
                                  >
                                    {decodeURI(type.name?.replaceAll("+", " "))}
                                  </Link>
                                </li>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {default_Category.map((category) => {
                            return (
                              <>
                                {category.map((type, index) => {
                                  const array = {
                                    type: type.name,
                                    value: type.types[0].name,
                                  };
                                  return (
                                    <li>
                                      <Link
                                        key={index}
                                        id={index}
                                        to={
                                          type.value && item.make
                                            ? `${item.make}-${item.model}-${item.year}-${type.name}-${type.value}.html`
                                            : type.name && item.make
                                            ? `${item.make}-${item.model}-${item.year}-${type.name}.html`
                                            : type.value
                                            ? `${type.name}-${type.value}.html`
                                            : `/${type.name}-${type.types[0].name}.html`
                                        }
                                        value={type.name}
                                        onClick={() => {
                                          dispatch(addLinks(array));
                                          setDefaultValue(type.types[0].name);
                                        }}
                                      >
                                        {decodeURI(
                                          type.name?.replaceAll("+", " ")
                                        )}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </>
                            );
                          })}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </NavLink>
            ))}{" "}
          {selected_Link.length > 0 &&
            selected_Link.map((item) =>
              (item.make &&
                item.model &&
                item.year &&
                item.type &&
                item.value) ||
              (item.type && item.value) ||
              (item.type && defaultValue) ? (
                <>- </>
              ) : null
            )}
          {selected_Link.length > 0 &&
            selected_Link.map((item) => (
              <NavLink
                to={
                  item.type && item.value && item.make
                    ? `${item.make}-${item.model}-${item.year}-${item.type}-${item.value}.html`
                    : item.type && item.make
                    ? `${item.make}-${item.model}-${item.year}-${item.type}.html`
                    : item.value
                    ? `${item.type}-${item.value}.html`
                    : `/${item.type}.html`
                }
                activeClassName="Links activeLinks"
                className="Links pl-0 first activeLinks"
              >
                {decodeURI(
                  item.value != null
                    ? item.value?.replaceAll("+", " ")
                    : item.value == null && item.type
                    ? defaultValue
                    : ""
                )}

                {categories != 0 ? (
                  <>
                    {categories.map((category) => {
                      return (
                        <>
                          {category.types.length != 1 && (
                            <>
                              {category.name == item.type ? (
                                <>
                                  <div className="moreOption">
                                    <div className="moreOptionInner">
                                      <ul>
                                        {category.types.map((i, index) => {
                                          const array = {
                                            make: item.make,
                                            model: item.model,
                                            year: item.year,
                                            type: item.type,
                                            value: i.name,
                                          };
                                          return (
                                            <li>
                                              <Link
                                                to={
                                                  i.name && item.make
                                                    ? `${item.make}-${item.model}-${item.year}-${item.type}-${i.name}.html`
                                                    : item.type && item.make
                                                    ? `${item.make}-${item.model}-${item.year}-${item.type}.html`
                                                    : i.name
                                                    ? `${item.type}-${i.name}.html`
                                                    : `/${item.type}.html`
                                                }
                                                key={index}
                                                value={decodeURI(i.name)}
                                                onClick={() =>
                                                  dispatch(addLinks(array))
                                                }
                                              >
                                                {i.name}
                                              </Link>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {default_Category.map((category) => {
                      return (
                        <>
                          {category.map((category, index) => {
                            return (
                              <>
                                {category.types.length != 1 && (
                                  <>
                                    {category.name == item.type ? (
                                      <>
                                        <div className="moreOption">
                                          <div className="moreOptionInner">
                                            <ul>
                                              {category.types.map(
                                                (i, index) => {
                                                  const array = {
                                                    make: item.make,
                                                    model: item.model,
                                                    year: item.year,
                                                    type: item.type,
                                                    value: i.name,
                                                  };
                                                  return (
                                                    <li>
                                                      <Link
                                                        to={
                                                          i.name && item.make
                                                            ? `${item.make}-${item.model}-${item.year}-${item.type}-${i.name}.html`
                                                            : item.type &&
                                                              item.make
                                                            ? `${item.make}-${item.model}-${item.year}-${item.type}.html`
                                                            : i.name
                                                            ? `${item.type}-${i.name}.html`
                                                            : `/${item.type}.html`
                                                        }
                                                        key={index}
                                                        value={decodeURI(
                                                          i.name
                                                        )}
                                                        onClick={() =>
                                                          dispatch(
                                                            addLinks(array)
                                                          )
                                                        }
                                                      >
                                                        {i.name}
                                                      </Link>
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                  </>
                                )}
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                  </>
                )}
              </NavLink>
            ))}{" "}
        </div>
        <div className="header">
          <div className="tagsOuter">
            {selected_Badge.length > 0 &&
              selected_Badge.map((item) => (
                <Badges
                  categoryName={item.type}
                  type={item.type}
                  value={item.name}
                  minValue={item.min}
                  maxValue={item.max}
                  onClick={() => {
                    dispatch(removeBadge(item));
                    removePriceBadge();
                  }}
                />
              ))}
            {selected_Check.length > 0 &&
              selected_Check.map((item) => (
                <Badges
                  categoryName={`${item.type}`}
                  type={item.type}
                  value={item.label}
                  onClick={() => {
                    dispatch(setCheckBox(item));
                    removeBrandParam(item);
                  }}
                />
              ))}
            {selected_rating.length > 0 &&
              selected_rating.map((item) => (
                <Badges
                  categoryName={`${item.type}`}
                  type={item.type}
                  value={item.label}
                  onClick={() => {
                    dispatch(setRatingBox(item));
                    removeRatingParam(item.value);
                  }}
                />
              ))}
            {selected_dynamicFilter.length > 0 &&
              selected_dynamicFilter.map((item) => (
                <Badges
                  categoryName={`${item.type}`}
                  type={item.type}
                  value={item.label}
                  onClick={() => {
                    dispatch(setCheckBox(item));
                    removeDynamicParam(item.label, item.type);
                  }}
                />
              ))}
          </div>
          <div className="viewIconsOuter">
            <div className="pageSort">
              <ControlledSelect
                options={sortBy}
                value={sorting}
                onChange={handleSort}
                label={sortByValue}
                open={open}
                onOpen={() => setopen(true)}
                onClose={() => setopen(false)}
              />
            </div>
            <div className="pageSize 1">
              <ControlledSelect
                options={page_size}
                value={pageSize}
                onChange={handleChange}
                label={showItems}
                open={open}
                onOpen={() => setopen(true)}
                onClose={() => setopen(false)}
              />
            </div>
            <div className="grids">
              <span className={active} onClick={func1}></span>
              <span className={activeb} onClick={func3}></span>
            </div>
          </div>
        </div>
        <div className="productsOuter">
          {loader || product.length == 0 ? (
            <>
              <div className="inner">
                {[1, 2, 3, 4, 5].map((i) => {
                  return <GridSkelton gridView={Gridview} key={i} />;
                })}
              </div>
            </>
          ) : (
            <>
              {product.items.length != 0 ? (
                <>
                  <div className="inner">
                    {product.items.map((i) => {
                      return (
                        <>
                          <ListGridViewProduct
                            key={i.productID}
                            pathName={DynamicUrl(
                              i.brand,
                              i.partNo,
                              i.productName
                            )}
                            productObject={i}
                            param={i.encryptedProductID}
                            imag={i.image && regexImg(i.image)}
                            gridView={Gridview}
                            _availableIn={i.availableIn}
                            _quantity={i.quantity ?? 0}
                            _ratingValue={i.rating}
                            _warranty={i.warranty}
                            _discountPrice={i.price}
                            _orignalPrice={i.basePrice}
                            _productId={i.productID}
                            _partNo={i.partNo}
                            _productName={i.productName}
                            _available={i.stock}
                            _markValue={i.brand}
                            _comment={
                              i.vehicle != null ? i.vehicle.comments : null
                            }
                            shipping_Cost={i.shippingCost}
                            _position={
                              i.vehicle != null ? i.vehicle.position : null
                            }
                            _application={
                              i.vehicle != null ? i.vehicle.applications : null
                            }
                            listView={listView}
                            requestPopupHandler={() => {
                              // setRequestPopup(true);
                            }}
                          />
                        </>
                      );
                    })}
                  </div>
                  {product.count > 1 && (
                    <Use_Pagination count={product.count} page={product.page} />
                  )}
                </>
              ) : (
                <div className="inner noResult">
                  <h1>{resultsNotFound}</h1>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default SortedProducts;
