import React, { useState, useEffect, useContext, useRef } from "react";
import { CustomButton, CloseButton } from "../../Component/index";
import { RadioBox } from "../../Component/radio-address/index";
import { Collapse, colors } from "@material-ui/core";
import "../../Component/customModal/style.scss";
import { useMercadopago } from "react-sdk-mercadopago";
import { Container, CircularProgress, Box } from "@material-ui/core";
import {
  addBillingBtn,
  AddNew,
  billingAddres,
  Continue,
  creditDebit,
  paymentMethod,
  payWithMercadoPago,
  payMethod,
  ShippingAddres,
  noInvoiceWant,
  TokenKey,
  error,
  selectPaymentMethod,
  debitCardPayment,
} from "../../common/constants";
import useForm from "../../Component/customModal/useForm";
import PaymentForm from "../../Component/addressForm/paymentForm";
import {
  getCheckoutAddresses,
  getCheckoutCardsWithToken,
  getCheckoutInvoices,
} from "./../../utils/api";
import { numericOnly } from "../../common/formValidation";
import { getCheckoutWithToken } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
// import { alertMsg, openAlert } from "../../Redux/reducers/alertSlice";
import ApplicationContext from "../../context-api/context";
import BillBox from "../../Component/billingBox/index";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextInput, GenricDropDown } from "../../Component/index";
import {
  cvvValidation,
  taxRegimenValidation,
} from "../../common/formValidation";
import MercadoPago from "../mercado-pago";
import PageNotFound from "../../Component/confirm-alert/error404";
import { PagesOutlined } from "@material-ui/icons";
import AlertBox from "../../Component/confirm-alert/alertBox";

const TokenForMobile = ({
  close,
  shipping,
  payment,
  billAddress,
  ...props
}) => {
  const { show, setShow, record, setOnSelect, addAddress, handleSubmit } =
    useForm(close);

  const [cvv, setCvv] = useState("");
  const [cardSaveCard, setCardSaveCard] = useState(false);
  const [data, setData] = useState("");
  const [url, setUrl] = useState("");
  const [inputForCvv, setInputForCvv] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [paymentDataList, setPaymentDataList] = useState([]);
  const [paymentSelectedId, setPaymentSelectedId] = useState(0);
  const [paymentSelectedType, setPaymentSelectedType] = useState(0);
  const [showBankModal, setshowBankModal] = useState(false);
  const [showStoreModal, setshowStoreModal] = useState(false);
  const [fetchDataToggle, setFetchDataToggle] = useState(false);
  const [checkoutObject, setCheckObject] = useState(null);
  const [cardName, setCardName] = useState("");
  const [checkoutDataPresent, setCheckoutDataPresent] = useState(false);
  const dispatch = useDispatch();
  const {
    selectedCheckoutBillingAddreess,
    setSelectedCheckoutBillingAddreess,
    selectedCheckoutShippingAddreess,
    setSelectedCheckoutShippingAddreess,
    selectedCheckoutPayment,
    setSelectedCheckoutPayment,
    banksCheckoutList,
    storesCheckoutList,
    setBanksCheckoutList,
    setStoresCheckoutList,
    checkoutFormValidation,
    setCheckoutFormValidation,
    setRegimenInvoiceList,
    setPurchaseReasonInvoiceList,
    setOpenAlert,
    setAlertMsg,
    alertMsg,
    openAlert,
  } = useContext(ApplicationContext);

  const formRef = useRef();
  const cvvFormRef = useRef();
  let cvvVar = 0;
  const numConverter = (input) => {
    input = input.replace(/^(\d{0,4})(\d{0,3})/, "$1 $2");
    var prefix = input.substr(input.length - 4);
    var suffix = input.substr(-4);
    var masked = prefix.replace(/\d/g, "*");
    var a = masked + suffix;
    return a;
  };

  useEffect(() => {
    setInputForCvv(true);
  }, []);

  const generateTokenForCVV = async (input) => {
    const newPaymentMethod = {
      ...selectedCheckoutPayment,
    };

    if (input) {
      const marcadoObj = {
        cardId: selectedCheckoutPayment.id,
        securityCode: input,
      };
      try {
        setButtonLoader(true);
        // in case of payment-Type 1
        const cardToken = await mercadopago.createCardToken(marcadoObj);
        setButtonLoader(false);

        //This is the token that you need
        const jsonToBeSend = {
          paymentType: 1,
          cardToken: cardToken.id,
          cardPaymentTypeID: newPaymentMethod.cardBrand,
          cardSaveCard: cardSaveCard,
          name: newPaymentMethod.name,
        };
        // sending Json to mobile
        sendJsonToMobile(jsonToBeSend);
      } catch (error) {
        setOpenAlert(true);
        setAlertMsg(error[0]?.message || "Someting went wrong with payment");
      }
    } else {
      newPaymentMethod.CardToken = "";
      newPaymentMethod.CardPaymentTypeID = "";
    }

    setSelectedCheckoutPayment(newPaymentMethod);
  };

  const obj1 = {
    cardId: cardNumber,
    securityCode: cvv,
  };
  const mercadopago = useMercadopago.v2(TokenKey, {
    locale: "en-US",
  });

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }
  const TokenFromUrl = () => {
    const token = useQuery().get("token");
    if (token) {
      setUrl(true);
    } else {
      setUrl(false);
    }
  };

  useEffect(() => {
    TokenFromUrl();
  }, []);

  const sendJsonToMobile = (json) => {
    const jsonToBeSendStringy = JSON.stringify(json);
    if (window.ReactNativeWebView) {
      // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage(jsonToBeSendStringy);
      setLoader(false);
    } else {
      console.log("Json is ", jsonToBeSendStringy);
    }
  };

  async function getData() {
    await getCheckoutWithToken()
      .then((response) => {
        if (response.status === 200) {
          const {
            data: {
              content: { defaultCard, banks, stores },
            },
          } = response;
          setCheckoutDataPresent(true);
          setCheckObject(response.data.content || null);
          setSelectedCheckoutPayment(defaultCard || null);
          setBanksCheckoutList(banks || null);
          setStoresCheckoutList(stores || null);
        } else {
          setOpenAlert(true);
          setAlertMsg(response.ExceptionMessage);
          // dispatch(openAlert());
          // dispatch(alertMsg(response.ExceptionMessage));
        }
      })
      .catch((error) => {
        setOpenAlert(true);
        setAlertMsg(error);
        // dispatch(openAlert());
        // dispatch(alertMsg(error));
      });
  }
  const handleClose = () => {
    setShow(false);
    close();
  };
  const handleChange = (e) => {
    const { value } = e.target.name;
    if (value === "" || numericOnly.test(value)) {
      setCvv(value);
    }
  };

  const closeOthers = () => {
    setshowBankModal(false);
    setshowStoreModal(false);
    setShow(false);
  };

  const handleBanksModal = () => {
    setshowBankModal(!showBankModal);
    setshowStoreModal(false);
    setInputForCvv(false);
    setShow(false);
  };

  const handleStoresModal = () => {
    setshowBankModal(false);
    setInputForCvv(false);
    setshowStoreModal(!showStoreModal);
    setShow(false);
  };

  const OnclickClose = () => {
    setPaymentSelectedId(0);
    setShow(!show);
  };

  const closePaymentModals = (selectedPaymentType) => {
    if (selectedPaymentType === 2) {
      setshowBankModal(true);
      setshowStoreModal(false);
      setShow(false);
    } else if (selectedPaymentType === 3) {
      setshowStoreModal(true);
      setshowBankModal(false);
      setShow(false);
    } else {
      closeOthers();
    }
  };

  useEffect(() => {
    getListData();
  }, [fetchDataToggle]);

  const getListData = async () => {
    // ===== It is use to get cards for the checkout page ===== //
    setLoader(true);
    const response = await getCheckoutCardsWithToken();
    if (response) {
      const {
        data: { content },
      } = response;
      if (content?.length === 0) {
        setInputForCvv(false);
      }
      if (selectedCheckoutPayment) {
        closePaymentModals(selectedCheckoutPayment.paymentType);
        changeIsDefaultToOther(
          content,
          setPaymentDataList,
          selectedCheckoutPayment.id
        );
      } else {
        setPaymentDataList(content);

        setLoader(false);
      }
    }
  };

  const changeIsDefaultToOther = (dataList, setDatalist, selectedId) => {
    dataList.forEach((value, index) => {
      dataList[index].isDefault = value.id === selectedId;
    });
    setDatalist(dataList);
  };

  const newInsertedCard = (data) => {
    setSelectedCheckoutPayment(data);
    setCardSaveCard(data.cardSaveCard);
    const jsonToBeSend = {
      paymentType: data.paymentType,
      cardToken: data.CardToken,
      cardPaymentTypeID: data.CardPaymentTypeID,
      cardSaveCard: data.CardSaveCard,
      name: data.name,
    };
    sendJsonToMobile(jsonToBeSend);
    handleClose();
  };

  useEffect(() => {
    if (checkoutObject == null) {
      getData();
    }
  }, []);

  const createToken = async () => {
    setLoader(true);
    const cardToken = await mercadopago.createCardToken(obj1);
    //This is the token that you need
    const Token = JSON.stringify(cardToken.id);
    if (window.ReactNativeWebView) {
      // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      console.log("Message Posted to webview");
      window.ReactNativeWebView.postMessage(Token);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  return (
    <>
      {url ? (
        <Container>
          {loader ? (
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={50} color="black" />{" "}
            </Box>
          ) : (
            <div>
              <div className="headline">
                <text className="texthead">{paymentMethod}</text>
              </div>
              <div className="upperFormContainer">
                <div className="formContainers">
                  {/* <RadioBox onClick={() => setShow(!show)}> */}
                  {/* <div className="upperContainer">
                    <BillBox
                      payment={creditDebit}
                      icon={3}
                      paymentType={3}
                      onClick={OnclickClose}
                    >
                      <Collapse
                        in={show}
                        className="collapseOpen"
                        classes={{
                          wrapper: "wrapperCollapse",
                        }}
                      >
                        <div className="formStyle">
                          <PaymentForm
                            checkout
                            addOrEdit={addAddress}
                            newCard={newInsertedCard}
                          />
                        </div>
                      </Collapse>
                    </BillBox>
                  </div> */}
                  {/* </RadioBox> */}
                </div>

                <div className="actualAddress">
                  {/* {paymentDataList.map((item) => {
                    const { id, name, cardBrand, isDefault, paymentType } =
                      item;
                    return (
                      <RadioBox
                        key={id}
                        onClick={() => {
                          setSelectedCheckoutPayment(item);
                          setPaymentSelectedType(1);
                          setInputForCvv(true);
                          setCardNumber(id);
                          setData(cardBrand);
                          setCardName(name);
                          changeIsDefaultToOther(
                            [...paymentDataList],
                            setPaymentDataList,
                            id
                          );
                        }}
                        select={isDefault}
                      >
                        <BillBox
                          payment={name}
                          icon={cardBrand}
                          paymentType={paymentType}
                        />
                      </RadioBox>
                    );
                  })} */}
                  <RadioBox
                    onClick={() => {
                      setPaymentSelectedType(5);
                      setInputForCvv(false);
                    }}
                    select={paymentSelectedType === 5 ? true : false}
                  >
                    <BillBox
                      bank={debitCardPayment}
                      icon={"images/wallet.png"}
                      marcadoCardPayment
                      marcadoImage={"images/marcadoCard.png"}
                      onClick={closeOthers}
                    />
                  </RadioBox>
                  {payMethod.map((item) => {
                    const { id, name, paymentType } = item;
                    return (
                      <div className="upperContainer">
                        <BillBox
                          payment={name}
                          icon={id}
                          paymentType={paymentType}
                          onClick={
                            id === 2 ? handleBanksModal : handleStoresModal
                          }
                        >
                          <Collapse
                            in={id === 2 ? showBankModal : showStoreModal}
                            className="collapseOpen"
                            classes={{
                              wrapper: "wrapperCollapse",
                            }}
                          >
                            {id === 2 ? (
                              <div className="formStyle banksubchild">
                                {banksCheckoutList.map((bankitem) => {
                                  const { image, name, id, paymentType } =
                                    bankitem;
                                  const newId = id + "_" + paymentType;
                                  return (
                                    <RadioBox
                                      key={id + paymentType}
                                      onClick={() => {
                                        //setSelectedCheckoutPayment(bankitem);
                                        setPaymentSelectedId(newId);
                                        setPaymentSelectedType(2);
                                        setInputForCvv(false);
                                      }}
                                      select={
                                        paymentSelectedType === 2 &&
                                        paymentSelectedId === newId
                                          ? true
                                          : false
                                      }
                                    >
                                      <BillBox bank={name} icon={image} />
                                    </RadioBox>
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="formStyle banksubchild">
                                {storesCheckoutList.map((item) => {
                                  const { image, name, id, paymentType } = item;
                                  const newId = id + "_" + paymentType;
                                  return (
                                    <RadioBox
                                      key={newId}
                                      onClick={() => {
                                        //setSelectedCheckoutPayment(item);
                                        setPaymentSelectedType(3);
                                        setPaymentSelectedId(newId);
                                        setInputForCvv(false);
                                      }}
                                      select={
                                        paymentSelectedType === 3 &&
                                        paymentSelectedId === newId
                                          ? true
                                          : false
                                      }
                                    >
                                      <BillBox bank={name} icon={image} />
                                    </RadioBox>
                                  );
                                })}
                              </div>
                            )}
                          </Collapse>
                        </BillBox>
                      </div>
                    );
                  })}
                  <RadioBox
                    onClick={() => {
                      setPaymentSelectedType(4);
                      setInputForCvv(false);
                    }}
                    select={paymentSelectedType === 4 ? true : false}
                  >
                    <BillBox
                      bank={payWithMercadoPago}
                      icon={"images/MercadoPago.png"}
                      onClick={closeOthers}
                    />
                  </RadioBox>

                  {/* {inputForCvv ? (
                    <>
                      {" "}
                      <Formik
                        validateOnChange={true}
                        validateOnBlur={true}
                        initialValues={{
                          id: new Date().getTime().toString(),
                          cvv: "",
                        }}
                        validationSchema={cvvValidation}
                        innerRef={cvvFormRef}
                      >
                        {({
                          errors,
                          handleSubmit,
                          validateForm,
                          handleChange,
                        }) => (
                          <Form>
                            <div
                              className={
                                props.checkout ? "checkoutRow" : "inputRow"
                              }
                            >
                              <TextInput
                                className={
                                  props.checkout
                                    ? "inputCheckout"
                                    : "textInputCheckout"
                                }
                                name="cvv"
                                type="number"
                                placeholder={"cvv"}
                                maxlength="4"
                                onInput={(e) => {
                                  if (e.target.value.length == 4)
                                    generateTokenForCVV(e.target.value);
                                  return (e.target.value = e.target.value.slice(
                                    0,
                                    4
                                  )); // vaidation to get only numbers from the user
                                }}
                                onChange={(e) => {
                                  handleChange(e);
                                  let {
                                    target: { value },
                                  } = e;
                                  cvvVar = value;
                                }}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </>
                  ) : null} */}

                  {show === false ? (
                    <div className="genralBtn">
                      <CustomButton
                        title={buttonLoader ? "" : Continue}
                        onClick={async () => {
                          const createObj = (
                            paymentType,
                            cardToken,
                            cardPaymentTypeID,
                            transferPaymentTypeID,
                            storePaymentTypeID
                          ) => {
                            let object = {
                              paymentType: paymentType || "",
                              cardToken: cardToken || "",
                              cardPaymentTypeID: cardPaymentTypeID || "",
                              transferPaymentTypeID:
                                transferPaymentTypeID || "",
                              storePaymentTypeID: storePaymentTypeID || "",
                            };
                            return object;
                          };
                          generateTokenForCVV(cvvVar);
                          if (
                            paymentSelectedType == 0 &&
                            selectedCheckoutPayment?.paymentType !== 1
                          ) {
                            setOpenAlert(true);
                            setAlertMsg(selectPaymentMethod);
                            return;
                          }
                          if (
                            paymentSelectedType === 2 ||
                            paymentSelectedType === 3
                          ) {
                            const getPaymentSelectedId =
                              paymentSelectedId.split("_");
                            let selectedPaymentData;
                            const selectedId = getPaymentSelectedId[0];
                            // check Payment Type
                            if (parseInt(getPaymentSelectedId[1]) === 2) {
                              selectedPaymentData = banksCheckoutList.filter(
                                (item) => item.id === selectedId
                              )[0];
                              const jsonToBeSend = {
                                paymentType: selectedPaymentData?.paymentType,
                                TransferPaymentTypeID: selectedPaymentData?.id,
                                TransferPaymentTypeName:
                                  selectedPaymentData.name,
                              };
                              sendJsonToMobile(jsonToBeSend);
                            } else {
                              selectedPaymentData = storesCheckoutList.filter(
                                (item) => item.id === selectedId
                              )[0];
                              const jsonToBeSend = {
                                paymentType: selectedPaymentData.paymentType,
                                StorePaymentTypeID: selectedPaymentData.id,
                                StorePaymentTypeName: selectedPaymentData.name,
                              };
                              sendJsonToMobile(jsonToBeSend);
                            }
                            setSelectedCheckoutPayment(selectedPaymentData);
                          } else if (paymentSelectedType === 4) {
                            const jsonToBeSend = {
                              paymentType: 4,
                              name: payWithMercadoPago,
                            };
                            sendJsonToMobile(jsonToBeSend);
                          } else if (paymentSelectedType === 5) {
                            const jsonToBeSend = {
                              paymentType: 4,
                              name: debitCardPayment,
                            };
                            sendJsonToMobile(jsonToBeSend);
                          } else if (
                            selectedCheckoutPayment?.paymentType === 1 &&
                            !selectedCheckoutPayment?.CardToken
                          ) {
                            if (cvvFormRef.current) {
                              cvvFormRef.current.handleSubmit();

                              if (cvvFormRef.current.errors) {
                                setCheckoutFormValidation(false);
                              }
                            }
                          } else {
                            setSelectedCheckoutPayment(
                              paymentDataList.filter(
                                (item) => item.isDefault
                              )[0]
                            );
                          }
                        }}
                        className="innerbtn"
                      >
                        {buttonLoader ? (
                          <CircularProgress size={20} color={"#FFF"} />
                        ) : (
                          ""
                        )}
                      </CustomButton>
                    </div>
                  ) : null}
                </div>
              </div>
              <br></br>
            </div>
          )}
          {/* in case of any error Dialogue box visible */}
          <AlertBox
            del={false}
            onCancel={() => setOpenAlert(false)}
            heading={error}
            text={alertMsg}
            onClose={() => setOpenAlert(false)}
            open={openAlert}
          />
        </Container>
      ) : (
        <PageNotFound />
      )}
    </>
  );
};
export default TokenForMobile;
