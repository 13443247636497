import React, { useEffect, useState, useContext, useRef } from "react";
import "./style.scss";
import {
  ListGridViewProduct,
  Products,
  RatingSummery,
  RatingCards,
  GridSkelton,
} from "../../Component/index";
import { Skeleton } from "@material-ui/lab";
import ImageGallery from "react-image-gallery";
import SliderImage from "react-zoom-slider";
import "../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
import {
  breakPoints,
  holaDummyProduct,
  excellentProduct,
  postDate,
  austerlitz,
  bLavett,
  goBack,
  removeFromFavorite,
  markAsFavorite,
  product,
} from "../../common/constants";
import MainHeader from "../../Sections/header";
import { useHistory } from "react-router";
import { path } from "../../common/routes-names";
import { userProfile } from "../../common/Images";
import PropertyTable from "./propertyTable";
import { renderAtterDetail } from "../../Component/tables/attributeTable";
import { renderProDetail } from "../../Component/tables/compatTable";
import ProBanner from "../../Sections/product-banner";
import { metaPath, regexImg } from "../../common/services";
import RatingSkelton from "../../Component/rating-summery/ratingSekelton";
import Images from "../../Component/imgLoader";
import RatingCardsSekelton from "../../Component/customer-rating-cards/ratingCard";
import ApplicationContext from "./../../context-api/context";
import MainLayout from "../../Component/layout/layout";
import useForm from "./useForm";
import Carousel from "react-elastic-carousel";
import SkeltonLoader from "../../Sections/side-bar/sideBarSkelton";
import {
  getFavouriteProducts,
  getMakers,
  sendProductRequest,
} from "../../utils/api";
import { postFavouritePro } from "../../utils/api";
import { useToasts } from "react-toast-notifications";
import { addToWishListPixel } from "../../utils/fbPixelEvent";
import AlertBox from "../../Component/confirm-alert/alertBox";

export default function ProDescriptionPage(props) {
  const {
    position,
    setposition,
    bullets,
    setBullets,
    isStarFill,
    setIsStarFill,
    history,
    productData,
    setProductData,
    id,
  } = useForm(props);
  const {
    isLoggedIn,
    setIsLoggedIn,
    favoriteData,
    setFavoriteData,
    cartList,
    isOpenLoginModel,
    setIsOpenLoginModel,
  } = useContext(ApplicationContext);
  const { addToast } = useToasts();

  const [requestPopup, setRequestPopup] = useState(false);
  const [requestBtnLoader, setRequestBtnLoader] = useState(false);
  const imgRef = useRef();
  useEffect(() => {
    if (window.innerWidth <= breakPoints.mdScreen) {
      setBullets(false);
    } else {
      setBullets(true);
    }
  }, [window.innerWidth]);
  useEffect(() => {
    if (window.innerWidth <= 767) {
      setposition("bottom");
    } else {
      setposition("left");
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (productData) {
      setIsStarFill(productData.isFavorite);
    }
  }, [productData]);

  const markFavorite = async () => {
    const obj = {
      productID: productData.productID,
      add: !isStarFill,
    };
    if (isLoggedIn) {
      const res = await postFavouritePro(obj);
      if (res && res.data?.success) {
        setIsStarFill(!isStarFill);
        favouriteToast(res.data?.description);
        const favData = await getFavouriteProducts();
        const {
          data: { content, success },
        } = favData;
        if (favData && success) {
          setFavoriteData(content);
        }
        // if (!isStarFill) {

        // } else {
        //   const filteredProducts = favoriteData.filter(
        //     (pro) => pro.productID !== productData.productID
        //   );
        //   // setFavoriteData(filteredProducts);
        // }
      }
    } else {
      setIsOpenLoginModel(true);
    }
  };
  const productImage =
    productData.length != 0 &&
    productData.images.map((i) => {
      return {
        original: regexImg(i) + "?v=1.0",
        thumbnail: regexImg(i) + "?v=1.0",
      };
    });
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 440, itemsToShow: 2 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];
  const { setMakersList, makersList, setSearchbarActive } =
    useContext(ApplicationContext);

  useEffect(() => {
    if (makersList.length === 0) {
      getMakerList();
    }
    setSearchbarActive(true);
    // setTimeout(() => {
    // }, 6000);
  }, []);
  const getMakerList = async () => {
    await getMakers()
      .then((response) => {
        if (response.status === 200) {
          setMakersList(response.data.content);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const favouriteToast = (message) => {
    addToast(message, { appearance: "success" });
  };

  const requestProductHandler = async () => {
    setRequestBtnLoader(true);
    let result = await sendProductRequest(id);
    setRequestBtnLoader(false);
    if (result && result.data.success) {
      addToast(result.data.description, { appearance: "success" });
    }
    setRequestPopup(false);
  };
  return (
    <MainLayout metaTitle={metaPath()}>
      <MainHeader isMenu otherIconOnHeader={true} logoBig={true} />
      <div className="proDescriptionWrapper">
        <ProBanner />
        <div className="container add">
          <div className="ProDesInner">
            <div className="header">
              <div className="goBack">
                <div
                  onClick={
                    props.location.state != undefined
                      ? () => history.goBack()
                      : () => history.push(path.home)
                  }
                >
                  <i class="fas fa-long-arrow-alt-left arraowSize" />
                  <span className="text">{goBack}</span>
                </div>
              </div>
              <div
                onClick={() => {
                  markFavorite();
                  addToWishListPixel(
                    productData.productID,
                    JSON.parse(localStorage.getItem("UserData")).email
                  );
                }}
                className="actionArea"
              >
                <span
                  className={
                    isStarFill === true
                      ? "fas fa-star startSize"
                      : "far fa-star startSize"
                  }
                ></span>
              </div>
            </div>

            <div className="areaGallery">
              <div className="contentGallery">
                <div className="gallery">
                  <div className="brandLogo">
                    {productData.length != 0 ? (
                      <p className="brandName">
                        <span> {productData.brand}</span>
                      </p>
                    ) : (
                      <>
                        <Images skeletonStyle="img-fluid" />
                      </>
                    )}
                  </div>
                  {productData.length != 0 ? (
                    <>
                      <ImageGallery
                        showPlayButton={false}
                        items={productImage}
                        ref={imgRef}
                        onClick={() => {
                          imgRef.current.toggleFullScreen();
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <SkeltonLoader imgeGallery />
                    </>
                  )}
                </div>
                <div className="proCard">
                  {productData.length != 0 ? (
                    <>
                      <ListGridViewProduct
                        _productId={productData.productID}
                        _partNo={productData.noParte}
                        _productName={productData.name}
                        _markValue={productData.brand}
                        _available={productData.stock}
                        _availableIn={productData.availableIn}
                        _ratingValue={productData.rating}
                        _discountPrice={productData.price}
                        _orignalPrice={productData.basePrice}
                        shipping_Cost={productData.shippingCost}
                        productObject={productData}
                        visibleTwoButtons={false}
                        detailView={true}
                        gridView="proWrapper gridView"
                        btnOne="Atributos"
                        btnTwo="Compatibilidades"
                        _warranty={productData.warranty}
                        onBuy={() => {
                          history.push(path.checkout);
                        }}
                        //Here you can get the quantity from CartObject in Redux of this specific product ID and put the quantity here! For now i am putting it as fixed 2.
                        _quantity={productData.quantity}
                        // _position={""}
                        requestPopupHandler={() => {
                          setRequestPopup(true);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <GridSkelton
                        detailView={true}
                        visibleTwoButtons={false}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <PropertyTable
              attributeBody={
                productData.length == 0 ? (
                  <>
                    {[1].map((i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {" "}
                            <SkeltonLoader table />
                          </td>
                          <td>
                            {" "}
                            <SkeltonLoader table />
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  productData.attributes.map(renderAtterDetail)
                )
              }
              compatibiltyBody={
                productData.length == 0 ? (
                  <>
                    {[1, 2].map((i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {" "}
                            <SkeltonLoader table />
                          </td>
                          <td>
                            {" "}
                            <SkeltonLoader table />
                          </td>
                          <td>
                            {" "}
                            <SkeltonLoader table />
                          </td>
                          <td>
                            {" "}
                            <SkeltonLoader table />
                          </td>
                          <td>
                            {" "}
                            <SkeltonLoader table />
                          </td>
                          <td>
                            {" "}
                            <SkeltonLoader table />
                          </td>
                          <td>
                            {" "}
                            <SkeltonLoader table />
                          </td>
                          <td>
                            {" "}
                            <SkeltonLoader table />
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  productData.compatibilities.map(renderProDetail)
                )
              }
            />
            {productData.length !== 0 ? (
              <div className="showProducts">
                <Products data={productData.featureProducts} />
              </div>
            ) : null}
            <div className="ratingSummeryWrapper">
              {productData.length !== 0 && (
                <RatingSummery reviewObj={productData.reviews} />
              )}
              {/* <RatingSkelton/> */}
            </div>

            {productData.length !== 0 ? (
              <Carousel breakPoints={breakPoints}>
                {productData.reviews.reviews.map((value) => (
                  <div className="ratingCardsOuter">
                    <RatingCard
                      userProfile={userProfile}
                      userName={value.reviewCustomer}
                      country={value.customerCity}
                      remarksDetail={value.reviewDescription}
                      remarksHeading={value.reviewTitle}
                      postDate={value.reviewDate}
                      ratingPoints={value.ratingPoints}
                    />
                  </div>
                ))}
              </Carousel>
            ) : (
              <div className="ratingCardsOuter">
                <Carousel breakPoints={breakPoints}>
                  {[1, 2, 3].map(() => (
                    <Skeleton
                      animation="wave"
                      height={720}
                      width="90%"
                      style={{ marginTop: -100 }}
                    />
                  ))}
                </Carousel>
              </div>
            )}
          </div>
        </div>
      </div>
      <AlertBox
        del={true}
        requestProduct={true}
        onCancel={() => {
          setRequestPopup(false);
        }}
        onSubmit={() => {
          requestProductHandler();
        }}
        onClose={requestPopup}
        open={requestPopup}
        btnLoader={requestBtnLoader}
      />
    </MainLayout>
  );
}

const RatingCard = ({
  userProfile,
  postDate,
  remarksHeading,
  remarksDetail,
  country,
  userName,
  ratingPoints,
}) => (
  <div className="Card">
    <RatingCards
      userImg={userProfile}
      userName={userName}
      country={country}
      remarksHeading={remarksHeading}
      remarksDetail={remarksDetail}
      postedDate={postDate}
      ratingPoints={ratingPoints}
    />
  </div>
);
