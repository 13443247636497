import { createSlice } from "@reduxjs/toolkit";
import { numericOnly } from "../../common/formValidation";
const initialState = {
  SelectedBtn: [],
  addCar: [],
  price: {
    min: 0,
    max: 5000,
  },
  isVehicleChange: "",
  isCategoryChange: "",
  checkedState: [],
  dynamicState: [],
  ratingCheckState: [],
  linkState: [],
  defaultList: [],
  pageSize: 10,
  pageNum: 1,
  orderby: 0,
};
const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    addLinks: (state, { payload }) => {
      if (
        state.linkState.find((item) => item.id === payload.id) !== undefined
      ) {
      } else state.linkState = [payload];
    },
    addDefaultCategories: (state, { payload }) => {
      if (
        state.defaultList.find((item) => item.id === payload.id) !== undefined
      ) {
      } else {
        state.defaultList = [payload];
      }
    },
    addBadge: (state, { payload }) => {
      if (payload.type === "Precio") {
        // if price value exsist then remove first one and add latest value
        if (
          state.SelectedBtn.find((item) => item.type === payload.type) !==
          undefined
        ) {
          state.SelectedBtn = state.SelectedBtn.filter(
            (item) => item.type !== payload.type
          );
          state.SelectedBtn = [...state.SelectedBtn, payload];
        } else {
          state.SelectedBtn = [...state.SelectedBtn, payload];
        }
      } else state.SelectedBtn = [...state.SelectedBtn, payload];
    },
    resetCoupon: (state, { payload }) => {
      state.SelectedBtn = initialState.SelectedBtn;
    },
    removeBadge: (state, { payload }) => {
      if (payload.type === "Precio") {
        state.price = { ...state.price, min: "", max: "" };
      }
      state.SelectedBtn = state.SelectedBtn.filter(
        (item) => item.id !== payload.id
      );
    },
    priceField: (state, { payload }) => {
      const { name, value } = payload.target;
      if (value === "" || numericOnly.test(value)) {
        state.price = { ...state.price, [name]: value };
      }
    },
    newRange: (state, { payload }) => {
      state.price = { ...state.price, min: payload[0], max: payload[1] };
    },
    setCheckBox: (state, { payload }) => {
      if (payload.type == "Marca") {
        if (
          state.checkedState.find((item) => item.name === payload.name) !==
          undefined
        ) {
          // value exist
          state.checkedState = state.checkedState.filter(
            (item) => item.name !== payload.name
          );
          state.checkedState = [...state.checkedState];
        } else {
          state.checkedState = [...state.checkedState, payload];
        }
      } else {
        if (
          state.dynamicState.find((item) => item.name === payload.name) !==
          undefined
        ) {
          // value exist
          state.dynamicState = state.dynamicState.filter(
            (item) => item.name !== payload.name
          );
          state.dynamicState = [...state.dynamicState];
        } else {
          state.dynamicState = [...state.dynamicState, payload];
        }
      }
    },
    setRatingBox: (state, { payload }) => {
      state.ratingCheckState = payload;
    },
    add_VehicleIntoUrl: (state, { payload }) => {
      state.addCar = payload;
    },
    priceMaxVal: (state, { payload }) => {
      state.price.max = payload;
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    setPageNum: (state, { payload }) => {
      state.pageNum = payload;
    },
    setOrder: (state, { payload }) => {
      state.orderby = payload;
    },
    checkVehicle: (state, { payload }) => {
      if (payload?.type == "vehicle") {
        state.isVehicleChange = payload.data;
      } else {
        state.isCategoryChange = payload;
      }
    },
    resetFilter: (state, { payload }) => {
      if (payload == "Marca") {
        state.SelectedBtn = initialState.SelectedBtn;
        state.checkedState = initialState.checkedState;
      } else if (payload == "calificaciones") {
        state.ratingCheckState = initialState.ratingCheckState;
      } else if (payload == "All") {
        state.pageSize = initialState.pageSize;
        state.pageNum = initialState.pageNum;
        state.ratingCheckState = initialState.ratingCheckState;
        state.SelectedBtn = initialState.SelectedBtn;
        state.checkedState = initialState.checkedState;
        state.dynamicState = initialState.dynamicState;
        state.orderby = initialState.orderby;
      } else {
        let myIndex = state.dynamicState.findIndex(
          (item) => item.type === payload
        );
        if (myIndex != -1) {
          state.dynamicState = state.dynamicState.filter(
            (item) => item.type !== payload
          );
        }
      }
    },
  },
});

export const {
  checkVehicle,
  addBadge,
  removeBadge,
  resetCoupon,
  priceField,
  newRange,
  setCheckBox,
  addLinks,
  setPageSize,
  setRatingBox,
  add_VehicleIntoUrl,
  addDefaultCategories,
  priceMaxVal,
  setPageNum,
  resetFilter,
  setOrder,
} = filterSlice.actions;

export default filterSlice.reducer;
