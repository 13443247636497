import React from "react";
import { Redirect } from "react-router-dom";
import { path } from "./common/routes-names";

class ErrorBoundary extends React.Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    // Update state so next render shows fallback UI.
    return { error: true };
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      setTimeout(() => {
        window.location.reload();
      }, 200);
      return (
        <>
          <Redirect to={path.notFound} />
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
