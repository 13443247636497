import React, { useEffect, useState, useContext } from "react";
import { Formik, Form } from "formik";
import "./style.scss";
import { TextInput, CustomButton, GenricDropDown } from "..";
import { billValidation } from "../../common/formValidation";
import { CircularProgress } from "@material-ui/core";
import ApplicationContext from "../../context-api/context";
import {
  getZipCodeDetail,
  AddUpdateUserInvoice,
  createNewInvoice,
} from "./../../utils/api";
import {
  saveChnges,
  rfc,
  razonSocial,
  regimentFiscal,
  postalCode,
  neighborhood,
  street,
  intno,
  intnoPlaceholder,
  extno,
  extnoPlaceholder,
  postalCodeLength,
} from "../../common/constant/formConstants";

const BillForm = (props) => {
  const {
    setRecordForEdit,
    recordForEdit,
    openPop,
    setIsmodalOpens,
    fromAccountSection,
    onSuccessEdited,
    puchaseReason,
  } = props;
  const [input, setInput] = useState({
    id: "", //new Date().getTime().toString(),
    taxno: "",
    taxregimen: "",
    taxname: "",
    postalCode: "",
    neighborhood: "",
    street: "",
    extno: "",
    intno: "",
  });
  let inputValueCoppy = {
    id: input.id,
    taxno: input.taxno,
    taxregimen: input.taxregimen,
    taxname: input.taxname,
    postalCode: input.postalCode,
    neighborhood: input.neighborhood,
    street: input.street,
    extno: input.extno,
    intno: input.intno,
  };
  const { regimenInvoiceList, invoiceData } = useContext(ApplicationContext);
  const [neighborhoodValue, setNeighborhoodValue] = useState([]);
  const [zipErrorMessage, setzipErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [postalLoader, setPostalLoader] = useState(false);

  const getColonies = async (zip) => {
    if (zip !== "" && zip.length === postalCodeLength) {
      setPostalLoader(true);
      const response = await getZipCodeDetail(zip);
      setPostalLoader(false);
      if (response) {
        const {
          data: { content, success, description },
        } = response;
        if (success) {
          if (content) {
            setNeighborhoodValue(content.colonies);
            setzipErrorMessage("");
            // DUE TO EMPTY THE NEIGHBORHOODID WE USE inputValueCoppy WHICH ENFORCE USER TO SELECT NEIGHBOR AFTER ZIPCODE CHANGE
            inputValueCoppy.neighborhood = "";
            setInput(inputValueCoppy);
          }
        } else {
          setNeighborhoodValue("");
          setzipErrorMessage(description);
          inputValueCoppy.neighborhood = "";
          setInput(inputValueCoppy);
        }
      }
    }
  };
  // in case of edit this useform triggers to set the values
  useEffect(() => {
    if (invoiceData) {
      const {
        taxName,
        invoicingID,
        extNo,
        intNo,
        neighborhoodID,
        street,
        taxNo,
        taxRegimen,
        zipCode,
        neighborhood,
        coloniesList,
      } = invoiceData;
      if (coloniesList?.length > 0) {
        setNeighborhoodValue(coloniesList);
      }
      setzipErrorMessage("");
      setInput({
        ...input,
        id: invoicingID,
        taxno: taxNo,
        taxregimen: taxRegimen,
        taxname: taxName,
        postalCode: zipCode,
        neighborhood: neighborhoodID,
        street: street,
        extno: extNo,
        intno: intNo,
      });
    } else {
      clearForm();
    }
  }, [invoiceData]);

  const addUpdateInvoice = async (data) => {
    setLoader(true);
    const response = await AddUpdateUserInvoice(data);
    if (response) {
      const inv = response.data.content;
      let arr = recordForEdit.invoices.filter((i) => {
        return i.invoicingID !== data.invoicingID;
      });
      arr.push({ ...inv, isOpened: false });
      setRecordForEdit({ invoices: arr });
      setIsmodalOpens(false);
      setLoader(false);
      return response;
    }
  };
  const createCheckoutInvoice = async (data) => {
    setLoader(true);
    const response = await createNewInvoice(data);
    if (response && response.data.success) {
      setLoader(false);
      return response;
    }
  };
  const handleForm = async (values, { resetForm }) => {
    if (fromAccountSection) {
      // in case when user create New Invoice from Account Section
      if (!postalLoader) {
        const invoicingID = invoiceData?.invoicingID;
        props.addOrEdit(values);

        const invoiceJson = {
          invoicingID: invoicingID ? invoicingID : "",
          userID: 0,
          taxNo: values.taxno,
          taxRegimen: values.taxregimen,
          taxName: values.taxname,
          zipCode: values.postalCode.toString(),
          neighborhoodID: values.neighborhood,
          street: values.street,
          extNo: values.extno,
          intNo: values.intno,
        };
        const response = await addUpdateInvoice(invoiceJson);
        if (invoicingID !== "") {
          onSuccessEdited(response.data.description);
        }
        if (response) {
          props.newInvoice(response);
          setLoader(false);
          resetForm();
        }
      }
    } else {
      // in case when user create New Invoice from Checkout Section
      if (!postalLoader) {
        props.addOrEdit(values);
        const invoiceJson = {
          taxNo: values.taxno,
          taxRegimen: values.taxregimen,
          taxName: values.taxname,
          zipCode: values.postalCode.toString(),
          colony: values.neighborhood,
          street: values.street,
          extNo: values.extno,
          intNo: values.intno,
        };
        const response = await createCheckoutInvoice(invoiceJson);
        if (response) {
          props.newInvoice(response);
          setLoader(false);
          resetForm();
        }
      }
    }
  };

  const clearForm = () => {
    setInput({
      id: "",
      taxno: "",
      taxregimen: "",
      taxname: "",
      postalCode: "",
      neighborhood: "",
      street: "",
      extno: "",
      intno: "",
    });
  };
  useEffect(() => {
    if (!openPop) {
      clearForm();
    }
  }, [openPop]);
  return (
    <>
      <Formik
        enableReinitialize
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={{
          id: input.id,
          taxno: input.taxno,
          taxregimen: input.taxregimen,
          taxname: input.taxname,
          postalCode: input.postalCode,
          neighborhood: input.neighborhood,
          street: input.street,
          extno: input.extno,
          intno: input.intno,
        }}
        validationSchema={billValidation}
        onSubmit={handleForm}
      >
        {({ handleSubmit, handleChange }) => (
          <Form>
            <div className={props.checkout ? "checkoutRow" : "inputRow"}>
              <TextInput
                className={
                  props.checkout ? "inputCheckout marginBtm" : "textInput"
                }
                name="taxno"
                type="text"
                placeholder={rfc}
                labelName={rfc}
                labelClass="lbl-bld"
                onChange={(e) => {
                  handleChange(e);
                  let {
                    target: { value },
                  } = e;
                  inputValueCoppy = { ...inputValueCoppy, taxno: value };
                }}
              />
              <GenricDropDown
                name="taxregimen"
                dataSet={regimenInvoiceList}
                labelName={regimentFiscal}
                onChange={(e) => {
                  handleChange(e);
                  let {
                    target: { value },
                  } = e;
                  inputValueCoppy = { ...inputValueCoppy, taxregimen: value };
                }}
              />
            </div>
            <TextInput
              className={props.checkout ? "inputCheckout" : "textInput"}
              name="taxname"
              type="text"
              placeholder={razonSocial}
              labelName={razonSocial}
              labelClass="lbl-bld"
              onChange={(e) => {
                handleChange(e);
                let {
                  target: { value },
                } = e;
                inputValueCoppy = { ...inputValueCoppy, taxname: value };
              }}
            />
            <TextInput
              className={props.checkout ? "inputCheckout" : "textInput"}
              name="postalCode"
              type="number"
              placeholder={postalCode}
              labelName={postalCode}
              // onBlur={getColonies}
              error={zipErrorMessage}
              onChange={(e) => {
                handleChange(e);
                let {
                  target: { value },
                } = e;
                inputValueCoppy = { ...inputValueCoppy, postalCode: value };
              }}
              labelClass="lbl-bld"
              loading={postalLoader}
              onInput={(e) => {
                if (e.target.value.length == postalCodeLength)
                  getColonies(e.target.value);
                return (e.target.value = e.target.value.slice(
                  0,
                  postalCodeLength
                )); // vaidation to get only numbers from the user
              }}
            />

            <div className={props.checkout ? "checkoutRow" : "inputRow"}>
              <GenricDropDown
                name="neighborhood"
                dataSet={neighborhoodValue}
                labelName={neighborhood}
              />
            </div>

            <div className={props.checkout ? "checkoutRow" : "inputRow"}>
              <TextInput
                className={
                  props.checkout ? "inputCheckout marginBtm" : "textInput"
                }
                name="street"
                type="text"
                placeholder={street}
                labelName={street}
                labelClass="lbl-bld"
                onChange={(e) => {
                  handleChange(e);
                  let {
                    target: { value },
                  } = e;
                  inputValueCoppy = { ...inputValueCoppy, street: value };
                }}
              />
            </div>

            <div className={props.checkout ? "checkoutRow" : "inputRow"}>
              <TextInput
                className={
                  props.checkout ? "inputCheckout marginBtm" : "textInput"
                }
                name="extno"
                type="text"
                placeholder={extnoPlaceholder}
                labelName={extno}
                labelClass="lbl-bld"
                onChange={(e) => {
                  handleChange(e);
                  let {
                    target: { value },
                  } = e;
                  inputValueCoppy = { ...inputValueCoppy, extno: value };
                }}
              />
              <TextInput
                className={props.checkout ? "inputCheckout " : "textInput"}
                name="intno"
                type="text"
                placeholder={intnoPlaceholder}
                labelName={intno}
                labelClass="lbl-bld"
                onChange={(e) => {
                  handleChange(e);
                  let {
                    target: { value },
                  } = e;
                  inputValueCoppy = { ...inputValueCoppy, intno: value };
                }}
              />
            </div>
            <div>
              <span className="title">{"Uso De CFDI: "}</span>
              {puchaseReason}
            </div>

            <CustomButton
              title={!loader ? saveChnges : ""}
              className="submitBtn"
              onClick={handleSubmit}
            >
              {loader ? <CircularProgress size={20} color="white" /> : ""}
            </CustomButton>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default BillForm;
