import React, { useState, useContext } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  Password,
  Email,
  email,
  forgotPassword,
  registerNow,
} from "../../common/constant/formConstants";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import { path } from "../../common/routes-names";
import "./style.scss";
import CloseIcon from "@material-ui/icons/Close";
import ApplicationContext from "../../context-api/context";

export const LoginModelPop = ({ email, pass, onPress, loading, error }) => {
  const [showPass, setShowPass] = useState(false);
  const [emailValue, setEmailValue] = useState(true);
  const [passwordValue, setPasswordValue] = useState(true);
  const { setIsOpenLoginModel } = useContext(ApplicationContext);

  const handleLoginClick = () => {
    error = false;
    if (emailValue !== true && passwordValue !== true) {
      onPress();
    } else {
      setEmailValue(false);
      setPasswordValue(false);
    }
  };

  return (
    <div className="mainContainerOne">
      <div className="topHeaders">
        <p className="topHeading">iniciar sesión</p>
        <CloseIcon
          onClick={() => {
            email = "";
            pass = "";
            setIsOpenLoginModel(false);
          }}
          className="crossMdlIcon"
        />
      </div>
      <div>
        <p className="fieldHeading">Correo Electrónico</p>
        <input
          className="inputFieldStyle"
          onChange={(e) => {
            email(e.target.value);
            setEmailValue(e.target.value);
          }}
          type={"email"}
          placeholder={Email}
          autoComplete={"off"}
        />
        {emailValue ? (
          ""
        ) : (
          <div class="error">correo electronico es requerido</div>
        )}
        {/* <p className="error">Fill the fiedl</p> */}
      </div>
      <div className="passwordDiv">
        <p className="fieldHeading">Contraseña</p>
        <div className="passField">
          <input
            type={showPass == false ? "password" : "text"}
            className="inputFieldStyle"
            onChange={(e) => {
              pass(e.target.value);
              setPasswordValue(e.target.value);
            }}
            placeholder={Password}
            autoComplete={"off"}
          />

          {showPass ? (
            <div className="eyes">
              <Visibility
                onClick={() => setShowPass(false)}
                color={"primary"}
              />
            </div>
          ) : (
            <div className="eyes">
              <VisibilityOff
                onClick={() => setShowPass(true)}
                color={"primary"}
              />
            </div>
          )}
        </div>
      </div>
      {passwordValue ? "" : <div class="error">se requiere contraseña</div>}

      <div>
        <Link to={path.signUp} className="registerNow">
          <p>{registerNow}</p>
        </Link>
        <Link to={path.forgetPassword} className="forgotPass">
          <p>{forgotPassword}</p>
        </Link>
      </div>

      {error ? <div class="error">{error}</div> : ""}
      {loading == true ? (
        <button className="loginBtn" onClick={() => handleLoginClick()}>
          <CircularProgress size={26} color="white" />
        </button>
      ) : (
        <button className="loginBtn" onClick={() => handleLoginClick()}>
          INICIAR SESIÓN
        </button>
      )}
    </div>
  );
};
