import React, { useEffect, useState, useContext, useRef } from "react";
import MiCuenta from "../mi-cuenta/index";
import {
  GetUserInfo,
  sendAccountActivationEmail,
  updateUserInfo,
  updateUserPassword,
} from "../../../utils/api";
import "./style.scss";
import OtpInput from "react-otp-input";
import { Skeleton } from "@material-ui/lab";
import {
  changPassword,
  coln,
  confirmar,
  current,
  edit,
  cancel,
  email,
  emailVerify,
  emailVerifyBtn,
  itsGood,
  name,
  newPass,
  password,
  phoneNum,
  phoneVerify,
  otp,
  retypeNew,
  security,
  upgrade,
  enterOldpassword,
  enterNewpassword,
  enterConfirmpassword,
  mobileverification,
  enterotp,
  remove,
  receivedCode,
  forward,
} from "../../../common/constants";
import { verified, unverified, closeIcon } from "../../../common/Images";
import { TextInput } from "../../../Component";
import { Form, Formik } from "formik";
import {
  newPasswordValidate,
  emailValidation,
  phoneNumberValidation,
  nameValidation,
} from "../../../common/formValidation";
import { CircularProgress, requirePropFactory } from "@material-ui/core";
import { useToasts } from "react-toast-notifications";
import ApplicationContext from "../../../context-api/context";
import CustomDialogs from "../../../Component/customModal/modalAlert";
const Security = () => {
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00:00");
  const [loading, setloading] = useState(false);
  const [emailVerification, setEmailVerification] = useState(false);
  const [iSemailvarifybtnDisable, setISemailvarifybtnDisable] = useState(false);
  const [emailSendLoader, setEmailSendLoader] = useState(false);
  const [phoneVerification, setPhoneVerification] = useState(false);
  const [editName, setEditName] = useState(true);
  const [editEmail, setEditEmail] = useState(true);
  const [editNum, setEditNum] = useState(true);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [changPass, setchangPass] = useState(false);
  const [mobileOtpPopUp, setMobileOtpPopUp] = useState(false);
  const [otpInput, setotpInput] = useState("");
  const [input, setinput] = useState({
    email: "",
    phone: "",
    name: "",
  });
  const { getUserDataFromLocalStorage } = useContext(ApplicationContext);
  const { addToast } = useToasts();
  var secLeft = 30;
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };
  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };
  const startTime = (e) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + secLeft);
    return deadline;
  };
  const showToast = (success, message) => {
    addToast(message, { appearance: success ? "success" : "error" });
  };
  const closePWPopUpHandler = () => {
    setchangPass(!changPass);
  };
  const closeNamePopUpHandler = () => {
    setEditName(!editName);
  };
  const closeEmailPopUpHandler = () => {
    setEditEmail(!editEmail);
  };
  const closePhonePopUpHandler = () => {
    setEditNum(!editNum);
  };

  const fetchUserData = async () => {
    try {
      setloading(true);
      let result = await GetUserInfo();
      if (result && result.data.success) {
        let response = result.data.content;
        setinput({
          name: response.userName,
          email: response.email,
          phone: response.phone,
        });
        setEmailVerification(response.emailActivated);
        setPhoneVerification(response.phoneActivated);
        setloading(false);
      }
    } catch (error) {
      alert(error);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);
  const createJsonForUpdate = (usernameParam, emailParam, telephoneParam) => {
    if (usernameParam !== "") {
      return {
        userID: 0,
        name: usernameParam,
        email: input.email,
        phone: input.phone,
      };
    } else if (emailParam !== "") {
      return {
        userID: 0,
        name: input.name,
        email: emailParam,
        phone: input.phone,
      };
    } else if (telephoneParam !== "") {
      return {
        userID: 0,
        name: input.name,
        email: input.email,
        phone: telephoneParam,
      };
    }
  };
  const updateNameInLocalStorage = (name) => {
    let userInfo = getUserDataFromLocalStorage();
    let updatedName = { ...userInfo, name: name };
    localStorage.setItem("UserData", JSON.stringify(updatedName));
  };

  const changeUserInfoLocally = (usernameParam, emailParam, telephoneParam) => {
    if (usernameParam !== "") {
      setinput({ ...input, name: usernameParam });
      updateNameInLocalStorage(usernameParam);
      closeNamePopUpHandler();
    } else if (emailParam !== "") {
      setinput({ ...input, email: emailParam });
      closeEmailPopUpHandler();
      fetchUserData();
    } else if (telephoneParam !== "") {
      setinput({ ...input, phone: telephoneParam });
      closePhonePopUpHandler();
    }
  };
  const onChangeUserInfo = async (
    usernameParam,
    emailParam,
    telephoneParam,
    setSubmitting
  ) => {
    let userInfo = createJsonForUpdate(
      usernameParam,
      emailParam,
      telephoneParam
    );
    let response = await updateUserInfo(userInfo);
    setSubmitting(false);
    const {
      data: { success, description },
    } = response || {};
    if (response && success) {
      // take action in case of success
      showToast(true, description);
      changeUserInfoLocally(usernameParam, emailParam, telephoneParam);
    } else {
      showToast(false, description);
      // in case of any error
    }
  };
  const onChangeUserPasword = async (
    oldPassword,
    newPassword,
    setSubmitting
  ) => {
    let passwordJson = {
      userID: 0,
      newPassword: newPassword,
      oldPassword: oldPassword,
    };
    let response = await updateUserPassword(passwordJson);
    const {
      data: { success, description },
    } = response || {};
    if (response && success) {
      setSubmitting(false);
      showToast(true, description);
      closePWPopUpHandler();
    } else {
      setSubmitting(false);
      showToast(false, description);
    }
  };
  const sendActiveEmail = async () => {
    try {
      setEmailSendLoader(true);
      setISemailvarifybtnDisable(true);
      startTime(getDeadTime());

      let result = await sendAccountActivationEmail();
      const {
        data: { success, description },
      } = result;
      if (result && success) {
        setTimeout(() => {
          setISemailvarifybtnDisable(false);
        }, secLeft * 1000);
        setEmailSendLoader(false);
        showToast(true, description);
      } else {
        showToast(false, description);
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <MiCuenta title={security}>
      <div className="main">
        <div className="securityScreen">
          {loading ? (
            [1, 2, 3].map((e) => (
              <Skeleton
                width={"96%"}
                height={150}
                style={{
                  marginBottom: -3,
                  marginTop: -7,
                  marginLeft: 17,
                }}
              />
            ))
          ) : (
            <>
              <div className="col">
                {editName ? (
                  <>
                    <div className="editText d-inline-block">
                      <div className="label d-inline-block">
                        <label>{name}</label>
                      </div>
                      <div className="textSave d-inline-block">
                        <span>{input.name}</span>
                      </div>
                    </div>
                    <div className="e-btn d-inline-block">
                      <button
                        className="edtBtn-secr"
                        onClick={closeNamePopUpHandler}
                      >
                        {edit}
                      </button>
                    </div>
                  </>
                ) : (
                  <Formik
                    enableReinitialize
                    validateOnChange={true}
                    validateOnBlur={true}
                    initialValues={{
                      name: input.name,
                    }}
                    validationSchema={nameValidation}
                    onSubmit={(values, { setSubmitting }) => {
                      onChangeUserInfo(values.name, "", "", setSubmitting);
                    }}
                  >
                    {({ handleSubmit, values, handleChange, isSubmitting }) => (
                      <>
                        <Form autocomplete="new-password">
                          <div className="field-update">
                            <div className="label-update">
                              <span>{name}</span>
                            </div>
                            <div className="input-updateFeild">
                              <TextInput
                                className={"form-control"}
                                placeholder="Nombre"
                                name="name"
                                onChange={handleChange("name")}
                                value={values.name}
                              />
                            </div>
                            {/* update button */}
                            <div className="actual">
                              <button
                                className="actual-btn-scre"
                                onClick={handleSubmit}
                                type="submit"
                              >
                                {isSubmitting ? (
                                  <CircularProgress size={20} color="#fff" />
                                ) : (
                                  upgrade
                                )}
                              </button>
                            </div>
                          </div>
                          {/* cancel button */}
                          <div className="cancel-button">
                            <button
                              className="btn-cancel-scre"
                              onClick={closeNamePopUpHandler}
                            >
                              {cancel}
                            </button>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                )}
              </div>
              <div className="col">
                {editEmail ? (
                  <>
                    <div className="editText d-inline-block">
                      <div className="label d-inline-block">
                        <label>{email}</label>
                      </div>
                      <div className="textSave d-inline-block">
                        <span>{input.email}</span>
                        <div className="email-status-img d-inline-block">
                          <img
                            style={{ height: "20px", width: "20px" }}
                            src={emailVerification ? verified : unverified}
                            alt="Verification"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="e-btn d-inline-block">
                      <button
                        className="edtBtn-secr"
                        onClick={closeEmailPopUpHandler}
                      >
                        {edit}
                      </button>
                    </div>
                    {emailVerification ? null : (
                      <div className="col-emailVerified">
                        <div className="emailVerified d-inline-block">
                          <span>{emailVerify}</span>
                        </div>
                        <div className="emailVerified-btn d-inline-block">
                          <button
                            className="emailVerified-Btn-secr"
                            style={{
                              backgroundColor: iSemailvarifybtnDisable
                                ? "gray"
                                : "#FF4242",
                            }}
                            onClick={
                              !iSemailvarifybtnDisable && sendActiveEmail
                            }
                          >
                            {emailSendLoader ? (
                              <CircularProgress size={20} color={"#fff"} />
                            ) : (
                              emailVerifyBtn
                            )}
                          </button>
                          {iSemailvarifybtnDisable && (
                            <div>
                              <span style={{ fontSize: "15px" }}>
                                {`reenviar ${timer}`}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <Formik
                      enableReinitialize
                      validateOnChange={true}
                      validateOnBlur={true}
                      initialValues={{
                        email: input.email,
                      }}
                      validationSchema={emailValidation}
                      onSubmit={(values, { setSubmitting }) => {
                        onChangeUserInfo("", values.email, "", setSubmitting);
                      }}
                    >
                      {({
                        handleSubmit,
                        values,
                        handleChange,
                        isSubmitting,
                      }) => (
                        <>
                          <Form autocomplete="new-password">
                            <div className="field-update">
                              <div className="label-update">
                                <span>{email}</span>
                              </div>
                              <div className="input-updateFeild">
                                <TextInput
                                  className={"form-control"}
                                  placeholder="abcd@gmail.com"
                                  name="email"
                                  onChange={handleChange("email")}
                                  value={values.email}
                                />
                              </div>
                              <div className="actual">
                                <button
                                  className="actual-btn-scre"
                                  type="submit"
                                  onClick={handleSubmit}
                                >
                                  {isSubmitting ? (
                                    <CircularProgress size={20} color="#fff" />
                                  ) : (
                                    upgrade
                                  )}
                                </button>
                              </div>
                            </div>

                            <div className="cancel-button">
                              <button
                                className="btn-cancel-scre"
                                onClick={closeEmailPopUpHandler}
                              >
                                {cancel}
                              </button>
                            </div>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </>
                )}
              </div>
              <div className="col">
                {editNum ? (
                  <>
                    <div className="editText d-inline-block">
                      <div className="label d-inline-block">
                        <label>{phoneNum}</label>
                      </div>
                      <div className="phone-textSave d-inline-block">
                        <span>{input.phone}</span>
                        {/* HINT: Its on hold Mobile Num Activation */}
                        {/* <div className="phone-status-img d-inline-block">
                          <img
                            style={{ height: "20px", width: "20px" }}
                            src={phoneVerification ? verified : unverified}
                            alt="UnVerified"
                          />
                        </div> */}
                      </div>
                    </div>
                    <div className="e-btn d-inline-block">
                      <button
                        className="edtBtn-secr"
                        onClick={closePhonePopUpHandler}
                      >
                        {edit}
                      </button>
                    </div>
                    {/* HINT: Its on hold Mobile Num Activation */}
                    {/* {phoneVerification ? null : (
                      <div className="col-phoneVerified">
                        <div className="phoneverified d-inline-block">
                          <span>{phoneVerify}</span>
                        </div>
                        <div className="phone-btn d-inline-block">
                          <button
                            className="phoneVerify-Btn-secr"
                            onClick={() => {
                              setMobileOtpPopUp(true);
                            }}
                          >
                            {otp}
                          </button>
                        </div>
                      </div>
                    )} */}
                  </>
                ) : (
                  <>
                    <Formik
                      enableReinitialize
                      validateOnChange={true}
                      validateOnBlur={true}
                      validationSchema={phoneNumberValidation}
                      initialValues={{
                        phone: input.phone,
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        onChangeUserInfo("", "", values.phone, setSubmitting);
                      }}
                    >
                      {({
                        handleSubmit,
                        values,
                        handleChange,
                        isSubmitting,
                      }) => (
                        <>
                          <Form autocomplete="new-password">
                            <div className="field-update">
                              <div className="label-update">
                                <span>{phoneNum}</span>
                              </div>
                              <div className="input-updateFeild">
                                <TextInput
                                  className="form-control"
                                  name="phone"
                                  onChange={handleChange("phone")}
                                  placeholder="0000000000"
                                  value={values.phone}
                                  maxlength={"10"}
                                />
                              </div>
                              <div className="actual">
                                <button
                                  type="submit"
                                  className="actual-btn-scre"
                                  onClick={handleSubmit}
                                >
                                  {isSubmitting ? (
                                    <CircularProgress size={20} color="#fff" />
                                  ) : (
                                    confirmar
                                  )}
                                </button>
                              </div>
                            </div>
                            <div className="cancel-button">
                              <button
                                className="btn-cancel-scre"
                                onClick={closePhonePopUpHandler}
                              >
                                {cancel}
                              </button>
                            </div>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </>
                )}
              </div>
              {changPass ? (
                <>
                  <div className="password-screen">
                    <div className="field-update">
                      <div className="">
                        <p className="chngPassText">
                          <span>{changPassword}</span>
                          <div>{itsGood}</div>
                        </p>
                      </div>
                      <div className="currentDiv">
                        <Formik
                          enableReinitialize
                          validateOnChange={true}
                          validateOnBlur={true}
                          initialValues={{
                            password: "",
                            newPassword: "",
                            cPassword: "",
                          }}
                          validationSchema={newPasswordValidate}
                          onSubmit={(values, { setSubmitting }) => {
                            onChangeUserPasword(
                              values.password,
                              values.cPassword,
                              setSubmitting
                            );
                          }}
                        >
                          {({ handleSubmit, isSubmitting }) => (
                            <>
                              <Form autocomplete="new-password">
                                <div className="password-field-scre">
                                  <div className="pw-Left">
                                    <div className="pw-label-update">
                                      <span>
                                        {current}
                                        {coln}
                                      </span>
                                    </div>
                                    <div className="pw-input">
                                      <TextInput
                                        // forgetPs
                                        className="inputText"
                                        name="password"
                                        type={showPass ? "text" : "password"}
                                        // labelName={Password}
                                        placeholder={enterOldpassword}
                                        labelClass="label"
                                        showEye={showPass}
                                        onEye={() => setShowPass(false)}
                                        onEyeOff={() => setShowPass(true)}
                                      />
                                    </div>
                                  </div>
                                  <div className="pw-Left">
                                    <div className="pw-label-update">
                                      <span>
                                        {newPass}
                                        {coln}
                                      </span>
                                    </div>
                                    <div className="pw-input">
                                      <TextInput
                                        // forgetPs
                                        className="inputText"
                                        name="newPassword"
                                        type={show ? "text" : "password"}
                                        placeholder={enterNewpassword}
                                        labelClass="label"
                                        showEye={show}
                                        onEye={() => setShow(false)}
                                        onEyeOff={() => setShow(true)}
                                      />
                                    </div>
                                  </div>
                                  <div className="pw-Left">
                                    <div className="pw-label-update">
                                      <span>
                                        {retypeNew}
                                        {coln}
                                      </span>
                                    </div>
                                    <div className="pw-input">
                                      <TextInput
                                        // forgetPs
                                        placeholder={enterConfirmpassword}
                                        className="inputText"
                                        name="cPassword"
                                        type={show2 ? "text" : "password"}
                                        showEye={show2}
                                        onEye={() => setShow2(false)}
                                        onEyeOff={() => setShow2(true)}
                                        colorEye="grey"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Form>

                              <div className="actual">
                                <button
                                  className="actual-btn-scre"
                                  onClick={handleSubmit}
                                >
                                  {isSubmitting ? (
                                    <CircularProgress size={20} color="#fff" />
                                  ) : (
                                    upgrade
                                  )}
                                </button>
                              </div>
                            </>
                          )}
                        </Formik>
                      </div>
                    </div>

                    <div className="pw-cancel-button">
                      <button
                        className="pw-btnCancel"
                        onClick={closePWPopUpHandler}
                      >
                        {cancel}
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col">
                  <>
                    <div className="editText d-inline-block">
                      <div className="label d-inline-block">
                        <label>{password}</label>
                      </div>
                      <div className="textSave d-inline-block">
                        <span>{"********"}</span>
                      </div>
                    </div>
                    <div className="e-btn d-inline-block">
                      <button
                        className="edtBtn-secr"
                        onClick={closePWPopUpHandler}
                      >
                        {edit}
                      </button>
                    </div>
                  </>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <CustomDialogs onClose={mobileOtpPopUp} open={mobileOtpPopUp}>
        <OtpModel
          otpInput={otpInput}
          setotpInput={setotpInput}
          phoneNumber={input.phone}
          setMobileOtpPopUp={setMobileOtpPopUp}
        />
      </CustomDialogs>
    </MiCuenta>
  );
};

const OtpModel = ({
  setMobileOtpPopUp,
  phoneNumber,
  otpInput,
  setotpInput,
}) => {
  return (
    <div
      className="otp-pop-up"
      onClick={() => {
        // setMobileOtpPopUp(false);
      }}
    >
      <div
        onClick={() => {
          setMobileOtpPopUp(false);
        }}
        className="otp-close-icon"
      >
        <div className="close-icon">
          <img src={closeIcon} />
        </div>
      </div>
      <div className="container otp">
        <div className="otp-content-text">
          <h4>{mobileverification}</h4>
          <p>
            {enterotp}
            <strong>
              <a href={`tel:${phoneNumber}`}>{` ${phoneNumber}`}</a>
            </strong>
          </p>
        </div>

        <OtpInput
          value={otpInput}
          onChange={(e) => {
            setotpInput(e);
          }}
          numInputs={5}
          inputStyle={"inputstyle"}
          containerStyle={"otp-digit-boxes"}
          separator={<span> </span>}
        />
        <div className="otpButton">
          <button
            onClick={() => {
              console.log(otpInput);
            }}
            className="otp-removeBtn"
          >
            {remove}
          </button>
          <div className="otp-content my-4">
            <span>
              {receivedCode}
              <a href="/">{forward}</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
