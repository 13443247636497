import React, { useState, useEffect, useContext } from "react";
import OrderBox from "../../../Component/orderBox";
import ProductBox from "../../../Component/productBox/productBox";
import MiCuenta from "../mi-cuenta";
import { Main_form } from "../../../Component/index";
import { alertMsg, openAlert } from "../../../Redux/reducers/alertSlice";
import {
  customStyles,
  itemInCart,
  orderItems,
  orders,
  noOrderInList,
} from "../../../common/constants";
import { GetAccountOrders, GetAccountOrderItems } from "../../../utils/api";
import "./style.scss";
import Modal from "react-modal";
import { product1, product2, yakima } from "../../../common/Images";
import { Collapse } from "@material-ui/core";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import SkeletonProductBox from "../../../Component/productBox/skeltonPBox";
import SkeletonOrderBox from "../../../Component/orderBox/skeletonOrder";
import ApplicationContext from "../../../context-api/context";

const Orders = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isProduct, setIsProduct] = useState(true);
  const dispatch = useDispatch();
  const [orderObject, setOrderObject] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [shippedOrders, setShippedOrders] = useState([]);
  const [confirmedOrders, setConfirmedOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [loading, setloading] = useState(false);
  const [statusId, setStatusId] = useState(0);
  const [orderDataResponse, setOrderDataResponse] = useState([]);
  const [orderItemsResponse, setOrderItemsResponse] = useState([]);
  const [problemDataJSon, setProblemDataJSon] = useState({
    saleID: "",
    folioNo: "",
    quantity: 0,
    saleItemID: 0,
    productID: 0,
    productName: "",
  });
  const [saleId, setSaleId] = useState("");
  const [order, setOrderOpen] = useState({
    place: 1,
    shipp: 2,
    deliver: 3,
    confirmed: 4,
    shift: 5,
  });
  const { setRegimenInvoiceList } = useContext(ApplicationContext);

  useEffect(() => {
    fetchOrderList(1, 1);
  }, []);

  //getOrders Api
  const fetchOrderList = async (filter, page) => {
    setOrderDataResponse([]);
    try {
      setloading(true);
      let result = await GetAccountOrders(filter, page);
      if (result && result.data.success) {
        let response = result?.data?.content.orders;
        let regimes = result?.data?.content.regimes;
        setRegimenInvoiceList(regimes);
        setOrderDataResponse(response);
        let pending = [],
          confirmed = [],
          shipped = [],
          delivered = [];
        response.map((e) => {
          if (e.statusID == 1) {
            pending.push(e);
          } else if (e.statusID == 2) {
            confirmed.push(e);
          } else if (e.statusID == 4) {
            shipped.push(e);
          } else if (e.statusID == 5) {
            delivered.push(e);
          }
        });
        setPendingOrders(pending);
        setShippedOrders(shipped);
        setConfirmedOrders(confirmed);
        setDeliveredOrders(delivered);
        setloading(false);
      }
    } catch (error) {
      alert(error);
    }
  };
  // async function getData() {
  //   await GetAccountOrders()
  //     .then((response) => {
  //       if (response.status === 200) {
  //         console.log("order object", response.data);
  //         const {
  //           data: { content },
  //         } = response;
  //         // setOrderObject(content);

  //         console.log("data fetched", orderObject);
  //       } else {
  //         dispatch(openAlert());
  //         dispatch(alertMsg(response.ExceptionMessage));
  //       }
  //     })
  //     .catch((error) => {
  //       dispatch(openAlert());
  //       dispatch(alertMsg(error));
  //       console.log("error fetching data", error);
  //     });
  // }

  const openModal = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = "unset";
  };
  return (
    <MiCuenta title={orders}>
      <div className="Ordermain">
        <div className="mainScreen">
          <h5>
            <label>Filtrado Por:</label>
          </h5>

          {orderDataResponse?.length === 0 && !loading ? (
            <div>
              <span className="message">{noOrderInList}</span>
            </div>
          ) : (
            <>
              <select
                name="taxregimen"
                className="custom-select-order custom-select custom-select-md mb-2"
                onChange={(e) => {
                  fetchOrderList(e.target.value, 1);
                  setloading(true);
                }}
              >
                <option value={1}>Mes Actual</option>
                <option value={2}>Mes Anterior</option>
                <option value={3}>Dos Meses Atras</option>
              </select>

              {orderDataResponse &&
                orderDataResponse.map((item) => {
                  return (
                    <OrderBox
                      saleID={item.saleID}
                      status={item.status}
                      statusID={item.statusID}
                      orderNo={item.folio}
                      orderBy={item.addressName}
                      orderDate={item.orderDate}
                      orderItems={item.orderItems}
                      orderTotal={item.orderTotal}
                      // onClick={() =>
                      //   setOrderOpen({ ...order, place: !order.place })
                      // }
                      // isCollapse={order.place}
                      placed
                      isProduct={isProduct}
                      url={item.url}
                      encodedId={item.encryptedProductID}
                      onOrderDetail={() => console.log("Orders details")}
                      onViewInvoice={() => console.log("invoice  details")}
                      onViewItem={(qty, itemID, productID, name) => {
                        openModal();
                        setProblemDataJSon({
                          saleID: item.saleID,
                          folioNo: item.folio,
                          quantity: qty,
                          productID: productID,
                          saleItemID: itemID,
                          productName: name,
                        });
                      }}
                    ></OrderBox>
                  );
                })}
            </>
          )}
          {loading && (
            <SkeletonOrderBox
              onClick={() => setOrderOpen({ ...order, place: !order.place })}
              placed
              isCollapse={order.place}
            >
              <SkeletonProductBox />
              <SkeletonProductBox />
              <SkeletonProductBox />
            </SkeletonOrderBox>
          )}
          {/* <SkeletonOrderBox
         onClick={()=>setOrderOpen({...order,place:!order.place})}
        placed
        isCollapse={order.place}
        >
           <SkeletonProductBox/>
           <SkeletonProductBox/>
           <SkeletonProductBox/>
          </SkeletonOrderBox> */}
          {/* {orderObject.map((item) => {
          const { saleID, addressID, addressName, status } = item;
          console.log("mapped", item.status);
          return (
            
             
          );
        })} */}
          {/* 
        {orderDataResponse.map((item) => {
          const {
            orderDate,
            orderTotal,
            orderItems,
            folio,
            statusID,
            status,
            items,
            addressName,
          } = item;
          return (
            <OrderBox
              status={status}
              statusID={statusID}
              orderNo={folio}
              orderBy={addressName}
              orderDate={orderDate}
              orderItems={orderItems}
              orderTotal={orderTotal}
              // onClick={() => setOrderOpen({ ...order, place: !order.place })}
              isCollapse={order.place}
              placed
            >
              {items.map((item) => {
                const {
                  brand,
                  productName,
                  partNo,
                  unitPrice,
                  quantity,
                  brandImage,
                  total,
                } = item;

                return (
                  <ProductBox
                    statusId={statusID}
                    Name={productName}
                    itemId={partNo}
                    Img={product2}
                    brandImg={brandImage}
                    quantity={quantity}
                    itemPrice={unitPrice}
                    totalItemPrice={total}
                    onViewItem={() => openModal()}
                  />
                );
              })}
            </OrderBox>
          );
        })} */}
        </div>
      </div>
      {
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          overlayClassName="overlayStyles"
          style={customStyles}
        >
          <Main_form
            formCloses={setIsOpen}
            data={problemDataJSon}
            modeClose={() => closeModal()}
            fetchOrderList={fetchOrderList}
          />
        </Modal>
      }
    </MiCuenta>
  );
};

export default Orders;
