import React, { useState, useEffect, useContext } from "react";
import * as service from "../../../common/services";
import {
  GetUserInvoices,
  AddUpdateUserInvoices,
  DeleteUserInvoice,
} from "../../../utils/api";
import ApplicationContext from "../../../context-api/context";
const useForm = () => {
  const [checked, setChecked] = useState(null);
  const [close, setClose] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [add, setAdd] = useState(false);
  const [records, setRecords] = useState(service.getAllData());
  const [orderDataResponse, setOrderDataResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {}, [records]);
  const { setRegimenInvoiceList, recordForEdit, setRecordForEdit } =
    useContext(ApplicationContext);

  const addOrEdit = (values) => {
    if (checked) {
      return (
        setRecordForEdit(),
        service.updateData(values),
        setRecords(service.getAllData()),
        setClose(false)
      );
    } else {
      return (
        setAdd(false),
        service.insertData(values),
        setRecords(service.getAllData())
      );
    }
  };

  // useEffect(() => {
  //   getUserInvoiceData();
  // }, [records]);
  useEffect(() => {
    setLoading(true);
    getUserInvoiceData();
  }, []);

  const getUserInvoiceData = async () => {
    setRecordForEdit([]);
    try {
      let result = await GetUserInvoices();

      if (result && result.data.success) {
        let response = result.data.content;

        const arr = response.invoices;
        let newArr = arr.map((obj, ind) => ({
          ...obj,
          isOpened: false,
        }));

        const res = {
          invoices: newArr,
          // taxRegimes:response.taxRegimes,
        };
        setRecordForEdit(res);
        setRegimenInvoiceList(response.taxRegimes);
        // setRecords(response)
        setLoading(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  const editItem = async () => {};

  const handleDelete = async (id, showToast) => {
    setOpenModal(false);
    const res = await DeleteUserInvoice(id);
    if (res && res.data.success) {
      showToast(res.data.description);
      let tempInvoiceList = [...recordForEdit.invoices];
      let index = tempInvoiceList.findIndex((e) => e.invoicingID == id);
      if (index !== -1) {
        tempInvoiceList.splice(index, 1);
        setRecordForEdit({ invoices: tempInvoiceList });
      }
    }
  };

  return {
    checked,
    setChecked,
    close,
    add,
    setAdd,
    records,
    setRecords,
    recordForEdit,
    setRecordForEdit,
    addOrEdit,
    editItem,
    handleDelete,
    openModal,
    setOpenModal,
    orderDataResponse,
    setOrderDataResponse,
    loading,
    setLoading,
  };
};

export default useForm;
