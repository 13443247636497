import React from "react";
import { nextIcon, previousIcon } from "../../common/Images";
import "./Slider.scss";
import { Next, prev } from "../../common/constants";
import LazyBackground from "../imgLoader/lazyBackground";
import { Skeleton } from "@material-ui/lab";
// import "../../../node_modules/jquery/dist/jquery.min";

function MainSlider(props) {
  const redirect = (url) => {
    if (url != "") {
      window.location.href = url;
    }
  };

  return (
    <>
      <section className="sliderWrapper">
        <div id="slider" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            {props.array.length != 0 ? (
              <>
                {props.array.map((i, index) => {
                  return (
                    <div
                      key={index}
                      active={index === 0}
                      className={
                        index === 0
                          ? " carousel-item active crosel"
                          : "carousel-item crosel"
                      }
                      onClick={() => redirect(i.url)}
                      style={{ cursor: "pointer" }}
                    >
                      <LazyBackground
                        src={i.bannerImage}
                        circularStyle="sekeltonStyles"
                        className="d-block w-100 bannerImage"
                        skeltonStyle="d-block w-100 bannerImage sekeltonStyles"
                      >
                        <div className="overlay">
                          <div className="inner">
                            <h1>{i.title}</h1>
                            <h2 className="textMsg">{i.textMessage}</h2>
                          </div>
                        </div>
                      </LazyBackground>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {[1, 2, 3].map((i, index) => {
                  return (
                    <div
                      key={index}
                      active={index === 0}
                      className={
                        index === 0
                          ? " carousel-item active crosel"
                          : "carousel-item crosel"
                      }
                    >
                      <Skeleton
                        animation="wave"
                        height={320}
                        className="d-block w-100 bannerImage sekeltonStyles"
                      />
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <a
            className="carousel-control-prev"
            href="#slider"
            role="button"
            data-slide="prev"
          >
            <span>
              <img src={previousIcon} />
            </span>
            <span className="sr-only">{prev}</span>
          </a>
          <a
            className="carousel-control-next"
            href="#slider"
            role="button"
            data-slide="next"
          >
            <span>
              <img src={nextIcon} />
            </span>
            <span className="sr-only">{Next}</span>
          </a>
        </div>
      </section>
    </>
  );
}
export default MainSlider;
