import React from "react";
import { yourName } from "../../common/constant/formConstants";
import { first } from "../../common/constants";
import "../../Pages/pro-description/style.scss";

export const renderAtterDetail = (item, index) => {
  return (
    <tr key={index}>
      {item.attributeName != "" ? <td>{item.attributeName}</td> : <td>N/A</td>}{" "}
      {item.attributeValue != "" ? (
        <td>{item.attributeValue}</td>
      ) : (
        <td>N/A</td>
      )}
    </tr>
  );
};
export const renderNoneDetail = (item, index) => {
  return (
    <tr key={index}>
      <td>N/A</td>
      <td>N/A</td>
    </tr>
  );
};
const AttributeTable = ({ children }) => {
  return (
    <>
      <table class="table table-bordered table-striped tablePopup">
        <thead>
          <tr>
            <th scope="col" className="name">
              {yourName}
            </th>

            <th scope="col">{first}</th>
          </tr>
        </thead>

        <tbody>{children}</tbody>
      </table>
    </>
  );
};

export default AttributeTable;
