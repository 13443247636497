export function removeRating(arr, num) {
    return arr.filter((el) => parseInt(el) !== num);
  }
  export function removeBrandItem(arr, num) {
    return arr.filter((el) => el !== num);
  }
  export function removeDynamicBrandItem(arr, num) {
    return arr.filter((el) => el.value !== num);
  }
  export function removeMinPrice(arr, num) {
    return arr.filter((el) => parseInt(el.min) !== num);
  }
  export function removeMaxPrice(arr, num) {
    return arr.filter((el) => parseInt(el.max) !== num);
  }