import { IconButton, Slide } from "@material-ui/core";
import React, { useContext, useState } from "react";
import {
  apply,
  applyDiscount,
  discount,
  enterCouponCode,
  itemReturnable,
  MispartesCom,
  orderRealizer,
  product,
  secureTransaction,
  shippingCost,
  summary,
  Total,
} from "../../common/constants";
import { addBadge, removeBadge } from "../../Redux/reducers/filterSlice";
import Badges from "../../Sections/sorted-products/badge";
import "./style.scss";
import { ReactComponent as OrderIcon } from "../../images/order.svg";
import { ReactComponent as ReturnIcon } from "../../images/return.svg";
import { ReactComponent as SecureIcon } from "../../images/secure.svg";
import { Skeleton } from "@material-ui/lab";

import {
  alertMsg,
  openAlert,
} from "../../Redux/reducers/alertSlice";
const SkeletonPaymentSummary = (props) => {


  return (
    <div className="summaryWrapper">
      <h3 className="summaryHeading">{summary}</h3>
      <div className="summmaryBody">
        <div className="priceSummary">
          <div className="detail">
            <span className="key">
              <Skeleton height={12} width={"250px"} />
            </span>
            <span className="value"><Skeleton height={12} width={"45px"} /></span>
          </div>
          <div className="detail">
            <span className="key"> <Skeleton height={12} width={"250px"} /></span>
            <span className="value"><Skeleton height={12} width={"45px"} /></span>
          </div>
          <div className="detail">
            <span className="key"><Skeleton height={12} width={"250px"} /></span>
            <span className="value"><Skeleton height={12} width={"45px"} /></span>
          </div>
          <div className="total">
            <span className="key"><Skeleton height={12} width={"250px"} /></span>
            <span className="value"><Skeleton height={12} width={"45px"} /></span>
          </div>
          <div className="couponArea">
            <h3 className="couponAreaHaeding">{applyDiscount}</h3>
            <div className="inputArea">
              <Skeleton height={12} width={"100%"} />
            </div>
            <div className="tagsOuter">
             <Skeleton height={12} width={"45px"} />
            </div>
          </div>
          <div className="order-Descript">
            <p>
              <span>
                <SecureIcon />
              </span>
              <span>{secureTransaction}</span>
            </p>
           
          </div>
        </div>

        <Skeleton height={40} />


      </div>
    </div>
  );
};

export default SkeletonPaymentSummary;
