import React from "react";
import MainLayout from "../../Component/layout/layout";
import MainHeader from "../../Sections/header";
import MetaTitle from "../../Component/metaTag";
import { metaPath } from "../../common/services";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { path } from "../../common/routes-names";

import { error, mispartes } from "../../common/constants";
import "./style.scss";

import done from "../../images/complete.jpeg";
import { Link } from "react-router-dom";

const OrderConfirmation = ({
  history: {
    location: {
      state: { paymentTypeId, responseObject, description } = {},
    } = {},
  },
}) => {
  const {
    externalURL = "",
    message = "",
    folio = false,
  } = responseObject && responseObject;
  const history = useHistory();

  console.log("Data : ", description);

  return (
    <div>
      <MetaTitle title={metaPath()} />
      <MainHeader
        otherIconOnHeader={true}
        isMenu={true}
        logoBig={true}
        isCartIcon={true}
        isFavIcon={true}
        isStickyCheckoutIcon
      />

      <div className="downContainer">
        <img src={done} width={170} alt="Order Confirmation" />
        <div className="OrderDtails">
          <p className="numOrder">¡Gracias por tu compra!</p>
          {folio ? (
            <p className="orderConfirm">
              <b> Folio: </b> {folio}
            </p>
          ) : (
            ""
          )}
          {[1].includes(paymentTypeId) && (
            <>
              <p>{message || ""}</p>
            </>
          )}
          {[2, 3].includes(paymentTypeId) && (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: `<p>${message.replace(
                    `{voucherLink}`,
                    `<a target="_blank" href="${externalURL}">Aqui</a>`
                  )}</p>`,
                }}
              ></div>
            </>
          )}

          {[4].includes(paymentTypeId) && (
            <p className="details">
              {message}
              {/* sabemos que está emocionado de obtener su producto, así que esto
              es lo que puede esperar: uno de nosotros recibirá su producto y se
              lo enviará en las próximas 24 horas Una vez que se envíe, recibirá
              un correo electrónico de confirmación con su el número de rastreo.
              Gracias por comprar con MisPartes, se lo agradecemos. solo
              queremos compartir el amor! */}
            </p>
          )}
        </div>
        <div className="btnContainer">
          <button className="continue" onClick={() => history.push(path.home)}>
            seguir comprando
          </button>
        </div>
      </div>
      <MainLayout metaTitle={`${mispartes}`} errorHeading={error} />
    </div>
  );
};

export default OrderConfirmation;
