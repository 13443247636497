import React, { useEffect } from 'react';
import { useHistory } from "react-router";
import {getTheCountUpdated} from './../../utils/api'
import { path } from "./../../common/routes-names";
import { welcomeText } from "./../../common/constants";
import { CircularProgress } from "@material-ui/core";
import './styles.scss';

const WelcomePage = ({}) => {

    let history = useHistory();

    useEffect(() => {
        getTheCountUpdatedOnBackend();
    },[])

    const getTheCountUpdatedOnBackend = async () => {
        await getTheCountUpdated();
        history.replace(path.home);
    }

    return (
        <div className='welcomeContainer'>
        <CircularProgress size={50} color="secondary" /> 
            <text className='welcomeText'>{welcomeText}</text>
        </div>
    )
}

export default WelcomePage;