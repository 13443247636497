import React, { useContext } from "react";
import { Footer } from "..";
import { genericError } from "../../common/constants";
import ApplicationContext from "../../context-api/context";
import AlertBox from "../confirm-alert/alertBox";
import MetaTitle from "../metaTag";

const MainLayout = (props) => {
  const { openAlert, setOpenAlert } = useContext(ApplicationContext);
  return (
    <>
      <MetaTitle title={props.metaTitle} />
      {props.children}
      <Footer />
      <AlertBox
        del={false}
        onCancel={() => setOpenAlert(false)}
        heading={props.errorHeading || "Error"}
        text={props.errorText || genericError}
        onClose={() => setOpenAlert(false)}
        open={openAlert}
      />
    </>
  );
};

export default MainLayout;
